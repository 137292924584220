import { Select, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { readRolesAction } from '../../redux-store/roles-store';
import settings from '../../settings';
import useMany from '../../shared/use-many';
import { allRolesQuery } from './constants';

const { Text } = Typography;

function SelectRole({ disabled, value, onChange, filters, ...moreProps }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: roles,
    refetch,
  } = useMany(
    allRolesQuery,
    (data) => data.allRoles,
    readRolesAction,
    'roles',
    ['name', 'desc', 'permissions'],
    settings.querySize,
    filters,
    (a, b) => a.name.localeCompare(b.name, locale),
  );
  const handleChange = useCallback(
    (_value, option) => {
      onChange(_value);
    },
    [onChange],
  );
  return (
    <Select
      disabled={disabled}
      onChange={handleChange}
      placeholder="Select a role"
      value={value}
      {...moreProps}
    >
      {loading && (
        <Select.Option value="__loading__">
          <Spin spinning={loading} style={{ margin: 8 }} />
        </Select.Option>
      )}
      {!loading && error && (
        <Select.Option value="__error__">
          <Text type="danger">{error.message}</Text>
        </Select.Option>
      )}
      {!loading &&
        roles.map((role) => (
          <Select.Option key={role._id} value={role._id} title={role.name}>
            <div className="role-option">
              <div>
                <Text>{role.name}</Text>
              </div>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectRole;
