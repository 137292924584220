import { gql, useSubscription } from '@apollo/client';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';

const Commands = {
  play_sound: {
    key: 'play_sound',
    label: 'Play Sound',
  },
  weather: {
    key: 'weather',
    label: 'Weather',
  },
  config: {
    key: 'config',
    label: 'Configuration',
  },
  reg_code: {
    key: 'reg_code',
    label: 'Registration Code',
  },
  gps_fix: {
    key: 'gps_fix',
    label: 'Acquire Gps Fix',
  },
  gps_on: {
    key: 'gps_on',
    label: 'Turn GPS On',
  },
  gps_off: {
    key: 'gps_off',
    label: 'Turn GPS off',
  },
  hb: {
    key: 'hb',
    label: 'Request Heartbeat',
  },
  quick_hb: {
    key: 'quick_hb',
    label: 'Request Quick Heartbeat',
  },
  reg: {
    key: 'reg',
    label: 'Register',
  },
  unreg: {
    key: 'unreg',
    label: 'Unregister',
  },
  ack_fall: {
    key: 'ack_fall',
    label: 'Acknowledge Fall',
  },
  loc: {
    key: 'loc',
    label: 'Location',
  },
  app_update: {
    key: 'app_update',
    label: 'Update Application',
  },
  latest_fw: {
    key: 'latest_fw',
    label: 'Latest Firmware',
  },
  fw_update: {
    key: 'fw_update',
    label: 'Update Firmware',
  },
  send_logs: {
    key: 'send_logs',
    label: 'Send Logs',
  },
  turbo_on: {
    key: 'turbo_on',
    label: 'Turn Turbo Mode On',
  },
  turbo_off: {
    key: 'turbo_off',
    label: 'Turn Turbo Mode Off',
  },
  quick_connect: {
    key: 'quick_connect',
    label: 'Quick Connect',
  },
  switch_env: {
    key: 'switch_env',
    label: 'Switch Environment',
  },
  reboot: {
    key: 'reboot',
    label: 'Reboot',
  },
};

export const AcknowledgementAttributes = gql`
  {
    _id
    event
    imei
    timestamp
    ackedCmd
    ackedTimestamp
    extra
    nackedCmd
    nackedTimestamp
    reason
  }
`;

export const cmdAcksSubscription = gql`
  subscription CmdAcks($deviceId: ID!) {
    cmdAcks(deviceId: $deviceId) ${AcknowledgementAttributes}
  }
`;

function useCmdAcks(deviceId) {
  const queryId = deviceId || 'default';
  useSubscription(cmdAcksSubscription, {
    skip: !deviceId,
    variables: {
      deviceId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const ack = result?.data?.cmdAcks;
        if (ack) {
          if (ack.event == 'ack') {
            notification.success({
              message: 'Acknowledgement',
              description: `Device ${ack.imei} received the command ${
                Commands[ack.ackedCmd]?.label
              }`,
            });
          } else {
            notification.error({
              message: 'Acknowledgement',
              description: `Device ${ack.imei} received the command ${
                Commands[ack.nackedCmd]?.label
              } but responded with the error ${ack.reason}`,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useCmdAcks;
