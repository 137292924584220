import { ApolloProvider } from '@apollo/client';
import { Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './apollo-client';
import AppLoading from './components/AppLoading';
import MainRouter from './pages/main/MainRouter';
import AuthRouter from './pages/auth/AuthRouter';
import { AuthContextProvider } from './shared/auth-context';
import { useWindowDimensions } from './shared/window-dimensions';

const { Text } = Typography;

const App = () => {
  useWindowDimensions();
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <AuthContextProvider>
      {(context) => (
        <>
          {context.state.isLoading ? (
            <AppLoading />
          ) : (
            <ApolloProvider client={client}>
              <Router>
                {context.state.user ? <MainRouter /> : <AuthRouter />}
              </Router>
            </ApolloProvider>
          )}
        </>
      )}
    </AuthContextProvider>
  );
};

export default App;
