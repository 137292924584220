import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';
import { shallowEqual, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from '../../../shared/autocomplete';

export const DayFilters = {
  ALL: {
    key: 'ALL',
    label: 'Show All Devices',
    moreThanHours: 0,
    lessThanHours: 0,
  },
  HOUR: {
    key: 'HOUR',
    label: 'Show devices updated within last hour',
    moreThanHours: 0,
    lessThanHours: 1,
  },
  HOUR_6: {
    key: 'HOUR_6',
    label: 'Show devices updated within last 6 hours',
    moreThanHours: 0,
    lessThanHours: 6,
  },
  ONE: {
    key: 'ONE',
    label: 'Show devices updated within last 24 hours',
    moreThanHours: 0,
    lessThanHours: 24,
  },
  TWO: {
    key: 'TWO',
    label: 'Show devices updated within last 2 days',
    moreThanHours: 0,
    lessThanHours: 48,
  },
  THREE: {
    key: 'THREE',
    label: 'Show devices updated within last 3 days',
    moreThanHours: 0,
    lessThanHours: 72,
  },
  WEEK: {
    key: 'WEEK',
    label: 'Show devices updated within last 7 days',
    moreThanHours: 0,
    lessThanHours: 168,
  },
  MONTH: {
    key: 'MONTH',
    label: 'Show devices updated within last 30 days',
    moreThanHours: 0,
    lessThanHours: 720,
  },
  MORE: {
    key: 'MORE',
    label: 'Show more options',
    moreThanHours: 0,
    lessThanHours: 0,
  },
};

const searchOptions = {
  includeScore: true,
  keys: [
    'device.ownerName',
    'device.wearerName',
    'device._id',
    'device.msisdn',
    'device.callCenterId',
  ],
};

function defaultSort(a, b) {
  if (a?.now?.latestLocationTimestampMs && b?.now?.latestLocationTimestampMs) {
    return b.now.latestLocationTimestampMs - a.now.latestLocationTimestampMs;
  }
  if (a?.now?.latestLocationTimestampMs) {
    return -1;
  }
  if (b?.now?.latestLocationTimestampMs) {
    return 1;
  }
  return 0;
}

function useFilterMapDevices({ tenantId, filters, sortBy }) {
  const queryId = tenantId || 'default';
  const nows = useSelector((store) => store.joonNows[queryId], shallowEqual);
  const devices = useSelector(
    (store) => store.joonDevices[queryId],
    shallowEqual,
  );

  const term$ = useRef(null);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    term$.current = new BehaviorSubject('');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          setSearchTerm(term);
          return of();
        }),
      )
      .subscribe();
  }, []);

  const search = useCallback((term) => {
    if (term !== undefined) {
      term$.current.next(term);
    }
  }, []);

  const jsonFilters = JSON.stringify(filters);
  const [unfiltered, filtered] = useMemo(() => {
    let _unfiltered = Object.values(devices || {})
      .filter((d) => d)
      .filter((d) => d.tenantId === tenantId)
      .map((device) => {
        const now = (nows || {})[device._id];
        return {
          _id: device._id,
          device,
          now,
        };
      });
    let _filtered = _unfiltered.map((d) => d);
    let lessThanHours;
    let moreThanHours;
    if (filters?.daysFilter === 'MORE') {
      lessThanHours = filters.lessThanHours;
      moreThanHours = filters.moreThanHours;
    } else {
      lessThanHours = DayFilters[filters?.daysFilter]?.lessThanHours;
      moreThanHours = DayFilters[filters?.daysFilter]?.moreThanHours;
    }
    if (lessThanHours) {
      _filtered = _filtered.filter((b, index) => {
        return b.now && b.now.latestLocationTimestampMs !== undefined
          ? new Date().getTime() - b.now.latestLocationTimestampMs <
              lessThanHours * 3600000
          : false;
      });
    }
    if (moreThanHours || moreThanHours === 0) {
      _filtered = _filtered.filter((b, index) => {
        return b.now && b.now.latestLocationTimestampMs !== undefined
          ? new Date().getTime() - b.now.latestLocationTimestampMs >
              moreThanHours * 3600000
          : true;
      });
    }
    if (searchTerm) {
      const fuse = new Fuse(_filtered, searchOptions);
      _filtered = fuse
        .search(searchTerm)
        .map((a) => {
          return a;
        })
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
    }
    const sorter = sortBy || defaultSort;
    return [_unfiltered.sort(sorter), _filtered.sort(sorter)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonFilters, nows, devices, sortBy, searchTerm]);
  return { unfiltered, filtered, search };
}

export default useFilterMapDevices;
