import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Space,
  Button,
  notification,
} from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateJoonDatasetAction } from '../../../../redux-store/joon-datasets-store';
import settings from '../../../../settings';
import { updateJoonDatasetMutation } from '../../constants';

const { Title, Text } = Typography;

function EditJoonDatasetForm({ handleSave, loading, joonDataset, handleBack }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (joonDataset) {
      form.setFieldsValue({
        ...joonDataset,
        tags: joonDataset.tags || [],
      });
    }
  }, [joonDataset, form]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSave}
      id="editJoonDatabase"
      form={form}
    >
      <Form.Item
        label="Dataset Name"
        name="name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Device IMEI" name="deviceId">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Type to create a tag"
          disabled={loading}
        />
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <Input.TextArea disabled={loading} rows={4} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleBack}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={loading} htmlType="submit">
            Save Theora Connect Database
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

function EditJoonDatasetModal({ visible, onFinish, onCancel }) {
  const { joonDataset, queryId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateJoonDataset] = useMutation(updateJoonDatasetMutation);

  const dispatch = useDispatch();
  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { ...values } = _values;
      try {
        if (joonDataset) {
          const result = await updateJoonDataset({
            variables: {
              joonDataset: {
                _id: joonDataset._id,
                ...values,
              },
            },
          });
          dispatch(
            updateJoonDatasetAction(
              result.data.updateJoonDataset.joonDataset,
              queryId,
            ),
          );
          notification.success({
            message: 'Saved',
            description: 'Dataset updated successfully',
          });
        }
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDataset, updateJoonDataset, dispatch, onFinish, queryId],
  );
  const title = 'Edit Dataset';
  const subtitle = joonDataset && joonDataset.name;
  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 32,
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          {title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text style={{ color: settings.colors.textGray, fontSize: 14 }}>
            {subtitle}
          </Text>
        </div>
        {visible && (
          <EditJoonDatasetForm
            handleSave={handleSave}
            loading={loading}
            joonDataset={joonDataset}
            handleBack={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{`
        .errors {
          text-align: center;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditJoonDatasetModal;
