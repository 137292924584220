import { Modal, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';
import useSafeState from '../../../shared/use-safe-state';

const { Text } = Typography;

function LoadingModal({ loading }) {
  const [percent, setPercent, _percent] = useSafeState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((_percent.current + 1) % 100);
    }, 250);
    return () => clearInterval(interval);
  }, [_percent, setPercent]);
  return (
    <Modal
      open={loading}
      footer={null}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 32,
        paddingBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
      }}
      width={300}
      closable={false}
    >
      <Progress type="circle" percent={percent} />
      <div style={{ marginTop: 16 }}>
        <Text style={{ fontSize: 18 }}>Loading Report Info ...</Text>
      </div>
    </Modal>
  );
}

export default LoadingModal;
