import { gql } from '@apollo/client';

export const RoleListAttributes = gql`
  {
    _id
    name
    desc
    permissions
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const RoleDetailedAttributes = gql`
  {
    _id
    name
    desc
    permissions
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const roleQuery = gql`
  query Role($_id: ID!) {
    role(_id: $_id) ${RoleDetailedAttributes}
  }
`;

export const allRolesQuery = gql`
  query AllRoles($first: Int, $after: String, $filters: RoleFilters, $sortBy: [SortBy]) {
    allRoles(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${RoleListAttributes}
      }
    }
  }
`;

export const createRoleMutation = gql`
  mutation createRole($role: RoleCreateInput!) {
    createRole(role: $role) {
      role ${RoleDetailedAttributes}
    }
  }
`;

export const updateRoleMutation = gql`
  mutation updateRole($role: RoleUpdateInput!) {
    updateRole(role: $role) {
      role ${RoleDetailedAttributes}
    }
  }
`;
