import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { generateUuid } from '../../../../shared/utils';
import SelectUser from '../../../users/SelectUser';
import { DeviceUserRoles } from '../../constants';
import { UserXJoon3Attributes } from './constants';

const { Title, Text, Paragraph } = Typography;

export const updateUserXJoon3Mutation = gql`
  mutation UpdateUserXJoon3($userXJoon3: UserXJoon3UpdateInput!) {
    updateUserXJoon3(userXJoon3: $userXJoon3) {
      userXJoon3 ${UserXJoon3Attributes}
    }
  }
`;

export const createUserXJoon3Mutation = gql`
  mutation CreateUserXJoon3($userXJoon3: UserXJoon3CreateInput!) {
    createUserXJoon3(userXJoon3: $userXJoon3)  {
      userXJoon3 ${UserXJoon3Attributes}
    }
  }
`;

const AddDeviceUserModal = ({ visible, onSuccess, onCancel, queryId }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { joonDevice, deviceUser } = visible || {};

  useEffect(() => {
    setLoading(false);
    form.resetFields();
  }, [visible, form]);

  useEffect(() => {
    if (deviceUser) {
      form.setFieldsValue({
        role: deviceUser.role,
        relationToWearer: deviceUser.relationToWearer,
        nickName: deviceUser.nickName,
        userId: deviceUser.userId,
      });
    } else {
      form.setFieldsValue({
        role: undefined,
        relationToWearer: undefined,
        nickName: undefined,
        userId: undefined,
      });
    }
  }, [deviceUser, form]);

  const [createUserXJoon3] = useMutation(createUserXJoon3Mutation);
  const [updateUserXJoon3] = useMutation(updateUserXJoon3Mutation);

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (deviceUser) {
          await updateUserXJoon3({
            variables: {
              userXJoon3: {
                _id: deviceUser._id,
                ...values,
              },
            },
          });
          notification.success({
            message: 'Success',
            description: 'Device user updated',
          });
        } else {
          await createUserXJoon3({
            variables: {
              userXJoon3: {
                _id: generateUuid(),
                deviceId: joonDevice._id,
                ...values,
              },
            },
          });
          notification.success({
            message: 'Success',
            description: 'Device user added',
          });
        }
        if (onSuccess) onSuccess();
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving device user',
        });
      }
      setLoading(false);
    },
    [createUserXJoon3, updateUserXJoon3, joonDevice, onSuccess, deviceUser],
  );

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          {deviceUser ? 'Edit Device User' : 'Add Device User'}
        </Title>
        <Form onFinish={handleFinish} layout="vertical" form={form}>
          {deviceUser ? (
            <div style={{ textAlign: 'center', fontSize: 14 }}>
              <Paragraph>
                <Text style={{ fontWeight: 600 }}>User</Text>
                <br />
                {deviceUser.user && deviceUser.user.name}
              </Paragraph>
            </div>
          ) : (
            <Form.Item
              label="User"
              name="userId"
              rules={[{ required: true, message: 'This field is required' }]}
              extra="Must be an existing user"
            >
              <SelectUser disabled={loading} />
            </Form.Item>
          )}

          {deviceUser ? (
            <div style={{ textAlign: 'center', fontSize: 14 }}>
              <Paragraph>
                <Text style={{ fontWeight: 600 }}>Role</Text>
                <br />
                {DeviceUserRoles[deviceUser.role] &&
                  DeviceUserRoles[deviceUser.role].label}
              </Paragraph>
            </div>
          ) : (
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                autoComplete="chrome-off"
                disabled={loading}
                onSearch={(search) => {
                  userSearch(search);
                }}
                placeholder="Select a role"
              >
                {Object.values(DeviceUserRoles)
                  .filter((r) => r.key !== DeviceUserRoles.OWNER.key)
                  .map((role) => (
                    <Select.Option
                      key={role.key}
                      value={role.key}
                      title={role.label}
                    >
                      <Text>{`${role.label}`}</Text>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.role !== curValues.role
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('role') !== DeviceUserRoles.WEARER.key && (
                <Form.Item
                  label="Relation to Wearer"
                  name="relationToWearer"
                  extra="e.g. Son, Daughter, Brother"
                >
                  <Input disabled={loading} />
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item
            label="Device Name for This User"
            name="nickName"
            extra="Will be displayed on the owner's mobile app, e.g. Mom, Dad, My Watch"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={onCancel}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Save Device User
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default AddDeviceUserModal;
