import { shallowEqual, useSelector } from 'react-redux';

function useFilterJoon3Config(configId) {
  const queryId = configId || 'default';
  const data = useSelector(
    (store) => store.joon3Configs[queryId]?.[configId],
    shallowEqual,
  );
  return data;
}

export default useFilterJoon3Config;
