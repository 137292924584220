import { parsePhoneNumber } from 'awesome-phonenumber';
import stableStringify from 'fast-json-stable-stringify';
import humanizeDuration from 'humanize-duration';
import { Subject } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, tap } from 'rxjs/operators';
import short from 'short-uuid';

export function generateUuid() {
  const translator = short();
  return translator.new();
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const titleCase = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .split(/\s/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hashCode = (s) =>
  Array.from(s).reduce((prev, curr) => {
    const hash = (prev << 5) - prev + curr.charCodeAt(0);
    return hash & hash;
  }, 0);

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export function compareObjects(a, b) {
  return stableStringify(a) === stableStringify(b);
}

export function doFileUpload({ url, fields, file, onProgress }) {
  const progressSubscriber = new Subject();
  const body = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    body.append(k, v);
  });
  body.append('Content-Type', file.type);
  body.append('Content-Disposition', `attachment; filename="${file.name}"`);
  body.append('file', file);
  const request$ = ajax({
    url,
    method: 'post',
    body,
    progressSubscriber,
  });
  progressSubscriber.subscribe((event) => onProgress(event));
  return request$
    .pipe(
      tap((response) => console.log(response)),
      map((response) => {
        return {
          ...response,
          text: async () => JSON.stringify(response.response),
        };
      }),
    )
    .toPromise();
}

export function zeroPad(num, length) {
  length = length || num.length; // defaults to 2 if no parameter is passed
  return (new Array(length).join('0') + num).slice(length * -1);
}

export function formatPhoneNumber(n) {
  const m = n && n.trim();
  if (m) {
    let standardPhone;
    if (!m.startsWith('+') && m.length === 10) {
      standardPhone = `+1${m}`;
    } else {
      standardPhone = m;
    }
    return standardPhone;
  }
  return null;
}

export function getFormattedPhoneNumber(joonDevice) {
  if (joonDevice.msisdn) {
    const parsed = parsePhoneNumber(joonDevice.msisdn, {
      regionCode: 'US',
    });
    return parsed && parsed.number && parsed.number.international;
  }
  return null;
}

export function formatPhoneNumberForDisplay(ph) {
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.international;
  }
  return null;
}

export function formatPhoneNumberForStorage(ph) {
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.e164;
  }
  return null;
}

export function formatAddress(address) {
  return [
    address?.address1,
    address?.address2,
    [[address?.city, address?.state].join(', '), address?.zipCode]
      .filter((a) => a)
      .join(' '),
    address?.country,
  ]
    .filter((a) => a)
    .join('\n');
}

export default function removeTypename(obj) {
  const { __typename, ...attrs } = obj;
  return attrs;
}

export function notNullOrUndefined(attr) {
  return attr !== undefined && attr !== null;
}
