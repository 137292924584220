import { useQuery, gql, useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { notNullOrUndefined } from '../pages/joonDevices/ViewJoonDevice/settings-v2/utils';

export default function useOne(
  query,
  extract,
  updateAction,
  tableName,
  _id,
  queryId = 'default',
  updateCallback = undefined,
  fetchPolicy = 'no-cache',
) {
  const last = useRef();
  const client = useApolloClient();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsync() {
      setError(undefined);
      setLoading(true);
      try {
        const resp = await client.query({
          query,
          variables: {
            _id,
          },
          fetchPolicy,
        });
        const result = extract(resp.data);
        if (result) {
          if (updateAction) {
            dispatch(updateAction(result, queryId));
          }
          if (updateCallback) {
            updateCallback(result, queryId);
          }
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
      setLoading(false);
    }
    const inputs = JSON.stringify({
      query,
      extract,
      updateAction,
      tableName,
      _id,
      queryId,
      updateCallback,
    });
    if (notNullOrUndefined(_id) && inputs != last.current) {
      last.current = inputs;
      doAsync();
    }
  }, [
    client,
    _id,
    query,
    tableName,
    queryId,
    extract,
    updateAction,
    updateCallback,
    dispatch,
  ]);

  const fromStore = useSelector(
    (store) =>
      store[tableName] &&
      store[tableName][queryId] &&
      store[tableName][queryId][_id],
    shallowEqual,
  );

  return {
    data: fromStore,
    loading,
    error,
  };
}
