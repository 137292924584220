import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteTenantAction } from '../../redux-store/tenants-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { deleteTenantMutation } from './constants';

function DeleteTenantModal({ tenant, setTenant, queryId = 'default' }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteTenant] = useMutation(deleteTenantMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteTenant({
        variables: {
          _id: tenant._id,
        },
      });
      dispatch(deleteTenantAction(tenant._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Tenant deleted successfully',
      });
      setTimeout(() => {
        setTenant(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [tenant, deleteTenant, setTenant, dispatch, history, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      setTenant(null);
    }
  }, [setTenant, loading]);

  return (
    <YesNoModal
      title="Delete Tenant"
      question={`Are you sure you want to delete this tenant (${
        tenant && tenant.name
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!tenant}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteTenantModal;
