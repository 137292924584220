import { gql } from '@apollo/client';

export const FirmwareVersionListAttributes = gql`
  {
    _id
    deviceType
    appName
    appFlavor
    versionName
    versionNumber
    fileId
    fingerprint
    tags
    notes
    downloadUrl
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const FirmwareVersionDetailedAttributes = gql`
  {
    _id
    deviceType
    appName
    appFlavor
    versionName
    versionNumber
    fileId
    fingerprint
    tags
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const firmwareVersionQuery = gql`
  query FirmwareVersion($_id: ID!) {
    firmwareVersion(_id: $_id) ${FirmwareVersionDetailedAttributes}
  }
`;

export const allFirmwareVersionsQuery = gql`
  query AllFirmwareVersions($first: Int, $after: String, $filters: FirmwareVersionFilters, $sortBy: [SortBy]) {
    allFirmwareVersions(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${FirmwareVersionListAttributes}
      }
    }
  }
`;

export const deleteFirmwareVersionMutation = gql`
  mutation deleteFirmwareVersion($_id: ID!) {
    deleteFirmwareVersion(_id: $_id) {
      deletedId
    }
  }
`;

export const createFirmwareVersionMutation = gql`
  mutation createFirmwareVersion($firmwareVersion: FirmwareVersionCreateInput!) {
    createFirmwareVersion(firmwareVersion: $firmwareVersion) {
      firmwareVersion ${FirmwareVersionDetailedAttributes}
    }
  }
`;

export const updateFirmwareVersionMutation = gql`
  mutation updateFirmwareVersion($firmwareVersion: FirmwareVersionUpdateInput!) {
    updateFirmwareVersion(firmwareVersion: $firmwareVersion) {
      firmwareVersion ${FirmwareVersionDetailedAttributes}
    }
  }
`;

export const DeviceTypes = {
  JOON3: {
    key: 'JOON3',
    label: 'Theora Connect',
  },
};
