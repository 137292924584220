import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoon3ContactAction,
  deleteJoon3ContactAction,
  updateJoon3ContactAction,
} from '../../../../redux-store/joon3-contacts-store';
import { Joon3ContactAttributes } from './constants';

export const Joon3ContactUpdateAttributes = gql`
{
  type
  crud
  id
  new ${Joon3ContactAttributes}
}
`;

export const joon3ContactUpdatesSubscription = gql`
  subscription Joon3ContactUpdates($deviceId: ID!) {
    joon3ContactUpdates(deviceId: $deviceId) ${Joon3ContactUpdateAttributes}
  }
`;

function useJoon3ContactUpdates(deviceId) {
  const queryId = deviceId || 'default';
  useSubscription(joon3ContactUpdatesSubscription, {
    skip: !deviceId,
    variables: {
      deviceId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        if (result && result.data && result.data.joon3ContactUpdates) {
          const updates = result.data.joon3ContactUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'JOON3_CONTACT_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoon3ContactAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoon3ContactAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoon3ContactAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoon3ContactAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useJoon3ContactUpdates;
