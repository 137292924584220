import { DatabaseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import JoonDatasets from './ViewJoonDevice/datasets/JoonDeviceDatasets';

const { Title, Text, Paragraph } = Typography;

function JoonDeviceDatasetsPage() {
  return (
    <CSPage
      title="Theora Connect&trade; Datasets"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <DatabaseOutlined style={{ marginRight: 16 }} />
            Theora Connect&trade; Datasets
          </Title>
        }
        topActions={undefined}
      />
      <JoonDatasets />
      <style jsx>{``}</style>
    </CSPage>
  );
}

export default JoonDeviceDatasetsPage;
