import React, { useEffect, useMemo, useRef, useState } from 'react';
import DeviceFilters from './DeviceFilters';
import DeviceList from './DeviceList';
import DeviceMap from './DeviceMap';
import useFilterMapDevices from './use-filter-map-devices';
import useTenantNowUpdates from './use-tenant-now-updates';
import useTenantDeviceUpdates from './use-tenant-device-updates';
import useProfile from '../../../shared/use-profile';

function MapView() {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const profile = useProfile();
  const showTenantFilter = useMemo(() => {
    return profile?.role?.permissions?.includes('list-tenants:all');
  }, [profile]);
  const tenantId = useMemo(() => {
    return showTenantFilter ? filters.tenantId : profile.tenantId;
  }, [showTenantFilter, profile.tenantId, filters.tenantId]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, [tenantId, filters]);

  useTenantNowUpdates(tenantId);
  useTenantDeviceUpdates(tenantId);
  const { unfiltered, filtered, search } = useFilterMapDevices({
    tenantId,
    filters,
  });

  const handleCenterTo = useRef(null);

  return (
    <div>
      <DeviceFilters
        filtered={filtered}
        unfiltered={unfiltered}
        searchDevices={search}
        setFilters={setFilters}
        showTenantFilter={showTenantFilter}
      />
      <div className="body">
        <div className="left">
          <DeviceList
            devices={filtered}
            listLoading={loading && unfiltered.length === 0}
            handleCenterTo={handleCenterTo}
          />
        </div>
        <div className="right">
          <DeviceMap devices={filtered} handleCenterTo={handleCenterTo} />
        </div>
      </div>
      <style jsx>
        {`
          .body {
            display: flex;
            margin-left: -12px;
            margin-right: -12px;
          }
          .left {
            margin-left: 12px;
            margin-right: 12px;
            width: 240px;
            overflow: scroll;
            height: 70vh;
          }
          .right {
            margin-right: 12px;
            margin-left: 12px;
            flex: 1;
          }
        `}
      </style>
    </div>
  );
}

export default MapView;
