import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  App,
  Button,
  Form,
  Modal,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useState } from 'react';

export const updateJoon3ConfigMutation = gql`
  mutation DeleteSomeSettingButton($config: UpdateJoon3ConfigInput!) {
    updateJoon3Config(config: $config)
  }
`;

const { Title, Paragraph } = Typography;

function DeleteSomeSettingButton({
  systemConfig,
  tenantConfig,
  deviceConfig,
  btnProps,
  noBtn,
  editing: editing1,
  onDone: onDone1,
  onCancel: onCancel1,
  title,
  description,
  table,
  settingKey,
}) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateJoon3Config] = useMutation(updateJoon3ConfigMutation);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const handleFinish = useCallback(async () => {
    setSaving(true);
    try {
      await updateJoon3Config({
        variables: {
          config: {
            _id: deviceConfig ? deviceConfig._id : tenantConfig._id,
            [table]: {
              _id: new Date().toISOString(),
              [settingKey]: null,
            },
          },
        },
      });
      notificationApi.success({
        message: 'Success',
        description: 'Setting deleted successfully',
      });
      if (noBtn) {
        onDone1();
      } else {
        onDone();
      }
    } catch (err) {
      console.error(err);
      notificationApi.error({
        message: 'Error',
        description: 'There was an error deleting your setting',
      });
    }
    setSaving(false);
  }, [
    deviceConfig,
    tenantConfig,
    updateJoon3Config,
    onDone,
    noBtn,
    onDone1,
    notificationApi,
    table,
    settingKey,
  ]);
  return (
    <>
      {contextHolder}
      <Modal
        open={noBtn ? editing1 : editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={noBtn ? onCancel1 : onCancel}
        // width={700}
        bodyStyle={{
          paddingTop: 16,
        }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          {title}
        </Title>
        <Paragraph
          style={{ textAlign: 'center', marginBottom: 32, fontSize: 14 }}
        >
          {description}
        </Paragraph>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={onCancel}
                htmlType="button"
                disabled={saving}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={saving}>
                Delete
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      {!noBtn && (
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            onClick={handleEdit}
            {...btnProps}
          />
        </Tooltip>
      )}
    </>
  );
}

export default DeleteSomeSettingButton;
