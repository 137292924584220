import { Table, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text } = Typography;

function JoonDatasetAudioFiles({ files }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <>
      {files && files.length > 0 && (
        <>
          <Title level={2}>Files</Title>
          <Table
            dataSource={files}
            pagination={false}
            style={{ width: '100%', marginBottom: 32 }}
            rowKey="_id"
          >
            <Table.Column
              title="Filename"
              dataIndex="filename"
              key="filename"
            />
            <Table.Column
              title="Timestamp"
              dataIndex="timestamp"
              render={(_, record) => {
                let timestamp;
                if (record.metadata) {
                  try {
                    const metadata = JSON.parse(record.metadata);
                    timestamp = metadata.timestamp;
                  } catch (err) {}
                }
                return (
                  timestamp &&
                  new Intl.DateTimeFormat(locale, {
                    timeStyle: 'long',
                  }).format(new Date(timestamp))
                );
              }}
            />
            <Table.Column
              title="Size"
              dataIndex="size"
              render={(_, record) => {
                return new Intl.NumberFormat('en-US', {
                  style: 'unit',
                  unit: 'kilobyte',
                }).format(record.size);
              }}
            />
            <Table.Column
              title="Play"
              dataIndex="play"
              render={(_, record) => {
                return (
                  <>
                    <audio src={record.url} controls />
                  </>
                );
              }}
            />
          </Table>
        </>
      )}
    </>
  );
}

export default JoonDatasetAudioFiles;
