export const READ_JOON3_CONTACTS = 'READ_JOON3_CONTACTS';
export const CREATE_JOON3_CONTACT = 'CREATE_JOON3_CONTACT';
export const UPDATE_JOON3_CONTACT = 'UPDATE_JOON3_CONTACT';
export const DELETE_JOON3_CONTACT = 'DELETE_JOON3_CONTACT';

export const readJoon3ContactsAction = (
  // eslint-disable-next-line no-shadow
  joon3Contacts,
  queryId = 'default',
) => ({
  type: READ_JOON3_CONTACTS,
  payload: {
    joon3Contacts,
    queryId,
  },
});

export const createJoon3ContactAction = (
  joon3Contact,
  queryId = 'default',
) => ({
  type: CREATE_JOON3_CONTACT,
  payload: {
    joon3Contact,
    queryId,
  },
});

export const updateJoon3ContactAction = (
  joon3Contact,
  queryId = 'default',
) => ({
  type: UPDATE_JOON3_CONTACT,
  payload: {
    joon3Contact,
    queryId,
  },
});

export const deleteJoon3ContactAction = (
  joon3ContactId,
  queryId = 'default',
) => ({
  type: DELETE_JOON3_CONTACT,
  payload: {
    joon3ContactId,
    queryId,
  },
});

const initialJoon3Contacts = { default: {} };

export function joon3Contacts(state = initialJoon3Contacts, action) {
  switch (action.type) {
    case READ_JOON3_CONTACTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joon3Contacts.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON3_CONTACT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Contact._id]: action.payload.joon3Contact,
        },
      };
    case UPDATE_JOON3_CONTACT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joon3Contact._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Contact._id]: {
            ...current,
            ...action.payload.joon3Contact,
          },
        },
      };
    }
    case DELETE_JOON3_CONTACT: {
      const { joon3ContactId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joon3ContactId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
