import { LoadingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Tooltip, notification } from 'antd';
import React, { useCallback, useState } from 'react';

const sendWebhookTestSignalMutation = gql`
  mutation SendWebhookTestSignal($webhookId: ID!) {
    sendWebhookTestSignal(webhookId: $webhookId)
  }
`;

function SendWebhookTestSignalButton({ webhook }) {
  const [loading, setLoading] = useState();
  const [sendWebhookTestSignal] = useMutation(sendWebhookTestSignalMutation);
  const doSendTestSignal = useCallback(async () => {
    setLoading(true);
    try {
      await sendWebhookTestSignal({
        variables: {
          webhookId: webhook._id,
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Test signal sent!',
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error sending test signal.',
      });
    }
    setLoading(false);
  }, [webhook, sendWebhookTestSignal]);
  return (
    <Tooltip title="Send Test Signal">
      <Button
        onClick={doSendTestSignal}
        disabled={loading}
        icon={loading ? <LoadingOutlined /> : <ThunderboltOutlined />}
      />
    </Tooltip>
  );
}

export default SendWebhookTestSignalButton;
