export const READ_FILES = 'READ_FILES';
export const CREATE_FILE = 'CREATE_FILE';
export const UPDATE_FILE = 'UPDATE_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const BULK_CREATE_FILES = 'BULK_CREATE_FILES';

// eslint-disable-next-line no-shadow
export const readFilesAction = (files, queryId = 'default') => ({
  type: READ_FILES,
  payload: {
    files,
    queryId,
  },
});

export const createFileAction = (file, queryId = 'default') => ({
  type: CREATE_FILE,
  payload: {
    file,
    queryId,
  },
});

export const bulkCreateFilesAction = (
  // eslint-disable-next-line no-shadow
  files,
  queryId = 'default',
) => ({
  type: BULK_CREATE_FILES,
  payload: {
    files,
    queryId,
  },
});

export const updateFileAction = (file, queryId = 'default') => ({
  type: UPDATE_FILE,
  payload: {
    file,
    queryId,
  },
});

export const deleteFileAction = (fileId, queryId = 'default') => ({
  type: DELETE_FILE,
  payload: {
    fileId,
    queryId,
  },
});

const initialFiles = { default: {} };

export function files(state = initialFiles, action) {
  switch (action.type) {
    case READ_FILES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.files.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_FILE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.file._id]: action.payload.file,
        },
      };
    case BULK_CREATE_FILES:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          ...action.payload.files.reduce((prev, curr) => {
            prev[curr._id] = curr;
            return prev;
          }, {}),
        },
      };
    case UPDATE_FILE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.file._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.file._id]: {
            ...current,
            ...action.payload.file,
          },
        },
      };
    }
    case DELETE_FILE: {
      const { fileId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [fileId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
