import { GlobalOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useProfile from '../../../shared/use-profile';
import { generateUuid } from '../../../shared/utils';
import SelectTenant from '../../tenants/SelectTenant';
import useWebhookUpdates from '../use-webhook-updates.js';
import EditWebhookModal from './EditWebhookModal';
import ListWebhooks from './ListWebhooks';

const { Title, Text } = Typography;

function ViewWebhooks() {
  const profile = useProfile();
  const allTenantPerm = useMemo(() => {
    if (profile && profile.role && profile.role.permissions) {
      return profile.role.permissions.includes('list-tenants:all');
    }
    return false;
  }, [profile]);
  useWebhookUpdates(allTenantPerm ? undefined : profile.tenantId);
  const [tenantId, setTenantId] = useState();
  useEffect(() => {
    setTenantId(allTenantPerm ? undefined : profile.tenantId);
  }, [allTenantPerm, profile]);
  const [editingWebhook, setEditingWebhook] = useState(null);
  const [form] = Form.useForm();
  const handleNewWebhook = () =>
    setEditingWebhook({ _id: generateUuid(), creating: true });
  const onTenantChange = useCallback((newTenantId) => {
    setTenantId(newTenantId);
  }, []);
  return (
    <CSPage title="Webhooks" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <GlobalOutlined style={{ marginRight: 16 }} />
            Webhooks
          </Title>
        }
      />
      <div className="top-actions">
        {allTenantPerm && (
          <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: 500, minWidth: 300, marginTop: 16 }}
          >
            <Form.Item label="Tenant" name="tenantId">
              <SelectTenant onChange={onTenantChange} allowClear />
            </Form.Item>
          </Form>
        )}
        <Button
          icon={<PlusCircleOutlined />}
          style={{ marginTop: 16, float: 'right' }}
          onClick={handleNewWebhook}
        >
          Add New Webhook
        </Button>
      </div>
      <ListWebhooks tenantId={tenantId} setEditingWebhook={setEditingWebhook} />
      <EditWebhookModal
        tenantId={tenantId}
        setWebhook={setEditingWebhook}
        webhook={editingWebhook}
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </CSPage>
  );
}

export default ViewWebhooks;
