import * as React from 'react';
import { isEmptyChildren, isFunction } from './react-utils';
import client from '../apollo-client';
import { gql } from '@apollo/client';
import { Modal, Typography } from 'antd';
import {
  authStateChangedAction,
  signOutAction,
} from '../redux-store/auth-store';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const { Text } = Typography;

const signIntoAdminMutation = gql`
  mutation SignIntoAdmin($username: String!, $password: String!) {
    signIntoAdmin(username: $username, password: $password) {
      token
    }
  }
`;

const signIntoAdminWithMagicLinkMutation = gql`
  mutation SignIntoAdminWithMagicLink($code: String!) {
    signIntoAdminWithMagicLink(code: $code) {
      token
    }
  }
`;

const myProfileMutation = gql`
  query MyProfile {
    myProfile {
      _id
      username
      name
      email
      phone
      role {
        permissions
      }
      tenantId
      tenant {
        name
      }
      primaryThumbnailUrl
    }
  }
`;

const initialAuthState = {
  isLoading: true,
  isSignout: false,
  user: null,
};

export const AuthContext = React.createContext({
  signIn: async () => '',
  signOut: () => null,
  signUp: async () => '',
  signInWithMagicLink: async () => '',
  state: initialAuthState,
});

function processErrorMessage(err) {
  console.log(err.message);
  if (err.message.includes('has not been assigned a role')) {
    Modal.info({
      title: 'Please contact your administrator',
      content: (
        <Text>{`The system recognizes your account, but your profile hasn't yet been assigned a role.  Please contact your administrator at admin@theoracare.com.`}</Text>
      ),
      onOk() {},
    });
    return undefined;
  }
  if (err.message.includes('does not exist')) {
    return 'Username and/or password incorrect';
  }
  return err.message;
}

export const AuthContextProvider = (props) => {
  const { component, children } = props;

  const dispatch = useDispatch();
  const authState = useSelector((store) => store.authState, shallowEqual);
  const currentUser = useSelector((store) => store.profile, shallowEqual);
  const state = React.useMemo(
    () => ({
      ...authState,
      user: currentUser,
    }),
    [authState, currentUser],
  );

  React.useEffect(() => {
    const doAsyncStuff = async () => {
      const token = localStorage.getItem('authToken');
      try {
        if (!token) {
          throw new Error('Not signed in');
        }
        let response;
        response = await client.query({
          query: myProfileMutation,
          fetchPolicy: 'network-only',
        });
        if (!response || !response.data || !response.data.myProfile) {
          throw new Error('Error trying to sign in');
        }
        dispatch(authStateChangedAction(response.data.myProfile));
      } catch (err) {
        console.error(err);
        localStorage.removeItem('authToken');
        client.close();
        dispatch(authStateChangedAction(null));
        // notification.open({
        //   message: 'Error',
        //   description: err.message,
        // });
      }
    };
    doAsyncStuff();
  }, [dispatch]);

  const authContext = React.useMemo(
    () => ({
      signIn: async (values) => {
        const { username, password } = values;
        try {
          let response = await client.mutate({
            mutation: signIntoAdminMutation,
            variables: {
              username: username,
              password: password,
            },
            fetchPolicy: 'no-cache',
          });
          if (!response || !response.data || !response.data.signIntoAdmin) {
            throw new Error('Error trying to sign in');
          }
          localStorage.setItem('authToken', response.data.signIntoAdmin.token);
          client.close();
          response = await client.query({
            query: myProfileMutation,
            fetchPolicy: 'network-only',
          });
          if (!response || !response.data || !response.data.myProfile) {
            throw new Error('Error trying to sign in');
          }
          dispatch(authStateChangedAction(response.data.myProfile));
        } catch (err) {
          console.error(err);
          localStorage.removeItem('authToken');
          client.close();
          return processErrorMessage(err);
        }
        return '';
      },
      signInWithMagicLink: async (code) => {
        try {
          let response = await client.mutate({
            mutation: signIntoAdminWithMagicLinkMutation,
            variables: {
              code,
            },
            fetchPolicy: 'no-cache',
          });
          if (
            !response ||
            !response.data ||
            !response.data.signIntoAdminWithMagicLink
          ) {
            throw new Error('Error trying to sign in');
          }
          localStorage.setItem(
            'authToken',
            response.data.signIntoAdminWithMagicLink.token,
          );
          client.close();
          response = await client.query({
            query: myProfileMutation,
            fetchPolicy: 'network-only',
          });
          if (!response || !response.data || !response.data.myProfile) {
            throw new Error('Error trying to sign in');
          }
          dispatch(authStateChangedAction(response.data.myProfile));
        } catch (err) {
          console.error(err);
          localStorage.removeItem('authToken');
          client.close();
          return processErrorMessage(err);
        }
        return '';
      },
      signOut: async () => {
        localStorage.removeItem('authToken');
        client.close();
        dispatch(signOutAction());
      },
      signUp: async (values) => {},
      state,
    }),
    [state, dispatch],
  );

  return (
    <AuthContext.Provider value={authContext}>
      {component
        ? React.createElement(component, authContext)
        : children // children come last, always called
        ? isFunction(children)
          ? children(authContext)
          : !isEmptyChildren(children)
          ? React.Children.only(children)
          : null
        : null}
    </AuthContext.Provider>
  );
};
