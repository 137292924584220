import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { sendApplicationUpdateCommandMutation } from '../../constants';

const { Text } = Typography;

function SendApplicationUpdateCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sendApplicationUpdateCommand] = useMutation(
    sendApplicationUpdateCommandMutation,
  );

  const handleSendCommand = useCallback(
    async (_values) => {
      setLoading(true);
      setError(undefined);
      try {
        const { ...values } = _values;
        await sendApplicationUpdateCommand({
          variables: {
            cmd: {
              deviceId: joonDevice._id,
              ...values,
            },
          },
        });
        message.success('App Update command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, sendApplicationUpdateCommand],
  );

  return (
    <Card
      title="Send App Update"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item
          label="App Name"
          name="appName"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          initialValue="network.clairvoyant.theora_lite"
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="App Flavor"
          name="appFlavor"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <style jsx global>{`
        .config-cmd .ant-input {
          padding: 4px 8px;
        }
        .config-cmd .ant-form-item-control-input {
          min-height: 32px;
        }
        .config-cmd .ant-input-number-input {
          height: 32px;
        }
      `}</style>
    </Card>
  );
}

export default SendApplicationUpdateCommand;
