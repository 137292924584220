import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Divider, Space, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { myProfileQuery } from '../constants';
import EditProfileForm from './EditProfileForm';
import ChangeProfilePasswordModal from '../ChangeProfilePasswordModal';

function EditProfile() {
  const history = useHistory();

  const fetchResult = useQuery(myProfileQuery, {
    fetchPolicy: 'network-only',
  });
  const { data, error, loading } = fetchResult;

  const profile = data?.myProfile;

  useEffect(() => {
    if (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: 'Failed to load profile',
      });
    }
  });

  const [changingPassword, setChangingPassword] = useState(undefined);
  const onFinishChangePassword = () => setChangingPassword(undefined);
  const onCancelChangePassword = () => setChangingPassword(undefined);

  const handleBack = useCallback(() => history.goBack(), [history]);

  return (
    <>
      <CSPage title="Edit Profile">
        <CSPageHeader
          title="Edit My Profile"
          backActions={[
            <Link key="back" to="/home">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to my dashboard
              </Button>
            </Link>,
          ]}
        />
        <EditProfileForm
          _id={profile?._id}
          handleCancel={handleBack}
          handleSuccess={() => history.push('/home')}
          profile={profile}
          loading={loading}
          style={{ maxWidth: 500 }}
        />
        <div style={{ maxWidth: 500 }}>
          <Divider />
          <div className="delete-box">
            <Space align="center">
              <Button
                onClick={() => setChangingPassword({ profile })}
                htmlType="button"
                disabled={loading}
                icon={<LockOutlined />}
              >
                Change Password
              </Button>
            </Space>
          </div>
        </div>
        <div style={{ minHeight: 300 }} />
        <ChangeProfilePasswordModal
          visible={changingPassword}
          onFinish={onFinishChangePassword}
          onCancel={onCancelChangePassword}
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditProfile;
