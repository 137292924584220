import { Divider, Tag, Typography } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import useChangeFilters from '../use-change-filters';
import useQueryParams from '../../../shared/use-query-params';

const { Title, Text } = Typography;

function FilterLabels({ filterLabels }) {
  const changeFilters = useChangeFilters();
  const urlFilters = useQueryParams();
  const _urlFilters = useRef(urlFilters);
  useEffect(() => {
    _urlFilters.current = urlFilters;
  }, [urlFilters]);
  const _filterLabels = useRef(filterLabels);
  useEffect(() => {
    _filterLabels.current = filterLabels;
  }, [filterLabels]);
  const handleClose = useCallback(
    (label) => {
      let newValue;
      if (label.type === 'string[]') {
        newValue = _filterLabels.current
          .filter((f) => f.name === label.name)
          .map((f) => f.value)
          .filter((f) => f !== label.value);
      }
      if (label.type === 'bool[]') {
        newValue = _filterLabels.current
          .filter((f) => f.name === label.name)
          .map((f) => f.value)
          .filter((f) => f !== label.value);
      }
      if (label.type === 'int[]') {
        newValue = _filterLabels.current
          .filter((f) => f.name === label.name)
          .map((f) => f.value)
          .filter((f) => f !== label.value);
      }
      if (label.type === 'range') {
        newValue = undefined;
      }
      changeFilters({
        ..._urlFilters.current,
        [label.name]: newValue,
      });
    },
    [changeFilters],
  );
  return (
    <div>
      {/* <Divider /> */}
      <Title style={{ marginTop: 0 }} level={5}>
        Applied Filters
      </Title>
      {filterLabels.length === 0 && <Text>No Filters</Text>}
      {filterLabels.map((label) => (
        <Tag
          key={label.value}
          onClose={() => handleClose(label)}
          color="red"
          closable
          style={{
            fontSize: 14,
            lineHeight: 2,
            paddingLeft: 12,
            paddingRight: 12,
            marginBottom: 8,
          }}
        >
          {label.label}
        </Tag>
      ))}
    </div>
  );
}

export default FilterLabels;
