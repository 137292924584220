import {
  ClockCircleOutlined,
  ExportOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import { AuditStatuses } from '../../joonDevices/constants';
import { exportDevicesToCsv } from './utils';

function DevicesTab({ reports, loading }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const onExport = useCallback(() => {
    exportDevicesToCsv(reports);
  }, [reports]);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button key="export" icon={<ExportOutlined />} onClick={onExport}>
          Export to CSV
        </Button>
      </div>
      <Table
        dataSource={reports}
        loading={!reports?.length && loading}
        pagination={{ ...pagination, total: reports?.length }}
        onChange={handleTableChange}
        style={{ width: '100%', marginTop: 16 }}
        rowKey="_id"
      >
        <Table.Column title="IMEI" dataIndex="imei" key="imei" />
        <Table.Column title="Serial No." dataIndex="serialNo" key="serialNo" />
        <Table.Column title="Tenant" dataIndex="tenantName" key="tenantName" />
        {/* <Table.Column title="Serial No." dataIndex="serialNo" key="serialNo" /> */}
        {/* <Table.Column title="ICCID" dataIndex="iccid" key="iccid" /> */}
        {/* <Table.Column
          title="Phone Number"
          dataIndex="msisdn"
          render={(text, record) => {
            if (record.msisdn) {
              const parsed = parsePhoneNumber(record.msisdn, {
                regionCode: 'US',
              });
              return parsed && parsed.number && parsed.number.international;
            }
            return null;
          }}
        /> */}
        {/* <Table.Column
          title="Call Center ID"
          dataIndex="callCenterId"
          key="callCenterId"
        /> */}
        <Table.Column
          title="Configuration"
          dataIndex="auditStatus"
          render={(text, record) => {
            const status =
              record.auditStatus && AuditStatuses[record.auditStatus];
            if (status) {
              return <Tag color={status.color}>{status.label}</Tag>;
            }
            return null;
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="Device Report Details">
                <Link
                  to={`/report-dashboard/view/${record.reportId}/devices/${record.imei}`}
                >
                  <Button icon={<PieChartOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="Device Dashboard">
                <Link to={`/joon-device/view/${record.imei}`}>
                  <Button icon={<ClockCircleOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
}

export default DevicesTab;
