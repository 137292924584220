import { useMutation, gql } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Title, Text, Paragraph } = Typography;

export const unregisterJoonDeviceMutation = gql`
  mutation UnregisterJoonDevice($deviceId: ID!) {
    unregisterJoonDevice(deviceId: $deviceId)
  }
`;

const UnregisterDeviceOwnerModal = ({
  visible,
  onSuccess,
  onCancel,
  queryId,
}) => {
  const [loading, setLoading] = useState(false);

  const { deviceUser, user } = visible || {};

  const [unregisterJoonDevice] = useMutation(unregisterJoonDeviceMutation);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await unregisterJoonDevice({
        variables: {
          deviceId: deviceUser.deviceId,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Owner unregistered from device',
      });
      if (onSuccess) onSuccess();
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'There was an error unregistering this device',
      });
    }
    setLoading(false);
  }, [unregisterJoonDevice, deviceUser, onSuccess]);

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48, textAlign: 'center' }}
      >
        <Title level={3} style={{ marginBottom: 16 }}>
          Unregister Device Owner
        </Title>
        <Paragraph>
          {`Are you sure you want to unregister this device from ${
            deviceUser && deviceUser.user && deviceUser.user.name
          }?`}
        </Paragraph>
        <Paragraph style={{ marginBottom: 24 }}>
          {`This action is irreversible and will remove all device users in addition to the owner.`}
        </Paragraph>
        <div>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button danger onClick={handleFinish}>
            Unregister
          </Button>
        </div>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default UnregisterDeviceOwnerModal;
