export const READ_JOON3_REPORTS = 'READ_JOON3_REPORTS';
export const CREATE_JOON3_REPORT = 'CREATE_JOON3_REPORT';
export const UPDATE_JOON3_REPORT = 'UPDATE_JOON3_REPORT';
export const DELETE_JOON3_REPORT = 'DELETE_JOON3_REPORT';

export const readJoon3ReportsAction = (
  // eslint-disable-next-line no-shadow
  joon3Reports,
  queryId = 'default',
) => ({
  type: READ_JOON3_REPORTS,
  payload: {
    joon3Reports,
    queryId,
  },
});

export const createJoon3ReportAction = (joon3Report, queryId = 'default') => ({
  type: CREATE_JOON3_REPORT,
  payload: {
    joon3Report,
    queryId,
  },
});

export const updateJoon3ReportAction = (joon3Report, queryId = 'default') => ({
  type: UPDATE_JOON3_REPORT,
  payload: {
    joon3Report,
    queryId,
  },
});

export const deleteJoon3ReportAction = (
  joon3ReportId,
  queryId = 'default',
) => ({
  type: DELETE_JOON3_REPORT,
  payload: {
    joon3ReportId,
    queryId,
  },
});

const initialJoon3Reports = { default: {} };

export function joon3Reports(state = initialJoon3Reports, action) {
  switch (action.type) {
    case READ_JOON3_REPORTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joon3Reports.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON3_REPORT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Report._id]: action.payload.joon3Report,
        },
      };
    case UPDATE_JOON3_REPORT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joon3Report._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Report._id]: {
            ...current,
            ...action.payload.joon3Report,
          },
        },
      };
    }
    case DELETE_JOON3_REPORT: {
      const { joon3ReportId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joon3ReportId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
