import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import useQueryParams from '../../shared/use-query-params';
import SignIn from '../SignIn';
import queryString from 'query-string';

function AuthRouter() {
  const query = useQueryParams();
  const location = useLocation();
  const redirectFromCatchAll = useCallback(() => {
    const { c } = query;
    const search = `?${queryString.stringify({ c, p: location.pathname })}`;
    return <Redirect to={`/${search}`} />;
  }, [query, location]);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={`/sign-in${location.search}`} />}
      />
      <Route exact path="/sign-in" component={SignIn} />
      <Route render={redirectFromCatchAll} />
    </Switch>
  );
}

export default AuthRouter;
