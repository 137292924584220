import { useState } from 'react';
import EditTagsInner from './EditTagsInner';
import NightlyReportEditSomethingModal from './NightlyReportEditSomethingModal';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import settings from '../../../settings';

function ReportTags({ report, queryId }) {
  const [editing, setEditing] = useState(false);
  const onDone = () => setEditing(false);
  const onCancel = () => setEditing(false);
  const onEdit = () => setEditing(true);
  return (
    <>
      {report.tags?.length > 0 ? (
        <div style={{ marginTop: 8 }}>
          {report.tags.map((tag) => (
            <Tag key={tag} color={settings.colors.secondary}>
              {tag}
            </Tag>
          ))}
          <Button icon={<EditOutlined />} onClick={onEdit} type="text" />
        </div>
      ) : (
        <Button
          style={{ marginTop: 16 }}
          icon={<EditOutlined />}
          onClick={onEdit}
        >
          Add Tags
        </Button>
      )}
      <NightlyReportEditSomethingModal
        editing={editing}
        report={report}
        onCancel={onCancel}
        onDone={onDone}
        queryId={queryId}
      >
        <EditTagsInner />
      </NightlyReportEditSomethingModal>
    </>
  );
}

export default ReportTags;
