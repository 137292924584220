import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, message } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import settings from '../../../settings';
import { WebhookTypes } from '../constants';
import useFilterWebhooks from '../use-filter-webhooks';
import DeleteWebhookModal from './DeleteWebhookModal';
import ViewWebhookModal from './ViewWebhookModal';
import SendWebhookTestSignalButton from './SendWebhookTestSignalButton';

function ListWebhooks({ tenantId, setEditingWebhook }) {
  const [deleting, setDeleting] = useState(null);
  const [viewing, setViewing] = useState(null);
  const [loadingTimeoutExpired, setLoadingTimeoutExpired] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const webhooks = useFilterWebhooks({ filters: { tenantId } });

  setTimeout(() => setLoadingTimeoutExpired(true), 1000);
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);
  return (
    <>
      <Table
        dataSource={webhooks}
        pagination={{ ...pagination, total: webhooks.length }}
        loading={!!tenantId && webhooks.length == 0 && !loadingTimeoutExpired}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        locale={{
          emptyText: 'No Webhooks Found.',
        }}
        scroll={{ x: 800 }}
      >
        <Column title="Webhook Name" dataIndex="name" key="name" />
        <Column title="Target URL" dataIndex="target" key="target" />
        <Column
          title="Tenant"
          key="target"
          render={(text, record) => record.tenant && record.tenant.name}
        />
        <Column
          title="Webhook Type"
          key="type"
          render={(text, record) =>
            record.type &&
            WebhookTypes[record.type] &&
            WebhookTypes[record.type].label
          }
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <>
              {record && (
                <Space size="middle">
                  <Tooltip title="View">
                    <Button
                      icon={<EyeOutlined />}
                      onClick={() => setViewing(record)}
                    />
                  </Tooltip>
                  <SendWebhookTestSignalButton webhook={record} />
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => setEditingWebhook(record)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      onClick={() => setDeleting(record)}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Space>
              )}
            </>
          )}
        />
      </Table>
      <DeleteWebhookModal setWebook={setDeleting} webhook={deleting} />
      <ViewWebhookModal webhook={viewing} onYes={() => setViewing(null)} />
    </>
  );
}

export default ListWebhooks;
