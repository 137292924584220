import { Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';

const { Text, Title } = Typography;

function PhotoCircle({
  initials,
  imageUrl,
  color,
  diameter,
  textStyle,
  style,
}) {
  const [loaded, setLoaded] = useState();
  const [error, setError] = useState(false);
  const img = useRef();
  const { top, left, height, width } = useMemo(() => {
    if (loaded) {
      let _top;
      let _left;
      let _height;
      let _width;
      if (loaded.height > loaded.width) {
        _width = diameter;
        _height = (diameter / loaded.width) * loaded.height;
        _top = (diameter - _height) / 2;
        _left = 0;
      } else {
        _width = (diameter / loaded.height) * loaded.width;
        _height = diameter;
        _top = 0;
        _left = (diameter - _width) / 2;
      }
      // _top = (diameter - loaded.height) / 2;
      // _left = (diameter - loaded.width) / 2;
      // _height = loaded.height;
      // _width = loaded.width;
      return {
        top: _top,
        left: _left,
        height: _height,
        width: _width,
      };
    }
    return {
      top: 0,
      left: 0,
      height: diameter,
      width: diameter,
    };
  }, [loaded, diameter]);
  return (
    <div
      style={{
        height: diameter,
        width: diameter,
        backgroundColor: color,
        borderRadius: '100%',
        overflow: 'hidden',
        position: 'relative',
        ...style,
      }}
    >
      {!!imageUrl && !error && (
        <img
          ref={img}
          src={imageUrl}
          onLoad={() => {
            setLoaded({
              width: img.current.naturalWidth,
              height: img.current.naturalHeight,
            });
          }}
          onError={() => setError(true)}
          style={{
            height,
            width,
            position: 'absolute',
            top,
            left,
          }}
        />
      )}
      {!loaded && (
        <div
          style={{
            height: diameter,
            width: diameter,
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={textStyle}>{initials}</div>
        </div>
      )}
    </div>
  );
}

export default PhotoCircle;
