import { Button, Form, InputNumber, Radio, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import DisplayDefault from '../DisplayDefault';
import {
  displayDurationSetting,
  getSettingOptions,
  getUnifiedSetting,
  notNullOrUndefined,
} from '../utils';

const { Title, Text } = Typography;

function EditNumberMinsModal({
  systemConfig,
  tenantConfig,
  deviceConfig,
  saving,
  handleFinish,
  onCancel,
  table,
  settingKey,
  label,
  initialValue,
  sampleRate,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    const [value, src] = getUnifiedSetting(
      systemConfig,
      tenantConfig,
      deviceConfig,
      table,
      settingKey,
    );
    form.setFieldsValue({
      useDefault: src,
      [settingKey]: value / sampleRate,
    });
  }, [
    systemConfig,
    tenantConfig,
    deviceConfig,
    form,
    table,
    settingKey,
    sampleRate,
  ]);
  const onFinish = useCallback(
    (values) => {
      const { [settingKey]: selected, useDefault } = values;
      let value;
      if (deviceConfig && useDefault === 'device') {
        value = selected * sampleRate;
      } else if (!deviceConfig && useDefault === 'tenant') {
        value = selected * sampleRate;
      } else {
        value = null;
      }
      handleFinish({
        [table]: {
          _id: new Date().toISOString(),
          [settingKey]: value,
        },
      });
    },
    [handleFinish, table, settingKey, sampleRate, deviceConfig],
  );
  const [systemAttr, tenantAttr, deviceAttr] = useMemo(() => {
    return getSettingOptions(
      systemConfig,
      tenantConfig,
      deviceConfig,
      table,
      settingKey,
    );
  }, [systemConfig, tenantConfig, deviceConfig, table, settingKey]);
  return (
    <>
      <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
        {label}
      </Title>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="useDefault">
          <Radio.Group
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {notNullOrUndefined(systemAttr) && (
              <Radio
                disabled={!!deviceConfig && notNullOrUndefined(tenantAttr)}
                value="system"
                style={{ marginBottom: 8 }}
              >
                {`System default (${displayDurationSetting(systemAttr)})`}
              </Radio>
            )}
            {!!deviceConfig && notNullOrUndefined(tenantAttr) && (
              <Radio
                value="tenant"
                style={{ marginBottom: 8 }}
              >{`Tenant default (${displayDurationSetting(
                tenantAttr,
              )})`}</Radio>
            )}
            {deviceConfig ? (
              <Radio value="device">Specify for this device</Radio>
            ) : (
              <Radio value="tenant">Specify for this tenant</Radio>
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.useDefault !== curValues.useDefault
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('useDefault') == 'system' ? (
              <DisplayDefault
                label={`${label} Enabled`}
                value={displayDurationSetting(systemAttr)}
              />
            ) : getFieldValue('useDefault') == 'tenant' ? (
              <>
                {!!deviceConfig ? (
                  <DisplayDefault
                    label={`${label} Enabled`}
                    value={displayDurationSetting(tenantAttr)}
                  />
                ) : (
                  <Form.Item
                    label={`${label} (minutes)`}
                    name={settingKey}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                    initialValue={initialValue}
                  >
                    <InputNumber
                      disabled={saving}
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </>
            ) : (
              <Form.Item
                label={`${label} (minutes)`}
                name={settingKey}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                initialValue={initialValue}
              >
                <InputNumber
                  disabled={saving}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={onCancel}
              htmlType="button"
              disabled={saving}
              style={{ marginRight: 16 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditNumberMinsModal;
