import React, { useCallback } from 'react';
import YesNoModal from '../../../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteJoonDatasetAction } from '../../../../redux-store/joon-datasets-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { deleteJoonDatasetMutation } from '../../constants';

function DeleteJoonDatasetModal({ visible, onCancel, onFinish }) {
  const { joonDataset, queryIds } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteJoonDataset] = useMutation(deleteJoonDatasetMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteJoonDataset({
        variables: {
          _id: joonDataset._id,
        },
      });
      queryIds.forEach((queryId) => {
        dispatch(deleteJoonDatasetAction(joonDataset._id, queryId));
      });
      notification.success({
        message: 'Deleted',
        description: 'Dataset deleted successfully',
      });
      onFinish();
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [joonDataset, deleteJoonDataset, onFinish, dispatch, queryIds]);

  return (
    <YesNoModal
      title="Delete Dataset"
      question={`Are you sure you want to delete this Dataset (${
        joonDataset && joonDataset.name
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={!!joonDataset}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteJoonDatasetModal;
