import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography, notification } from 'antd';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { updateJoon3DeviceReportAction } from '../../../redux-store/joon3-device-reports-store';
import { updateJoon3ReportAction } from '../../../redux-store/joon3-reports-store';
import settings from '../../../settings';
import useOne from '../../../shared/use-one';
import LoadingModal from './LoadingModal';
import { joon3DeviceReportQuery, joon3ReportMetaQuery } from './constants';
import useNightlyFilters from './use-nightly-filters';
import Chart from 'chart.js/auto';

const { Title, Text } = Typography;

const ChartsToShow = [
  {
    title: 'Heartbeat History',
    chartId: 'heartbeatHistory',
    data: (d, x0) => ({
      x: (d.t - x0) / 1000 / 60 / 60 - 24,
      y: d.missed ? 0 : 1,
    }),
    backgroundColor: (d) => settings.colors.blue,
    borderColor: (d) => settings.colors.blue,
    tooltip: (dateFormatter) => (context) => {
      const d = context.dataset.original[context.dataIndex];
      return dateFormatter.format(new Date(d.t));
    },
  },
  {
    title: 'Battery History',
    chartId: 'batteryHistory',
    data: (d, x0) => ({
      x: (d.t - x0) / 1000 / 60 / 60 - 24,
      y: d.l,
    }),
    backgroundColor: (d) => (d.isCharging ? '#52c41a' : '#e7282f'),
    borderColor: (d) => (d.isCharging ? '#52c41a' : '#e7282f'),
    tooltip: (dateFormatter) => (context) => {
      const d = context.dataset.original[context.dataIndex];
      return `${dateFormatter.format(new Date(d.t))} ${
        d.isCharging ? 'Charging' : 'Discharging'
      } ${d.l}%`;
    },
  },
  {
    title: 'Signal History',
    chartId: 'signalHistory',
    data: (d, x0) => ({
      x: (d.t - x0) / 1000 / 60 / 60 - 24,
      y: d.l,
    }),
    backgroundColor: (d) => settings.colors.blue,
    borderColor: (d) => settings.colors.blue,
    tooltip: (dateFormatter) => (context) => {
      const d = context.dataset.original[context.dataIndex];
      return `${dateFormatter.format(new Date(d.t))} ${d.l} RSSI`;
    },
  },
  {
    title: 'Steps Since Midnight',
    chartId: 'stepHistory',
    data: (d, x0) => ({
      x: (d.t - x0) / 1000 / 60 / 60 - 24,
      y: d.csm,
    }),
    backgroundColor: (d) => settings.colors.blue,
    borderColor: (d) => settings.colors.blue,
    tooltip: (dateFormatter) => (context) => {
      const d = context.dataset.original[context.dataIndex];
      return `${dateFormatter.format(new Date(d.t))} ${d.csm} Steps`;
    },
  },
  {
    title: 'Suspected Lockups',
    chartId: 'suspectedLockups',
    data: (d, x0) => ({
      x: (d.t - x0) / 1000 / 60 / 60 - 24,
      y: d.nMissedHeartbeats,
    }),
    backgroundColor: (d) => settings.colors.blue,
    borderColor: (d) => settings.colors.blue,
    tooltip: (dateFormatter) => (context) => {
      const d = context.dataset.original[context.dataIndex];
      return `${dateFormatter.format(new Date(d.t))} ${
        d.nMissedHeartbeats
      } Missed Heartbeats`;
    },
  },
];

const queryId = 'reportsDashboard';

function ViewDeviceReportDetails() {
  const { reportId, deviceId } = useParams();
  const locale = useSelector((store) => store.locale, shallowEqual);

  const fetchResult1 = useOne(
    joon3ReportMetaQuery,
    (data) => data && data.joon3Report,
    updateJoon3ReportAction,
    'joon3Reports',
    reportId,
    queryId,
    undefined,
    'cache-first',
  );
  const { data: report, loading1, error1 } = fetchResult1;

  const fetchResult2 = useOne(
    joon3DeviceReportQuery,
    (data) => data && data.joon3DeviceReport,
    updateJoon3DeviceReportAction,
    'joon3DeviceReports',
    `${reportId}.${deviceId}`,
    queryId,
    undefined,
    'cache-first',
  );
  const { data: deviceReport, loading2, error2 } = fetchResult2;

  const [notificationApi, contextHolder] = notification.useNotification();
  useEffect(() => {
    if (error1 || error2) {
      console.error(error);
      notificationApi.error({
        description: 'There was an error fetching your device report details',
        message: 'Error',
      });
    }
  }, [notificationApi, error1, error2]);
  const loading = loading1 || loading2;

  const title = useMemo(() => {
    if (report && deviceReport) {
      const datetime = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
        timeStyle: 'short',
      }).format(new Date(report.createdAt));
      return (
        <>
          <Title
            className="cs-header-title"
            level={4}
            style={{ marginBottom: 0 }}
          >
            {report.name}
          </Title>
          <div>
            <Text style={{ fontSize: 16, color: settings.colors.primary }}>
              {datetime}
            </Text>
          </div>
          <Title
            className="cs-header-title"
            style={{ marginBottom: 8, marginTop: 16 }}
          >
            Device Report Details
          </Title>
          <div>
            <Text style={{ fontSize: 18, color: settings.colors.primary }}>
              {`Serial No.: ${deviceReport.serialNo} | IMEI: ${deviceReport.imei}`}
            </Text>
          </div>
        </>
      );
    }
    return (
      <>
        <div>
          <Text style={{ fontSize: 18, color: settings.colors.primary }}>
            Theora Connect Nightly Report
          </Text>
        </div>
        <Title className="cs-header-title" style={{ marginBottom: 8 }}>
          Device Report Details
        </Title>
      </>
    );
  }, [report, deviceReport, locale]);

  const containerRef = useRef();
  const [width, setWidth] = useState();
  const myChart = useRef({});
  useLayoutEffect(() => {
    let x0 = new Date();
    if (deviceReport?.heartbeatHistory?.length > 0) {
      x0 = new Date(deviceReport.heartbeatHistory[0].t);
      x0.setHours(0);
      x0.setMinutes(0);
      x0.setSeconds(0);
      x0.setMilliseconds(0);
    }
    const dateFormatter = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'short',
    });
    setWidth(containerRef.current.offsetWidth);
    setTimeout(() => {
      ChartsToShow.forEach((details) => {
        let data = [];
        // let labels = [];
        let backgroundColor = [];
        let borderColor = [];
        if (deviceReport?.[details.chartId]?.length > 0) {
          data = deviceReport[details.chartId].map((d) => details.data(d, x0));
          // const labelMaker = details.label(dateFormatter);
          // labels = deviceReport[details.chartId].map((d) => labelMaker(d));
          backgroundColor = deviceReport[details.chartId].map(
            details.backgroundColor,
          );
          borderColor = deviceReport[details.chartId].map(details.borderColor);
        }
        const ctx = document.getElementById(details.chartId);
        if (myChart.current[details.chartId]) {
          myChart.current[details.chartId].destroy();
        }
        myChart.current[details.chartId] = new Chart(ctx, {
          type: 'scatter',
          data: {
            // labels,
            datasets: [
              {
                showLine: true,
                label: details.title,
                data,
                pointBorderColor: borderColor,
                pointBackgroundColor: backgroundColor,
                borderColor: settings.colors.blue,
                borderWidth: 1,
                original: deviceReport?.[details.chartId],
              },
              // data2 && {
              //   data: data2,
              //   backgroundColor: Array(data.length).fill('#e7282f1f'),
              //   borderColor: Array(data.length).fill('#e7282f'),
              //   borderWidth: 1,
              //   fill: 1,
              // },
            ].filter((d) => d),
          },
          options: {
            responsive: true,
            scales: {
              // y: {
              //   beginAtZero: true,
              //   stacked: true,
              // },
              x: {
                type: 'linear',
                position: 'bottom',
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: details.tooltip(dateFormatter),
                },
              },
            },
          },
        });
      });
    }, 0);
  }, [deviceReport, locale]);

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      {contextHolder}
      <CSPageHeader
        backActions={[
          <Link to={`/report-dashboard/view/${reportId}/graphs`} key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Report
            </Button>
          </Link>,
        ]}
        titleComponent={title}
      />
      <LoadingModal loading={loading} />
      <div ref={containerRef}>
        {ChartsToShow.map((details) => (
          <div key={details.chartId} style={{ marginBottom: 16 }}>
            <Title level={2}>{details.title}</Title>
            {width !== undefined && (
              <canvas id={details.chartId} width={width} height={300} />
            )}
          </div>
        ))}
      </div>
    </CSPage>
  );
}

export default ViewDeviceReportDetails;
