import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, notification, Space, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { updateProfileAction } from '../../../redux-store/auth-store';
import { editProfileMutation } from '../constants';
import UserQuestions from './questions';

const { Text } = Typography;

function EditProfileForm({
  _id,
  handleCancel,
  handleSuccess,
  profile,
  loading,
  style,
  fieldsToSet = {},
}) {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [editProfile] = useMutation(editProfileMutation);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (profile) {
      const { profilePhoto, ...otherFields } = profile;
      form.setFieldsValue(otherFields);
    }
  }, [profile, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      setError(null);
      const {
        confirm,
        peopleGroups: selectedPeopleGroups,
        ...values
      } = _values;
      try {
        if (profile) {
          const result = await editProfile({
            variables: {
              profile: {
                _id,
                ...values,
                ...UserQuestions.phone.finish(values),
                ...fieldsToSet,
              },
            },
          });
          dispatch(updateProfileAction(result.data.editProfile.profile));
          notification.success({
            message: 'Saved',
            description: 'Profile updated successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setSaving(false);
    },
    [
      profile,
      _id,
      editProfile,
      dispatch,
      fieldsToSet,
      setSaving,
      handleSuccess,
    ],
  );

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      id="editUser"
      form={form}
      style={style}
    >
      <UserQuestions.profilePhoto.FormItem
        loading={loading || saving}
        fieldsToSet={fieldsToSet}
        user={profile}
        userId={_id}
        form={form}
      />
      <UserQuestions.username.Display user={profile} />
      <UserQuestions.name.FormItem
        loading={loading || saving}
        fieldsToSet={fieldsToSet}
        user={profile}
        userId={_id}
        form={form}
      />
      <UserQuestions.email.FormItem
        loading={loading || saving}
        fieldsToSet={fieldsToSet}
        user={profile}
        userId={_id}
        form={form}
      />
      <UserQuestions.phone.FormItem
        loading={loading || saving}
        fieldsToSet={fieldsToSet}
        user={profile}
        userId={_id}
        form={form}
      />
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading || saving}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button
            key="send"
            type="primary"
            loading={loading || saving}
            htmlType="submit"
          >
            Save Profile
          </Button>
        </Space>
      </Form.Item>
      {error && (
        <Text type="danger" style={{ marginTop: 16 }}>
          {error}
        </Text>
      )}
    </Form>
  );
}

export default EditProfileForm;
