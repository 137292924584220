import { Select } from 'antd';
import React from 'react';

function SelectMultiple({ options, value, onChange, ...other }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Filter devices by tenant"
      allowClear
      mode="multiple"
      {...other}
    >
      {Object.values(options).map((field) => (
        <Select.Option key={field.key} value={field.key}>
          {field.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectMultiple;
