import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteJoonDeviceAction } from '../../redux-store/joon-devices-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { deleteJoonDeviceMutation } from './constants';

function DeleteJoonDeviceModal({ joonDevice, setJoonDevice }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteJoonDevice] = useMutation(deleteJoonDeviceMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteJoonDevice({
        variables: {
          _id: joonDevice._id,
        },
      });
      dispatch(deleteJoonDeviceAction(joonDevice._id));
      notification.success({
        message: 'Deleted',
        description: 'Joon Device deleted successfully',
      });
      setTimeout(() => {
        setJoonDevice(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [joonDevice, deleteJoonDevice, setJoonDevice, dispatch, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setJoonDevice(null);
    }
  }, [setJoonDevice, loading]);

  return (
    <YesNoModal
      title="Delete JoonDevice"
      question={`Are you sure you want to delete this Joon Device (${
        joonDevice && joonDevice._id
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!joonDevice}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteJoonDeviceModal;
