import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { notNullOrUndefined } from '../../../shared/utils';
import PlotSomethingPie from './PlotSomethingPie';
import SelectMultipleFilter from './SelectMultipleFilter';

const { Title } = Typography;

function getLabel(label, noLabel) {
  if (typeof label === 'boolean' && notNullOrUndefined(label)) {
    return label ? 'True' : 'False';
  }
  return notNullOrUndefined(label) ? label : noLabel;
}

function makeValuesArray(reports, idAttr, makeLabel, noLabel) {
  if (reports) {
    const valuesObj = reports.reduce((prev, curr) => {
      let _id;
      if (typeof idAttr === 'function') {
        _id = idAttr(curr);
      } else {
        _id = curr[idAttr];
      }
      if (!prev[_id]) {
        prev[_id] = {
          count: 0,
          _id,
          label: makeLabel(curr, noLabel),
        };
      }
      prev[_id].count += 1;
      return prev;
    }, {});
    const valuesArr = Object.values(valuesObj);

    return valuesArr;
  }
  return [];
}

function ASomethingPie({
  unfilteredReports,
  filteredReports,
  style,
  loading,
  name,
  idAttr,
  makeLabel,
  noLabel,
  filterLabel,
}) {
  const items = useMemo(() => {
    if (unfilteredReports) {
      const valuesArr = makeValuesArray(
        unfilteredReports,
        idAttr,
        makeLabel,
        noLabel,
      );
      return valuesArr;
    }
    return [];
  }, [unfilteredReports, idAttr, makeLabel, noLabel]);
  const axis = useMemo(() => {
    if (filteredReports) {
      const valuesArr = makeValuesArray(
        filteredReports,
        idAttr,
        makeLabel,
        noLabel,
      );
      const labels = valuesArr.map((v) => v.label);
      const values = valuesArr.map((v) => v.count);
      return {
        labels,
        values,
      };
    }
    return [];
  }, [filteredReports, idAttr, makeLabel, noLabel]);
  return (
    <div style={style}>
      <div style={{ marginBottom: 8 }}>
        <SelectMultipleFilter
          name={name}
          items={items}
          noLabel={noLabel}
          filterLabel={filterLabel}
        />
      </div>
      <PlotSomethingPie
        loading={loading}
        name={name}
        height={600}
        mode={undefined}
        axes={[axis]}
      />
    </div>
  );
}

export default ASomethingPie;
