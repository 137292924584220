import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import settings from '../../../../settings';
import { useManyRemote } from '../../../../shared/use-many-remote';
import { allJoonDailiesQuery } from '../../constants';
import Chart from 'chart.js/auto';
import { Button, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { ExportOutlined } from '@ant-design/icons';
import { shortEnglishHumanizer, zeroPad } from '../../../../shared/utils';

const { Title } = Typography;

const ChartsToShow = [
  {
    title: 'Steps',
    chartId: 'stepCount',
    label: (context) => {
      return `${context.raw} Steps`;
    },
  },
  {
    title: 'Screen On Minutes',
    chartId: 'screeOnSecs',
    parse: (d) => (d || 0) / 60,
    max: 24 * 60,
    label: (context) => {
      return `${shortEnglishHumanizer(context.raw * 1000 * 60, {
        largest: 2,
        round: true,
      })}`;
    },
  },
  {
    title: 'Powered On Hours',
    chartId: 'onSecs',
    parse: (d) => (d || 0) / 60 / 60,
    max: 24,
    label: (context) => {
      return `${shortEnglishHumanizer(context.raw * 1000 * 60 * 60, {
        largest: 2,
        round: true,
      })}`;
    },
  },
  {
    title: 'On Charger Hours',
    chartId: 'onChargerSecs',
    parse: (d) => (d || 0) / 60 / 60,
    max: 24,
    label: (context) => {
      return `${shortEnglishHumanizer(context.raw * 1000 * 60 * 60, {
        largest: 2,
        round: true,
      })}`;
    },
  },
  {
    title: 'Detected Falls',
    chartId: 'detectedFallCount',
    parse: (d) => d || 0,
    label: (context) => {
      return `${context.raw} Falls`;
    },
  },
  {
    title: 'SOS Calls',
    chartId: 'sosCalls',
    parse: (d) => d || 0,
    label: (context) => {
      return `${context.raw} Calls`;
    },
  },
];

function DailyStats({ joonDevice, handleExportToCsv }) {
  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      from: `${fromDate.getFullYear()}-${zeroPad(
        fromDate.getMonth() + 1,
        2,
      )}-${zeroPad(fromDate.getDate(), 2)}`,
      until: `${untilDate.getFullYear()}-${zeroPad(
        untilDate.getMonth() + 1,
        2,
      )}-${zeroPad(untilDate.getDate(), 2)}`,
      deviceId: joonDevice._id,
    };
  }, [joonDevice]);

  const [filters, setFilters] = useState(initialFilters);
  const {
    error,
    loading,
    data: usages,
    refetch,
    search: usageSearch,
  } = useManyRemote(
    allJoonDailiesQuery,
    (data) => data.allJoonDailies,
    settings.querySize,
    filters,
    [{ key: 'date', order: 'ASC' }],
  );
  const containerRef = useRef();
  const [width, setWidth] = useState();
  const myChart = useRef({});
  const locale = useSelector((store) => store.locale, shallowEqual);
  useLayoutEffect(() => {
    setWidth(containerRef.current.offsetWidth);
    setTimeout(() => {
      ChartsToShow.forEach((details) => {
        let data = [];
        let data2;
        let labels = [];
        if (usages && usages.length) {
          data = usages.map((d) =>
            details.parse && details.max
              ? Math.min(details.parse(d[details.chartId]), details.max || 0)
              : d[details.chartId],
          );
          if (details.max && details.parse) {
            data2 = usages.map(
              (d) =>
                details.max -
                Math.min(details.parse(d[details.chartId]), details.max || 0),
            );
          }
          const dateFormatter = new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          });
          labels = usages.map((d) =>
            dateFormatter.format(new Date(`${d.date}T00:00:00`)),
          );
        }
        const ctx = document.getElementById(details.chartId);
        const backgroundColor = data.map((d) =>
          d > 0.5 ? '#52c41a1f' : '#e7282f1f',
        );
        const borderColor = data.map((d) => (d > 0.5 ? '#52c41a' : '#e7282f'));
        if (myChart.current[details.chartId]) {
          myChart.current[details.chartId].destroy();
        }
        myChart.current[details.chartId] = new Chart(ctx, {
          type: 'bar',
          data: {
            labels,
            datasets: [
              {
                label: details.title,
                data,
                backgroundColor,
                borderColor,
                borderWidth: 1,
              },
              data2 && {
                data: data2,
                backgroundColor: Array(data.length).fill('#e7282f1f'),
                borderColor: Array(data.length).fill('#e7282f'),
                borderWidth: 1,
                fill: 1,
              },
            ].filter((d) => d),
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
              },
              x: {
                stacked: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: details.label,
                },
              },
            },
          },
        });
      });
    }, 0);
  }, [usages, locale]);

  handleExportToCsv.current = useCallback(() => {
    if (usages && usages.length) {
      const data = [];
      const dateFormatter = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
      });
      ChartsToShow.forEach((details) => {
        usages
          .filter((d) => d.type === details.type)
          .forEach((d) => {
            data.push({
              IMEI: joonDevice._id,
              'Serial No.': joonDevice.serialNo,
              ICCID: joonDevice.iccid,
              IMSI: joonDevice.imsi,
              'Data Type': details.title,
              Date: dateFormatter.format(new Date(d.timestampMs)),
              Usage: d.totalUsage > 0 ? 'Used' : 'Not Used',
            });
          });
      });
      const options = mkConfig({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showColumnHeaders: true,
        useTextFile: false,
        useBom: true,
        filename: `DailyStats-${dateFormatter
          .format(new Date(usages[usages.length - 1].timestampMs))
          .replace(/\//g, '-')}-IMEI${joonDevice._id}`,
        columnHeaders: [
          'IMEI',
          'Serial No.',
          'ICCID',
          'IMSI',
          'Data Type',
          'Date',
          'Usage',
        ],
      });

      const csv = generateCsv(options)(data);
      download(options)(csv);
    }
  }, [usages, joonDevice, locale]);
  return (
    <div ref={containerRef}>
      {ChartsToShow.map((details) => (
        <div key={details.chartId} style={{ marginBottom: 16 }}>
          <Title level={2}>{details.title}</Title>
          {width !== undefined && (
            <canvas id={details.chartId} width={width} height={300} />
          )}
        </div>
      ))}
      <style jsx>{``}</style>
    </div>
  );
}

export default DailyStats;
