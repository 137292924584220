import { Typography } from 'antd';
import React from 'react';
import SendRequestHeartbeatCommand from './SendRequestHeartbeatCommand';

const { Title, Text } = Typography;

function TenantAdminCommands({ joonDevice }) {
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <SendRequestHeartbeatCommand joonDevice={joonDevice} />
    </div>
  );
}

export default TenantAdminCommands;
