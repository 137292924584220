import humanizeDuration from 'humanize-duration';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

function useUpdatedAgo(deviceId) {
  const joonNow = useSelector(
    (store) => store.joonNows[deviceId]?.[deviceId],
    shallowEqual,
  );
  const [updatedNow, setUpdatedNow] = useState();
  const updatedTimeout = useRef();
  useEffect(() => {
    if (joonNow && joonNow.latestLocationTimestampMs) {
      updatedTimeout.current = setInterval(() => {
        const locAgo =
          new Date().getTime() -
          new Date(joonNow.latestLocationTimestampMs).getTime();
        const commAgo =
          new Date().getTime() -
          new Date(joonNow.latestMsgTimestampMs).getTime();
        setUpdatedNow({
          location: `${humanizeDuration(locAgo, {
            largest: 1,
            round: true,
          })} ago`,
          comms: `${humanizeDuration(commAgo, {
            largest: 1,
            round: true,
          })} ago`,
        });
      }, 1000);
    }
    return () =>
      updatedTimeout.current && clearInterval(updatedTimeout.current);
  }, [joonNow]);
  return updatedNow;
}

export default useUpdatedAgo;
