import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonNowAction,
  deleteJoonNowAction,
  updateJoonNowAction,
} from '../../../redux-store/joon-nows-store';

export const JoonNowAttributes = gql`
  {
    _id
    screeOnSecs
    onChargerSecs
    onSecs
    stepCount
    stepsSinceMidnight
    onSinceMMs
    onChargerSinceMMs
    screenOnSinceMMs
    sinceBootMs
    detectedFallCount
    verifiedFallCount
    cancelledFallCount
    noResponseFallCount
    callMadeFallCount
    sosCalls
    fwVer
    appVer
    appFlavor
    confVer
    signalLevel
    batteryLevel
    loc {
      type
      lat
      lon
      alt
      acc
      speed
      bearing
    }
    drain
    timezone
    isPoweredOn
    isCharging
    wifiSsid
    isTurboMode
    online
    latestMsgTimestampMs
    latestLocationTimestampMs
    latestHeartbeatTimestampMs
    batteryLifeStart {
      timestampMs
      level
    }
    batteryLifeEnd {
      timestampMs
      level
    }
    latestBatteryLife {
      timestampMsDelta
      levelDelta
    }
    updatedAt
  }
`;

export const JoonNowUpdateAttributes = gql`
{
  type
  crud
  id
  new ${JoonNowAttributes}
}
`;

export const joon3NowUpdatesSubscription = gql`
  subscription JoonNowUpdates($deviceId: ID!) {
    joon3NowUpdates(deviceId: $deviceId) ${JoonNowUpdateAttributes}
  }
`;

function useJoonNowUpdates(deviceId) {
  const queryId = deviceId || 'default';
  const dispatch = useDispatch();
  useSubscription(joon3NowUpdatesSubscription, {
    skip: !deviceId,
    variables: {
      deviceId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.joon3NowUpdates;
        switch (update.type) {
          case 'JOON_NOW_CRUD': {
            switch (update.crud) {
              case 'create':
                dispatch(createJoonNowAction(update.new, queryId));
                break;
              case 'read':
                dispatch(updateJoonNowAction(update.new, queryId));
                break;
              case 'update':
                dispatch(updateJoonNowAction(update.new, queryId));
                break;
              case 'delete':
                dispatch(deleteJoonNowAction(update.id, queryId));
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useJoonNowUpdates;
