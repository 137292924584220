import {
  CodeOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { readFirmwareVersionsAction } from '../../../redux-store/firmware-versions-store';
import settings from '../../../settings';
import useMany from '../../../shared/use-many';
import { generateUuid } from '../../../shared/utils';
import { allFirmwareVersionsQuery } from '../constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Title, Text } = Typography;

function ListFirmwareVersions() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const [form] = Form.useForm();

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: firmwareVersions,
    refetch,
    search: fwVersionSearch,
  } = useMany(
    allFirmwareVersionsQuery,
    (data) => data.allFirmwareVersions,
    readFirmwareVersionsAction,
    'firmwareVersions',
    ['deviceType', 'appName', 'appFlavor', 'versionName', 'tags', 'notes'],
    settings.querySize,
    {},
    (a, b) => b.versionNumber - a.versionNumber,
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        fwVersionSearch(changed.search);
      }
    },
    [fwVersionSearch],
  );
  return (
    <CSPage title="FirmwareVersions" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <CodeOutlined style={{ marginRight: 16 }} />
            Firmware Versions
          </Title>
        }
        topActions={[
          <Link
            key="add"
            to={{
              pathname: `fw-version/edit/${generateUuid()}`,
              state: { mode: 'create' },
            }}
          >
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new Firmware Version
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={firmwareVersions}
        loading={!firmwareVersions.length && loading}
        pagination={{ ...pagination, total: firmwareVersions.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="Device Type" dataIndex="deviceType" key="deviceType" />
        <Column title="App Name" dataIndex="appName" key="appName" />
        <Column title="App Flavor" dataIndex="appFlavor" key="appFlavor" />
        <Column
          title="Version Name"
          dataIndex="versionName"
          key="versionName"
        />
        <Column
          title="Version #"
          dataIndex="versionNumber"
          key="versionNumber"
        />
        <Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.createdAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {record.fingerprint && (
                <Tooltip title="Copy Fingerprint">
                  <CopyToClipboard
                    text={record.fingerprint}
                    onCopy={() => message.success(record.fingerprint)}
                  >
                    <Button icon={<CopyOutlined />} />
                  </CopyToClipboard>
                </Tooltip>
              )}
              {record.downloadUrl && (
                <Tooltip title="Download">
                  <a key="download" href={record.downloadUrl}>
                    <Button icon={<DownloadOutlined />} />
                  </a>
                </Tooltip>
              )}
              <Tooltip title="Edit">
                <Link
                  key="add"
                  to={{
                    pathname: `fw-version/edit/${record._id}`,
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </CSPage>
  );
}

export default ListFirmwareVersions;
