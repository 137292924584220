import { CopyOutlined } from '@ant-design/icons';
import { Button, Form, Typography, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Title, Text, Paragraph } = Typography;

const ViewWebhookModal = ({ webhook, onYes }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <>
      <Modal
        title={null}
        open={!!webhook}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        okText="OK"
        onOk={onYes}
        footer={null}
      >
        <div style={{ textAlign: 'center', paddingTop: 16 }}>
          <Text style={{ marginBottom: 0 }}>Webhook</Text>
          <Title level={1} style={{ marginTop: 0 }}>
            {webhook && webhook.name}
          </Title>
        </div>

        {webhook && (
          <Paragraph
            style={{
              marginBottom: 16,
            }}
          >
            <pre>{webhook.publicKey}</pre>
          </Paragraph>
        )}
        <Paragraph
          style={{
            marginBottom: 32,
          }}
        >
          {`This is your webhook's public key. Use this to verify incoming updates are from us.`}
        </Paragraph>
        <div style={{ textAlign: 'center' }}>
          {webhook && (
            <CopyToClipboard
              text={webhook.publicKey}
              onCopy={() => message.success('Copied!')}
            >
              <Button
                style={{ marginRight: 16 }}
                icon={<CopyOutlined />}
                type="primary"
              >
                Copy to Clipboard
              </Button>
            </CopyToClipboard>
          )}
          <Button onClick={onYes}>Done</Button>
        </div>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ViewWebhookModal;
