import { Button, Form, Input, Radio, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import DisplayDefault from '../DisplayDefault';
import {
  displayPhoneNumberSetting,
  getSettingOptions,
  getUnifiedSetting,
  notNullOrUndefined,
} from '../utils';
import { parsePhoneNumber } from 'awesome-phonenumber';

const { Title, Text } = Typography;

export const validatePhoneNumber = ({ getFieldValue: _getFieldValue }) => ({
  validator(_, v) {
    try {
      let ph = v.trim();
      if (ph) {
        let parsed;
        if (ph.startsWith('+')) {
          parsed = parsePhoneNumber(ph);
        } else {
          parsed = parsePhoneNumber(ph, {
            regionCode: 'US',
          });
        }
        if (!parsed.valid) {
          return Promise.reject(new Error(`${v} is not a valid phone number`));
        }
      }
      return Promise.resolve();
    } catch (err) {
      console.error(err);
      return Promise.reject(`Error: ${err.message}`);
    }
  },
});

export function formatPhoneNumber(text) {
  let ph = text.trim();
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    if (parsed.valid && parsed?.number?.e164) {
      return parsed?.number?.e164;
    }
  }
  return null;
}

function MyFormItem({ label, settingKey, initialValue, saving, required }) {
  return (
    <Form.Item
      label={label}
      name={settingKey}
      initialValue={initialValue}
      rules={[
        validatePhoneNumber,
        {
          required,
          message: 'This field is required',
        },
      ]}
    >
      <Input disabled={saving} />
    </Form.Item>
  );
}

function EditPhoneNoSettingModal({
  systemConfig,
  tenantConfig,
  deviceConfig,
  saving,
  handleFinish,
  onCancel,
  table,
  settingKey,
  label,
  initialValue,
  required,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    const [value, src] = getUnifiedSetting(
      systemConfig,
      tenantConfig,
      deviceConfig,
      table,
      settingKey,
    );
    form.setFieldsValue({
      useDefault: src,
      [settingKey]: value,
    });
  }, [systemConfig, tenantConfig, deviceConfig, , form, table, settingKey]);
  const onFinish = useCallback(
    (values) => {
      const { [settingKey]: selected, useDefault } = values;
      let value;
      if (deviceConfig && useDefault === 'device') {
        value = selected;
      } else if (!deviceConfig && useDefault === 'tenant') {
        value = selected;
      } else {
        value = null;
      }
      const phoneNumber = formatPhoneNumber(value);
      handleFinish({
        [table]: {
          _id: new Date().toISOString(),
          [settingKey]: phoneNumber,
        },
      });
    },
    [handleFinish, table, settingKey, deviceConfig],
  );
  const [systemAttr, tenantAttr, deviceAttr] = useMemo(() => {
    return getSettingOptions(
      systemConfig,
      tenantConfig,
      deviceConfig,
      table,
      settingKey,
    );
  }, [systemConfig, tenantConfig, deviceConfig, table, settingKey]);
  return (
    <>
      <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
        {label}
      </Title>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="useDefault">
          <Radio.Group
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {(notNullOrUndefined(systemAttr) || !required) && (
              <Radio
                disabled={!!deviceConfig && notNullOrUndefined(tenantAttr)}
                value="system"
                style={{ marginBottom: 8 }}
              >
                {`System default (${displayPhoneNumberSetting(systemAttr)})`}
              </Radio>
            )}
            {!!deviceConfig && notNullOrUndefined(tenantAttr) && (
              <Radio
                value="tenant"
                style={{ marginBottom: 8 }}
              >{`Tenant default (${displayPhoneNumberSetting(
                tenantAttr,
              )})`}</Radio>
            )}
            {deviceConfig ? (
              <Radio value="device">Specify for this device</Radio>
            ) : (
              <Radio value="tenant">Specify for this tenant</Radio>
            )}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.useDefault !== curValues.useDefault
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('useDefault') == 'system' ? (
              <DisplayDefault
                label={label}
                value={displayPhoneNumberSetting(systemAttr)}
              />
            ) : getFieldValue('useDefault') == 'tenant' ? (
              <>
                {deviceConfig ? (
                  <DisplayDefault
                    label={label}
                    value={displayPhoneNumberSetting(tenantAttr)}
                  />
                ) : (
                  <MyFormItem
                    label={label}
                    settingKey={settingKey}
                    initialValue={initialValue}
                    required={required}
                    saving={saving}
                  />
                )}
              </>
            ) : (
              <MyFormItem
                label={label}
                settingKey={settingKey}
                initialValue={initialValue}
                required={required}
                saving={saving}
              />
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={onCancel}
              htmlType="button"
              disabled={saving}
              style={{ marginRight: 16 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditPhoneNoSettingModal;
