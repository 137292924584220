export const READ_ROLES = 'READ_ROLES';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

// eslint-disable-next-line no-shadow
export const readRolesAction = (roles, queryId = 'default') => ({
  type: READ_ROLES,
  payload: {
    roles,
    queryId,
  },
});

export const createRoleAction = (role, queryId = 'default') => ({
  type: CREATE_ROLE,
  payload: {
    role,
    queryId,
  },
});

export const updateRoleAction = (role, queryId = 'default') => ({
  type: UPDATE_ROLE,
  payload: {
    role,
    queryId,
  },
});

export const deleteRoleAction = (roleId, queryId = 'default') => ({
  type: DELETE_ROLE,
  payload: {
    roleId,
    queryId,
  },
});

const initialRoles = { default: {} };

export function roles(state = initialRoles, action) {
  switch (action.type) {
    case READ_ROLES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.roles.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ROLE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.role._id]: action.payload.role,
        },
      };
    case UPDATE_ROLE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.role._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.role._id]: {
            ...current,
            ...action.payload.role,
          },
        },
      };
    }
    case DELETE_ROLE: {
      const { roleId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [roleId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
