import { gql, useQuery } from '@apollo/client';
import { Button, List, Modal, Skeleton, Typography } from 'antd';
import humanizeDuration from 'humanize-duration';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../../settings';
import { getFormattedPhoneNumber } from '../../../../shared/utils';
import {
  EventLevels,
  JoonDeviceEventTypes,
  JoonLocationTypes,
} from '../../constants';
import { notNullOrUndefined } from '../settings-v2/utils';

const { Title, Paragraph, Text } = Typography;

export const eventQuery = gql`
  query JoonDeviceEvent($_id: ID!) {
    joonDeviceEvent(_id: $_id) {
      _id
      type
      level
      tenantId
      tenant {
        _id
        name
      }
      userIds
      users {
        _id
        name
      }
      contactId
      contact {
        _id
        name
      }
      initById
      initiatedBy {
        _id
        name
      }
      deviceId
      timestampMs
      sentAt
      receivedAt
      signalLevel
      batteryLevel
      stepCount
      stepsSinceMidnight
      mTxBytes
      mRxBytes
      mBytesMs
      earfcn
      onSinceMMs
      onChargerSinceMMs
      screenOnSinceMMs
      sinceBootMs
      loc {
        type
        lat
        lon
        alt
        acc
        speed
        bearing
      }
      drain
      screenOnSecs
      onChargerSecs
      onSecs
      timezone
      isCharging
      isTurboMode
      iccid
      msisdn
      fwVer
      appVer
      appFlavor
      confVer
      commVer
      extraId
      wifiSsid
      userSettings {
        shakeToWake
        lockdownModeEnabled
        screenBrightness
        callVolume
        ringVolume
      }
      powerOffReason
      notes
    }
  }
`;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text style={{ color: settings.colors.primary, fontSize: 14 }}>
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 14 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

function ViewEventModal({ visible, onClose }) {
  const { event: parentEvent } = visible || {};

  const { loading, data } = useQuery(eventQuery, {
    variables: {
      _id: parentEvent?._id,
    },
    skip: !parentEvent,
    fetchPolicy: 'cache-first',
  });
  const event = useMemo(() => data?.joonDeviceEvent, [data]);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
        timeStyle: 'long',
      }),
    [locale],
  );
  const timeFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        timeStyle: 'medium',
      }),
    [locale],
  );
  const byteFormat = Intl.NumberFormat(locale, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });

  const fields = useMemo(() => {
    if (event) {
      return [
        event.loc && {
          title: 'Location',
          component: (
            <div>
              <div>{JoonLocationTypes[event.loc.type]?.label}</div>
              <div>{`${event.loc.lat}, ${event.loc.lon}`}</div>
              <div>{`${new Intl.NumberFormat('en-US', {
                style: 'unit',
                unit: 'meter',
              }).format(event.loc.acc)}`}</div>
            </div>
          ),
        },
        event.signalLevel && {
          title: 'Signal Strength (RSRP)',
          value: `${event.signalLevel} dBm`,
        },
        event.batteryLevel && {
          title: 'Battery Percent',
          value: `${event.batteryLevel}%`,
        },
        event.stepsSinceMidnight && {
          title: 'Step Count (Since Midnight)',
          value: `${event.stepsSinceMidnight} steps`,
        },

        event.sentAt && {
          title: 'Message Sent',
          value: timeFormat.format(event.sentAt),
        },
        event.receivedAt && {
          title: 'Message Received',
          value: timeFormat.format(event.receivedAt),
        },
        event.earfcn && {
          title: 'EARFNC',
          value: event.earfcn,
        },
        event.mRxBytes && {
          title: 'Cellular RX Bytes',
          value: byteFormat.format(event.mRxBytes),
        },
        event.mTxBytes && {
          title: 'Cellular TX Bytes',
          value: byteFormat.format(event.mTxBytes),
        },
        notNullOrUndefined(event.mBytesMs) && {
          title: 'Cellular Bytes Period',
          value: humanizeDuration(event.mBytesMs, {
            largest: 1,
            round: true,
          }),
        },
        notNullOrUndefined(event.onSinceMMs) && {
          title: 'Time on since midnight',
          value: humanizeDuration(event.onSinceMMs, {
            largest: 1,
            round: true,
          }),
        },
        notNullOrUndefined(event.onChargerSinceMMs) && {
          title: 'Time charging since midnight',
          value: humanizeDuration(event.onChargerSinceMMs, {
            largest: 1,
            round: true,
          }),
        },
        notNullOrUndefined(event.screenOnSinceMMs) && {
          title: 'Screen on time since midnight',
          value: humanizeDuration(event.screenOnSinceMMs, {
            largest: 1,
            round: true,
          }),
        },
        notNullOrUndefined(event.sinceBootMs) && {
          title: 'Time since boot',
          value: humanizeDuration(event.onChargerSinceMMs, {
            largest: 1,
            round: true,
          }),
        },
        event.timezone && {
          title: 'Timezone',
          value: event.timezone,
        },
        notNullOrUndefined(event.isCharging) && {
          title: 'Is charging',
          value: event.isCharging ? 'Yes' : 'No',
        },
        notNullOrUndefined(event.isTurboMode) && {
          title: 'Is in Turbo Mode',
          value: event.isTurboMode ? 'Yes' : 'No',
        },
        event.wifiSsid && {
          title: 'Current Wi-Fi SSID',
          value: event.wifiSsid,
        },
        event.deviceId && {
          title: 'IMEI',
          value: event.deviceId,
        },
        event.iccid && {
          title: 'ICCID',
          value: event.iccid,
        },
        event.msisdn && {
          title: 'MSISDN',
          value: getFormattedPhoneNumber(event),
        },
        event.fwVer && {
          title: 'FW Version',
          value: event.fwVer,
        },
        event.appVer && {
          title: 'App Version',
          value: event.appVer,
        },
        event.appFlavor && {
          title: 'App Flavor',
          value: event.appFlavor,
        },
        event.commVer && {
          title: 'API Version',
          value: event.commVer,
        },
        event.confVer && {
          title: 'Configuration Version',
          value: event.confVer,
        },
        event.j3ConfVers?.callCenter && {
          title: 'Call Center Settings Version',
          value: event.j3ConfVers?.callCenter,
        },
        event.j3ConfVers?.environment && {
          title: 'Environment Settings Version',
          value: event.j3ConfVers?.environment,
        },
        event.j3ConfVers?.fallDetection && {
          title: 'Fall Detection Settings Version',
          value: event.j3ConfVers?.fallDetection,
        },
        event.j3ConfVers?.general && {
          title: 'General Settings Version',
          value: event.j3ConfVers?.general,
        },
        event.j3ConfVers?.onDevice && {
          title: 'Device Settings Version',
          value: event.j3ConfVers?.onDevice,
        },
        event.j3ConfVers?.user && {
          title: 'User Settings Version',
          value: event.j3ConfVers?.user,
        },
        notNullOrUndefined(event.userSettings?.shakeToWake) && {
          title: 'User Settings - Shake-to-wake',
          value: event.userSettings?.shakeToWake ? 'Enabled' : 'Disabled',
        },
        notNullOrUndefined(event.userSettings?.lockdownModeEnabled) && {
          title: 'User Settings - Lockdown Mode',
          value: event.userSettings?.lockdownModeEnabled
            ? 'Enabled'
            : 'Disabled',
        },
        notNullOrUndefined(event.userSettings?.screenBrightness) && {
          title: 'User Settings - Screen Brightness',
          value: event.userSettings?.screenBrightness,
        },
        notNullOrUndefined(event.userSettings?.callVolume) && {
          title: 'User Settings - Call Volume',
          value: event.userSettings?.callVolume,
        },
        notNullOrUndefined(event.userSettings?.ringVolume) && {
          title: 'User Settings - Ring Volume',
          value: event.userSettings?.ringVolume,
        },
        notNullOrUndefined(event.userSettings?.useMetric) && {
          title: 'User Settings - Use Metric',
          value: event.userSettings?.useMetric ? 'Yes' : 'No',
        },
        event.powerOffReason && {
          title: 'Power-off reason',
          value: event.powerOffReason,
        },
        event.notes && {
          title: 'Extra Notes',
          value: event.notes,
        },
        event.contact && {
          title: 'Contact',
          value: event.contact.name,
        },
        event.users?.length > 0 && {
          title: 'Users',
          value: event.users.map((u) => u.name).join(', '),
        },
        event.tenant && {
          title: 'Tenant',
          value: event.tenant.name,
        },
        event.initiatedBy && {
          title: 'Initiated by',
          value: event.initiatedBy.name,
        },
      ].filter((a) => a);
    }
    return [];
  }, [event, timeFormat, byteFormat]);

  return (
    <Modal
      header={null}
      footer={null}
      open={visible}
      closable={true}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={onClose}
      bodyStyle={{ paddingTop: 16 }}
      width={800}
    >
      <Title level={2} style={{ marginBottom: 16 }}>
        Event Details
      </Title>
      {loading && <Skeleton active paragraph avatar />}
      <div
        style={{ display: 'flex', alignItems: 'baseline', marginBottom: 16 }}
      >
        <div>
          <div>
            <Text style={{ fontSize: 24, fontWeight: 600 }}>
              {JoonDeviceEventTypes[event?.type]?.label}
            </Text>
          </div>
          <div>
            <Text>{`Event ID: ${event?._id}`}</Text>
          </div>
          {event?.level && (
            <div>
              <Text>{`Event Level: ${EventLevels[event?.level]?.label}`}</Text>
            </div>
          )}
        </div>
        <div style={{ flex: 1 }} />
        <div>
          <Text style={{ fontSize: 18 }}>
            {dateFormat.format(event?.timestampMs)}
          </Text>
        </div>
      </div>
      {loading && (
        <List
          dataSource={Array(10).fill(0)}
          renderItem={() => <Skeleton active paragraph />}
          locale={{
            emptyText: <div />,
          }}
          grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4, gutter: 16 }}
        />
      )}
      <List
        dataSource={fields}
        renderItem={(item) => (
          <DisplayField
            title={item.title}
            value={item.value}
            component={item.component}
          />
        )}
        locale={{
          emptyText: <div />,
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4, gutter: 16 }}
      />
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Button onClick={onClose} style={{ marginRight: 16 }}>
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ViewEventModal;
