import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsCategoryTable from './SettingsCategoryTable';
import EditCheckboxEnabledModal from './edit-modals/EditCheckboxEnabledModal';
import EditMultiChoiceModal from './edit-modals/EditMultiChoiceModal';
import SelectCallVolume, { CallVolumes } from './edit-modals/SelectCallVolume';
import SelectRingVolume, { RingVolumes } from './edit-modals/SelectRingVolume';
import SelectScreenBrightness, {
  ScreenBrightnesses,
} from './edit-modals/SelectScreenBrightness';
import {
  displayEnabledDisabledSetting,
  displayMultiChoice,
  getUnifiedSetting,
  makeAuditStatusRow,
} from './utils';
import { TenantSettings } from './constants';

const { Title, Text } = Typography;

function OnDeviceSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  audit,
  loading,
  isDevice,
  isTenantAdmin,
}) {
  const makeSettingRow = useCallback(
    (table, key, label, displayFunc, editModal) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return {
        key,
        label,
        value: displayFunc(value),
        src,
        editModal,
        isEditable: !!editModal,
      };
    },
    [systemConfig, tenantConfig, deviceConfig],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig
    ) {
      return [
        makeAuditStatusRow(audit, 'onDevice'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.onDevice?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.onDevice?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig?.onDevice?._id,
          editable: false,
        },
        makeSettingRow(
          'onDevice',
          'shakeToWake',
          'Shake-to-Wake',
          (v) => displayEnabledDisabledSetting(v),
          <EditCheckboxEnabledModal
            table="onDevice"
            settingKey="shakeToWake"
            label="Shake-to-Wake"
            warnText="If you change this setting, you must reboot the watch before it
            comes into effect."
          />,
        ),
        makeSettingRow(
          'onDevice',
          'screenBrightness',
          'Screen Brightness',
          (v) => displayMultiChoice(v, ScreenBrightnesses),
          <EditMultiChoiceModal
            table="onDevice"
            settingKey="screenBrightness"
            label="Screen Brightness"
            SelectComponent={SelectScreenBrightness}
            choices={ScreenBrightnesses}
          />,
        ),
        makeSettingRow(
          'onDevice',
          'callVolume',
          'Call Volume',
          (v) => displayMultiChoice(v, CallVolumes),
          <EditMultiChoiceModal
            table="onDevice"
            settingKey="callVolume"
            label="Call Volume"
            SelectComponent={SelectCallVolume}
            choices={CallVolumes}
          />,
        ),
        makeSettingRow(
          'onDevice',
          'ringVolume',
          'Ring Volume',
          (v) => displayMultiChoice(v, RingVolumes),
          <EditMultiChoiceModal
            table="onDevice"
            settingKey="ringVolume"
            label="Ring Volume"
            SelectComponent={SelectRingVolume}
            choices={RingVolumes}
          />,
        ),
        makeSettingRow(
          'onDevice',
          'useMetric',
          'Use Metric (C°)',
          (v) => displayEnabledDisabledSetting(v),
          <EditCheckboxEnabledModal
            table="onDevice"
            settingKey="useMetric"
            label="Use Metric (C°)"
          />,
        ),
      ]
        .filter((f) => f)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.onDevice.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    audit,
    isDevice,
    makeSettingRow,
    isTenantAdmin,
  ]);

  return (
    <>
      <Title level={2}>On Device Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default OnDeviceSettingsTable;
