import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  List,
  Skeleton,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { updateTenantAction } from '../../redux-store/tenants-store';
import settings from '../../settings';
import useOne from '../../shared/use-one';
import useProfile from '../../shared/use-profile';
import { myTenantQuery } from './constants';
import EditCallCenterSettingsModal from './EditCallCenterSettingsModal';
import {
  CallCenterCos,
  extractConfig,
  joon3ConfigQuery,
  tenantJoon3ConfigQuery,
} from '../joonDevices/ViewJoonDevice/settings-v2/constants';
import useIsTenantAdmin from '../joonDevices/ViewJoonDevice/use-is-tenant-admin';
import { updateJoon3ConfigAction } from '../../redux-store/joon3-configs-store';
import useJoon3ConfigUpdates from '../joonDevices/ViewJoonDevice/settings-v2/use-joon3-config-updates';
import useFilterJoon3Config from '../joonDevices/ViewJoonDevice/settings-v2/use-filter-joon3-config';

const { Title, Text } = Typography;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text style={{ color: settings.colors.primary, fontSize: 14 }}>
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 14 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

const queryId = 'dashboard';

function CallCenterSettings() {
  const profile = useProfile();
  const [notificationApi, contextHolder] = notification.useNotification();
  const fetchResult1 = useOne(
    myTenantQuery,
    (data) => data && data.myTenant,
    updateTenantAction,
    'tenants',
    profile.tenantId,
    queryId,
  );
  const { data: tenant, loading: loading1, error: error1 } = fetchResult1;

  const isTenantAdmin = useIsTenantAdmin();
  const configQuery = useMemo(() => {
    if (isTenantAdmin) {
      return tenantJoon3ConfigQuery;
    }
    return joon3ConfigQuery;
  }, [isTenantAdmin]);
  const fetchResult2 = useOne(
    configQuery,
    (data) => extractConfig(data, isTenantAdmin),
    updateJoon3ConfigAction,
    'joon3Configs',
    tenant?._id,
    tenant?._id,
  );
  useJoon3ConfigUpdates(tenant?._id, isTenantAdmin);
  const tenantConfig = useFilterJoon3Config(tenant?._id);
  const { loading: loading2, error: error2 } = fetchResult2;
  const loading = loading1 || loading2;
  useEffect(() => {
    if (error1 || error2) {
      notificationApi.error({
        description: 'There was an error fetching configuration',
        message: 'Error',
      });
    }
  }, [error1, error2, notificationApi]);

  const callCenterFields = useMemo(() => {
    if (tenant && tenantConfig) {
      return [
        {
          title: 'Call Center',
          value: CallCenterCos[tenantConfig?.callCenter?.type]?.label,
        },
        {
          title: 'Call Center #',
          value: tenantConfig?.callCenter?.callCenterNumber,
        },
        {
          title: "Whitelist #'s",
          value: tenantConfig?.callCenter?.whitelistNumbers,
        },
      ].filter((a) => a.value);
    }
  }, [tenant, tenantConfig]);

  const [editing, setEditing] = useState();
  const handleCancelEditing = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleFinishEditing = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleEdit = useCallback(() => {
    setEditing({ tenant, tenantConfig, queryId });
  }, [tenant, tenantConfig]);

  return (
    <>
      {contextHolder}
      <Card style={{ width: 360 }} bodyStyle={{ position: 'relative' }}>
        <Title level={3}>Call Center Details</Title>
        {loading && <Skeleton active title paragraph />}
        {!loading && tenant && (
          <>
            <List
              dataSource={callCenterFields}
              renderItem={(item) => (
                <DisplayField title={item.title} value={item.value} />
              )}
              loading={loading}
              locale={{
                emptyText: <div />,
              }}
              grid={{ column: 1, gutter: 16 }}
            />
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <Tooltip title="Edit">
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                />
              </Tooltip>
            </div>
          </>
        )}
      </Card>
      <EditCallCenterSettingsModal
        visible={editing}
        onFinish={handleFinishEditing}
        onCancel={handleCancelEditing}
      />
    </>
  );
}

export default CallCenterSettings;
