import { shallowEqual, useSelector } from 'react-redux';

function useFilterJoon3Audit(auditId) {
  const queryId = auditId || 'default';
  const data = useSelector(
    (store) => store.joon3Audits[queryId]?.[auditId],
    shallowEqual,
  );
  return data;
}

export default useFilterJoon3Audit;
