import React, { useEffect } from 'react';
import { notification } from 'antd';
import useOne from '../../shared/use-one';
import { updateJoon3ConfigAction } from '../../redux-store/joon3-configs-store';
import {
  SYSTEM_DEFAULT_CONFIG_ID,
  joon3ConfigQuery,
} from '../joonDevices/ViewJoonDevice/settings-v2/constants';
import useJoon3ConfigUpdates from '../joonDevices/ViewJoonDevice/settings-v2/use-joon3-config-updates';
import useFilterJoon3Config from '../joonDevices/ViewJoonDevice/settings-v2/use-filter-joon3-config';
import GeneralSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/GeneralSettingsTable';
import CallCenterSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/CallCenterSettingsTable';
import OnDeviceSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/OnDeviceSettingsTable';
import FallDetectionSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/FallDetectionSettingsTable';
import UserSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/UserSettingsTable';
import EnvironmentSettingsTable from '../joonDevices/ViewJoonDevice/settings-v2/EnvironmentSettingsTable';

function SettingsTab({ tenant }) {
  const fetchResult2 = useOne(
    joon3ConfigQuery,
    (data) => data && data.joon3Config,
    updateJoon3ConfigAction,
    'joon3Configs',
    tenant._id,
    tenant._id,
  );
  const fetchResult3 = useOne(
    joon3ConfigQuery,
    (data) => data && data.joon3Config,
    updateJoon3ConfigAction,
    'joon3Configs',
    SYSTEM_DEFAULT_CONFIG_ID,
    SYSTEM_DEFAULT_CONFIG_ID,
  );

  const [notificationApi, contextHolder] = notification.useNotification();
  useJoon3ConfigUpdates(tenant._id);
  useJoon3ConfigUpdates(SYSTEM_DEFAULT_CONFIG_ID);
  const tenantConfig = useFilterJoon3Config(tenant._id);
  const systemConfig = useFilterJoon3Config(SYSTEM_DEFAULT_CONFIG_ID);
  const { loading: loading2, error: error2 } = fetchResult2;
  const { loading: loading3, error: error3 } = fetchResult3;
  const loading = loading2 || loading3;
  useEffect(() => {
    if (error2 || error3) {
      notificationApi.error({
        description: 'There was an error fetching configuration',
        message: 'Error',
      });
    }
  }, [error2, error3, notificationApi]);

  return (
    <>
      {contextHolder}
      <GeneralSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
      <CallCenterSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
      <OnDeviceSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
      <FallDetectionSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
      <UserSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
      <EnvironmentSettingsTable
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        loading={loading}
      />
    </>
  );
}

export default SettingsTab;
