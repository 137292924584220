export const READ_API_KEYS = 'READ_API_KEYS';
export const CREATE_API_KEY = 'CREATE_API_KEY';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';

// eslint-disable-next-line no-shadow
export const readApiKeysAction = (apiKeys, queryId = 'default') => ({
  type: READ_API_KEYS,
  payload: {
    apiKeys,
    queryId,
  },
});

export const createApiKeyAction = (apiKey, queryId = 'default') => ({
  type: CREATE_API_KEY,
  payload: {
    apiKey,
    queryId,
  },
});

export const updateApiKeyAction = (apiKey, queryId = 'default') => ({
  type: UPDATE_API_KEY,
  payload: {
    apiKey,
    queryId,
  },
});

export const deleteApiKeyAction = (apiKeyId, queryId = 'default') => ({
  type: DELETE_API_KEY,
  payload: {
    apiKeyId,
    queryId,
  },
});

const initialApiKeys = { default: {} };

export function apiKeys(state = initialApiKeys, action) {
  switch (action.type) {
    case READ_API_KEYS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.apiKeys.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_API_KEY:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.apiKey._id]: action.payload.apiKey,
        },
      };
    case UPDATE_API_KEY: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.apiKey._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.apiKey._id]: {
            ...current,
            ...action.payload.apiKey,
          },
        },
      };
    }
    case DELETE_API_KEY: {
      const { apiKeyId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [apiKeyId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
