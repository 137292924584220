import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonDeviceAction,
  deleteJoonDeviceAction,
  updateJoonDeviceAction,
} from '../../../redux-store/joon-devices-store';
import { JoonDeviceDetailedAttributes } from '../constants';

export const JoonDeviceUpdateAttributes = gql`
{
  type
  crud
  id
  new ${JoonDeviceDetailedAttributes}
}
`;

export const joonDeviceUpdatesSubscription = gql`
  subscription JoonDeviceUpdates($filters: JoonDeviceFilters!) {
    joonDeviceUpdates(filters: $filters) ${JoonDeviceUpdateAttributes}
  }
`;

function useJoonDeviceUpdates(filters) {
  const queryId = JSON.stringify(filters) || 'default';
  useSubscription(joonDeviceUpdatesSubscription, {
    skip: !filters,
    variables: {
      filters,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        if (result && result.data && result.data.joonDeviceUpdates) {
          const updates = result.data.joonDeviceUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'JOON_DEVICE_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoonDeviceAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoonDeviceAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useJoonDeviceUpdates;
