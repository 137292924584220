import { Form, Input } from 'antd';
import React from 'react';
import settings from '../settings';
import { generateUuid } from '../shared/utils';

function AddressFormItem({ field, loading }) {
  return (
    <div className="address">
      <Form.Item
        label="Address Line 1"
        name={[field.name, 'address1']}
        extra="Start typing and select an address"
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item label="Address Line 2" name={[field.name, 'address2']}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="City"
          name={[field.name, 'city']}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <Input autoComplete="chrome-off" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="State"
          name={[field.name, 'state']}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            marginLeft: 16,
          }}
        >
          <Input autoComplete="chrome-off" disabled={loading} />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label="Zip Code"
        name={[field.name, 'zipCode']}
        rules={[
          {
            pattern: /(^[0-9a-zA-Z-]{5,7}$)/,
            message: 'Please enter a valid zip code.',
          },
        ]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Country" name={[field.name, 'country']}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>

      <style jsx>{`
        .address {
          border: 1px solid ${settings.colors.borderGray};
          border-radius: 4px;
          padding: 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default AddressFormItem;
