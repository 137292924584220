import { gql } from '@apollo/client';

export const FallDetectSampleRate = 50;

export const FallDetectAlgorithmNos = {
  6: {
    key: 6,
    label:
      'Algorithm 1 - Intense movement followed by several seconds of no movement',
  },
  7: {
    key: 7,
    label: 'Algorithm 2 - Just intense movement',
  },
};

export const EnvironmentNames = {
  dev: {
    key: 'dev',
    label: 'Development',
  },
  prod: {
    key: 'prod',
    label: 'Production',
  },
  local: {
    key: 'loca',
    label: 'Local',
  },
};

export const CallCenterCos = {
  CLAIRVOYANT: {
    key: 'CLAIRVOYANT',
    label: 'Theora Link 2.0',
  },
  AVANT_GUARD: {
    key: 'AVANT_GUARD',
    label: 'Avant Guard',
  },
  AFFILIATED: {
    key: 'AFFILIATED',
    label: 'Affiliated',
  },
  TEST: {
    key: 'TEST',
    label: 'Test Call Center',
  },
  LEGACY_SS: {
    key: 'LEGACY_SS',
    label: 'Legacy Security Services',
  },
  MOBILE_HELP: {
    key: 'MOBILE_HELP',
    label: 'Mobile Help',
  },
  MEDICAL_GUARDIAN: {
    key: 'MEDICAL_GUARDIAN',
    label: 'Medical Guardian',
  },
};

export const Joon3ConfigAttributes = gql`
  {
    _id
    tenantId
    userIds
    callCenter {
      _id
      type
      callCenterNumber
      transmitCode
      whitelistNumbers
      url
      username
      password
      ip
      port
      sdnis
    }
    environment {
      _id
      envName
      mqttHost
      mqttPort
      apiHost
    }
    fallDetection {
      _id
      enabled
      dataLengthBefore
      dataLengthAfter
      algorithmNo
      verifyFallOnDevice
    }
    general {
      _id
      heartbeatIntervalMs
      lockdownModeEnabled
      canDisconnectSosCall
      showContactsApp
      bootAnimation
      saveLogs
      turboModeIntervalMs
      turboModeTimeoutMs
    }
    onDevice {
      _id
      shakeToWake
      screenBrightness
      callVolume
      ringVolume
      useMetric
    }
    user {
      _id
      contacts {
        id
        name
        phone
        isDisplayed
      }
      wifiNetworks {
        _id
        ssid
        isOpen
        isWep
        safeZoneId
        lastConnectedAt
        isConnected
      }
      ownerId
      ownerName
      wearerName
    }
    createdAt
    updatedAt
  }
`;

export const TenantJoon3ConfigAttributes = gql`
  {
    _id
    tenantId
    userIds
    callCenter {
      _id
      type
      callCenterNumber
      transmitCode
      whitelistNumbers
    }
    environment {
      _id
      envName
    }
    fallDetection {
      _id
      enabled
    }
    general {
      _id
      heartbeatIntervalMs
      lockdownModeEnabled
      canDisconnectSosCall
    }
    onDevice {
      _id
      shakeToWake
      screenBrightness
      callVolume
      ringVolume
      useMetric
    }
    user {
      _id
      contacts {
        id
        name
        phone
        isDisplayed
      }
      wifiNetworks {
        _id
        ssid
        isOpen
        isWep
        safeZoneId
        isConnected
        lastConnectedAt
      }
      ownerId
      ownerName
      wearerName
    }
    createdAt
    updatedAt
  }
`;

export const Joon3AuditAttributes = gql`
  {
    _id
    tenantId
    userIds
    callCenter {
      _id
      type
      callCenterNumber
      transmitCode
      whitelistNumbers
      url
      username
      password
      ip
      port
      sdnis
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    environment {
      _id
      envName
      mqttHost
      mqttPort
      apiHost
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    fallDetection {
      _id
      enabled
      dataLengthBefore
      dataLengthAfter
      algorithmNo
      verifyFallOnDevice
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    general {
      _id
      heartbeatIntervalMs
      lockdownModeEnabled
      canDisconnectSosCall
      showContactsApp
      bootAnimation
      saveLogs
      turboModeIntervalMs
      turboModeTimeoutMs
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    onDevice {
      _id
      shakeToWake
      screenBrightness
      callVolume
      ringVolume
      useMetric
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    user {
      _id
      contacts
      wifiNetworks {
        _id
        ssid
        isOpen
        isWep
        safeZoneId
        connectStatus
        statusMsg
      }
      ownerId
      ownerName
      wearerName
      versionSent
      versionRecv
      versionSaved
      auditStatus
      auditNotes
    }
    legacyVersionSent
    legacyVersionRecv
    legacyVersionSaved
    auditStatus
    auditNotes
    createdAt
    updatedAt
  }
`;

export const joon3ConfigQuery = gql`
  query Joon3Config($_id: ID!) {
    joon3Config(_id: $_id) ${Joon3ConfigAttributes}
  }
`;

export const joon3AuditQuery = gql`
  query Joon3Audit($_id: ID!) {
    joon3Audit(_id: $_id) ${Joon3AuditAttributes}
  }
`;

export const tenantJoon3ConfigQuery = gql`
  query TenantJoon3Config($_id: ID!) {
    tenantJoon3Config(_id: $_id) ${TenantJoon3ConfigAttributes}
  }
`;

export const tenantJoon3AuditQuery = gql`
  query TenantJoon3Audit($_id: ID!) {
    tenantJoon3Audit(_id: $_id) ${Joon3AuditAttributes}
  }
`;

export const SYSTEM_DEFAULT_CONFIG_ID = 'gwRjZkNPqxdLq9aDcnmkmW';

export const extractConfig = (data, isTenantAdmin) => {
  if (isTenantAdmin) {
    return data?.tenantJoon3Config;
  }
  return data?.joon3Config;
};

export const extractAudit = (data, isTenantAdmin) => {
  if (isTenantAdmin) {
    return data?.tenantJoon3Audit;
  }
  return data?.joon3Audit;
};

export const TenantSettings = {
  callCenter: [
    '_id',
    'type',
    'callCenterNumber',
    'transmitCode',
    'whitelistNumbers',
  ],
  environment: ['_id', 'envName'],
  fallDetection: ['_id', 'enabled'],
  general: [
    '_id',
    'heartbeatIntervalMs',
    'lockdownModeEnabled',
    'canDisconnectSosCall',
  ],
  onDevice: [
    '_id',
    'shakeToWake',
    'screenBrightness',
    'callVolume',
    'ringVolume',
    'useMetric',
  ],
  user: [
    '_id',
    'contacts',
    'wifiNetworks',
    'ownerId',
    'ownerName',
    'wearerName',
  ],
};
