import { useMutation } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { deleteGeofenceMutation } from './constants';

const { Title, Text, Paragraph } = Typography;

const DeleteSafeZoneModal = ({ deleting, onSuccess, onCancel }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { safeZone } = deleting || {};

  const [deleteGeofence] = useMutation(deleteGeofenceMutation);

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      await deleteGeofence({
        variables: {
          _id: safeZone._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Safe Zone deleted',
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [onSuccess, deleteGeofence, safeZone]);

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={deleting}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Delete Safe Zone
        </Title>
        <Paragraph style={{ textAlign: 'center' }}>
          Are you sure you want to delete this safe zone?
        </Paragraph>
        <Paragraph style={{ textAlign: 'center' }}>
          {safeZone && <h5>{`${safeZone.name}`}</h5>}
        </Paragraph>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button danger onClick={handleFinish} type="primary">
            Delete
          </Button>
        </div>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default DeleteSafeZoneModal;
