import React from 'react';
import Icon from '@ant-design/icons';

const LocationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ionicon"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
  >
    <title>Location</title>
    <path
      d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="256"
      cy="192"
      r="48"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

export const LocationIcon = (props) => (
  <Icon component={LocationSvg} {...props} />
);

const LocationSvg2 = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 329 428"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55 287.5C96.737 319.67 128.873 348.879 165.5 428C200.992 351.659 232.459 320.325 273.789 287.5H55Z"
      fill="currentColor"
    />
    <circle cx="164.5" cy="164.5" r="164.5" fill="currentColor" />
    <circle cx="164.5" cy="164.5" r="51.5" stroke="white" strokeWidth="16" />
  </svg>
);

export const LocationIcon2 = (props) => (
  <Icon component={LocationSvg2} {...props} />
);
