import { Typography } from 'antd';
import React from 'react';
import theoraLogo from '../assets/esp-logo-sm.png';
import { Helmet } from 'react-helmet';

const { Text } = Typography;

function CSPage({ children, containerStyle, title = 'Theoracare Admin' }) {
  return (
    <div className="page">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="logos">
        <img className="esp-logo" src={theoraLogo} alt="Theora ESP" />
        <Text
          style={{
            color: '#23aae1',
            fontSize: 32,
            marginLeft: 4,
            position: 'relative',
            top: 8,
          }}
        >
          Lite
        </Text>
        {/* <img
          className="maker-logo"
          src={makerLogo}
          alt="Clairvoyant Networks"
        /> */}
      </div>
      <div className="container" style={containerStyle}>
        {children}
      </div>
      <style jsx>{`
        .page {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          flex-direction: column;
          position: relative;
          height: 100vh;
          padding-top: 90px;
          padding-bottom: 50px;
          padding-left: 55px;
          padding-right: 55px;
        }
        .logos {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: -13px;
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          padding-top: 16px;
          padding-left: 55px;
          padding-right: 42px;
        }
        @media screen and (max-width: 992px) {
          .page {
            height: calc(100vh - 72px);
            padding-top: 24px;
            padding-bottom: 50px;
            padding-left: 55px;
            padding-right: 55px;
            width: 100vw;
          }
          .logos {
            display: none;
            padding-top: 16px;
            padding-left: 55px;
            padding-right: 42px;
          }
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 24px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100vw;
          }
          .logos {
            display: none;
            padding-top: 20px;
            padding-left: 16px;
            padding-right: 8px;
          }
        }
        .container {
          max-width: 800px;
        }
        .maker-logo {
          height: 100px;
        }
        .esp-logo {
          padding-top: 16px;
          height: 60px;
        }
      `}</style>
    </div>
  );
}

export default CSPage;
