import enUS from 'antd/es/locale/en_US';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { apiKeys } from './api-keys-store';
import { authState, profile, SIGN_OUT } from './auth-store';
import { joonDevices } from './joon-devices-store';
import { joonDatasets } from './joon-datasets-store';
import { queries } from './queries-store';
import { roles } from './roles-store';
import { simpleLists } from './simple-lists-store';
import { users } from './users-store';
import { tenants } from './tenants-store';
import { userXJoon3s } from './user-x-joon3s-store';
import { files } from './files-store';
import { joon3Contacts } from './joon3-contacts-store';
import { joonNows } from './joon-nows-store';
import { joonDeviceConfigs } from './joon-device-configs-store';
import { firmwareVersions } from './firmware-versions-store';
import { webhooks } from './webhooks-store';
import { geofences } from './geofences-store';
import { joon3Configs } from './joon3-configs-store';
import { joon3Audits } from './joon3-audits-store';
import { joon3Reports } from './joon3-reports-store';
import { joon3DeviceReports } from './joon3-device-reports-store';
import { responsiveMode, windowDimensions } from './window-store';

const appReducer = combineReducers({
  authState,
  profile,
  roles,
  users,
  apiKeys,
  joonDevices,
  joonDatasets,
  joon3Contacts,
  joon3Reports,
  joon3DeviceReports,
  joonNows,
  joonDeviceConfigs,
  tenants,
  userXJoon3s,
  files,
  queries,
  simpleLists,
  firmwareVersions,
  webhooks,
  geofences,
  joon3Configs,
  joon3Audits,
  windowDimensions,
  responsiveMode,
  locale: () => enUS,
});

const rootReducer = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
    console.log('action', action);
  }
  let newState;
  if (action.type === SIGN_OUT) {
    newState = appReducer(undefined, action);
    localStorage.clear();
  } else {
    newState = appReducer(state, action);
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('new state', newState);
  }
  return newState;
};

export default createStore(rootReducer, applyMiddleware(thunk));
