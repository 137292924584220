import React from 'react';
import humanizeDuration from 'humanize-duration';
import { AuditStatuses, BootAnimationScreens } from '../../constants';
import {
  formatPhoneNumberForDisplay,
  getFormattedPhoneNumber,
} from '../../../../shared/utils';
import { Tag, Typography } from 'antd';

export const ConfigSources = {
  device: {
    key: 'device',
    label: 'Device',
  },
  tenant: {
    key: 'tenant',
    label: 'Tenant',
  },
  system: {
    key: 'system',
    label: 'System',
  },
};

export function notNullOrUndefined(attr) {
  return attr !== undefined && attr !== null;
}

export function getUnifiedSetting(
  systemConfig,
  tenantConfig,
  deviceConfig,
  category,
  attr,
) {
  const systemAttr = systemConfig[category]?.[attr];
  const tenantAttr = tenantConfig[category]?.[attr];
  const deviceAttr = deviceConfig?.[category]?.[attr];
  if (notNullOrUndefined(deviceAttr)) {
    return [deviceAttr, 'device'];
  }
  if (notNullOrUndefined(tenantAttr)) {
    return [tenantAttr, 'tenant'];
  }
  return [systemAttr, 'system'];
}

export function getSettingOptions(
  systemConfig,
  tenantConfig,
  deviceConfig,
  category,
  attr,
) {
  const systemAttr = systemConfig[category]?.[attr];
  const tenantAttr = tenantConfig[category]?.[attr];
  const deviceAttr = deviceConfig?.[category]?.[attr];
  return [systemAttr, tenantAttr, deviceAttr];
}

export function displayDurationSetting(heartbeatIntervalMs) {
  return humanizeDuration(heartbeatIntervalMs, {
    largest: 2,
    round: false,
  });
}

export function displayRoundedDurationSetting(heartbeatIntervalMs) {
  return humanizeDuration(heartbeatIntervalMs, {
    largest: 2,
    round: true,
  });
}

export function displaySampleRateSetting(v, sampleRate) {
  return humanizeDuration((v / sampleRate) * 1000, {
    largest: 2,
    round: false,
  });
}

export function displayEnabledDisabledSetting(s) {
  return s ? 'Enabled' : 'Disabled';
}

export function displayYesNoSetting(s) {
  return s ? 'Yes' : 'No';
}

export function displayBootAnimation(b) {
  return BootAnimationScreens[b]?.label;
}

export function displayMultiChoice(v, choices) {
  return choices[v]?.label;
}

export function displayUnspecifiable(v) {
  if (v === undefined || v === null) {
    return 'Not Specified';
  }
  return v;
}

export function displayPhoneNumberSetting(v) {
  return v && formatPhoneNumberForDisplay(v);
}

export function displayMultiPhoneNumberSetting(vs) {
  return (
    vs &&
    vs
      .split('\n')
      .map((v) => formatPhoneNumberForDisplay(v))
      .join(' ')
  );
}

const { Text } = Typography;

export function makeAuditStatusRow(audit, table) {
  if (!audit) return undefined;
  let auditStatus = AuditStatuses[audit[table]?.auditStatus];
  let auditNotes = audit[table]?.auditNotes;
  if (!auditStatus) {
    auditStatus = AuditStatuses.UNKNOWN;
  }
  return {
    key: 'auditStatus',
    label: 'Status',
    component: (
      <>
        <Tag color={auditStatus.color}>{auditStatus.label}</Tag>
        <Text>{auditNotes}</Text>
      </>
    ),
    editable: false,
  };
}
