import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Space, Typography, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export const tenantAdminDeviceResetMutation = gql`
  mutation TenantAdminDeviceReset($deviceId: ID!) {
    tenantAdminDeviceReset(deviceId: $deviceId)
  }
`;

function TenantAdminResetDeviceModal({ visible, onCancel, onFinish }) {
  const { joonDevice } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tenantAdminDeviceReset] = useMutation(tenantAdminDeviceResetMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await tenantAdminDeviceReset({
        variables: {
          deviceId: joonDevice._id,
        },
      });
      notification.success({
        message: 'Device Reset',
        description: 'Device setttings reset successfully',
      });
      if (onFinish) {
        onFinish();
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error resetting your device.',
      });
    }
    setLoading(false);
  }, [joonDevice, tenantAdminDeviceReset, onFinish]);

  return (
    <Modal
      header={null}
      open={visible}
      closable
      destroyOnClose={true}
      maskClosable
      footer={null}
      onCancel={onCancel}
      bodyStyle={{
        paddingTop: 32,
      }}
    >
      <Title level={3} style={{ marginBottom: 24, textAlign: 'center' }}>
        Reset Theora Connect
      </Title>
      {visible && (
        <div
          style={{
            marginBottom: 32,
            fontSize: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            This action will:
          </div>
          <div style={{ textAlign: 'left', marginBottom: 16 }}>
            <div>
              <span style={{ marginRight: 8 }}>&bull;</span> Remove all users
              and contacts from this device
            </div>
            <div>
              <span style={{ marginRight: 8 }}>&bull;</span> Reset all settings
              to defaults
            </div>
            <div>
              <span style={{ marginRight: 8 }}>&bull;</span> Remove any
              Safezones or Wi-Fi networks
            </div>
            <div>
              <span style={{ marginRight: 8 }}>&bull;</span> Remove all wearer
              details
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            Are you sure you want to continue?
          </div>
        </div>
      )}
      <div>
        <Space
          style={{
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            key="cancel"
            onClick={onCancel}
            htmlType="button"
            disabled={loading}
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button
            icon={<WarningOutlined />}
            key="send"
            type="primary"
            loading={loading}
            onClick={onYes}
          >
            Reset Device
          </Button>
        </Space>
      </div>
    </Modal>
  );
}

export default TenantAdminResetDeviceModal;
