import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createUserXJoon3Action,
  deleteUserXJoon3Action,
  updateUserXJoon3Action,
} from '../../../../redux-store/user-x-joon3s-store';
import { UserXJoon3Attributes } from './constants';

export const UserXJoon3UpdateAttributes = gql`
{
  type
  crud
  id
  new ${UserXJoon3Attributes}
}
`;

export const userXJoon3UpdatesSubscription = gql`
  subscription UserXJoon3Updates($deviceId: ID!) {
    userXJoon3Updates(deviceId: $deviceId) ${UserXJoon3UpdateAttributes}
  }
`;

function useUserXJoon3Updates(deviceId) {
  const queryId = deviceId || 'default';
  const dispatch = useDispatch();
  useSubscription(userXJoon3UpdatesSubscription, {
    skip: !deviceId,
    variables: {
      deviceId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        if (result && result.data && result.data.userXJoon3Updates) {
          const updates = result.data.userXJoon3Updates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'USER_X_JOON3_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createUserXJoon3Action(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateUserXJoon3Action(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateUserXJoon3Action(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteUserXJoon3Action(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useUserXJoon3Updates;
