import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { App, Button, Modal, Tooltip, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import useIsTenantAdmin from '../../use-is-tenant-admin';

export const updateJoon3ConfigMutation = gql`
  mutation EditSomeSettingModal($config: UpdateJoon3ConfigInput!) {
    updateJoon3Config(config: $config)
  }
`;

export const updateTenantJoon3ConfigMutation = gql`
  mutation TenantEditSomeSettingModal($config: TenantUpdateJoon3ConfigInput!) {
    updateTenantJoon3Config(config: $config)
  }
`;

function EditSomeSettingModal({
  systemConfig,
  tenantConfig,
  deviceConfig,
  children,
  btnProps,
  btnText,
  noBtn,
  editing: editing1,
  onDone: onDone1,
  onCancel: onCancel1,
}) {
  const isTenantAdmin = useIsTenantAdmin();
  const query = useMemo(() => {
    if (isTenantAdmin) {
      return updateTenantJoon3ConfigMutation;
    }
    return updateJoon3ConfigMutation;
  }, [isTenantAdmin]);
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateJoon3Config] = useMutation(query);
  const [notificationApi, contextHolder] = notification.useNotification();

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateJoon3Config({
          variables: {
            config: {
              _id: deviceConfig ? deviceConfig._id : tenantConfig._id,
              ...values,
            },
          },
        });
        notificationApi.success({
          message: 'Success',
          description: 'Setting updated successfully',
        });
        if (noBtn) {
          onDone1();
        } else {
          onDone();
        }
      } catch (err) {
        console.error(err);
        notificationApi.error({
          message: 'Error',
          description: 'There was an error saving your setting',
        });
      }
      setSaving(false);
    },
    [
      deviceConfig,
      tenantConfig,
      updateJoon3Config,
      onDone,
      noBtn,
      onDone1,
      notificationApi,
    ],
  );
  return (
    <>
      {contextHolder}
      <Modal
        open={noBtn ? editing1 : editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={noBtn ? onCancel1 : onCancel}
        // width={700}
        bodyStyle={{
          paddingTop: 16,
        }}
      >
        {React.cloneElement(children, {
          systemConfig,
          tenantConfig,
          deviceConfig,
          handleFinish,
          saving,
          onCancel: noBtn ? onCancel1 : onCancel,
        })}
      </Modal>
      {!noBtn && (
        <Tooltip title="Edit">
          <Button icon={<EditOutlined />} onClick={handleEdit} {...btnProps} />
        </Tooltip>
      )}
    </>
  );
}

export default EditSomeSettingModal;
