import { gql } from '@apollo/client';

export const GeofenceTypes = {
  CIRCLE: {
    key: 'CIRCLE',
    label: 'Circle',
  },
  RECTANGLE: {
    key: 'RECTANGLE',
    label: 'Rectangle',
  },
  POLYGON: {
    key: 'POLYGON',
    label: 'Polygon',
  },
};

export const GeofenceDetailedAttributes = gql`
  {
    _id
    name
    address {
      address1
      address2
      city
      state
      county
      country
      zipCode
    }
    type
    center {
      lat
      lon
    }
    radius
    topLeft {
      lat
      lon
    }
    bottomRight {
      lat
      lon
    }
    points {
      lat
      lon
    }
    deviceId
    tenantId
    userIds
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const GeofenceUpdateAttributes = gql`
{
  type
  crud
  id
  new ${GeofenceDetailedAttributes}
}
`;

export const geofenceQuery = gql`
  query Geofence($_id: ID!) {
    geofence(_id: $_id) ${GeofenceDetailedAttributes}
  }
`;

export const allGeofencesQuery = gql`
  query AllGeofences($first: Int, $after: String, $filters: GeofenceFilters, $sortBy: [SortBy]) {
    allGeofences(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${GeofenceDetailedAttributes}
      }
    }
  }
`;

export const deleteGeofenceMutation = gql`
  mutation deleteGeofence($_id: ID!) {
    deleteGeofence(_id: $_id) {
      deletedId
    }
  }
`;

export const createGeofenceMutation = gql`
  mutation createGeofence($geofence: GeofenceCreateInput!) {
    createGeofence(geofence: $geofence) {
      geofence ${GeofenceDetailedAttributes}
    }
  }
`;

export const updateGeofenceMutation = gql`
  mutation updateGeofence($geofence: GeofenceUpdateInput!) {
    updateGeofence(geofence: $geofence) {
      geofence ${GeofenceDetailedAttributes}
    }
  }
`;

export const geofenceSubscription = gql`
subscription GeofenceUpdates($deviceId: ID!) {
  geofenceUpdates(deviceId: $deviceId) ${GeofenceUpdateAttributes}
}
`;

const AddressAttributes = gql`
  {
    address1
    address2
    city
    state
    country
    county
    zipCode
  }
`;

export const addressSearchQuery = gql`
  query AddressSearch($search: String!) {
    addressSearch(search: $search) {
      address ${AddressAttributes}
      loc {
        lat
        lon
      }
    }
  }
`;

export const reverseGeocodeQuery = gql`
  query ReverseGeocode($loc: GeofenceLatLonInput!) {
    reverseGeocode(loc: $loc) ${AddressAttributes}
  }
`;
