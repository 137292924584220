import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Typography,
  Form,
  Table,
  Button,
  Input,
  Space,
  Tooltip,
  Tag,
} from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useManyRemote } from '../../../../shared/use-many-remote';
import { allJoonDatasetsQuery, JoonDatasetTypes } from '../../constants';
import settings from '../../../../settings';

const { Title, Text } = Typography;

function JoonDatasets({ joonDevice }) {
  const [deleting, setDeleting] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const [form] = Form.useForm();

  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      deviceId: joonDevice ? joonDevice._id : undefined,
    };
  }, [joonDevice]);

  const sortBy = useRef([{ key: 'timestampMs', order: 'ASC' }]);

  const filters = useRef(initialFilters);
  const {
    error,
    loading,
    data: datasets,
    refetch,
    search: datasetSearch,
    hasNextPage,
    next,
  } = useManyRemote(
    allJoonDatasetsQuery,
    (data) => data.allJoonDatasets,
    settings.querySize,
    filters.current,
    sortBy.current,
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const onSearchChange = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          { key: 'timestampMs', order: 'ASC' },
        ];
        datasetSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [refetch, datasetSearch],
  );
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={datasets}
        loading={!datasets.length && loading}
        pagination={{ ...pagination, total: datasets.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Table.Column title="IMEI" dataIndex="deviceId" key="deviceId" />
        <Table.Column
          title="Name"
          dataIndex="name"
          render={(text, record) => {
            return record.name;
          }}
        />
        <Table.Column
          title="Tags"
          dataIndex="tags"
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag key={tag} color={settings.colors.secondary}>
                  {tag}
                </Tag>
              ))
            );
          }}
        />
        <Table.Column
          title="Start Time"
          dataIndex="timestampMs"
          render={(text, record) => {
            return (
              record.timestampMs &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'short',
                timeStyle: 'long',
              }).format(new Date(record.timestampMs))
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link to={`/joon-dataset/view/${record._id}`}>
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="View">
                <Button icon={<DeleteOutlined />} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && (
          <Button style={{ minWidth: 200 }} onClick={next} loading={loading}>
            Load More
          </Button>
        )}
      </div>
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default JoonDatasets;
