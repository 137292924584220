import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Space,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createJoonDeviceAction,
  updateJoonDeviceAction,
} from '../../../redux-store/joon-devices-store';
import useIsCreating from '../../../shared/use-is-creating';
import useOne from '../../../shared/use-one';
import {
  createJoonDeviceMutation,
  joonDeviceQuery,
  updateJoonDeviceMutation,
} from '../constants';
import DeleteJoonDeviceModal from '../DeleteJoonDeviceModal';
import SelectTenant from '../../tenants/SelectTenant';
import useQueryParams from '../../../shared/use-query-params';
import {
  formatPhoneNumberForDisplay,
  formatPhoneNumberForStorage,
} from '../../../shared/utils';

const { Text } = Typography;

function EditJoonDevice() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [updateJoonDevice] = useMutation(updateJoonDeviceMutation);
  const [createJoonDevice] = useMutation(createJoonDeviceMutation);
  const dispatch = useDispatch();

  const { _id } = useParams();
  const queryId = useMemo(() => {
    return Math.round(Math.random() * 10000);
  }, []);

  const fetchResult = useOne(
    joonDeviceQuery,
    (data) => data && data.joonDevice,
    updateJoonDeviceAction,
    'joonDevices',
    _id,
    queryId,
  );

  const [form] = Form.useForm();
  const labelInput = useRef(null);
  const [joonDevice, setJoonDevice] = useState();

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (!fetchResult.loading && !onlyOnce.current && fetchResult.data) {
      setJoonDevice(fetchResult.data);
      onlyOnce.current = true;
    }
  }, [fetchResult, loading]);

  useEffect(() => {
    if (joonDevice) {
      const { msisdn, ...otherFields } = joonDevice;
      form.setFieldsValue({
        msisdn: formatPhoneNumberForDisplay(msisdn),
        ...otherFields,
      });
    }
  }, [form, joonDevice, _id]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const { from } = useQueryParams();
  const handleBack = useCallback(() => {
    if (from) {
      history.push(from);
    } else {
      history.push('/joon-devices');
    }
  }, [history, from]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { msisdn: unformattedMsisdn, ...values } = _values;
      let msisdn = formatPhoneNumberForStorage(unformattedMsisdn);
      try {
        if (joonDevice) {
          const result = await updateJoonDevice({
            variables: {
              joonDevice: {
                _id,
                msisdn,
                ...values,
              },
            },
          });
          dispatch(
            updateJoonDeviceAction(result.data.updateJoonDevice.joonDevice),
          );
          notification.success({
            message: 'Saved',
            description: 'Theora Connect device updated successfully',
          });
        } else {
          const result = await createJoonDevice({
            variables: {
              joonDevice: {
                _id,
                msisdn,
                ...values,
              },
            },
          });
          dispatch(
            createJoonDeviceAction(result.data.createJoonDevice.joonDevice),
          );
          notification.success({
            message: 'Saved',
            description: 'Theora Connect device created successfully',
          });
        }
        handleBack();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, _id, createJoonDevice, updateJoonDevice, dispatch, handleBack],
  );

  const { title, showDelete } = useIsCreating(
    location,
    joonDevice,
    loading,
    'Create a Theora Connect Device',
    'Edit Theora Connect Device',
  );

  return (
    <>
      <CSPage title={title}>
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Theora Connect Devices
            </Button>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editJoonDevice"
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item label="IMEI" name="_id" initialValue={_id}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="ICCID"
            name="iccid"
            rules={[
              {
                pattern: /(^[0-9]{19,20}$)/,
                message: 'Please enter a valid ICCID',
              },
            ]}
          >
            <Input disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item
            label="Serial Number"
            name="serialNo"
            rules={[
              {
                pattern: /(^[0-9]{3,20}$)/,
                message: 'Please enter a valid ICCID',
              },
            ]}
          >
            <Input disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="msisdn"
            rules={[
              {
                pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                message: 'Please enter a valid phone number (ex. 5124026225).',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item label="Comms. Version" name="commVer">
            <InputNumber
              disabled={loading}
              min={0}
              step={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="Tenant" name="tenantId">
            <SelectTenant disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item label="Call Center ID" name="callCenterId">
            <Input disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item label="Description" name="desc">
            <Input disabled={loading || fetchResult.loading} />
          </Form.Item>
          <Form.Item label="Tags" name="tags">
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Type to create a tag"
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                key="cancel"
                onClick={handleBack}
                htmlType="button"
                type="text"
                size="small"
                disabled={loading}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: -7 }}
              >
                Cancel
              </Button>
              <Button
                key="send"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Save Theora Connect Device
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <div style={{ marginTop: 16 }}>
              <Text type="danger">{error}</Text>
            </div>
          )}
          {showDelete && (
            <>
              <Divider />
              <div className="delete-box">
                <Space align="center">
                  <Button
                    onClick={() => setDeleting(joonDevice)}
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                  >
                    Delete JoonDevice
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Form>
        <div style={{ minHeight: 300 }} />
        <DeleteJoonDeviceModal
          setJoonDevice={setDeleting}
          joonDevice={deleting}
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditJoonDevice;
