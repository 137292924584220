export const READ_JOON_DEVICES = 'READ_JOON_DEVICES';
export const CREATE_JOON_DEVICE = 'CREATE_JOON_DEVICE';
export const UPDATE_JOON_DEVICE = 'UPDATE_JOON_DEVICE';
export const DELETE_JOON_DEVICE = 'DELETE_JOON_DEVICE';
export const BULK_CREATE_JOON_DEVICES = 'BULK_CREATE_JOON_DEVICES';

// eslint-disable-next-line no-shadow
export const readJoonDevicesAction = (joonDevices, queryId = 'default') => ({
  type: READ_JOON_DEVICES,
  payload: {
    joonDevices,
    queryId,
  },
});

export const createJoonDeviceAction = (joonDevice, queryId = 'default') => ({
  type: CREATE_JOON_DEVICE,
  payload: {
    joonDevice,
    queryId,
  },
});

export const bulkCreateJoonDevicesAction = (
  // eslint-disable-next-line no-shadow
  joonDevices,
  queryId = 'default',
) => ({
  type: BULK_CREATE_JOON_DEVICES,
  payload: {
    joonDevices,
    queryId,
  },
});

export const updateJoonDeviceAction = (joonDevice, queryId = 'default') => ({
  type: UPDATE_JOON_DEVICE,
  payload: {
    joonDevice,
    queryId,
  },
});

export const deleteJoonDeviceAction = (joonDeviceId, queryId = 'default') => ({
  type: DELETE_JOON_DEVICE,
  payload: {
    joonDeviceId,
    queryId,
  },
});

const initialJoonDevices = { default: {} };

export function joonDevices(state = initialJoonDevices, action) {
  switch (action.type) {
    case READ_JOON_DEVICES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joonDevices.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON_DEVICE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDevice._id]: action.payload.joonDevice,
        },
      };
    case BULK_CREATE_JOON_DEVICES:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          ...action.payload.joonDevices.reduce((prev, curr) => {
            prev[curr._id] = curr;
            return prev;
          }, {}),
        },
      };
    case UPDATE_JOON_DEVICE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joonDevice._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDevice._id]: {
            ...current,
            ...action.payload.joonDevice,
          },
        },
      };
    }
    case DELETE_JOON_DEVICE: {
      const { joonDeviceId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joonDeviceId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
