export const READ_TENANTS = 'READ_TENANTS';
export const CREATE_TENANT = 'CREATE_TENANT';
export const UPDATE_TENANT = 'UPDATE_TENANT';
export const DELETE_TENANT = 'DELETE_TENANT';

// eslint-disable-next-line no-shadow
export const readTenantsAction = (tenants, queryId = 'default') => ({
  type: READ_TENANTS,
  payload: {
    tenants,
    queryId,
  },
});

export const createTenantAction = (tenant, queryId = 'default') => ({
  type: CREATE_TENANT,
  payload: {
    tenant,
    queryId,
  },
});

export const updateTenantAction = (tenant, queryId = 'default') => ({
  type: UPDATE_TENANT,
  payload: {
    tenant,
    queryId,
  },
});

export const deleteTenantAction = (tenantId, queryId = 'default') => ({
  type: DELETE_TENANT,
  payload: {
    tenantId,
    queryId,
  },
});

const initialTenants = { default: {} };

export function tenants(state = initialTenants, action) {
  switch (action.type) {
    case READ_TENANTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.tenants.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_TENANT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.tenant._id]: action.payload.tenant,
        },
      };
    case UPDATE_TENANT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.tenant._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.tenant._id]: {
            ...current,
            ...action.payload.tenant,
          },
        },
      };
    }
    case DELETE_TENANT: {
      const { tenantId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [tenantId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
