import { Typography } from 'antd';
import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import NightlyReportsTable from './nightly-reports/NightlyReportsTable';

const { Title, Text } = Typography;

function ReportDashboardPage() {
  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader title="Report Dashboard" />
      <NightlyReportsTable />
      <style jsx>{`
        .body {
          display: flex;
        }
      `}</style>
    </CSPage>
  );
}

export default ReportDashboardPage;
