import { useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createWebhookAction,
  deleteWebhookAction,
  updateWebhookAction,
} from '../../redux-store/webhooks-store';
import { webhookSubscription } from './constants';

function useWebhookUpdates(tenantId) {
  const queryId = 'default';
  useSubscription(webhookSubscription, {
    variables: {
      tenantId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        if (result && result.data && result.data.webhookUpdates) {
          const updates = result.data.webhookUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'WEBHOOK_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createWebhookAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateWebhookAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateWebhookAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteWebhookAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useWebhookUpdates;
