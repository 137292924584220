import * as tf from '@tensorflow/tfjs';

export function plotRawMotion(newData, data, source) {
  newData.forEach((mData) => {
    if (mData.type === 'A') {
      ['ax', 'ay', 'az'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(mData[a]);
      });
    }
    if (mData.type === 'G') {
      ['gx', 'gy', 'gz'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(mData[a]);
      });
    }
    if (mData.type === 'S') {
      ['step'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(15);
        data.current[source][a].text.push(`Steps: ${mData[a]}`);
      });
    }
    if (mData.type === 'WT') {
      ['wt'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(16);
      });
    }
    if (mData.type === 'PF') {
      ['pf'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(17);
      });
    }
    if (mData.type === 'CMC') {
      ['cmc'].forEach((a) => {
        data.current[source][a].x.push(new Date(mData.timestampMs));
        data.current[source][a].y.push(18);
        data.current[source][a].text.push(`CMC: ${mData[a]}`);
      });
    }
  });
}

export function windowTheWindow(windowIn, length) {
  const windowOut = windowIn.slice(window.length - length);
  return windowOut;
}

export function windowAve(window) {
  let t = window.reduce((prev, curr) => prev + curr, 0);
  return t / window.length;
}

export function windowSum(window) {
  let t = window.reduce((prev, curr) => prev + curr, 0);
  return t;
}

export function plotDiffFromAve(newData, data, source, windowLen) {
  function processDiffAve(frame, dataType, axes, window) {
    if (frame.type === dataType) {
      axes.forEach((a) => {
        window.current[a].push(frame[a]);
        window.current[a] = windowTheWindow(window.current[a], windowLen);
      });
      const aves = {};
      axes.forEach((a) => {
        aves[a] = windowAve(window.current[a]);
      });
      axes.forEach((a) => {
        data.current[source][a].x.push(new Date(frame.timestampMs));
        data.current[source][a].y.push(frame[a] - aves[a]);
      });
    }
  }

  const a3Window = { current: { ax: [], ay: [], az: [] } };
  newData.forEach((mData) => {
    processDiffAve(mData, 'A', ['ax', 'ay', 'az'], a3Window);
  });

  const g3Window = { current: { gx: [], gy: [], gz: [] } };
  newData.forEach((mData) => {
    processDiffAve(mData, 'G', ['gx', 'gy', 'gz'], g3Window);
  });
}

export function setupPlot(data, source, axes) {
  if (data.current[source] === undefined) {
    data.current[source] = {};
  }
  axes.forEach((a) => {
    if (data.current[source][a] === undefined) {
      data.current[source][a] = {
        x: [],
        y: [],
        text: [],
      };
    }
  });
}

export function plotTotal(newData, data, source) {
  newData.forEach((mData) => {
    if (mData.type === 'A') {
      let total = 0;
      ['ax', 'ay', 'az'].forEach((a) => {
        total += Math.pow(mData[a], 2);
      });
      total = Math.pow(total, 0.5);
      data.current[source].a.x.push(new Date(mData.timestampMs));
      data.current[source].a.y.push(total);
    }
    if (mData.type === 'G') {
      let total = 0;
      ['gx', 'gy', 'gz'].forEach((a) => {
        total += Math.pow(mData[a], 2);
      });
      total = Math.pow(total, 0.5);
      data.current[source].g.x.push(new Date(mData.timestampMs));
      data.current[source].g.y.push(total);
    }
  });
}

export function plotTotalDiffFromAve(newData, data, source, windowLen) {
  function processFrame(frame, dataType, axes, window, dest) {
    if (frame.type === dataType) {
      let total = 0;
      axes.forEach((a) => {
        total += Math.pow(frame[a], 2);
      });
      total = Math.pow(total, 0.5);
      window.current.push(total);
      window.current = windowTheWindow(window.current, windowLen);
      let ave = windowAve(window.current);
      data.current[source][dest].x.push(new Date(frame.timestampMs));
      data.current[source][dest].y.push(total - ave);
    }
  }

  const aWindow = { current: [] };
  newData.forEach((mData) => {
    processFrame(mData, 'A', ['ax', 'ay', 'az'], aWindow, 'a');
  });

  const gWindow = { current: [] };
  newData.forEach((mData) => {
    processFrame(mData, 'G', ['gx', 'gy', 'gz'], gWindow, 'g');
  });
}

export function plot1sIntegrals(data, source, windowLen) {
  function processVelocityFrame(y, x, window, dest) {
    window.current.push(Math.abs(y));
    window.current = windowTheWindow(window.current, windowLen);
    let sum = windowSum(window.current);
    data.current[source][dest].x.push(x);
    data.current[source][dest].y.push(sum);
  }

  const a6Window = { current: [] };
  data.current.totalMotion.a.y.forEach((y, i) => {
    processVelocityFrame(y, data.current.totalMotion.a.x[i], a6Window, 'ad');
  });

  const g6Window = { current: [] };
  data.current.totalMotion.g.y.forEach((y, i) => {
    processVelocityFrame(y, data.current.totalMotion.g.x[i], g6Window, 'gd');
  });
}

export function plot1sAveAves(newData, data, source, windowLen) {
  function processFrame(y, x, window, dest) {
    window.current.push(y);
    window.current = windowTheWindow(window.current, windowLen);
    let ave = windowAve(window.current);
    data.current[source][dest].x.push(x);
    data.current[source][dest].y.push(ave);
  }

  newData.forEach((mData) => {
    if (mData.type === 'A') {
      let total = 0;
      ['ax', 'ay', 'az'].forEach((a) => {
        total += mData[a];
      });
      total = total / 3.0;
      data.current[source].a.x.push(new Date(mData.timestampMs));
      data.current[source].a.y.push(total);
    }
    if (mData.type === 'G') {
      let total = 0;
      ['gx', 'gy', 'gz'].forEach((a) => {
        total += mData[a];
      });
      total = total / 3.0;
      data.current[source].g.x.push(new Date(mData.timestampMs));
      data.current[source].g.y.push(total);
    }
  });

  const aWindow = { current: [] };
  data.current[source].a.y.forEach((y, i) => {
    processFrame(y, data.current[source].a.x[i], aWindow, 'A 1s Average');
  });

  const gWindow = { current: [] };
  data.current[source].g.y.forEach((y, i) => {
    processFrame(y, data.current[source].g.x[i], gWindow, 'G 1s Average');
  });
}

export function plot1sAngleDiffs(newData, data, source, windowLen) {
  function processFrameAngle(frame, dataType, axes, window, dest) {
    if (frame.type === dataType) {
      axes.forEach((a) => {
        window.current[a].push(frame[a]);
        window.current[a] = windowTheWindow(window.current[a], windowLen);
      });
      const aves = {};
      axes.forEach((a) => {
        aves[a] = windowAve(window.current[a]);
      });
      // angle = arccos[(xa * xb + ya * yb + za * zb) / (√(xa2 + ya2 + za2) * √(xb2 + yb2 + zb2))]
      const sumOfProducts = Object.entries(aves).reduce(
        (prev, [axis, val]) => val * frame[axis] + prev,
        0,
      );
      const totalAve = Math.pow(
        axes.reduce((prev, axis) => prev + Math.pow(aves[axis], 2), 0),
        0.5,
      );
      const totalEdge = Math.pow(
        axes.reduce((prev, axis) => prev + Math.pow(frame[axis], 2), 0),
        0.5,
      );
      const angle = Math.acos(sumOfProducts / (totalAve * totalEdge));
      data.current[source][dest].x.push(new Date(frame.timestampMs));
      data.current[source][dest].y.push(angle);
    }
  }

  const a2Window = { current: { ax: [], ay: [], az: [] } };
  newData.forEach((mData) => {
    processFrameAngle(mData, 'A', ['ax', 'ay', 'az'], a2Window, 'a');
  });

  const g2Window = { current: { gx: [], gy: [], gz: [] } };
  newData.forEach((mData) => {
    processFrameAngle(mData, 'G', ['gx', 'gy', 'gz'], g2Window, 'g');
  });
}

export function plotPeaksAndValleys(
  data,
  source,
  windowLen,
  paramMinPeakDistance,
) {
  function findPeaksAndValleys(_data, minPeakDistance) {
    let state;
    let prevState;
    let prev;
    let potentialPeak;
    let potentialValley;
    let lastPeak;
    let lastValley;
    let lastAnything;
    const peaks = [];
    const valleys = [];
    _data.forEach((a, i) => {
      if (potentialPeak === undefined) {
        potentialPeak = [a, i];
        lastPeak = [a, i];
        // peaks.push[lastPeak];
      }
      if (potentialValley === undefined) {
        potentialValley = [a, i];
        lastValley = [a, i];
        // valleys.push[lastValley];
      }
      if (prev !== undefined) {
        if (a > prev) {
          state = 'rising';
        } else if (a < prev) {
          state = 'falling';
        }
        if (state != prevState) {
          if (state === 'rising') {
            potentialPeak = [a, i];
          } else {
            potentialValley = [a, i];
          }
        }
        if (state === 'rising' && a > potentialPeak[0]) {
          potentialPeak = [a, i];
        }
        if (
          state === 'rising' &&
          valleys.length === 0 &&
          lastPeak[0] - potentialValley[0] > 0
        ) {
          valleys.push(potentialValley);
          lastValley = potentialValley;
          lastAnything = 'valley';
        } else if (
          state === 'rising' &&
          lastPeak[0] - potentialValley[0] > minPeakDistance
        ) {
          // potentialPeak = [a, i];
          if (lastValley[1] !== potentialValley[1]) {
            if (lastAnything !== 'valley') {
              valleys.push(potentialValley);
              lastValley = potentialValley;
            } else if (potentialValley[0] < lastValley[0]) {
              lastValley = potentialValley;
              valleys[valleys.length - 1] = potentialValley;
            }
            lastAnything = 'valley';
          }
        }
        if (state === 'falling' && a < potentialValley[0]) {
          potentialValley = [a, i];
        }
        if (
          state === 'falling' &&
          peaks.length === 0 &&
          potentialPeak[0] - lastValley[0] > 0
        ) {
          peaks.push(potentialPeak);
          lastPeak = potentialPeak;
          lastAnything = 'peak';
        } else if (
          state === 'falling' &&
          potentialPeak[0] - lastValley[0] > minPeakDistance
        ) {
          // potentialValley = [a, i];
          if (lastPeak[1] !== potentialPeak[1]) {
            if (lastAnything !== 'peak') {
              peaks.push(potentialPeak);
              lastPeak = potentialPeak;
            } else if (potentialPeak[0] > lastPeak[0]) {
              lastPeak = potentialPeak;
              peaks[peaks.length - 1] = potentialPeak;
            }
            lastAnything = 'peak';
          }
        }
      }
      prev = a;
      prevState = state;
    });
    return [peaks, valleys];
  }

  const [apeaks, avalleys] = findPeaksAndValleys(
    data.current.totalMotion.a.y,
    paramMinPeakDistance,
  );

  apeaks.forEach(([a, i]) => {
    data.current[source].ap.x.push(data.current.totalMotion.a.x[i]);
    data.current[source].ap.y.push(a);
  });

  avalleys.forEach(([a, i]) => {
    data.current[source].av.x.push(data.current.totalMotion.a.x[i]);
    data.current[source].av.y.push(a);
  });

  data.current[source].a.x = data.current.totalMotion.a.x;
  data.current[source].a.y = data.current.totalMotion.a.y;

  function processPeakWindow(x, window, axis) {
    window.current.push(x);
    window.current = windowTheWindow(window.current, windowLen);
    const windowStart = window.current[0];
    const windowEnd = window.current[window.current.length - 1];
    const peaks = data.current[source].ap.x.reduce((prev, curr) => {
      if (
        curr.getTime() >= windowStart.getTime() &&
        curr.getTime() < windowEnd.getTime()
      ) {
        return prev + 1;
      }
      return prev;
    }, 0);
    const valleys = data.current[source].av.x.reduce((prev, curr) => {
      if (
        curr.getTime() >= windowStart.getTime() &&
        curr.getTime() < windowEnd.getTime()
      ) {
        return prev + 1;
      }
      return prev;
    }, 0);
    data.current[source][`${axis}pw`].x.push(x);
    data.current[source][`${axis}pw`].y.push(peaks);
    data.current[source][`${axis}vw`].x.push(x);
    data.current[source][`${axis}vw`].y.push(valleys);
    data.current[source][`${axis}w`].x.push(x);
    data.current[source][`${axis}w`].y.push(peaks + valleys);
  }

  const a4Window = { current: [] };
  data.current.totalMotion.a.x.forEach((x) => {
    processPeakWindow(x, a4Window, 'a');
  });
}

export async function plotFft(newData, data, source, update) {
  const total = newData
    .map((frame) => {
      if (frame.type === 'A') {
        return Math.pow(
          Math.pow(frame.ax, 2) + Math.pow(frame.ay, 2) + Math.pow(frame.az, 2),
          0.5,
        );
      }
      return undefined;
    })
    .filter((a) => a !== undefined);
  const real = tf.tensor1d(total);

  const fxs = await tf.range(0, real.size).div(50).dataSync();
  data.current.fft.g.x = fxs;
  const fys = await real.dataSync();
  data.current.fft.g.y = fys;

  const imag = tf.zeros([real.size]);
  const x = tf.complex(real, imag);
  let result = tf.spectral.fft(x);
  result = tf.real(result);
  const xs = await tf
    .range(0, result.size)
    .div((1.0 / 50.0) * real.size)
    .dataSync();
  result = await result.dataSync();
  result = result.slice(0, Math.floor(result.length / 2));
  data.current[source].a.x = xs;
  data.current[source].a.y = result;

  update();
}

export async function plot1sWindowFfts(
  newData,
  data,
  source,
  windowLen,
  update,
) {
  async function processFftWindow(x, y, window, axis) {
    window.current.push(y);
    window.current = windowTheWindow(window.current, windowLen);

    if (window.current.length === windowLen) {
      let real = tf.tensor1d(window.current);
      real = real.sub(real.mean());
      let result = tf.spectral.rfft(real);
      result = tf.real(result);
      const xs = await tf
        .range(0, result.size)
        .div((1.0 / 50.0) * real.size)
        .arraySync();
      result = await result.slice([0, 1], [1, Math.floor(result.size / 2) - 1]);
      const index = result.argMax(1).arraySync()[0];
      const val = result.arraySync()[0][index];
      // console.log('resut', index, val);
      data.current[source].a.x.push(x);
      data.current[source].a.y.push(index);
      data.current[source].g.x.push(x);
      data.current[source].g.y.push(val);
    }
  }

  const window = { current: [] };
  for (let i = 0; i < data.current.totalMotion.a.x.length; i += 1) {
    await processFftWindow(
      data.current.totalMotion.a.x[i],
      data.current.totalMotion.a.y[i],
      window,
      'a',
    );
  }
  update();
}

export async function plot1sRmsTensor(
  newData,
  data,
  source,
  windowLen,
  update,
) {
  let prev = tf.scalar(0);

  let ay = tf.tensor1d([]);
  let gy = tf.tensor1d([]);

  async function processFftWindow(x, y, window, axis) {
    window.current.push(y);
    window.current = windowTheWindow(window.current, windowLen);

    if (window.current.length === windowLen) {
      let real = tf.tensor1d(window.current);
      // real = real.sub(real.mean());
      // real = real.pow(tf.scalar(2));
      // real = real.sum().div(real.size).sqrt();
      real = tf.moments(real).variance.sqrt();
      let realA = real.array();
      // real.print();
      // // console.log('resut', index, val);
      data.current[source].a.x.push(x);
      // ay = ay.concat(real.reshape([1, 1]));
      data.current[source].a.y.push(realA);

      data.current[source].g.x.push(x);
      // gy = gy.concat(real.sub(prev));
      data.current[source].g.y.push(real.sub(prev).array());
      prev = real;
    }
  }

  const window = { current: [] };
  for (let i = 0; i < data.current.totalMotion.a.x.length; i += 1) {
    processFftWindow(
      data.current.totalMotion.a.x[i],
      data.current.totalMotion.a.y[i],
      window,
      'a',
    );
  }
  data.current[source].a.y = await Promise.all(data.current[source].a.y);
  // data.current[source].a.y = await ay.array();
  data.current[source].g.y = await Promise.all(data.current[source].g.y);
  // data.current[source].g.y = await gy.array();
  update();
}

export function plot1sRms(data, source, windowLen) {
  function processFrame(y, x, window, dest) {
    window.current.push(y);
    window.current = windowTheWindow(window.current, windowLen);
    let sum = windowSum(window.current);
    let ave = sum / window.current.length;
    let std = window.current.reduce((prev, curr) => {
      return prev + Math.pow(curr - ave, 2.0);
    }, 0.0);
    std = Math.pow(std / windowLen, 0.5);

    data.current[source][dest].x.push(x);
    data.current[source][dest].y.push(std);
  }

  const a6Window = { current: [] };
  data.current.totalMotion.a.y.forEach((y, i) => {
    processFrame(y, data.current.totalMotion.a.x[i], a6Window, 'a');
  });

  const g6Window = { current: [] };
  data.current.totalMotion.g.y.forEach((y, i) => {
    processFrame(y, data.current.totalMotion.g.x[i], g6Window, 'g');
  });
}
