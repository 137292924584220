import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Space, Tooltip, Typography, Upload } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import AttachmentCard from './AttachmentCard';
import csv from 'papaparse';

const { Paragraph } = Typography;

function UploadCSVButton({ onCsvParsed, onDelete }) {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [csvFile, setCsvFile] = useState();

  const doUpload = useCallback(
    async (obj) => {
      try {
        setError(null);
        setUploadLoading(true);

        csv.parse(obj.file, {
          complete: function (results) {
            console.log('csv results', results);
            onCsvParsed(results.data);
          },
        });

        setCsvFile(obj.file);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setUploadLoading(false);
    },
    [onCsvParsed],
  );

  return (
    <>
      <Space direction="vertical" style={{ marginRight: 8 }}>
        {csvFile && (
          <AttachmentCard
            key={csvFile.uid}
            onUploadDelete={() => {
              setCsvFile(undefined);
              onDelete();
            }}
            fileName={csvFile.name}
            fileSize={csvFile.size}
          />
        )}
        {!csvFile && (
          <Upload
            className="profile-pic-upload"
            showUploadList={false}
            listType="picture"
            customRequest={doUpload}
          >
            {error && (
              <Tooltip title={error}>
                <Button danger icon={<ExclamationCircleOutlined />}>
                  Failed
                </Button>
              </Tooltip>
            )}
            {!error && (
              <Button
                disabled={uploadLoading}
                icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
              >
                {uploadLoading ? 'Processing ...' : 'Upload CSV'}
              </Button>
            )}
          </Upload>
        )}
      </Space>
    </>
  );
}

export default UploadCSVButton;
