import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Button, List, Skeleton, Tag, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../../components/CSPage';
import CSPageHeader from '../../../../components/CSPageHeader';
import { updateJoonDatasetAction } from '../../../../redux-store/joon-datasets-store';
import settings from '../../../../settings';
import { useManyRemote } from '../../../../shared/use-many-remote';
import useOne from '../../../../shared/use-one';
import useProfile from '../../../../shared/use-profile';
import useQueryParams from '../../../../shared/use-query-params';
import { allJoonDeviceDataQuery, joonDatasetQuery } from '../../constants';
import Plotly from 'plotly.js-dist';
import JoonDatasetPlot from './JoonDatasetPlot';
import EditJoonDatasetModal from './EditJoonDatasetModal';
import DeleteJoonDatasetModal from './DeleteDatasetModal';
import JoonDatasetAudioFiles from './JoonDatasetAudioFiles';

const { Title, Text, Paragraph } = Typography;

function DisplayField({ title, value }) {
  return (
    <List.Item>
      <div>
        <Text style={{ color: settings.colors.primary, fontSize: 14 }}>
          {title}
        </Text>
      </div>
      <div>
        <Text style={{ fontSize: 14 }}>{value}</Text>
      </div>
    </List.Item>
  );
}

function ViewJoonDataset() {
  const { _id } = useParams();
  const { from } = useQueryParams();
  const history = useHistory();

  const extract = useCallback((data) => data && data.joonDataset, []);
  const fetchResult = useOne(
    joonDatasetQuery,
    extract,
    updateJoonDatasetAction,
    'joonDatasets',
    _id,
    'view-dataset',
  );
  const [joonDataset, setJoonDevice] = useState();
  useEffect(() => {
    if (!fetchResult.loading && fetchResult.data) {
      setJoonDevice(fetchResult.data);
    }
  }, [fetchResult]);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const joonDatasetFields = useMemo(() => {
    if (joonDataset) {
      return [
        {
          title: 'Name',
          value: joonDataset.name,
        },
        {
          title: 'Device IMEI',
          value: joonDataset.deviceId,
        },
        {
          title: 'Start Time',
          value:
            joonDataset.timestampMs &&
            new Intl.DateTimeFormat(locale, {
              dateStyle: 'short',
              timeStyle: 'long',
            }).format(new Date(joonDataset.timestampMs)),
        },
      ].filter((a) => a.value);
    }
  }, [joonDataset, locale]);

  const error = fetchResult.error && fetchResult.error.message;
  const loading = fetchResult.loading;
  const location = useLocation();

  const profile = useProfile();
  const showUpdate = useMemo(() => {
    if (profile && profile.role && profile.role.permissions) {
      return profile.role.permissions.includes('update-joon3-dataset:all');
    }
    return false;
  }, [profile]);

  const handleExportToCsv = useRef();

  const [backLink, backLabel] = useMemo(() => {
    if (from) {
      return [from, 'Go back'];
    }
    if (joonDataset) {
      return [
        `/joon-device/view/${joonDataset.deviceId}/datasets`,
        'Back to Theora Connect™ Datasets',
      ];
    }
    return ['/joon-devices', 'Back to Theora Connect devices'];
  }, [from, joonDataset]);

  const getData = useRef();

  const [editing, setEditing] = useState();
  const handleCancelEditing = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleFinishEditing = useCallback(() => {
    setEditing(undefined);
  }, []);
  const handleEdit = useCallback(() => {
    setEditing({ joonDataset, queryId: 'view-dataset' });
  }, [joonDataset]);

  const [deleting, setDeleting] = useState();
  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleFinishDelete = useCallback(() => {
    history.push(backLink);
  }, [history, backLink]);
  const handleDelete = useCallback(() => {
    setDeleting({ joonDataset, queryIds: ['view-dataset', 'default'] });
  }, [joonDataset]);

  const handleExportToJson = useCallback(() => {
    if (joonDataset && getData.current) {
      const filename = `${joonDataset.name}.json`;
      const jsonStr = JSON.stringify(getData.current());

      let element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr),
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }, [joonDataset]);

  return (
    <CSPage
      title="Theora Connect&trade; Device"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        titleComponent={
          <div style={{ position: 'relative' }}>
            <Title className="cs-header-title" style={{ marginBottom: 8 }}>
              <DatabaseOutlined style={{ marginRight: 16 }} />
              Theora Connect&trade; Dataset
            </Title>
          </div>
        }
        backActions={[
          <Link to={backLink} key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              {backLabel}
            </Button>
          </Link>,
        ]}
        topActions={[
          <Button
            key="export"
            type="link"
            size="large"
            icon={<ExportOutlined />}
            onClick={handleExportToJson}
          >
            Export to JSON
          </Button>,
          <Button
            key="edit"
            type="link"
            size="large"
            onClick={handleEdit}
            icon={<EditOutlined />}
          >
            Edit Dataset Metadata
          </Button>,
          <Button
            key="delete"
            type="link"
            size="large"
            onClick={handleDelete}
            icon={<DeleteOutlined />}
          >
            Delete Dataset
          </Button>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-box">
        {!joonDataset && loading && <Skeleton active paragraph title />}
        <List
          dataSource={joonDatasetFields}
          renderItem={(item) => (
            <DisplayField title={item.title} value={item.value} />
          )}
          loading={loading}
          locale={{
            emptyText: <div />,
          }}
          grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
        />
        {joonDataset && joonDataset.tags && joonDataset.tags.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            {joonDataset.tags.map((tag) => (
              <Tag key={tag} color={settings.colors.secondary}>
                {tag}
              </Tag>
            ))}
          </div>
        )}
        {joonDataset && joonDataset.notes && (
          <div>
            <Title level={4}>Notes</Title>
            <Paragraph>{joonDataset.notes}</Paragraph>
          </div>
        )}
      </div>
      <JoonDatasetAudioFiles files={joonDataset && joonDataset.files} />
      <JoonDatasetPlot datasetId={_id} getData={getData} />
      <EditJoonDatasetModal
        visible={editing}
        onFinish={handleFinishEditing}
        onCancel={handleCancelEditing}
      />
      <DeleteJoonDatasetModal
        visible={deleting}
        onCancel={handleCancelDelete}
        onFinish={handleFinishDelete}
      />
      <style jsx>{`
        .top-box {
          border: 1px solid ${settings.colors.borderGray};
          border-radius: 24px;
          padding: 16px 24px;
          margin-bottom: 24px;
        }
        .load-more {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ViewJoonDataset;
