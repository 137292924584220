import { gql } from '@apollo/client';

export const Joon3ReportDashboardAttributes = gql`
  {
    _id
    name
    notes
    tags
    numDeviceReports
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allJoon3ReportsQuery = gql`
  query AllJoon3Reports($first: Int, $after: String, $filters: Joon3ReportFilters, $sortBy: [SortBy]) {
    allJoon3Reports(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node ${Joon3ReportDashboardAttributes}
      }
    }
  }
`;

export const Joon3ReportAttributes = gql`
  {
    _id
    name
    numDeviceReports
    deviceReports
    notes
    tags
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const joon3ReportQuery = gql`
  query Joon3Report($_id: ID!) {
    joon3Report(_id: $_id) ${Joon3ReportAttributes}
  }
`;

export const joon3ReportMetaQuery = gql`
  query Joon3ReportMeta($_id: ID!) {
    joon3Report(_id: $_id) ${Joon3ReportDashboardAttributes}
  }
`;

export const joon3DeviceReportQuery = gql`
  query Joon3DeviceReport($_id: ID!) {
    joon3DeviceReport(_id: $_id) {
      _id
      reportId
      imei
      serialNo
      iccid
      msisdn
      callCenterId
      tenantId
      tenantName
      fwVer
      appVer
      fallAppVer
      fallDetectionEnabled
      fallStats {
        detected
        no_response
        verified
        canceled
      }
      heartbeatIntervalMs
      auditStatus
      maxStepsSinceMidnight
      signalHistory {
        _id
        t
        l
      }
      aveSignal
      detectedFallCount
      callMadeFallCount
      sosCalls
      pingHistory {
        _id
        t
        missed
      }
      nPings
      nMissedPings
      latestPingMs
      latestLocationMs
      heartbeatHistory {
        _id
        t
        missed
      }
      nHeartbeats
      nMissedHeartbeats
      pMissedHeartbeats
      batteryHistory {
        _id
        t
        l
        isCharging
      }
      batteryLives {
        _id
        startLevel
        endLevel
        startTimeMs
        endTimeMs
        totalEstimateMs
      }
      aveBatteryLifeMs
      stepHistory {
        _id
        t
        c
        csm
      }
      suspectedLockups {
        _id
        t
        nMissedHeartbeats
        signalLevel
        batteryLevel
      }
      onlineHistory {
        _id
        t
        eventType
      }
      safeZoneHistory {
        _id
        t
        geofences {
          _id
          name
        }
      }
    }
  }
`;
