import { SendOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import ShowErrorsFormItem from '../../../../components/ShowErrorsFormItem';

const { Text } = Typography;

const sendCallCommandMutation = gql`
  mutation SendCallCommand($deviceId: ID!, $number: String!) {
    sendCallCommand(deviceId: $deviceId, number: $number)
  }
`;

function SendCallCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sendCallCommand] = useMutation(sendCallCommandMutation);

  const handleSendCommand = useCallback(
    async (_values) => {
      setLoading(true);
      setError(undefined);
      try {
        const { ...values } = _values;
        await sendCallCommand({
          variables: {
            deviceId: joonDevice._id,
            number: values.number,
          },
        });
        message.success('Call command sent');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, sendCallCommand],
  );

  return (
    <Card
      title="Make a Call"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item
          label="Phone Number"
          name="number"
          rules={[
            { required: true, message: 'This field is required' },
            {
              pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: 'Please enter a valid phone number (ex. 5124026225).',
            },
          ]}
          extra="The watch will call this phone number"
        >
          <Input disabled={loading} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <style jsx global>{`
        .config-cmd .ant-input {
          padding: 4px 8px;
        }
        .config-cmd .ant-form-item-control-input {
          min-height: 32px;
        }
        .config-cmd .ant-input-number-input {
          height: 32px;
        }
      `}</style>
    </Card>
  );
}

export default SendCallCommand;
