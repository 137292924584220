import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteFirmwareVersionAction } from '../../redux-store/firmware-versions-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { deleteFirmwareVersionMutation } from './constants';

function DeleteFirmwareVersionModal({
  firmwareVersion,
  setFirmwareVersion,
  queryId = 'default',
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteFirmwareVersion] = useMutation(deleteFirmwareVersionMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteFirmwareVersion({
        variables: {
          _id: firmwareVersion._id,
        },
      });
      dispatch(deleteFirmwareVersionAction(firmwareVersion._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Firmware Version deleted successfully',
      });
      setTimeout(() => {
        setFirmwareVersion(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [
    firmwareVersion,
    deleteFirmwareVersion,
    setFirmwareVersion,
    dispatch,
    history,
    queryId,
  ]);

  const onNo = useCallback(() => {
    if (!loading) {
      setFirmwareVersion(null);
    }
  }, [setFirmwareVersion, loading]);

  return (
    <YesNoModal
      title="Delete Firmware Version"
      question={`Are you sure you want to delete this Firmware Version (${
        firmwareVersion && firmwareVersion.appName
      } ${firmwareVersion && firmwareVersion.versionName})?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!firmwareVersion}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteFirmwareVersionModal;
