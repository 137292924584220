import { Button, Form, Select, Spin, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../settings';
import { gql } from '@apollo/client';
import useMany from '../../shared/use-many';
import { readUsersAction } from '../../redux-store/users-store';

const { Text } = Typography;

const selectUserQuery = gql`
  query SelectUser(
    $first: Int
    $after: String
    $filters: UserFilters
    $sortBy: [SortBy]
  ) {
    allUsers(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          username
          email
          name
          phone
        }
      }
    }
  }
`;

function SelectUser({ disabled, filters, value, onChange }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: users,
    search: userSearch,
  } = useMany(
    selectUserQuery,
    (data) => data.allUsers,
    readUsersAction,
    'users',
    ['username', 'name', 'email'],
    settings.querySize,
    filters,
    (a, b) => a.name.localeCompare(b.name, locale),
  );
  const handleChange = useCallback(
    (_value, option) => {
      onChange(_value);
    },
    [onChange],
  );
  return (
    <Select
      showSearch
      filterOption={() => true}
      autoComplete="chrome-off"
      allowClear
      disabled={disabled}
      onSearch={(search) => {
        userSearch(search);
      }}
      onChange={handleChange}
      placeholder="Select a user"
      value={value}
    >
      {loading && (
        <Select.Option value="__loading__">
          <Spin spinning={loading} style={{ margin: 8 }} />
        </Select.Option>
      )}
      {!loading && error && (
        <Select.Option value="__error__">
          <Text type="danger">{error.message}</Text>
        </Select.Option>
      )}
      {!loading &&
        users.map((user) => (
          <Select.Option key={user._id} value={user._id} title={user.username}>
            <div className="user-option">
              <div>
                <Text>{`${user.name} (${user.username})`}</Text>
              </div>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectUser;
