import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { BootAnimationScreens } from '../../constants';
import SettingsCategoryTable from './SettingsCategoryTable';
import EditCheckboxEnabledModal from './edit-modals/EditCheckboxEnabledModal';
import EditCheckboxYesModal from './edit-modals/EditCheckboxYesModal';
import EditMultiChoiceModal from './edit-modals/EditMultiChoiceModal';
import EditNumberMinsModal from './edit-modals/EditNumberMinsModal';
import SelectBootAnimation from './edit-modals/SelectBootAnimation';
import {
  displayBootAnimation,
  displayDurationSetting,
  displayEnabledDisabledSetting,
  displayYesNoSetting,
  getUnifiedSetting,
  makeAuditStatusRow,
} from './utils';
import { TenantSettings } from './constants';

const { Title, Text } = Typography;

function GeneralSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  audit,
  isDevice,
  loading,
  isTenantAdmin,
}) {
  const makeSettingRow = useCallback(
    (table, key, label, displayFunc, editModal) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return {
        key,
        label,
        value: displayFunc(value),
        src,
        editModal,
        isEditable: !!editModal,
      };
    },
    [systemConfig, tenantConfig, deviceConfig],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig
    ) {
      return [
        makeAuditStatusRow(audit, 'general'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.general?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.general?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig.general?._id,
          editable: false,
        },
        makeSettingRow(
          'general',
          'heartbeatIntervalMs',
          'Heartbeat Interval',
          (v) => v && displayDurationSetting(v),
          <EditNumberMinsModal
            table="general"
            settingKey="heartbeatIntervalMs"
            label="Heartbeat Interval"
            initialValue={60}
            sampleRate={1000 * 60}
          />,
        ),
        makeSettingRow(
          'general',
          'lockdownModeEnabled',
          'Lockdown Mode',
          (v) => displayEnabledDisabledSetting(v),
          <EditCheckboxEnabledModal
            table="general"
            settingKey="lockdownModeEnabled"
            label="Lockdown Mode"
          />,
        ),
        makeSettingRow(
          'general',
          'canDisconnectSosCall',
          'Can Disconnect SOS Call',
          (v) => displayYesNoSetting(v),
          <EditCheckboxYesModal
            table="general"
            settingKey="canDisconnectSosCall"
            label="Can Disconnect SOS Call"
            initialValue={true}
          />,
        ),
        makeSettingRow(
          'general',
          'showContactsApp',
          'Show Contacts App',
          (v) => displayYesNoSetting(v),
          <EditCheckboxYesModal
            table="general"
            settingKey="showContactsApp"
            label="Show Contacts App"
            initialValue={false}
          />,
        ),
        makeSettingRow(
          'general',
          'bootAnimation',
          'Boot Animation',
          (v) => displayBootAnimation(v),
          <EditMultiChoiceModal
            table={'general'}
            settingKey={'bootAnimation'}
            label={'Boot Animation'}
            SelectComponent={SelectBootAnimation}
            choices={BootAnimationScreens}
          />,
        ),
        makeSettingRow(
          'general',
          'saveLogs',
          'Save Logs',
          (v) => displayEnabledDisabledSetting(v),
          <EditCheckboxEnabledModal
            table="general"
            settingKey="saveLogs"
            label="Save Logs"
            initialValue={false}
          />,
        ),
        makeSettingRow(
          'general',
          'turboModeIntervalMs',
          'Turbo Mode Interval',
          (v) => v && displayDurationSetting(v),
          <EditNumberMinsModal
            table="general"
            settingKey="turboModeIntervalMs"
            label="Turbo Mode Interval"
            initialValue={3}
            sampleRate={1000 * 60}
          />,
        ),
        makeSettingRow(
          'general',
          'turboModeTimeoutMs',
          'Turbo Mode Timeout',
          (v) => v && displayDurationSetting(v),
          <EditNumberMinsModal
            table="general"
            settingKey="turboModeTimeoutMs"
            label="Turbo Mode Timeout"
            initialValue={20}
            sampleRate={1000 * 60}
          />,
        ),
      ]
        .filter((f) => f)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.general.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    audit,
    isDevice,
    makeSettingRow,
    isTenantAdmin,
  ]);

  return (
    <>
      <Title level={2}>General Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default GeneralSettingsTable;
