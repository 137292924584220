import {
  DeleteOutlined,
  DownloadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Button, Progress, Space, Typography } from 'antd';
import filesize from 'filesize';
import React from 'react';
import ellipsis from 'text-ellipsis';
import settings from '../../../settings';
import { getFileIcon } from './FileIcons';

const { Text } = Typography;

const AttachmentCard = ({
  thumbnailUrl,
  fileName,
  fileSize,
  onUploadDelete,
  deleted,
  progress,
  url,
  style = {},
}) => {
  return (
    <Button
      className="ant-btn upload-list"
      style={{
        paddingRight: 8,
        paddingTop: !deleted && !onUploadDelete ? 8 : 0,
        paddingBottom: 0,
        height: 64,
        ...style,
      }}
      target={(!deleted && !onUploadDelete && '_blank') || undefined}
      download={(!deleted && !onUploadDelete && fileName) || undefined}
      href={(!deleted && !onUploadDelete && url) || undefined}
      onClick={(!deleted && onUploadDelete) || undefined}
    >
      <Space>
        {progress !== undefined && (
          <Progress type="circle" percent={progress.toFixed(0)} width={48} />
        )}
        {progress === undefined && (
          <>
            {thumbnailUrl ? (
              <img className="upload-list-img" src={thumbnailUrl} />
            ) : (
              getFileIcon(fileName, { fontSize: 18 })
            )}
          </>
        )}
        <div>
          {`${ellipsis(fileName, 20)} (${filesize(fileSize, {
            round: 1,
          })})`}
        </div>
        {!deleted && onUploadDelete && (
          <div className="download" style={{ color: settings.colors.red }}>
            <DeleteOutlined />
          </div>
        )}
        {deleted && (
          <div style={{ marginRight: 8 }}>
            <Text type="danger">Will be Deleted</Text>
          </div>
        )}
        {!deleted && !onUploadDelete && url && (
          <div className="download">
            <DownloadOutlined />
          </div>
        )}
      </Space>
      <style jsx>{`
        .upload-list-img {
          height: 48px;
        }
        .download {
          padding-right: 8px;
          padding-left: 8px;
          font-size: 16px;
        }
      `}</style>
    </Button>
  );
};

export default AttachmentCard;
