import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, message, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { sendPlaySoundCommandMutation } from '../../constants';
import SendApplicationUpdateCommand from './SendApplicationUpdateCommand';
import SendFirmwareUpdateCommand from './SendFirmwareUpdateCommand';
import SendGetLogsCommand from './SendGetLogsCommand';
import SendRegisterCommand from './SendRegisterCommand';
import SendSwitchEnvCommand from './SendSwitchEnvCommand';
import SendRequestHeartbeatCommand from './SendRequestHeartbeatCommand';
import SendRebootCommand from './SendRebootCommand';
import SendCallCommand from './SendCallCommand';

const { Title, Text } = Typography;

function JoonDeviceCommands({ joonDevice }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [sendPlaySoundCommand] = useMutation(sendPlaySoundCommandMutation);

  const handleSendPlaySound = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      await sendPlaySoundCommand({
        variables: {
          cmd: {
            deviceId: joonDevice._id,
          },
        },
      });
      message.success('Play sound command sent');
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [joonDevice, sendPlaySoundCommand]);

  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Card title="Send Play Sound Command" style={{ marginBottom: 16 }}>
        <Button
          icon={<SendOutlined />}
          onClick={handleSendPlaySound}
          loading={loading}
        >
          Send
        </Button>
      </Card>
      <SendApplicationUpdateCommand joonDevice={joonDevice} />
      <SendFirmwareUpdateCommand joonDevice={joonDevice} />
      <SendGetLogsCommand joonDevice={joonDevice} />
      <SendRegisterCommand joonDevice={joonDevice} />
      <SendRequestHeartbeatCommand joonDevice={joonDevice} />
      <SendRebootCommand joonDevice={joonDevice} />
      <SendCallCommand joonDevice={joonDevice} />
      <SendSwitchEnvCommand joonDevice={joonDevice} />
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default JoonDeviceCommands;
