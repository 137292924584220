import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { generate } from 'short-uuid';
import {
  GeofenceTypes,
  addressSearchQuery,
  createGeofenceMutation,
  reverseGeocodeQuery,
  updateGeofenceMutation,
} from './constants';
import removeTypename, { formatAddress } from '../../../../shared/utils';

const { Title, Text } = Typography;

const DEFAULT_RADIUS = 91.44;
const FEET_TO_METERS = 3.28084;

function EditSafeZonesModal({ editSafeZone, onFinish, onCancel }) {
  const { joonDeviceId, safeZone, creating } = editSafeZone || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateGeofence] = useMutation(updateGeofenceMutation);
  const [createGeofence] = useMutation(createGeofenceMutation);
  const client = useApolloClient();

  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      try {
        const {
          lat,
          lon,
          address: enteredAddress,
          radius: radiusInFt,
          ...values
        } = _values;
        const radius = radiusInFt / FEET_TO_METERS;
        const response = await client.query({
          query: reverseGeocodeQuery,
          variables: {
            loc: {
              lat: lat,
              lon: lon,
            },
          },
        });
        const address =
          response && response.data && response.data.reverseGeocode;
        if (creating) {
          await createGeofence({
            variables: {
              geofence: {
                _id: safeZone._id,
                deviceId: joonDeviceId,
                address: address && removeTypename(address),
                type: GeofenceTypes.CIRCLE.key,
                radius,
                center: {
                  lat,
                  lon,
                },
                ...values,
              },
            },
          });
        } else {
          await updateGeofence({
            variables: {
              geofence: {
                _id: safeZone._id,
                address: address && removeTypename(address),
                type: GeofenceTypes.CIRCLE.key,
                radius,
                center: {
                  lat,
                  lon,
                },
                ...values,
              },
            },
          });
        }
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      client,
      creating,
      createGeofence,
      updateGeofence,
      onFinish,
      joonDeviceId,
      safeZone,
    ],
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (safeZone) {
      form.setFieldsValue({
        _id: safeZone._id,
        name: safeZone.name,
        lat: safeZone.center && safeZone.center.lat,
        lon: safeZone.center && safeZone.center.lon,
        radius: safeZone.radius
          ? safeZone.radius * FEET_TO_METERS
          : DEFAULT_RADIUS,
        address:
          safeZone.address &&
          formatAddress(safeZone.address).replaceAll('\n', ' '),
      });
    } else {
      form.resetFields();
    }
  }, [safeZone, form]);

  const [searchResults, setSearchResults] = useState();
  const [searching, setSearching] = useState();
  const doGeocode = useCallback(
    async (searchTerm) => {
      try {
        const response = await client.query({
          query: addressSearchQuery,
          variables: {
            search: searchTerm,
          },
          fetchPolicy: 'cache-first',
        });
        if (response && response.data && response.data.addressSearch) {
          setSearchResults(response.data.addressSearch);
          form.setFieldsValue({
            lat:
              response.data.addressSearch.loc &&
              response.data.addressSearch.loc.lat,
            lon:
              response.data.addressSearch.loc &&
              response.data.addressSearch.loc.lon,
          });
        } else {
          setSearchResults(null);
          form.setFieldsValue({
            lat: null,
            lon: null,
          });
        }
      } catch (err) {
        console.error(err);
      }
      setSearching(false);
    },
    [client, form],
  );

  return (
    <>
      <Modal
        title={`Edit Safe Zone`}
        open={!!editSafeZone}
        closable
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        cancelText="Cancel"
        okText="Save Safe Zone"
        onOk={() => form.submit()}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSave}
          className="config-cmd"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
        >
          <Form.Item
            name="name"
            label="Safe Zone Name"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            hasFeedback
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item name="address" label="Address" rules={[]}>
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.address !== curValues.address
            }
            noStyle
          >
            {({ getFieldValue }) => (
              <Form.Item>
                <Button
                  onClick={() => doGeocode(getFieldValue('address'))}
                  htmlType="button"
                  disabled={loading}
                  loading={searching}
                >
                  Lookup GPS Location
                </Button>
              </Form.Item>
            )}
          </Form.Item>
          {searchResults && searchResults.address && (
            <Form.Item>
              {formatAddress(searchResults.address)
                .split('\n')
                .map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
            </Form.Item>
          )}
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="lat"
              label="Latitude"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              style={{ marginRight: 16, flex: 1 }}
              hasFeedback
            >
              <InputNumber
                min={-90}
                max={90}
                step={0.000001}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="lon"
              label="Longitude"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              style={{ flex: 1 }}
              hasFeedback
            >
              <InputNumber
                min={-180}
                max={180}
                step={0.000001}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="radius"
            label="Radius in feet"
            initialValue={300}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            style={{ marginRight: 16, flex: 1 }}
            hasFeedback
          >
            <InputNumber min={300} step={1} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditSafeZonesModal;
