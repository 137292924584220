import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Card, List, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import PhotoCircle from '../../../../components/PhotoCircle';
import settings from '../../../../settings';
import { formatPhoneNumberForDisplay } from '../../../../shared/utils';
import { DeviceUserRoles } from '../../constants';
import AddDeviceUserModal from './AddDeviceUserModal';
import DeleteDeviceUserModal from './DeleteDeviceUserModal';
import RegisterNewOwnerModal from './RegisterNewOwnerModal';
import UnregisterDeviceOwnerModal from './UnregisterDeviceOwnerModal';
import useFilterUserXJoon3s from './use-filter-user-x-joon3s';
import useProfile from '../../../../shared/use-profile';
import { Link } from 'react-router-dom';

const { Title, Text, Paragraph } = Typography;

export const deviceUserUserQuery = gql`
  query DeviceUserUserQuery($_id: ID!) {
    user(_id: $_id) {
      _id
      username
      email
      name
      phone
      primaryThumbnailUrl
    }
  }
`;

function DeviceUserItem({ item, onDeleteClick, onEditClick, showUpdate }) {
  return (
    <List.Item>
      <Card bodyStyle={{ textAlign: 'center', position: 'relative' }}>
        {showUpdate && (
          <div
            style={{
              top: 0,
              left: 0,
              position: 'absolute',
              width: '100%',
              display: 'flex',
            }}
          >
            <Link to={`/user/view/${item.user._id}/devices`}>
              <Button type="text" icon={<EyeOutlined />} />
            </Link>
            <div style={{ flex: 1 }} />
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => onEditClick(item)}
            />
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(item)}
            />
          </div>
        )}
        {item.user && (
          <div
            style={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PhotoCircle
              color={settings.colors.secondary}
              diameter={100}
              imageUrl={item.user.primaryThumbnailUrl}
              textStyle={{ color: 'white' }}
              initials={
                item.user.name &&
                item.user.name
                  .split(' ')
                  .map((n) => n && n[0])
                  .filter((l) => l)
                  .join('')
              }
            />
          </div>
        )}
        {item.user && (
          <Title level={5} style={{ marginBottom: 0 }}>
            {item.user.name}
          </Title>
        )}
        {item.role && DeviceUserRoles[item.role] && (
          <div
            style={{
              marginBottom: 8,
              color: settings.colors.textGray,
              fontWeight: 600,
            }}
          >
            {DeviceUserRoles[item.role].label}
          </div>
        )}
        {item.user && (
          <div style={{ marginBottom: 8 }}>
            <div>{item.user.username}</div>
            <div>{item.user.email}</div>
            <div>
              {item.user.phone && formatPhoneNumberForDisplay(item.user.phone)}
            </div>
          </div>
        )}
        {item.relationToWearer && (
          <div>
            <div style={{ color: settings.colors.primary }}>
              Relation to Wearer:
            </div>
            <div>{item.relationToWearer}</div>
          </div>
        )}
        <div>
          <div style={{ color: settings.colors.primary }}>
            User Specified Device Name:
          </div>
          <div>{item.nickName}</div>
        </div>
      </Card>
    </List.Item>
  );
}

function JoonDeviceUsers({ joonDevice, queryId }) {
  const deviceUsers = useFilterUserXJoon3s({ deviceId: joonDevice._id });
  const profile = useProfile();
  const showUpdate = useMemo(() => {
    return (
      profile &&
      profile.role &&
      profile.role.permissions &&
      profile.role.permissions.includes('update-joon3:all')
    );
  }, [profile]);

  const [adding, setAdding] = useState();
  const onCancelAdd = useCallback(() => {
    setAdding(undefined);
  }, []);
  const onAddSuccess = useCallback(() => {
    setAdding(undefined);
  }, []);
  const onAddClick = useCallback(
    (deviceUser) => {
      setAdding({ joonDevice, deviceUser });
    },
    [joonDevice],
  );

  const [registering, setRegistering] = useState();
  const onCancelRegister = useCallback(() => {
    setRegistering(undefined);
  }, []);
  const onRegisterSuccess = useCallback(() => {
    setRegistering(undefined);
  }, []);
  const onRegisterClick = useCallback(() => {
    setRegistering({ joonDevice });
  }, [joonDevice]);

  const [unregistering, setUnregistering] = useState();
  const onCancelUnregister = useCallback(() => {
    setUnregistering(undefined);
  }, []);
  const onUnregisterSuccess = useCallback(() => {
    setUnregistering(undefined);
  }, []);

  const [deleting, setDeleting] = useState();
  const onCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteSuccess = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteClick = useCallback((deviceUser) => {
    if (deviceUser.role === DeviceUserRoles.OWNER.key) {
      setUnregistering({ deviceUser });
    } else {
      setDeleting({ deviceUser });
    }
  }, []);
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <div className="top-actions"></div>
      <List
        dataSource={deviceUsers}
        renderItem={(item) => (
          <DeviceUserItem
            item={item}
            onDeleteClick={onDeleteClick}
            onEditClick={onAddClick}
            showUpdate={showUpdate}
          />
        )}
        locale={{
          emptyText: <div />,
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
      />
      {showUpdate && (
        <div style={{ textAlign: 'center' }}>
          {joonDevice.ownerId ? (
            <Button icon={<PlusOutlined />} onClick={() => onAddClick()}>
              Add a Device User
            </Button>
          ) : (
            <Button icon={<PlusOutlined />} onClick={onRegisterClick}>
              Register New Owner
            </Button>
          )}
        </div>
      )}
      <DeleteDeviceUserModal
        visible={deleting}
        onCancel={onCancelDelete}
        onSuccess={onDeleteSuccess}
        queryId={queryId}
      />
      <UnregisterDeviceOwnerModal
        visible={unregistering}
        onCancel={onCancelUnregister}
        onSuccess={onUnregisterSuccess}
      />
      <AddDeviceUserModal
        visible={adding}
        onCancel={onCancelAdd}
        onSuccess={onAddSuccess}
        queryId={queryId}
      />
      <RegisterNewOwnerModal
        visible={registering}
        onCancel={onCancelRegister}
        onSuccess={onRegisterSuccess}
      />
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default JoonDeviceUsers;
