import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  Joon3ConfigAttributes,
  TenantJoon3ConfigAttributes,
} from './constants';
import {
  createJoon3ConfigAction,
  deleteJoon3ConfigAction,
  updateJoon3ConfigAction,
} from '../../../../redux-store/joon3-configs-store';

export const Joon3ConfigUpdateAttributes = gql`
{
  type
  crud
  id
  new ${Joon3ConfigAttributes}
}
`;

export const joon3ConfigUpdatesSubscription = gql`
  subscription Joon3ConfigUpdates($deviceId: ID!) {
    joon3ConfigUpdates(deviceId: $deviceId) ${Joon3ConfigUpdateAttributes}
  }
`;

export const TenantJoon3ConfigUpdateAttributes = gql`
{
  type
  crud
  id
  new ${TenantJoon3ConfigAttributes}
}
`;

export const tenantJoon3ConfigUpdatesSubscription = gql`
  subscription TenantJoon3ConfigUpdates($deviceId: ID!) {
    tenantJoon3ConfigUpdates(deviceId: $deviceId) ${TenantJoon3ConfigUpdateAttributes}
  }
`;

const extract = (result, isTenantAdmin) => {
  if (isTenantAdmin) {
    return result?.data?.tenantJoon3ConfigUpdates;
  }
  return result?.data?.joon3ConfigUpdates;
};

function useJoon3ConfigUpdates(deviceId, isTenantAdmin = false) {
  const queryId = deviceId || 'default';
  const query = isTenantAdmin
    ? tenantJoon3ConfigUpdatesSubscription
    : joon3ConfigUpdatesSubscription;
  useSubscription(query, {
    skip: !deviceId,
    variables: {
      deviceId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const updates = extract(result, isTenantAdmin);
        if (updates) {
          updates.forEach((update) => {
            switch (update.type) {
              case 'JOON3_CONFIG_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoon3ConfigAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoon3ConfigAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoon3ConfigAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoon3ConfigAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useJoon3ConfigUpdates;
