import { useMutation } from '@apollo/client';
import { Form, Input, notification, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { editProfileMutation } from './constants';

const { Text } = Typography;

const ChangeProfilePasswordModal = ({ visible, onFinish, onCancel }) => {
  const { profile } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const [editProfile] = useMutation(editProfileMutation);

  const handleFinish = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { password } = _values;
      try {
        await editProfile({
          variables: {
            profile: {
              _id: profile._id,
              password,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Password updated successfully',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [profile, editProfile],
  );

  return (
    <>
      <Modal
        title={`Change password for ${profile?.name}`}
        open={!!visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        cancelText="Cancel"
        okText="Change Password"
        onOk={() => form.submit()}
        handleOk={() => form.submit()}
        onCancel={onCancel}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
      >
        <Form
          form={form}
          onFinish={handleFinish}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input a password',
              },
            ]}
            hasFeedback
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!',
                  );
                },
              }),
            ]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ChangeProfilePasswordModal;
