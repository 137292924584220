import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Form,
  Select,
  notification,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { bulkCreateJoonDevicesAction } from '../../../redux-store/joon-devices-store';
import settings from '../../../settings';
import SelectTenant from '../../tenants/SelectTenant';
import { bulkCreateJoonDevicesMutation } from '../constants';
import EnterImeiModal from '../EnterImeiModal';
import PreviewTable from './PreviewTable';
import UploadCSVButton from './UploadCSVButton';

const { Title, Text, Paragraph } = Typography;

const JoonDeviceFields = {
  _id: {
    key: '_id',
    label: 'IMEI',
    required: true,
  },
  iccid: {
    key: 'iccid',
    label: 'ICCID',
    required: false,
  },
  serialNo: {
    key: 'serialNo',
    label: 'Serial Number',
    required: false,
  },
  callCenterId: {
    key: 'callCenterId',
    label: 'Call Center ID (Transmit Code)',
    required: false,
  },
  desc: {
    key: 'desc',
    label: 'Description',
    required: false,
  },
};

function SelectField({ value, onChange }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select a field"
      allowClear
    >
      {Object.values(JoonDeviceFields).map((field) => (
        <Select.Option key={field.key} value={field.key}>
          {field.label}
        </Select.Option>
      ))}
    </Select>
  );
}

const validateJoonDeviceField = ({ getFieldValue, setFieldValue }) => ({
  validator({ field: fieldName }, value) {
    let reason;
    Object.values(JoonDeviceFields).forEach((field) => {
      const val = getFieldValue(field.key);
      if (
        val !== undefined &&
        val !== null &&
        field.key !== fieldName &&
        val === value
      ) {
        reason = 'You may only select this field once';
      }
    });
    if (reason) {
      return Promise.reject(reason);
    }
    return Promise.resolve();
  },
});

function ImportJoonDevices() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [csvResult, setCsvResult] = useState();
  const handleCsvParsed = useCallback((data) => {
    setCsvResult({
      headers: data[0],
      rows: data.slice(1),
    });
  }, []);

  const [bulkCreateJoonDevices] = useMutation(bulkCreateJoonDevicesMutation);
  const dispatch = useDispatch();
  const history = useHistory();
  const [readyToImport, setReadyToImport] = useState();

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { tenantId, tags, ...columns } = _values;
      try {
        const mapping = [];
        Object.entries(columns).forEach(([fieldName, columnIndex]) => {
          if (fieldName && fieldName !== '__blank__') {
            mapping.push([fieldName, columnIndex]);
          }
        });
        const creating = [];
        csvResult.rows.forEach((row, i) => {
          const device = mapping.reduce(
            (prev, [fieldName, columnIndex]) => {
              if (typeof row[columnIndex] === 'string') {
                prev[fieldName] = row[columnIndex].trim();
              } else {
                prev[fieldName] = row[columnIndex];
              }
              return prev;
            },
            {
              tenantId,
              tags,
            },
          );
          if (device._id) {
            if (device.iccid) {
              device.iccid = device.iccid.replace(/\D/g, '');
            }
            creating.push(device);
          }
        });
        setReadyToImport(creating);
        window.scrollTo(0, 0);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [csvResult],
  );

  const handleDoImport = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await bulkCreateJoonDevices({
        variables: {
          joonDevices: readyToImport,
        },
      });
      dispatch(
        bulkCreateJoonDevicesAction(
          result.data.bulkCreateJoonDevices.joonDevices,
        ),
      );
      notification.success({
        message: 'Saved',
        description: 'Theora Connect devices imported successfully',
      });
      history.push('/joon-devices');
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [history, bulkCreateJoonDevices, dispatch, readyToImport]);

  const SelectHeader = useCallback(
    ({ value, onChange, disabled }) => {
      return (
        <Select
          value={value}
          onChange={onChange}
          placeholder="Select a field"
          allowClear
          disabled={disabled}
        >
          {csvResult.headers.map((header, index) => (
            <Select.Option key={index} value={index}>
              {`${header} (Column ${index + 1})`}
            </Select.Option>
          ))}
        </Select>
      );
    },
    [csvResult],
  );

  return (
    <CSPage
      title="Import Theora Connect Devices"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        title="Import Theora Connect Devices"
        backActions={[
          <Link key="import" to="/joon-devices">
            <Button key="back" type="text" icon={<ArrowLeftOutlined />}>
              Back to Theora Connect Devices
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      {!csvResult && (
        <div style={{ marginBottom: 24 }}>
          <p>Upload a CSV that includes the following columns:</p>
          <ul>
            <li>IMEI</li>
            <li>ICCID (Optional)</li>
            <li>Serial Number (Optional)</li>
            <li>Call Center ID or Transmit Code (Optional)</li>
            <li>Description (Optional)</li>
          </ul>
        </div>
      )}
      <div className="top-actions">
        <UploadCSVButton
          onCsvParsed={handleCsvParsed}
          onDelete={() => {
            setCsvResult(undefined);
            setReadyToImport(undefined);
          }}
        />
      </div>
      {!!csvResult && (
        <Form
          layout="vertical"
          onFinish={onSubmit}
          style={{ maxWidth: 800, display: readyToImport ? 'none' : undefined }}
        >
          <Paragraph
            style={{
              fontSize: 16,
              textAlign: 'center',
              marginBottom: 8,
              color: settings.colors.secondary,
            }}
          >{`You are about to import ${csvResult.rows.length} devices.`}</Paragraph>
          <Form.Item
            name="tenantId"
            label="Which tenant do these devices belong to?"
          >
            <SelectTenant disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Add tags to every device in this import so you can find them easily"
            name="tags"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Type to create a tag"
              disabled={loading}
            />
          </Form.Item>
          <Paragraph
            style={{
              fontSize: 16,
              textAlign: 'center',
              marginBottom: 24,
              color: settings.colors.secondary,
            }}
          >{`Please match the headers in your CSV with the Theora Connect device fields.`}</Paragraph>
          {Object.values(JoonDeviceFields).map((field) => (
            <div key={field.key} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  color: settings.colors.secondary,
                  marginBottom: 24,
                }}
              >
                {field.label}
              </div>
              <div style={{ width: 32 }} />
              <Form.Item
                name={field.key}
                style={{ flex: 1 }}
                rules={[
                  validateJoonDeviceField,
                  {
                    required: field.required,
                    message: 'This field is required',
                  },
                ]}
              >
                <SelectHeader disabled={loading} />
              </Form.Item>
            </div>
          ))}
          {/* {csvResult.headers.map((header, index) => (
            <div key={header} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  color: settings.colors.secondary,
                  marginBottom: 24,
                }}
              >{`${header} (Column ${index + 1})`}</div>
              <div style={{ width: 32 }} />
              <Form.Item
                name={header}
                style={{ flex: 1 }}
                rules={[validateJoonDeviceField]}
              >
                <SelectField disabled={loading} />
              </Form.Item>
            </div>
          ))} */}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Link key="import" to="/joon-devices">
                <Button
                  key="cancel"
                  htmlType="button"
                  type="text"
                  size="small"
                  disabled={loading}
                  icon={<ArrowLeftOutlined />}
                  style={{ marginLeft: -7 }}
                >
                  Cancel
                </Button>
              </Link>
              <Button
                key="send"
                type="primary"
                loading={loading}
                htmlType="submit"
                icon={<ArrowRightOutlined />}
              >
                Next
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <div className="errors">
              <Text type="danger">{error}</Text>
            </div>
          )}
        </Form>
      )}
      {readyToImport && (
        <>
          <Title level={2}>Preview Import</Title>
          <Paragraph
            style={{ fontSize: 16, textAlign: 'center', marginBottom: 24 }}
          >
            Please carefully review ALL of the data below. If you see mistakes,
            please edit your CSV and re-import. Does this look right?
          </Paragraph>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 40,
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => setReadyToImport(undefined)}
            >
              No, Go Back
            </Button>
            <div style={{ width: 16 }} />
            <Button
              type="primary"
              onClick={handleDoImport}
              icon={<ImportOutlined />}
            >
              Yes, Import Devices
            </Button>
          </div>
          <PreviewTable joonDevices={readyToImport} />
        </>
      )}
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </CSPage>
  );
}

export default ImportJoonDevices;
