import React, { useContext, useMemo } from 'react';
import { Typography, Button, Dropdown, Menu, notification } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import useProfile from '../shared/use-profile';
import { AuthContext } from '../shared/auth-context';
import PhotoCircle from './PhotoCircle';
import settings from '../settings';

const { Title } = Typography;

function CSPageHeader({
  topActions = null,
  backActions = null,
  title,
  titleComponent = null,
}) {
  const profile = useProfile();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const initials = useMemo(() => {
    if (profile && profile.name) {
      return profile.name
        .split()
        .map((p) => p && p[0])
        .join('');
    }
    return 'P';
  }, [profile]);
  return (
    <div className="header">
      {profile && (
        <div className="profile-menu">
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit-profile',
                  icon: <UserOutlined />,
                  label: <Link to="/edit-profile">Profile</Link>,
                },
                {
                  type: 'divider',
                },
                {
                  key: 'sign-out',
                  icon: <LogoutOutlined />,
                  label: (
                    <Link
                      to="/sign-in"
                      onClick={() => {
                        authContext.signOut();
                        notification.open({
                          message: 'Success',
                          description: `Successfully signed out!`,
                        });
                      }}
                    >
                      Sign Out
                    </Link>
                  ),
                },
              ],
            }}
            trigger={['click']}
          >
            <Button
              type="text"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <PhotoCircle
                initials={initials}
                color={settings.colors.primary}
                diameter={40}
                style={{ marginRight: 12 }}
                textStyle={{ color: 'white', fontSize: 16 }}
                imageUrl={profile.primaryThumbnailUrl}
              />
              {profile.name}
            </Button>
          </Dropdown>
        </div>
      )}
      <div className="back-actions">{backActions}</div>
      {titleComponent}
      {title && !titleComponent && (
        <Title className="cs-header-title" style={{ marginBottom: 8 }}>
          {title}
        </Title>
      )}
      <div className="top-actions" style={{ marginLeft: -15 }}>
        {topActions}
      </div>
      <style jsx>{`
        .profile-menu {
          position: absolute;
          top: 24px;
          right: 32px;
        }
        .header {
          margin-bottom: 32px;
        }
        .back-actions {
          height: 50px;
          margin-left: -15px;
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
      <style jsx global>{`
        .cs-header-title {
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
    </div>
  );
}

export default CSPageHeader;
