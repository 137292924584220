import { gql, useMutation } from '@apollo/client';
import { Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateJoon3ReportAction } from '../../../redux-store/joon3-reports-store';

export const updateJoon3ReportMutation = gql`
  mutation UpdateJoon3Report($report: Joon3ReportUpdateInput!) {
    updateJoon3Report(report: $report)
  }
`;

function NightlyReportEditSomethingModal({
  report,
  children,
  editing,
  onDone,
  onCancel,
  queryId,
}) {
  const [saving, setSaving] = useState(false);
  const [updateJoon3Report] = useMutation(updateJoon3ReportMutation);

  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateJoon3Report({
          variables: {
            report: {
              _id: report._id,
              ...values,
            },
          },
        });
        dispatch(updateJoon3ReportAction({ ...report, ...values }, queryId));
        notificationApi.success({
          message: 'Success',
          description: 'Report updated successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notificationApi.error({
          message: 'Error',
          description: 'There was an error updating your report',
        });
      }
      setSaving(false);
    },
    [report, updateJoon3Report, onDone, notificationApi, dispatch, queryId],
  );
  return (
    <Modal
      header={null}
      footer={null}
      open={!!editing}
      closable
      destroyOnClose={true}
      maskClosable
      onCancel={onCancel}
      bodyStyle={{ paddingTop: 48 }}
    >
      {contextHolder}
      {React.cloneElement(children, {
        report,
        handleFinish,
        saving,
        onCancel,
      })}
    </Modal>
  );
}

export default NightlyReportEditSomethingModal;
