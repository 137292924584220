import { EyeOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { readUserXJoon3sAction } from '../../redux-store/user-x-joon3s-store';
import settings from '../../settings';
import useMany from '../../shared/use-many';
import { shallowEqual, useSelector } from 'react-redux';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { AuditStatuses } from '../joonDevices/constants';
import { useManyRemote } from '../../shared/use-many-remote';

export const ViewUserXJoon3Attributes = gql`
  {
    _id
    role
    relationToWearer
    nickName
    userId
    deviceId
    device {
      _id
      desc
      tags
      tenantId
      tenant {
        _id
        name
      }
      ownerId
      ownerName
      wearerId
      wearerName
      serialNo
      imsi
      eid
      iccid
      msisdn
      simStatus
      activatedAt
      callCenterId
      fwVer
      appVer
      appFlavor
      fallAppVer
      fallAppFlavor
      confVer
      auditStatus
      commVer
      envName
      updatedAt
    }
    tenantId
    updatedAt
  }
`;

export const viewAllUserXJoon3sQuery = gql`
  query ViewAllUserXJoon3sQuery($first: Int, $after: String, $filters: UserXJoon3Filters, $sortBy: [SortBy]) {
    allUserXJoon3s(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${ViewUserXJoon3Attributes}
      }
    }
  }
`;

function UserDevicesTab({ user }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ userId: user._id });
  const { loading, data: userXJoon3s } = useManyRemote(
    viewAllUserXJoon3sQuery,
    (data) => data.allUserXJoon3s,
    settings.querySize,
    filters.current,
    sortBy.current,
  );
  const devices = useMemo(() => {
    return userXJoon3s?.map((x) => x.device) || [];
  }, [userXJoon3s]);

  return (
    <Table
      dataSource={devices}
      loading={!devices.length && loading}
      pagination={false}
      style={{ width: '100%', marginTop: 16 }}
      scroll={{ x: 800 }}
      rowKey="_id"
    >
      <Table.Column title="IMEI" dataIndex="_id" key="_id" />
      <Table.Column title="Serial No." dataIndex="serialNo" key="serialNo" />
      <Table.Column title="ICCID" dataIndex="iccid" key="iccid" />
      <Table.Column
        title="Phone Number"
        dataIndex="msisdn"
        render={(text, record) => {
          if (record.msisdn) {
            const parsed = parsePhoneNumber(record.msisdn, {
              regionCode: 'US',
            });
            return parsed && parsed.number && parsed.number.international;
          }
          return null;
        }}
      />
      <Table.Column
        title="Tenant"
        dataIndex="tenant"
        key="tenant"
        render={(text, record) => {
          return (
            record.tenant && (
              <Link to={`/tenant/view/${record.tenant._id}/settings`}>
                {record.tenant.name}
              </Link>
            )
          );
        }}
      />
      <Table.Column
        title="Call Center ID"
        dataIndex="callCenterId"
        key="callCenterId"
      />
      <Table.Column
        title="Configuration"
        dataIndex="auditStatus"
        render={(text, record) => {
          const status =
            record.auditStatus && AuditStatuses[record.auditStatus];
          if (status) {
            return <Tag color={status.color}>{status.label}</Tag>;
          }
          return null;
        }}
      />
      <Table.Column
        title="Tags"
        dataIndex="tags"
        render={(text, record) => {
          return (
            record.tags &&
            record.tags.map((tag) => (
              <Tag
                key={tag}
                color={settings.colors.secondary}
                style={{ marginBottom: 4 }}
              >
                {tag}
              </Tag>
            ))
          );
        }}
      />
      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="View">
              <Link to={`/joon-device/view/${record._id}/daily-stats`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            {/* {showUpdate && (
              <Tooltip title="Edit">
                <Link
                  to={{
                    pathname: `joon-device/edit/${record._id}`,
                    state: { mode: 'update' },
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
            )} */}
          </Space>
        )}
      />
    </Table>
  );
}

export default UserDevicesTab;
