import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsCategoryTable from './SettingsCategoryTable';
import { EnvironmentNames, TenantSettings } from './constants';
import {
  displayMultiChoice,
  getUnifiedSetting,
  makeAuditStatusRow,
} from './utils';

const { Title, Text } = Typography;

function EnvironmentSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  loading,
  audit,
  isDevice,
  isTenantAdmin,
}) {
  const makeSettingRow = useCallback(
    (table, key, label, displayFunc, editModal) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return {
        key,
        label,
        value: displayFunc(value),
        src,
        isEditable: !!editModal,
        editModal,
      };
    },
    [systemConfig, tenantConfig, deviceConfig],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig
    ) {
      return [
        makeAuditStatusRow(audit, 'environment'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.environment?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.environment?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig.environment?._id,
          editable: false,
        },
        makeSettingRow('environment', 'envName', 'Environment Name', (v) =>
          displayMultiChoice(v, EnvironmentNames),
        ),
        makeSettingRow(
          'environment',
          'mqttHost',
          'MQTT Host Address',
          (v) => v,
        ),
        makeSettingRow('environment', 'mqttPort', 'MQTT Port', (v) => v),
        makeSettingRow('environment', 'apiHost', 'API Host Address', (v) => v),
      ]
        .filter((f) => f)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.environment.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    audit,
    makeSettingRow,
    isDevice,
    isTenantAdmin,
  ]);

  return (
    <>
      <Title level={2}>Environment Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default EnvironmentSettingsTable;
