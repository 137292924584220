import { Select } from 'antd';

export const RingVolumes = [...Array(7).keys()].reduce((prev, curr) => {
  const v = curr + 1;
  prev[v] = {
    key: v,
    label: `${v}`,
  };
  return prev;
}, {});

function SelectRingVolume({ value, onChange, ...other }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select a ring volume"
      allowClear
      {...other}
    >
      {Object.values(RingVolumes).map((v) => (
        <Select.Option key={v.key} value={v.key}>
          {v.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectRingVolume;
