import { Button, Empty, List, Skeleton, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { getLastUpdated } from './utils';
import settings from '../../../settings';
import { LocationIcon } from '../../../icons/icons';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Text } = Typography;

function DeviceList({ devices, listLoading, handleCenterTo }) {
  const [numShown, setNumShown] = useState(settings.pageSize);
  return (
    <>
      {!listLoading ? (
        <List
          rowKey="_id"
          dataSource={[...devices.slice(0, numShown)]}
          renderItem={(item) => {
            const device = item.device;
            const now = item.now;
            const { timeSinceUpdate, secondsSinceUpdate } = getLastUpdated(
              now && now.latestMsgTimestampMs,
            );
            const wearerName = device.wearerName;
            // const color = settings.colors.blue;
            let color;
            if (secondsSinceUpdate <= 86400) {
              color = settings.colors.green;
            } else if (secondsSinceUpdate <= 1209600) {
              color = settings.colors.yellow;
            } else {
              color = settings.colors.red;
            }
            return (
              <List.Item>
                <div
                  className="item-content"
                  style={{
                    borderLeft: `2px solid ${color}`,
                    borderRadius: 4,
                    paddingLeft: 12,
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <div className="item-info">
                    <div className="item-line">
                      <div className="item-label">IMEI</div>
                      <div className="item-value">
                        <Text>{device._id}</Text>
                      </div>
                    </div>
                    <div className="item-line">
                      <div className="item-label">Wearer</div>
                      <div className="item-value">
                        <Text>{wearerName || 'No wearer'}</Text>
                      </div>
                    </div>
                    {timeSinceUpdate && (
                      <div className="item-line">
                        <div className="item-label">Updated</div>
                        <div className="item-value">
                          <Text>{timeSinceUpdate}</Text>
                        </div>
                      </div>
                    )}
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                      {now && (
                        <Tooltip title="Zoom To">
                          <Button
                            icon={<LocationIcon />}
                            onClick={() => handleCenterTo.current(now)}
                            size="medium"
                            style={{
                              height: 32,
                              width: 32,
                              padding: 0,
                              marginRight: 4,
                            }}
                          />
                        </Tooltip>
                      )}
                      {device && (
                        <Tooltip title="View Device">
                          <Link to={`/joon-device/view/${device._id}/settings`}>
                            <Button
                              icon={<EyeOutlined />}
                              size="medium"
                              style={{ height: 32, width: 32, padding: 0 }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </List.Item>
            );
          }}
          loading={listLoading}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No devices match the current filters"
              />
            ),
          }}
          loadMore={
            devices.length > numShown && (
              <div className="load-more">
                <Button
                  onClick={() => setNumShown(numShown + settings.pageSize)}
                >
                  Load More
                </Button>
              </div>
            )
          }
        />
      ) : (
        <List
          dataSource={Array(settings.pageSize).fill(0)}
          renderItem={(item) => (
            <List.Item>
              <Skeleton active title={false} avatar />
            </List.Item>
          )}
        />
      )}
      <style jsx>{`
        .load-more {
          display: flex;
          justify-content: center;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        .item-label {
          color: ${settings.colors.blue};
          font-weight: 500;
        }
      `}</style>
    </>
  );
}

export default DeviceList;
