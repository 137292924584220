import { useMutation } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import {
  updateJoon3WifiNetworksMutation,
  updateTenantJoonWifiNetworksMutation,
} from './constants';
import useIsTenantAdmin from '../use-is-tenant-admin';

const { Title, Text, Paragraph } = Typography;

const DeleteWifiNetworkModal = ({ deleteDetails, onSuccess, onCancel }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { joonDeviceId, wifiNetworks, wifiNetwork } = deleteDetails || {};

  const isTenantAdmin = useIsTenantAdmin();
  const query = useMemo(() => {
    if (isTenantAdmin) {
      return updateTenantJoonWifiNetworksMutation;
    }
    return updateJoon3WifiNetworksMutation;
  }, [isTenantAdmin]);
  const [updateWifiNetworks] = useMutation(query);

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const remaining = wifiNetworks
        .filter((w) => w._id != wifiNetwork._id)
        // This .map is required to filter out the __typename from graphql
        .map((w) => {
          return { _id: w._id };
        });
      await updateWifiNetworks({
        variables: {
          deviceId: joonDeviceId,
          wifiNetworks: remaining,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Wifi Network deleted',
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [joonDeviceId, onSuccess, updateWifiNetworks, wifiNetworks, wifiNetwork]);

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={deleteDetails}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 32, paddingBottom: 32 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Delete Wifi Network
        </Title>
        <Paragraph style={{ textAlign: 'center' }}>
          Are you sure you want to delete this wifi network?
        </Paragraph>
        <Paragraph style={{ textAlign: 'center' }}>
          {wifiNetwork && <h5>{`${wifiNetwork.ssid}`}</h5>}
        </Paragraph>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button danger onClick={handleFinish} type="primary">
            Delete
          </Button>
        </div>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default DeleteWifiNetworkModal;
