import { Form, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function ShowErrorsFormItem() {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsError }) => {
        const errorList = getFieldsError();
        let showError = false;
        errorList.forEach((errors) => {
          if (errors.errors.length) {
            showError = true;
          }
        });
        return (
          showError && (
            <Text type="danger" style={{ marginTop: 16 }}>
              Please correct the errors above
            </Text>
          )
        );
      }}
    </Form.Item>
  );
}

export default ShowErrorsFormItem;
