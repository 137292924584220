import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { windowDimsChangedAction } from '../redux-store/window-store';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () =>
      dispatch(windowDimsChangedAction(getWindowDimensions()));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);
}

export function useWindowDimsChanging() {
  const [dimsChanging, setDimsChanging] = useState(false);
  const dimsTimeout = useRef(null);
  const effectTimeout = useRef(null);
  const dims = useRef(null);
  useSelector((store) => {
    if (
      !dims.current ||
      store.windowDimensions.width !== dims.current.width ||
      store.windowDimensions.height !== dims.current.height
    ) {
      dims.current = store.windowDimensions;
      clearTimeout(effectTimeout.current);
      effectTimeout.current = setTimeout(() => {
        setDimsChanging(true);
        clearTimeout(dimsTimeout.current);
        dimsTimeout.current = setTimeout(() => {
          setDimsChanging(false);
        }, 500);
      }, 10);
    }
  }, shallowEqual);
  return dimsChanging;
}
