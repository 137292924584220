import { useCallback, useEffect, useRef, useState } from 'react';
import SelectMultiple from './SelectMultiple';
import useQueryParams from '../../../shared/use-query-params';
import useChangeFilters from '../use-change-filters';
import { notNullOrUndefined } from '../../../shared/utils';
import { getArrayFromUrlFilters } from './utils';

function SelectMultipleFilter({ name, items, noLabel, filterLabel }) {
  const [value, setValue] = useState([]);
  const urlFilters = useQueryParams();
  const _urlFilters = useRef(urlFilters);

  useEffect(() => {
    _urlFilters.current = urlFilters;
  }, [urlFilters]);

  const last = useRef();
  useEffect(() => {
    try {
      const nextV = getArrayFromUrlFilters(urlFilters, name);
      if (JSON.stringify(nextV) !== JSON.stringify(last.current)) {
        last.current = nextV;
        setValue(nextV);
      }
    } catch (err) {
      console.error(err);
    }
  }, [urlFilters, name]);
  const changeFilters = useChangeFilters();
  const onFilterChange = useCallback(
    (v) => {
      changeFilters({
        ..._urlFilters.current,
        [name]: v,
      });
    },
    [changeFilters, name],
  );

  return (
    <div>
      <SelectMultiple
        value={value}
        onChange={onFilterChange}
        options={
          items
            ? Object.values(items).map((t) => ({
                key: notNullOrUndefined(t._id) ? t._id : '__undefined__',
                label: notNullOrUndefined(t.label) ? t.label : noLabel,
              }))
            : []
        }
        placeholder={filterLabel}
        style={{ width: '100%' }}
      />
    </div>
  );
}

export default SelectMultipleFilter;
