import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsCategoryTable from './SettingsCategoryTable';
import { CallCenterCos, TenantSettings } from './constants';
import EditMultiChoiceModal from './edit-modals/EditMultiChoiceModal';
import EditMultiPhoneNoSettingModal from './edit-modals/EditMultiPhoneNoSettingModal';
import EditNumberModal from './edit-modals/EditNumberModal';
import EditPhoneNoSettingModal from './edit-modals/EditPhoneNoSettingModal';
import EditTextModal from './edit-modals/EditTextModal';
import SelectCallCenterCo from './edit-modals/SelectCallCenterCo';
import useCallCenterCoMeta from './use-call-center-co-meta';
import {
  displayMultiChoice,
  displayMultiPhoneNumberSetting,
  displayPhoneNumberSetting,
  displayUnspecifiable,
  getUnifiedSetting,
  makeAuditStatusRow,
} from './utils';

const { Title, Text } = Typography;

function CallCenterSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  loading,
  isDevice,
  audit,
  isTenantAdmin,
}) {
  const callCenterCo = useMemo(() => {
    if ((isDevice ? deviceConfig : true) && tenantConfig && systemConfig) {
      const [callCenterType, _] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        'callCenter',
        'type',
      );
      return callCenterType;
    }
  }, [isDevice, systemConfig, tenantConfig, deviceConfig]);
  const callCenterMeta = useCallCenterCoMeta(callCenterCo);
  const makeSettingRow = useCallback(
    (
      table,
      key,
      label,
      displayFunc,
      isEditable,
      isRequired,
      editModal,
      isDeletable,
    ) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return (
        isRequired && {
          key,
          label,
          value: displayFunc(value),
          src,
          isEditable,
          editModal,
          error: audit?.[table]?.[key],
          deleteProps: isDeletable
            ? {
                table,
                settingKey: key,
                title: `Delete ${label}`,
                description:
                  'Are you sure your want to delete this setting and return it to its default?',
              }
            : undefined,
        }
      );
    },
    [systemConfig, tenantConfig, deviceConfig, audit],
  );
  const checkIsRequired = useCallback(
    (attr) => {
      if (isDevice) {
        return callCenterMeta.deviceRequiredFields.includes(attr);
      }
      return callCenterMeta.tenantRequiredFields.includes(attr);
    },
    [isDevice, callCenterMeta],
  );

  const checkIsEditable = useCallback(
    (attr) => {
      if (isDevice) {
        return callCenterMeta.deviceEditableFields.includes(attr);
      }
      return callCenterMeta.tenantEditableFields.includes(attr);
    },
    [isDevice, callCenterMeta],
  );

  const checkIsDeletable = useCallback(
    (attr) => {
      if (isDevice) {
        return !callCenterMeta.deviceEditableFields.includes(attr);
      }
      return !callCenterMeta.tenantEditableFields.includes(attr);
    },
    [isDevice, callCenterMeta],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig &&
      callCenterMeta
    ) {
      return [
        makeAuditStatusRow(audit, 'callCenter'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.callCenter?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.callCenter?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig.callCenter?._id,
          editable: false,
        },
        makeSettingRow(
          'callCenter',
          'type',
          'Call Center Type',
          (v) => displayMultiChoice(v, CallCenterCos),
          checkIsEditable('type'),
          checkIsRequired('type'),
          <EditMultiChoiceModal
            table="callCenter"
            settingKey="type"
            label="Call Center Type"
            SelectComponent={SelectCallCenterCo}
            choices={CallCenterCos}
          />,
        ),
        makeSettingRow(
          'callCenter',
          'url',
          'URL',
          (v) => displayUnspecifiable(v),
          checkIsEditable('url'),
          checkIsRequired('url'),
          <EditTextModal
            table="callCenter"
            settingKey="url"
            label="URL"
            required={checkIsRequired('url')}
          />,
        ),
        makeSettingRow(
          'callCenter',
          'username',
          'Username',
          (v) => displayUnspecifiable(v),
          checkIsEditable('username'),
          checkIsRequired('username'),
          <EditTextModal
            table="callCenter"
            settingKey="username"
            label="Username"
            required={checkIsRequired('username')}
          />,
        ),
        checkIsEditable('password') &&
          makeSettingRow(
            'callCenter',
            'password',
            'Password',
            (v) => displayUnspecifiable(v),
            checkIsEditable('password'),
            checkIsRequired('password'),
            <EditTextModal
              table="callCenter"
              settingKey="password"
              label="Password"
              required={checkIsRequired('password')}
            />,
          ),
        makeSettingRow(
          'callCenter',
          'transmitCode',
          'Transmit Code',
          (v) => displayUnspecifiable(v),
          checkIsEditable('transmitCode'),
          checkIsRequired('transmitCode'),
          <EditTextModal
            table="callCenter"
            settingKey="transmitCode"
            label="Transmit Code"
            required={checkIsRequired('transmitCode')}
          />,
        ),
        makeSettingRow(
          'callCenter',
          'callCenterNumber',
          'Call Center Number',
          (v) => displayPhoneNumberSetting(v),
          checkIsEditable('callCenterNumber'),
          checkIsRequired('callCenterNumber'),
          <EditPhoneNoSettingModal
            table="callCenter"
            settingKey="callCenterNumber"
            label="Call Center Number"
            required={checkIsRequired('callCenterNumber')}
          />,
          checkIsDeletable('callCenterNumber'),
        ),
        makeSettingRow(
          'callCenter',
          'whitelistNumbers',
          'White List Numbers',
          (v) => displayMultiPhoneNumberSetting(v),
          checkIsEditable('whitelistNumbers'),
          checkIsRequired('whitelistNumbers'),
          <EditMultiPhoneNoSettingModal
            table="callCenter"
            settingKey="whitelistNumbers"
            label="White List Numbers"
            required={checkIsRequired('whitelistNumbers')}
          />,
          checkIsDeletable('callCenterNumber'),
        ),
        makeSettingRow(
          'callCenter',
          'ip',
          'IP Address',
          (v) => displayUnspecifiable(v),
          checkIsEditable('ip'),
          checkIsRequired('ip'),
          <EditTextModal
            table="callCenter"
            settingKey="ip"
            label="IP Address"
            required={checkIsRequired('ip')}
          />,
        ),
        makeSettingRow(
          'callCenter',
          'port',
          'IP Port',
          (v) => displayUnspecifiable(v),
          checkIsEditable('port'),
          checkIsRequired('port'),
          <EditNumberModal
            table="callCenter"
            settingKey="port"
            label="IP Port"
            required={checkIsRequired('port')}
          />,
        ),
        makeSettingRow(
          'callCenter',
          'sdnis',
          'SDNIS',
          (v) => displayUnspecifiable(v),
          checkIsEditable('sdnis'),
          checkIsRequired('sdnis'),
          <EditTextModal
            table="callCenter"
            settingKey="sdnis"
            label="SDNIS"
            required={checkIsRequired('sdnis')}
          />,
        ),
      ]
        .filter((a) => a)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.callCenter.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    makeSettingRow,
    callCenterMeta,
    isDevice,
    checkIsEditable,
    checkIsRequired,
    audit,
    isTenantAdmin,
    checkIsDeletable,
  ]);

  return (
    <>
      <Title level={2}>Call Center Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default CallCenterSettingsTable;
