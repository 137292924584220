import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Space,
  Button,
  notification,
} from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateJoonDeviceAction } from '../../../redux-store/joon-devices-store';
import settings from '../../../settings';
import { updateJoonDeviceMetadataMutation } from '../constants';

const { Title, Text } = Typography;

function EditDeviceMetadataForm({
  handleSave,
  loading,
  joonDevice,
  handleBack,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (joonDevice) {
      form.setFieldsValue({
        ...joonDevice,
        tags: joonDevice.tags || [],
      });
    }
  }, [joonDevice, form]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSave}
      id="editDeviceMetadata"
      form={form}
    >
      <Form.Item label="Call Center ID (Transmit Code)" name="callCenterId">
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Description" name="desc">
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Type to create a tag"
          disabled={loading}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleBack}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={loading} htmlType="submit">
            Save Metadata
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

function EditDeviceMetadataModal({ visible, onFinish, onCancel }) {
  const { joonDevice, queryId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateJoonDevice] = useMutation(updateJoonDeviceMetadataMutation);

  const dispatch = useDispatch();
  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { ...values } = _values;
      try {
        if (joonDevice) {
          const result = await updateJoonDevice({
            variables: {
              joonDevice: {
                _id: joonDevice._id,
                ...values,
              },
            },
          });
          dispatch(
            updateJoonDeviceAction(
              result.data.updateJoonDeviceMetadata.joonDevice,
              queryId,
            ),
          );
          notification.success({
            message: 'Saved',
            description: 'Metadata updated successfully',
          });
        }
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, updateJoonDevice, dispatch, onFinish, queryId],
  );
  const title = 'Edit Device Metadata';
  const subtitle = joonDevice && joonDevice.name;
  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 32,
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          {title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text style={{ color: settings.colors.textGray, fontSize: 14 }}>
            {subtitle}
          </Text>
        </div>
        {visible && (
          <EditDeviceMetadataForm
            handleSave={handleSave}
            loading={loading}
            joonDevice={joonDevice}
            handleBack={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{`
        .errors {
          text-align: center;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditDeviceMetadataModal;
