export const READ_QUERIES = 'READ_QUERIES';
export const CREATE_QUERY = 'CREATE_QUERY';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const DELETE_QUERY = 'DELETE_QUERY';

// eslint-disable-next-line no-shadow
export const readQueriesAction = (queries) => ({
  type: READ_QUERIES,
  payload: {
    queries,
  },
});

export const createQueryAction = (query) => ({
  type: CREATE_QUERY,
  payload: {
    query,
  },
});

export const updateQueryAction = (query) => ({
  type: UPDATE_QUERY,
  payload: {
    query,
  },
});

export const deleteQueryAction = (queryId) => ({
  type: DELETE_QUERY,
  payload: {
    queryId,
  },
});

const initialQueries = [];

export function queries(state = initialQueries, action) {
  switch (action.type) {
    case READ_QUERIES: {
      const newState = { ...state };
      action.payload.queries.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_QUERY:
      return {
        ...state,
        [action.payload.query._id]: action.payload.query,
      };
    case UPDATE_QUERY:
      return {
        ...state,
        [action.payload.query._id]: action.payload.query,
      };
    case DELETE_QUERY: {
      const { queryId } = action.payload;
      return { ...state, [queryId]: undefined };
    }
    default:
      return state;
  }
}
