import { useMutation } from '@apollo/client';
import { Checkbox, Form, Input, Modal, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  updateJoon3WifiNetworksMutation,
  updateTenantJoonWifiNetworksMutation,
} from './constants';
import useIsTenantAdmin from '../use-is-tenant-admin';
import SelectSafeZone from '../safe-zones/SelectSafeZone';

const { Title, Text } = Typography;

function EditJoonWifiNetworksModal({ editWifiState, onFinish, onCancel }) {
  const { joonDeviceId, wifiNetworks, wifiNetwork } = editWifiState || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isTenantAdmin = useIsTenantAdmin();
  const query = useMemo(() => {
    if (isTenantAdmin) {
      return updateTenantJoonWifiNetworksMutation;
    }
    return updateJoon3WifiNetworksMutation;
  }, [isTenantAdmin]);
  const [updateWifiNetworks] = useMutation(query);

  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      try {
        const { isOpen, confirm, isWep, ...values } = _values;
        const existingNetworks = wifiNetworks
          .filter((w) => w._id != wifiNetwork._id)
          // This .map is required to filter out the __typename from graphql
          .map((w) => {
            return { _id: w._id };
          });
        const updatedWifiNetworks = existingNetworks.concat({
          _id: wifiNetwork._id,
          isOpen: !isOpen,
          isWep: !!isWep,
          ...values,
        });

        await updateWifiNetworks({
          variables: {
            deviceId: joonDeviceId,
            wifiNetworks: updatedWifiNetworks,
          },
        });
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [wifiNetworks, updateWifiNetworks, onFinish, joonDeviceId, wifiNetwork],
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (wifiNetwork) {
      form.setFieldsValue({
        _id: wifiNetwork._id,
        ssid: wifiNetwork.ssid,
        isOpen: !wifiNetwork.isOpen,
        isWep: wifiNetwork.isWep,
        safeZoneId: wifiNetwork.safeZoneId,
      });
    } else {
      form.resetFields();
    }
  }, [wifiNetwork, form]);
  return (
    <>
      <Modal
        title={`Edit Wifi Network`}
        open={!!editWifiState}
        closable
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        cancelText="Cancel"
        okText="Save Wifi Network"
        onOk={() => form.submit()}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSave}
          className="config-cmd"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
        >
          <Form.Item
            name="ssid"
            label="Wifi Network Name"
            rules={[
              {
                required: true,
                message: 'Please input an SSID',
              },
            ]}
            hasFeedback
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item name="isOpen" valuePropName="checked" initialValue={true}>
            <Checkbox>Check if network requires a password</Checkbox>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.isOpen !== curValues.isOpen
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('isOpen') && (
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input a password',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      ({ getFieldValue: getFieldValue2 }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue2('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            'The two passwords that you entered do not match!',
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{ width: '100%' }}
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item
                    name="isWep"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>Check if this is an older WEP network</Checkbox>
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item name="safeZoneId">
            <SelectSafeZone deviceId={joonDeviceId} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditJoonWifiNetworksModal;
