import { gql } from '@apollo/client';

export const ApiKeyListAttributes = gql`
  {
    _id
    label
    desc
    privateKey
    publicKey
    issuer
    audience
    roleId
    role {
      _id
      name
      desc
      permissions
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const ApiKeyDetailedAttributes = gql`
  {
    _id
    label
    desc
    privateKey
    publicKey
    issuer
    audience
    roleId
    role {
      _id
      name
      desc
      permissions
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    tenantId
    tenant {
      _id
      name
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allApiKeysQuery = gql`
  query AllApiKeys($first: Int, $after: String, $filters: ApiKeyFilters, $sortBy: [SortBy]) {
    allApiKeys(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${ApiKeyListAttributes}
      }
    }
  }
`;

export const apiKeyQuery = gql`
  query ApiKey($_id: ID!) {
    apiKey(_id: $_id) ${ApiKeyDetailedAttributes}
  }
`;

export const createApiKeyMutation = gql`
  mutation createApiKey($apiKey: ApiKeyCreateInput!) {
    createApiKey(apiKey: $apiKey) {
      apiKey ${ApiKeyDetailedAttributes}
    }
  }
`;

export const updateApiKeyMutation = gql`
  mutation updateApiKey($apiKey: ApiKeyUpdateInput!) {
    updateApiKey(apiKey: $apiKey) {
      apiKey ${ApiKeyDetailedAttributes}
    }
  }
`;

export const TenantApiKeyDetailedAttributes = gql`
  {
    _id
    label
    desc
    privateKey
    publicKey
    issuer
    audience
    roleId
    role {
      _id
      name
      desc
      permissions
      createdAt
      updatedAt
    }
    tenantId
    tenant {
      _id
      name
    }
    createdAt
    updatedAt
  }
`;
