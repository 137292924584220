import { Table, Tag, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';

const { Title, Text } = Typography;

function PreviewTable({ joonDevices }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const tenants = useSelector((store) => store.tenants.default, shallowEqual);

  return (
    <>
      <Table
        dataSource={joonDevices}
        pagination={{ ...pagination, total: joonDevices.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="IMEI" dataIndex="_id" key="_id" />
        <Column title="Serial No." dataIndex="serialNo" key="serialNo" />
        <Column title="ICCID" dataIndex="iccid" key="iccid" />
        <Column
          title="Call Center ID"
          dataIndex="callCenterId"
          key="callCenterId"
        />
        <Column title="Description" dataIndex="desc" key="desc" />
        <Column
          title="Tags"
          dataIndex="tags"
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag key={tag} color={settings.colors.secondary}>
                  {tag}
                </Tag>
              ))
            );
          }}
        />
        <Column
          title="Tenant"
          dataIndex="tenantId"
          render={(text, record) => {
            const tenant = tenants[record.tenantId];
            return tenant && tenant.name;
          }}
        />
      </Table>
      <style jsx>{``}</style>
    </>
  );
}

export default PreviewTable;
