import { gql } from '@apollo/client';

export const updateJoon3WifiNetworksMutation = gql`
  mutation UpdateJoon3WifiNetworks(
    $deviceId: ID!
    $wifiNetworks: [UpdateJoon3WifiNetworkInput]!
  ) {
    updateJoon3WifiNetworks(deviceId: $deviceId, wifiNetworks: $wifiNetworks)
  }
`;

export const updateTenantJoonWifiNetworksMutation = gql`
  mutation UpdateTenantJoonWifiNetworks(
    $deviceId: ID!
    $wifiNetworks: [UpdateJoon3WifiNetworkInput]!
  ) {
    updateTenantJoonWifiNetworks(
      deviceId: $deviceId
      wifiNetworks: $wifiNetworks
    )
  }
`;
