import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import settings from '../../settings';

import { CallCenterCos } from '../joonDevices/ViewJoonDevice/settings-v2/constants';
import {
  formatMultiplePhoneNumbers,
  validateMultiplePhoneNumbers,
} from '../joonDevices/ViewJoonDevice/settings-v2/edit-modals/EditMultiPhoneNoSettingModal';
import {
  formatPhoneNumber,
  validatePhoneNumber,
} from '../joonDevices/ViewJoonDevice/settings-v2/edit-modals/EditPhoneNoSettingModal';
import {
  updateJoon3ConfigMutation,
  updateTenantJoon3ConfigMutation,
} from '../joonDevices/ViewJoonDevice/settings-v2/edit-modals/EditSomeSettingModal';
import useIsTenantAdmin from '../joonDevices/ViewJoonDevice/use-is-tenant-admin';

const { Title, Text } = Typography;

function EditCallCenterSettingsForm({
  handleSave,
  loading,
  tenantConfig,
  handleBack,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (tenantConfig?.callCenter) {
      form.setFieldsValue({
        ...tenantConfig?.callCenter,
      });
    }
  }, [tenantConfig, form]);

  return (
    <Form layout="vertical" onFinish={handleSave} form={form}>
      <Form.Item
        label="Call Center Phone Number"
        name="callCenterNumber"
        rules={[
          validatePhoneNumber,
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Whitelist Phone Numbers"
        name="whitelistNumbers"
        extra="Place one number on each line"
        rules={[
          validateMultiplePhoneNumbers,
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input.TextArea disabled={loading} rows={4} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleBack}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <div>
            <Button
              key="send"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Space>
      </Form.Item>
    </Form>
  );
}

function EditCallCenterSettingsModal({ visible, onFinish, onCancel }) {
  const { tenantConfig } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isTenantAdmin = useIsTenantAdmin();
  const query = useMemo(() => {
    if (isTenantAdmin) {
      return updateTenantJoon3ConfigMutation;
    }
    return updateJoon3ConfigMutation;
  }, [isTenantAdmin]);
  const [updateSettings] = useMutation(query);

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(null);
      const { callCenterNumber: ccn, whitelistNumbers: wln } = values;
      try {
        const callCenterNumber = formatPhoneNumber(ccn);
        const whitelistNumbers = wln ? formatMultiplePhoneNumbers(wln) : null;
        if (tenantConfig) {
          await updateSettings({
            variables: {
              config: {
                _id: tenantConfig._id,
                callCenter: {
                  _id: new Date().toISOString(),
                  callCenterNumber,
                  whitelistNumbers,
                },
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Call center details updated successfully',
          });
        }
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [tenantConfig, updateSettings, onFinish],
  );
  const title = 'Call Center Details';
  const subtitle = CallCenterCos[tenantConfig?.callCenter?.type]?.label;

  console.log('tenantConfig', tenantConfig);

  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 32,
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          {title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text style={{ color: settings.colors.textGray, fontSize: 14 }}>
            {subtitle}
          </Text>
        </div>
        {visible && (
          <EditCallCenterSettingsForm
            handleSave={handleSave}
            loading={loading}
            tenantConfig={tenantConfig}
            handleBack={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{`
        .errors {
          text-align: center;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditCallCenterSettingsModal;
