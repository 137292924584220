import {
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
} from '@ant-design/icons';
import React from 'react';

export function getFileIcon(fileName, style) {
  if (
    fileName.endsWith('.pptx') ||
    fileName.endsWith('.pptm') ||
    fileName.endsWith('.ppt') ||
    fileName.endsWith('.key') ||
    fileName.endsWith('.odp')
  ) {
    return <FilePptOutlined style={style} />;
  }
  if (
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.jfif') ||
    fileName.endsWith('.pjpeg') ||
    fileName.endsWith('.pjp')
  ) {
    return <FileJpgOutlined style={style} />;
  }
  if (
    fileName.endsWith('.png') ||
    fileName.endsWith('.svg') ||
    fileName.endsWith('.webp') ||
    fileName.endsWith('.tif') ||
    fileName.endsWith('.tiff') ||
    fileName.endsWith('.bmp') ||
    fileName.endsWith('.eps') ||
    fileName.endsWith('.raw') ||
    fileName.endsWith('.cr2') ||
    fileName.endsWith('.orf') ||
    fileName.endsWith('.sr2') ||
    fileName.endsWith('.nef')
  ) {
    return <FileImageOutlined style={style} />;
  }
  if (
    fileName.endsWith('.xlsx') ||
    fileName.endsWith('.xlsm') ||
    fileName.endsWith('.xlsb') ||
    fileName.endsWith('.xltx') ||
    fileName.endsWith('.xltx') ||
    fileName.endsWith('.xltm') ||
    fileName.endsWith('.xls') ||
    fileName.endsWith('.xlt') ||
    fileName.endsWith('.numbers') ||
    fileName.endsWith('.ods')
  ) {
    return <FileExcelOutlined style={style} />;
  }
  if (fileName.endsWith('.pdf')) {
    return <FilePdfOutlined style={style} />;
  }
  if (fileName.endsWith('.gif')) {
    return <FileGifOutlined style={style} />;
  }
  if (
    fileName.endsWith('.txt') ||
    fileName.endsWith('.rtf') ||
    fileName.endsWith('.log') ||
    fileName.endsWith('.md')
  ) {
    return <FileTextOutlined style={style} />;
  }
  if (
    fileName.endsWith('.doc') ||
    fileName.endsWith('.docx') ||
    fileName.endsWith('.odt') ||
    fileName.endsWith('.pages')
  ) {
    return <FileWordOutlined style={style} />;
  }
  return <FileOutlined style={style} />;
}
