import { Spin, Typography } from 'antd';
import Plotly from 'plotly.js-dist';
import settings from '../../../settings';
import React, { useEffect, useRef } from 'react';
import useSafeState from '../../../shared/use-safe-state';

const { Title } = Typography;

function PlotSomethingPie({
  loading,
  name,
  height,
  mode,
  axes,
  xAxisType = 'linear',
  yAxisType = 'linear',
  xAxisLabel,
  yAxisLabel,
  style,
}) {
  const data = useRef({});
  const first = useRef({});
  const [srCounter, setSRCounter, _srCounter] = useSafeState(0);
  useEffect(() => {
    if (!first.current[name]) {
      if (data.current[name] === undefined) {
        data.current[name] = {};
      }
      axes.forEach((a) => {
        if (data.current[name][a.name] === undefined) {
          data.current[name][a.name] = {
            label: a.label,
            values: a.values,
            labels: a.labels,
          };
        }
      });
      first.current[name] = true;
      Plotly.newPlot(
        `plot_${name}`,
        [
          // {
          //   type: 'pie',
          //   textinfo: 'label+percent',
          //   textposition: 'outside',
          //   automargin: true,
          // },
        ],
        {
          height,
          margin: { t: 0, b: 0, l: 0, r: 0 },
          showlegend: false,
          uniformtextMinsize: 12,
          uniformtextMode: 'hide',
          // xaxis: {
          //   autorange: true,
          //   type: xAxisType,
          //   title: xAxisLabel && {
          //     text: xAxisLabel,
          //   },
          // },
          // yaxis: {
          //   autorange: true,
          //   type: yAxisType,
          //   title: yAxisLabel && {
          //     text: yAxisLabel,
          //   },
          // },
          // margin: {
          //   l: 64,
          //   r: 0,
          //   b: 80,
          //   t: 24,
          //   pad: 4,
          // },
          // legend: {
          //   x: 1,
          //   xanchor: 'right',
          //   y: 1,
          // },
        },
      );
      Object.entries(data.current[name]).forEach(([axisName, axi]) => {
        const { label: _label, ...values } = axi;
        Plotly.addTraces(`plot_${name}`, {
          ...values,
          type: 'pie',
          mode,
          name: _label,
        });
        first.current[axisName] = true;
      });
    }
  }, [name, mode, axes, xAxisType, yAxisType, xAxisLabel, yAxisLabel, height]);

  useEffect(() => {
    if (!loading) {
      axes.forEach((a) => {
        data.current[name][a.name] = {
          values: a.values,
          labels: a.labels,
        };
      });
      setSRCounter(_srCounter.current + 1);
    }
  }, [axes, _srCounter, setSRCounter, name, loading]);

  useEffect(() => {
    try {
      Plotly.update(
        `plot_${name}`,
        {
          values: Object.values(data.current[name]).map((a) => a.values),
          labels: Object.values(data.current[name]).map((a) => a.labels),
          textinfo: 'label+percent',
          textposition: 'inside',
          automargin: true,
        },
        {
          // height,
          // xaxis: {
          //   autorange: true,
          //   type: xAxisType,
          //   title: xAxisLabel && {
          //     text: xAxisLabel,
          //   },
          // },
          // yaxis: {
          //   autorange: true,
          //   type: yAxisType,
          //   title: yAxisLabel && {
          //     text: yAxisLabel,
          //   },
          // },
        },
        Object.values(data.current[name]).map((a, i) => i),
      );
    } catch (err) {
      console.error(err);
    }
  }, [srCounter, name, xAxisType, yAxisType, xAxisLabel, yAxisLabel]);

  return (
    <div
      className="plot-holder"
      style={{
        width: '100%',
        height: height + 32,
        position: 'relative',
        ...style,
      }}
    >
      <div
        id={`plot_${name}`}
        className="plot"
        style={{
          height: height + 32,
        }}
      />
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
          }}
        >
          <Spin spinning>
            <div
              style={{ height: height + 32, background: settings.colors.ghost }}
            />
          </Spin>
        </div>
      )}
    </div>
  );
}

export default PlotSomethingPie;
