import React, { Suspense } from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux-store';
import { ConfigProvider } from 'antd';
import AppLoading from './components/AppLoading';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#e7282f',
            fontFamily: `Source Sans Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
            fontSize: 14,
            controlHeight: 48,
            colorTextHeading: '#093151',
            colorLink: '#e7282f',
            borderRadius: 2,
          },
        }}
      >
        <Suspense fallback={<AppLoading />}>
          <App />
        </Suspense>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
