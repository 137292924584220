import { useApolloClient } from '@apollo/client';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from './autocomplete';

export default function useMany(
  query,
  extract,
  updateAction,
  tableName,
  searchKeys,
  first,
  filters,
  defaultSort,
  queryId = 'default',
  fetchPolicy = 'network-only',
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);
  const term$ = useRef(null);
  const [search, setSearch] = useState();
  const apolloClient = useApolloClient();
  const [uiFilters, setUiFilters] = useState({});
  const [total, setTotal] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        let hasNextPage = true;
        let after;
        let i = 0;
        while (hasNextPage && i < first) {
          const result = await apolloClient.query({
            query,
            variables: {
              first,
              after,
              filters,
            },
            fetchPolicy,
          });
          const data = result && extract(result.data);
          if (data) {
            hasNextPage = data.pageInfo.hasNextPage;
            after = data.pageInfo.endCursor;
            const _total = data.totalCount;
            if (_total) {
              setTotal(_total);
            }
            dispatch(
              updateAction(
                data.edges.map((edge) => edge.node),
                queryId,
              ),
            );
            // const bas = [];
            // for (let j = 0; j < 1000; j += 1) {
            //   const b = {
            //     ...data.edges[0].node,
            //     _id: j,
            //   };
            //   bas.push(b);
            // }
            // dispatch(updateAction(bas, queryId));
          } else {
            break;
          }
          i += 1;
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    }
    if (_refetches.current !== refetches) {
      _refetches.current = refetches;
      doAsyncStuff();
    }
  }, [
    query,
    dispatch,
    filters,
    refetches,
    first,
    queryId,
    extract,
    updateAction,
    apolloClient,
    fetchPolicy,
  ]);

  const fromStore = useSelector((store) => {
    let _users = Object.values(
      (store[tableName] && store[tableName][queryId]) || [],
    ).filter((b) => b);
    if (uiFilters && uiFilters.tenantId) {
      _users = _users.filter((b) => b.tenantId === uiFilters.tenantId);
    }
    if (search) {
      const fuse = new Fuse(_users, {
        includeScore: true,
        keys: searchKeys,
      });
      _users = fuse
        .search(search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return _users;
    }
    return _users.sort(defaultSort);
  }, shallowEqual);

  useEffect(() => {
    term$.current = new BehaviorSubject('');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          return of(setSearch(term));
        }),
      )
      .subscribe();
  }, []);

  const resultSearch = useCallback((term) => {
    if (term !== undefined) {
      term$.current.next(term);
    }
  }, []);

  return {
    data: fromStore,
    loading,
    error,
    refetch: () => setRefetches(refetches + 1),
    search: resultSearch,
    uiFilter: (fs) => setUiFilters({ ...uiFilters, ...fs }),
    total,
  };
}
