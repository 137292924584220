export const READ_WEBHOOKS = 'READ_WEBHOOKS';
export const CREATE_WEBHOOK = 'CREATE_WEBHOOK';
export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';

export const readWebhooksAction = (
  // eslint-disable-next-line no-shadow
  webhooks,
  queryId = 'default',
) => ({
  type: READ_WEBHOOKS,
  payload: {
    webhooks,
    queryId,
  },
});

export const createWebhookAction = (webhook, queryId = 'default') => ({
  type: CREATE_WEBHOOK,
  payload: {
    webhook,
    queryId,
  },
});

export const updateWebhookAction = (webhook, queryId = 'default') => ({
  type: UPDATE_WEBHOOK,
  payload: {
    webhook,
    queryId,
  },
});

export const deleteWebhookAction = (webhookId, queryId = 'default') => ({
  type: DELETE_WEBHOOK,
  payload: {
    webhookId,
    queryId,
  },
});

const initialWebhooks = { default: {} };

export function webhooks(state = initialWebhooks, action) {
  switch (action.type) {
    case READ_WEBHOOKS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.webhooks.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_WEBHOOK:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.webhook._id]: action.payload.webhook,
        },
      };
    case UPDATE_WEBHOOK: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.webhook._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.webhook._id]: {
            ...current,
            ...action.payload.webhook,
          },
        },
      };
    }
    case DELETE_WEBHOOK: {
      const { webhookId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [webhookId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
