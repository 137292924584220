export const READ_SIMPLE_LISTS = 'READ_SIMPLE_LISTS';
export const CREATE_SIMPLE_LIST = 'CREATE_SIMPLE_LIST';
export const UPDATE_SIMPLE_LIST = 'UPDATE_SIMPLE_LIST';
export const DELETE_SIMPLE_LIST = 'DELETE_SIMPLE_LIST';

// eslint-disable-next-line no-shadow
export const readSimpleListsAction = (simpleLists) => ({
  type: READ_SIMPLE_LISTS,
  payload: {
    simpleLists,
  },
});

export const createSimpleListAction = (simpleList) => ({
  type: CREATE_SIMPLE_LIST,
  payload: {
    simpleList,
  },
});

export const updateSimpleListAction = (simpleList) => ({
  type: UPDATE_SIMPLE_LIST,
  payload: {
    simpleList,
  },
});

export const deleteSimpleListAction = (simpleListId) => ({
  type: DELETE_SIMPLE_LIST,
  payload: {
    simpleListId,
  },
});

const initialSimpleLists = [];

export function simpleLists(state = initialSimpleLists, action) {
  switch (action.type) {
    case READ_SIMPLE_LISTS: {
      const newState = { ...state };
      action.payload.simpleLists.forEach((p) => {
        newState[p.name] = p;
      });
      return newState;
    }
    case CREATE_SIMPLE_LIST:
      return {
        ...state,
        [action.payload.simpleList.name]: action.payload.simpleList,
      };
    case UPDATE_SIMPLE_LIST:
      return {
        ...state,
        [action.payload.simpleList.name]: action.payload.simpleList,
      };
    case DELETE_SIMPLE_LIST: {
      const { simpleListId } = action.payload;
      return { ...state, [simpleListId]: undefined };
    }
    default:
      return state;
  }
}
