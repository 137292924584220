import HumanizeDuration from 'humanize-duration';

export function getLastUpdated(updatedAt) {
  let timeSinceUpdate;
  let secondsSinceUpdate;
  if (updatedAt) {
    const milliSecondsSinceUpdate = new Date() - new Date(updatedAt);
    secondsSinceUpdate = milliSecondsSinceUpdate / 1000;
    timeSinceUpdate = `${HumanizeDuration(milliSecondsSinceUpdate, {
      largest: 2,
      round: true,
    })} ago`;
  }
  return {
    timeSinceUpdate,
    secondsSinceUpdate,
  };
}
