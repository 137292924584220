import { Button, Form, Select, Typography } from 'antd';
import React, { useEffect } from 'react';

const { Title, Text } = Typography;

function EditTagsInner({ report, saving, handleFinish, onCancel }) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        tags: report.tags,
      });
    }
  }, [report, form]);
  return (
    <>
      <Title level={3}>Update Report Tags</Title>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item name="tags" label="Tags">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Type to create a tag"
            disabled={saving}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={onCancel}
              htmlType="button"
              disabled={saving}
              style={{ marginRight: 16 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditTagsInner;
