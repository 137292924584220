import { gql } from '@apollo/client';

export const UserXJoon3Attributes = gql`
  {
    _id
    role
    relationToWearer
    nickName
    userId
    user {
      _id
      name
      email
      phone
      primaryThumbnailUrl
    }
    deviceId
    tenantId
    profilePhotoId
    primaryThumbnailUrl
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const registerJoonDeviceMutation = gql`
  mutation RegisterJoonDevice($registration: RegisterJoon3DeviceInput!) {
    registerJoonDevice(registration: $registration)
  }
`;
