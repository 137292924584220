import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Space,
  Button,
  notification,
} from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AddressFormItem from '../../../components/AddressFormItem';
import { updateJoonDeviceAction } from '../../../redux-store/joon-devices-store';
import settings from '../../../settings';
import { updateJoonDeviceMetadataMutation } from '../constants';

const { Title, Text } = Typography;

function EditWearerDetailsForm({
  handleSave,
  loading,
  joonDevice,
  handleBack,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (joonDevice) {
      form.setFieldsValue({
        ...joonDevice,
        tags: joonDevice.tags || [],
      });
    }
  }, [joonDevice, form]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSave}
      id="editWearerDetails"
      form={form}
    >
      <Form.Item label="Name" name="wearerName">
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item
        name="wearerPhone"
        label="Phone Number"
        rules={[
          {
            pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            message: 'Please enter a valid phone number (ex. 5124026225).',
          },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Email"
        name="wearerEmail"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid email (ex. aaron@gmail.com).',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <AddressFormItem field={{ name: 'wearerAddress' }} loading={loading} />
      {/* <Form.Item label="Notes" name="notes">
        <Input.TextArea disabled={loading} rows={4} />
      </Form.Item> */}
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsError }) => {
          const errorList = getFieldsError();
          let showError = false;
          errorList.forEach((errors) => {
            if (errors.errors.length) {
              showError = true;
            }
          });
          return (
            showError && (
              <Text type="danger" style={{ marginTop: 16 }}>
                Please correct the errors above
              </Text>
            )
          );
        }}
      </Form.Item>
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleBack}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <div>
            <Button
              disabled={loading}
              style={{ marginRight: 16 }}
              onClick={() => {
                form.setFieldsValue({
                  wearerName: null,
                  wearerPhone: null,
                  wearerEmail: null,
                  wearerAddress: null,
                });
              }}
              icon={<DeleteOutlined />}
            >
              Clear
            </Button>
            <Button
              key="send"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Save Wearer Details
            </Button>
          </div>
        </Space>
      </Form.Item>
    </Form>
  );
}

function EditWearerDetailsModal({ visible, onFinish, onCancel }) {
  const { joonDevice, queryId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateJoonDevice] = useMutation(updateJoonDeviceMetadataMutation);

  const dispatch = useDispatch();
  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { ...values } = _values;
      try {
        if (joonDevice) {
          const result = await updateJoonDevice({
            variables: {
              joonDevice: {
                _id: joonDevice._id,
                ...values,
              },
            },
          });
          dispatch(
            updateJoonDeviceAction(
              result.data.updateJoonDeviceMetadata.joonDevice,
              queryId,
            ),
          );
          notification.success({
            message: 'Saved',
            description: 'Wearer details updated successfully',
          });
        }
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, updateJoonDevice, dispatch, onFinish, queryId],
  );
  const title = 'Edit Wearer Details';
  const subtitle = joonDevice && joonDevice.name;
  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        bodyStyle={{
          paddingTop: 32,
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          {title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text style={{ color: settings.colors.textGray, fontSize: 14 }}>
            {subtitle}
          </Text>
        </div>
        {visible && (
          <EditWearerDetailsForm
            handleSave={handleSave}
            loading={loading}
            joonDevice={joonDevice}
            handleBack={onCancel}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{`
        .errors {
          text-align: center;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditWearerDetailsModal;
