import React, { useMemo } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { useParams, Link, useHistory } from 'react-router-dom';
import { userQuery } from './constants';
import useOne from '../../shared/use-one';
import { updateUserAction } from '../../redux-store/users-store';
import { Button, List, Skeleton, Tabs, Typography } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import PhotoCircle from '../../components/PhotoCircle';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../settings';
import { formatPhoneNumberForDisplay } from '../../shared/utils';
import UserDevicesTab from './UserDevicesTab';

const { Text, Title } = Typography;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text
          style={{
            color: settings.colors.primary,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 16 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

function ViewUser() {
  const { _id, tab } = useParams();
  const queryId = 'view-user';
  const { data: user, loading } = useOne(
    userQuery,
    (data) => data && data.user,
    updateUserAction,
    'users',
    _id,
    queryId,
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dashboardFields = useMemo(() => {
    if (user && !loading) {
      return [
        {
          title: 'Name',
          value: user.name,
        },
        {
          title: 'Email',
          value: user.email,
        },
        {
          title: 'Phone Number',
          value: user.phone && formatPhoneNumberForDisplay(user.phone),
        },
        {
          title: 'Role',
          value: user.role?.name,
        },
        {
          title: 'Tenant',
          value: user.tenant && (
            <Link to={`/tenant/view/${user.tenant._id}/settings`}>
              {user.tenant.name}
            </Link>
          ),
        },
        {
          title: 'Email Verified',
          value: user.emailVerified ? 'Yes' : 'No',
        },
        {
          title: 'Accepted Eula Version',
          value: user.acceptedEulaVersion,
        },
        {
          title: 'Created',
          value: new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(new Date(user.createdAt)),
        },
      ].filter((a) => a.value !== undefined || a.component !== undefined);
    }
  }, [loading, user, locale]);
  const title = user ? user.name : 'View User';
  const history = useHistory();
  return (
    <>
      <CSPage title={title} containerStyle={{ maxWidth: 'unset' }}>
        <CSPageHeader
          titleComponent={
            user ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PhotoCircle
                    diameter={100}
                    color={settings.colors.primary}
                    imageUrl={user.primaryThumbnailUrl}
                    textStyle={{ color: 'white', fontSize: 24 }}
                    initials={
                      user &&
                      user.name
                        .split()
                        .map((p) => p[0])
                        .join()
                    }
                  />
                  <Title
                    className="cs-header-title"
                    style={{ marginBottom: 8, marginLeft: 16 }}
                  >
                    {user.name}
                  </Title>
                </div>
                <Link to={`/user/edit/${_id}`}>
                  <Button icon={<EditOutlined />}>Edit User</Button>
                </Link>
              </div>
            ) : (
              <Title className="cs-header-title" style={{ marginBottom: 8 }}>
                {title}
              </Title>
            )
          }
          backActions={[
            <Link to="/users" key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to Users
              </Button>
            </Link>,
          ]}
        />
        <div
          style={{
            border: `1px solid ${settings.colors.borderGray}`,
            borderRadius: 24,
            padding: '16px 24px',
            marginBottom: 16,
          }}
        >
          {loading && <Skeleton active paragraph title />}
          <List
            dataSource={dashboardFields}
            renderItem={(item) => (
              <DisplayField
                title={item.title}
                value={item.value}
                component={item.component}
              />
            )}
            loading={loading}
            locale={{
              emptyText: <div />,
            }}
            grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
          />
        </div>
        <Tabs
          activeKey={tab}
          items={[
            user && {
              key: 'devices',
              label: 'Devices',
              children: <UserDevicesTab user={user} />,
            },
          ].filter((t) => t)}
          onChange={(key) => history.push(`/user/view/${_id}/${key}`)}
        />
      </CSPage>
    </>
  );
}

export default ViewUser;
