import { Button, Card, Form, Input, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clairvoyantLogo from '../assets/maker-logo-md.png';
import { AuthContext } from '../shared/auth-context';
import useProfile from '../shared/use-profile';
import useQueryParams from '../shared/use-query-params';

const { Title, Text } = Typography;

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const query = useQueryParams();
  const once = useRef(false);
  useEffect(() => {
    async function doAsyncStuff(code) {
      setLoading(true);
      setServerError(null);
      let error;
      try {
        error = await authContext.signInWithMagicLink(code);
      } catch (err) {
        console.error(err);
        error = err.message;
      }
      if (error) {
        setServerError(error);
        setLoading(false);
      }
    }
    const { c } = query;
    if (c && !once.current) {
      once.current = true;
      doAsyncStuff(c);
    }
  }, [authContext, query]);

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    let error;
    try {
      error = await authContext.signIn(values);
    } catch (err) {
      console.error(err);
      error = err.message;
    }
    if (error) {
      setServerError(error);
      setLoading(false);
    }
  };

  const profile = useProfile();
  useEffect(() => {
    if (profile) {
      history.push('/map');
    }
  }, [profile, history]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  return (
    <div className="page qr-gen">
      <div className="logo-box">
        <img className="logo" src={clairvoyantLogo} alt="MG Logo" />
      </div>
      <Card style={{ boxShadow: '0px 0px 8px #f0f0f0' }}>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="signin-form"
        >
          <Title level={3} style={{ textAlign: 'center' }}>
            Sign In
          </Title>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter your username' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            {/* <Button
              type="link"
              htmlType="button"
              onClick={() => history.push('/sign-up')}
            >
              Sign Up
            </Button> */}
            <div className="sign-in-btns">
              <Button type="primary" htmlType="submit" style={{ width: 150 }}>
                Sign In
              </Button>
            </div>
          </Form.Item>
          {serverError && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{serverError}</div>
            </div>
          )}
          {/* <Divider>Or</Divider>
          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Space
              align="center"
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => googleLogin.signIn()}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={googleLogin.loading}
              >
                Sign in with Google
              </Button>
              <Button
                onClick={() => authContext.signInWithApple()}
                icon={<AppleOutlined />}
                style={{ marginBottom: 8 }}
              >
                Sign in with Apple
              </Button>
            </Space>
          </Form.Item> */}
        </Form>
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 5vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .sign-in-btns {
          display: flex;
          justify-content: center;
        }
        .logo {
          max-width: 300px;
          margin-left: 16px;
          margin-right: 16px;
        }
        .logo-sep {
          margin-left: 16px;
          margin-right: 16px;
          border-right: 1px solid #e8e8e8;
          height: 150px;
        }
        .logo-box {
          margin-top: 32px;
          display: flex;
          flex-wrap: no-wrap;
          max-width: 100%;
          padding-left: 64px;
          padding-right: 64px;
          margin-left: -16px;
          margin-right: -16px;
          align-items: center;
          margin-bottom: 32px;
        }
        @media screen and (max-width: 992px) {
          .logo {
            max-width: unset;
            width: 35vw;
          }
          .logo-box {
            margin-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        @media screen and (max-width: 600px) {
          .logo {
            max-width: unset;
            width: 35vw;
          }
          .logo-box {
            margin-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .ant-form-item-explain,
        .ant-form-item-extra {
          padding-bottom: 8px;
        }
        .signin-form {
          width: 30vw;
        }
        @media screen and (max-width: 992px) {
          .signin-form {
            width: 60vw;
          }
        }
        @media screen and (max-width: 600px) {
          .signin-form {
            width: 80vw;
          }
        }
      `}</style>
    </div>
  );
};

export default SignIn;
