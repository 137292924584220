import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteRoleAction } from '../../redux-store/roles-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteRoleMutation = gql`
  mutation deleteRole($_id: ID!) {
    deleteRole(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteRoleModal({ role, setRole, queryId = 'default' }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteRole] = useMutation(deleteRoleMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteRole({
        variables: {
          _id: role._id,
        },
      });
      dispatch(deleteRoleAction(role._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Role deleted successfully',
      });
      setTimeout(() => {
        setRole(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [role, deleteRole, setRole, dispatch, history, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      setRole(null);
    }
  }, [setRole, loading]);

  return (
    <YesNoModal
      title="Delete Role"
      question={`Are you sure you want to delete this role (${
        role && role.name
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!role}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteRoleModal;
