import React from 'react';
import { Divider, Typography } from 'antd';

const { Text } = Typography;

function DisplayDefault({ label, value }) {
  return (
    <>
      <Divider />
      <div
        style={{
          textAlign: 'center',
          fontSize: 16,
        }}
      >
        <Text style={{ fontSize: 16 }}>{`${label} = ${value}`}</Text>
      </div>
      <Divider />
    </>
  );
}

export default DisplayDefault;
