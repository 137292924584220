import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Space, Tooltip, Upload, Form, Input } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { doFileUpload, generateUuid, toBase64 } from '../../../shared/utils';
import { createFileMutation, getFileUploadUrlMutation } from '../constants';
import ProfilePhoto from '../../../components/ProfilePhoto';

function ProfilePhotoFormItem({
  parent,
  parentId,
  parentType,
  form,
  name,
  readOnly,
  loading,
  fileType = 'profile-pic',
  tags = ['profilePhoto'],
}) {
  const _fileList = useRef([]);
  const [fileList, _setFileList] = useState(_fileList.current);
  const setFileList = useCallback((value) => {
    _fileList.current = value;
    _setFileList(value);
  }, []);
  const [uploadLoading, setUploadLoading] = useState(false);
  const _uploadProgress = useRef({});
  const [uploadProgress, _setUploadProgress] = useState(
    _uploadProgress.current,
  );
  const setUploadProgress = useCallback((value) => {
    _uploadProgress.current = value;
    _setUploadProgress(value);
  }, []);
  const [error, setError] = useState(null);
  const [getFileUploadUrl] = useMutation(getFileUploadUrlMutation);
  const [createFile] = useMutation(createFileMutation);

  useEffect(() => {
    if (parent && parent.profilePhoto) {
      setFileList([parent.profilePhoto]);
    }
  }, [parent, setFileList]);

  const doUpload = useCallback(
    async (obj) => {
      console.log('onBefore', obj);
      try {
        setError(null);
        setUploadLoading(true);
        const profilePhotoId = generateUuid();
        const s3Key = `${parentType}:${parentId}:profile:${profilePhotoId}`;

        let base64Url;
        if (obj.file.type.startsWith('image')) {
          base64Url = await toBase64(obj.file);
        }
        setFileList([
          ..._fileList.current,
          {
            _id: profilePhotoId,
            s3Key,
            size: obj.file.size,
            filename: obj.file.name,
            contentType: obj.file.type,
            base64Url,
          },
        ]);

        let response;
        response = await getFileUploadUrl({
          variables: {
            request: {
              _id: profilePhotoId,
              s3Key,
              type: fileType,
              parentId,
            },
          },
        });
        if (!response || !response.data || !response.data.getFileUploadUrl) {
          throw new Error('Bad upload URL');
        }

        setUploadProgress({
          ..._uploadProgress.current,
          [profilePhotoId]: 0,
        });
        await doFileUpload({
          url: response.data.getFileUploadUrl.url,
          fields: response.data.getFileUploadUrl.fields,
          file: obj.file,
          onProgress: (event) => {
            console.log('progress', event);
            setUploadProgress({
              ..._uploadProgress.current,
              [profilePhotoId]: (event.loaded / event.total) * 100,
            });
          },
        });
        setTimeout(() => {
          setUploadProgress({
            ..._uploadProgress.current,
            [profilePhotoId]: undefined,
          });
        }, 1000);

        response = await createFile({
          variables: {
            file: {
              _id: profilePhotoId,
              filename: obj.file.name,
              s3Key,
              type: fileType,
              parentId,
              tags,
              uploaded: true,
            },
          },
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setUploadLoading(false);
    },
    [
      getFileUploadUrl,
      parentType,
      parentId,
      createFile,
      setUploadProgress,
      setFileList,
      tags,
      fileType,
    ],
  );

  const onUploadDelete = useCallback(
    (file) => () => {
      const fileIndex = _fileList.current.findIndex((f) => f._id === file._id);
      if (fileIndex >= 0) {
        setFileList([
          ..._fileList.current.slice(0, fileIndex),
          ..._fileList.current.slice(fileIndex + 1),
        ]);
      }
    },
    [setFileList],
  );

  const profilePhoto = useMemo(() => {
    if (fileList.length) {
      return fileList[fileList.length - 1];
    }
    return null;
  }, [fileList]);

  useEffect(() => {
    if (fileList.length) {
      form.setFieldsValue({
        [name]: fileList[fileList.length - 1]._id,
      });
    } else {
      form.setFieldsValue({
        [name]: null,
      });
    }
  }, [fileList, form, name]);

  return (
    <>
      <Form.Item name={name} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Space direction="vertical" style={{ marginRight: 8 }}>
        {profilePhoto && (
          <ProfilePhoto
            key={profilePhoto._id}
            thumbnailUrl={(() => {
              const photo =
                profilePhoto.images &&
                profilePhoto.images.find((i) => i.sizeCode === 'SM');
              return (photo && photo.url) || profilePhoto.base64Url;
            })()}
            onUploadDelete={!readOnly && onUploadDelete(profilePhoto)}
            progress={uploadProgress[profilePhoto._id]}
          />
        )}
        {!readOnly && (
          <ImgCrop>
            <Upload
              className="profile-pic-upload"
              showUploadList={false}
              listType="picture"
              customRequest={doUpload}
            >
              {error && (
                <Tooltip title={error}>
                  <Button danger icon={<ExclamationCircleOutlined />}>
                    Failed
                  </Button>
                </Tooltip>
              )}
              {!error && (
                <Button
                  disabled={uploadLoading || loading}
                  icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
                >
                  {uploadLoading ? 'Saving ...' : 'Select Photo'}
                </Button>
              )}
            </Upload>
          </ImgCrop>
        )}
      </Space>
    </>
  );
}

export default ProfilePhotoFormItem;
