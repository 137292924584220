import { Form, Input, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';

const { Title, Text, Paragraph } = Typography;

const EnterImeiModal = ({ visible, setVisible, onYes }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    (values) => {
      setLoading(true);
      onYes(values.macAddress);
    },
    [onYes],
  );

  return (
    <>
      <Modal
        title="Create a Theora Connect Device"
        open={visible}
        closable={true}
        destroyOnClose={true}
        maskClosable={true}
        okText="OK"
        cancelText="Cancel"
        onOk={() => form.submit()}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
        onCancel={() => setVisible(false)}
      >
        <div style={{ textAlign: 'left' }}>
          <Form form={form} onFinish={handleFinish} layout="vertical">
            <Form.Item
              label="Enter your device's IMEI"
              name="macAddress"
              rules={[
                {
                  required: true,
                  message: "Please enter your device's IMEI",
                },
                {
                  pattern: /(^[0-9]{15}$)/,
                  message: 'Please enter a valid IMEI',
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default EnterImeiModal;
