import { Tag, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsCategoryTable from './SettingsCategoryTable';
import { getUnifiedSetting, makeAuditStatusRow } from './utils';
import { AuditStatuses } from '../../constants';
import { TenantSettings } from './constants';

const { Title, Text } = Typography;

function UserSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  audit,
  loading,
  isDevice,
  isTenantAdmin,
}) {
  const makeSettingRow = useCallback(
    (table, key, label, displayFunc, editModal, viewLink) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return {
        key,
        label,
        value: displayFunc(value),
        src,
        editModal,
        isEditable: !!editModal,
        viewLink,
      };
    },
    [systemConfig, tenantConfig, deviceConfig],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig
    ) {
      const [ownerId, _] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        'user',
        'ownerId',
      );
      return [
        makeAuditStatusRow(audit, 'user'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.user?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.user?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig.user?._id,
          editable: false,
        },
        isDevice &&
          makeSettingRow(
            'user',
            'contacts',
            'Contacts',
            (v) => v && `${v.length} contacts`,
            undefined,
            {
              link: `/joon-device/view/${deviceConfig._id}/contacts`,
              label: 'View/Edit Contacts',
            },
          ),
        isDevice &&
          makeSettingRow(
            'user',
            'wifiNetworks',
            'Wi-Fi Networks',
            (v) => v && `${v.length} networks`,
            undefined,
            {
              link: `/joon-device/view/${deviceConfig._id}/wifi`,
              label: 'View/Edit Wi-Fi Networks',
            },
          ),
        makeSettingRow('user', 'ownerName', 'Owner', (v) => v, undefined, {
          link: `/user/view/${ownerId}/devices`,
          label: 'View/Edit Owner',
        }),
        makeSettingRow('user', 'wearerName', 'Wearer Name', (v) => v),
      ]
        .filter((f) => f)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.user.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    audit,
    isDevice,
    makeSettingRow,
    isTenantAdmin,
  ]);

  return (
    <>
      <Title level={2}>User Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default UserSettingsTable;
