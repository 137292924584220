import React from 'react';
// import packageJson from '../../package.json';
import { Typography } from 'antd';
import { gql, useQuery } from '@apollo/client';

const { Text } = Typography;

export const versionQuery = gql`
  query Version {
    version
  }
`;

const Version = () => {
  const {
    loading: loadingVersion,
    error: errorVersion,
    data: versionData,
    refetch: refetchVersion,
  } = useQuery(versionQuery, {
    variables: {},
    fetchPolicy: 'network-only',
  });
  const version = versionData?.version;

  return (
    <div className="version">
      <Text>{`Version ${version}`}</Text>
      <style jsx>{`
        .version {
          bottom: 16px;
          right: 16px;
        }
      `}</style>
    </div>
  );
};

export default Version;
