import { Layout } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import Error404 from '../../components/Error404';
import Footer from '../../components/Footer';
import MainNavMenu from '../../components/MainNavMenu';
import ScrollToTop from '../../components/ScrollToTop';
import settings from '../../settings';
import useQueryParams from '../../shared/use-query-params';
import EditApiKey from '../api-keys/EditApiKey';
import ListApiKeys from '../api-keys/ListApiKeys';
import Dashboard from '../Dashboard';
import EditFirmwareVersion from '../firmware-versions/EditFirmwareVersion';
import ListFirmwareVersions from '../firmware-versions/ListFirmwareVersions';
import EditJoonDevice from '../joonDevices/EditJoonDevice';
import ImportJoonDevices from '../joonDevices/ImportJoonDevices';
import JoonDeviceDatasetsPage from '../joonDevices/JoonDeviceDatasetsPage';
import ListJoonDevices from '../joonDevices/ListJoonDevices';
import MapViewPage from '../joonDevices/MapView/MapViewPage';
import ViewJoonDevice from '../joonDevices/ViewJoonDevice';
import ViewJoonDataset from '../joonDevices/ViewJoonDevice/datasets/ViewJoonDataset';
import EditRole from '../roles/EditRole';
import ListRoles from '../roles/ListRoles';
import EditTenant from '../tenants/EditTenants';
import ListTenants from '../tenants/ListTenants';
import EditUser from '../users/EditUser';
import EditProfile from '../users/EditProfile/EditProfile';
import ListUsers from '../users/ListUsers';
import ViewWebhooks from '../webhooks/ListWebhooks/ViewWebhooks';
import TenantApiKeysPage from '../api-keys/TenantApiKeysPage';
import ReportDashboardPage from '../reports/ReportDashboardPage';
import ViewUser from '../users/ViewUser';
import ViewTenant from '../tenants/ViewTenant';
import ViewNightlyReportPage from '../reports/nightly-reports/ViewNightlyReport';
import ViewDeviceReportDetails from '../reports/nightly-reports/ViewDeviceReportDetails';

function MainRouter() {
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const query = useQueryParams();
  const forwardAfterLogin = useCallback(() => {
    const { p } = query;
    if (p) {
      return <Redirect to={p} />;
    }
    return <Redirect to="/home" />;
  }, [query]);

  return (
    <Layout
      hasSider={responsiveMode !== 'mobile'}
      style={{
        minHeight: '100vh',
        background: settings.colors.white,
      }}
    >
      <MainNavMenu />
      <Layout
        style={{
          background: settings.colors.white,
          boxShadow:
            responsiveMode !== 'mobile'
              ? 'inset 1px 0px 10px rgba(0, 0, 0, 0.05)'
              : undefined,
        }}
      >
        <ScrollToTop>
          <Switch>
            <Route exact path="/" render={forwardAfterLogin} />
            <Route exact path="/sign-(in|up)" render={forwardAfterLogin} />
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route
              exact
              path="/sign-(in|up)"
              render={() => <Redirect to="/home" />}
            />
            <Route exact path="/edit-profile" component={EditProfile} />
            <Route exact path="/home" component={Dashboard} />
            <Route
              exact
              path="/report-dashboard/reports"
              component={ReportDashboardPage}
            />
            <Route
              exact
              path="/report-dashboard/view/:_id/:_tab"
              component={ViewNightlyReportPage}
            />
            <Route
              exact
              path="/report-dashboard/view/:reportId/devices/:deviceId"
              component={ViewDeviceReportDetails}
            />
            <Route exact path="/roles" component={ListRoles} />
            <Route exact path="/role/edit/:_id" component={EditRole} />
            <Route exact path="/users" component={ListUsers} />
            <Route exact path="/user/edit/:_id" component={EditUser} />
            <Route exact path="/user/view/:_id/:tab" component={ViewUser} />
            <Route exact path="/tenants" component={ListTenants} />
            <Route exact path="/tenant/view/:_id/:tab" component={ViewTenant} />
            <Route exact path="/tenant/edit/:_id" component={EditTenant} />
            <Route exact path="/fw-versions" component={ListFirmwareVersions} />
            <Route
              exact
              path="/fw-version/edit/:_id"
              component={EditFirmwareVersion}
            />
            <Route exact path="/webhooks" component={ViewWebhooks} />
            <Route
              exact
              path="/tenant-api-keys"
              component={TenantApiKeysPage}
            />
            <Route exact path="/api-keys" component={ListApiKeys} />
            <Route exact path="/api-key/edit/:_id" component={EditApiKey} />
            <Route exact path="/joon-devices" component={ListJoonDevices} />
            <Route
              exact
              path="/joon-device/edit/:_id"
              component={EditJoonDevice}
            />
            <Route
              exact
              path="/joon-devices/import"
              component={ImportJoonDevices}
            />
            <Route
              exact
              path="/joon-device/view/:_id/:_tab?"
              component={ViewJoonDevice}
            />
            <Route
              exact
              path="/joon-dataset/view/:_id"
              component={ViewJoonDataset}
            />
            <Route
              exact
              path="/joon-datasets"
              component={JoonDeviceDatasetsPage}
            />
            <Route exact path="/joon-devices/map" component={MapViewPage} />
            <Route component={Error404} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default MainRouter;
