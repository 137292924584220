import { useMemo } from 'react';
import useProfile from '../../../shared/use-profile';

function useIsTenantAdmin() {
  const profile = useProfile();
  const isTenantAdmin = useMemo(() => {
    return !!profile?.role?.permissions?.includes('ui:tenant-admin');
  }, [profile]);
  return isTenantAdmin;
}

export default useIsTenantAdmin;
