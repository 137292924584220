import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteWifiNetworkModal from './DeleteWifiNetworkModal';
import EditJoonWifiNetworksModal from './EditJoonWifiNetworksModal';
import { generateUuid } from '../../../../shared/utils';
import useOne from '../../../../shared/use-one';
import {
  extractConfig,
  joon3ConfigQuery,
  tenantJoon3ConfigQuery,
} from '../settings-v2/constants';
import useJoon3ConfigUpdates from '../settings-v2/use-joon3-config-updates';
import useFilterJoon3Config from '../settings-v2/use-filter-joon3-config';
import { updateJoon3ConfigAction } from '../../../../redux-store/joon3-configs-store';
import useIsTenantAdmin from '../use-is-tenant-admin';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text } = Typography;

function WifiNetworksTab({ joon3 }) {
  const isTenantAdmin = useIsTenantAdmin();
  const configQuery = useMemo(() => {
    if (isTenantAdmin) {
      return tenantJoon3ConfigQuery;
    }
    return joon3ConfigQuery;
  }, [isTenantAdmin]);
  const fetchResult1 = useOne(
    configQuery,
    (data) => extractConfig(data, isTenantAdmin),
    updateJoon3ConfigAction,
    'joon3Configs',
    joon3._id,
    joon3._id,
  );
  useJoon3ConfigUpdates(joon3._id);
  const deviceConfig = useFilterJoon3Config(joon3._id);
  const { loading: loading1, error: error1 } = fetchResult1;
  const loading = loading1;
  useEffect(() => {
    if (error1) {
      notification.error({
        description: 'There was an error fetching Wi-Fi networks',
        message: 'Error',
      });
    }
  }, [error1]);
  const wifiNetworks = deviceConfig?.user?.wifiNetworks;
  const joonNow = useSelector(
    (store) => store.joonNows[joon3._id]?.[joon3._id],
    shallowEqual,
  );

  const [editWifiState, setEditWifiState] = useState();
  const [deleteDetails, setDeleteDetails] = useState();
  const handleCancelEditingWifi = useCallback(() => {
    setEditWifiState(undefined);
  }, []);
  const handleFinishEditingWifi = useCallback(() => {
    setEditWifiState(undefined);
  }, []);
  const handleCancelDeleteWifi = useCallback(() => {
    setDeleteDetails(undefined);
  }, []);
  const handleFinishDeleteWifi = useCallback(() => {
    setDeleteDetails(undefined);
  }, []);

  const handleCreateWifiNetwork = useCallback(
    (record) => {
      setEditWifiState({
        wifiNetwork: { _id: generateUuid() },
        wifiNetworks: wifiNetworks || [],
        joonDeviceId: joon3._id,
      });
    },
    [joon3, wifiNetworks],
  );
  const handleEditWifiNetwork = useCallback(
    (record) => {
      setEditWifiState({
        wifiNetworks: wifiNetworks || [],
        wifiNetwork: record,
        joonDeviceId: joon3._id,
      });
    },
    [joon3, wifiNetworks],
  );
  const handleDeleteWifiNetwork = useCallback(
    async (record) => {
      setDeleteDetails({
        wifiNetworks: wifiNetworks || [],
        wifiNetwork: record,
        joonDeviceId: joon3._id,
      });
    },
    [wifiNetworks, joon3],
  );

  const tableData = wifiNetworks || [];
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
    timeStyle: 'medium',
  });

  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <div className="top-actions">
        <Title level={2} style={{ marginTop: 32 }}>
          Wi-Fi Networks
        </Title>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={handleCreateWifiNetwork}
          style={{ marginTop: 16, float: 'right' }}
          disabled={loading}
        >
          Add New WiFi Network
        </Button>
      </div>
      {!!tableData && tableData.length > 0 && (
        <Table
          dataSource={tableData}
          pagination={false}
          style={{ width: '100%', marginBottom: 32 }}
          rowKey="_id"
          loading={loading}
          locale={{
            emptyText: 'No Wi-Fi Networks Found.',
          }}
        >
          <Table.Column title="SSID" dataIndex="ssid" key="ssid" width={400} />
          <Table.Column
            title="Status"
            dataIndex="connected"
            key="connected"
            width={200}
            render={(value, record) => {
              let humanAgo;
              if (record.isConnected) {
                humanAgo = 'Connected';
              } else if (record.lastConnectedAt) {
                const ago = dateFormat.format(new Date(record.lastConnectedAt));
                humanAgo = `Last connected ${ago}`;
              } else {
                humanAgo = 'Never connected';
              }
              return humanAgo;
            }}
          />
          <Table.Column
            title="Action"
            key="action"
            width={100}
            render={(_, record) => (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      onClick={() => handleEditWifiNetwork(record)}
                      icon={<EditOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button
                      onClick={() => handleDeleteWifiNetwork(record)}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      )}
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
      <EditJoonWifiNetworksModal
        editWifiState={editWifiState}
        onFinish={handleFinishEditingWifi}
        onCancel={handleCancelEditingWifi}
      />
      <DeleteWifiNetworkModal
        deleteDetails={deleteDetails}
        onCancel={handleCancelDeleteWifi}
        onSuccess={handleFinishDeleteWifi}
      />
    </div>
  );
}

export default WifiNetworksTab;
