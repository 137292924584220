import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../../components/YesNoModal';
import { deleteWebhookMutation } from '../constants';

function DeleteWebhookModal({ setWebook, webhook, tenantId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteWebhook] = useMutation(deleteWebhookMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteWebhook({
        variables: {
          _id: webhook._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Webhook deleted successfully',
      });
      setTimeout(() => {
        setWebook(null);
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [webhook, setWebook, deleteWebhook]);

  const onNo = useCallback(() => {
    if (!loading) {
      setWebook(null);
    }
  }, [setWebook, loading]);

  return (
    <YesNoModal
      title="Delete Webhook"
      question={`Are you sure you want to delete this Webhook (${
        webhook && webhook.name
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!webhook}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteWebhookModal;
