import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, Typography, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  createApiKeyAction,
  updateApiKeyAction,
} from '../../redux-store/api-keys-store';
import { generateUuid } from '../../shared/utils';
import { TenantApiKeyDetailedAttributes } from './constants';

export const createTenantApiKeyMutation = gql`
  mutation createTenantApiKey($apiKey: TenantApiKeyCreateInput!) {
    createTenantApiKey(apiKey: $apiKey) {
      apiKey ${TenantApiKeyDetailedAttributes}
    }
  }
`;

export const updateTenantApiKeyMutation = gql`
  mutation updateTenantApiKey($apiKey: TenantApiKeyUpdateInput!) {
    updateTenantApiKey(apiKey: $apiKey) {
      apiKey ${TenantApiKeyDetailedAttributes}
    }
  }
`;

const { Text } = Typography;

function EditTenantApiKeyModal({ visible, onCancel, onFinish }) {
  const { apiKey, creating } = visible || {};
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateTenantApiKey] = useMutation(updateTenantApiKeyMutation);
  const [createTenantApiKey] = useMutation(createTenantApiKeyMutation);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useEffect(() => {
    if (apiKey) {
      const { ...otherFields } = apiKey;
      form.setFieldsValue(otherFields);
    }
  }, [form, apiKey]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const handleBack = () => {
    form.resetFields();
    onCancel();
  };

  const onSubmit = useCallback(
    async (_values) => {
      const { ...values } = _values;
      setLoading(true);
      setError(null);
      let updated;
      try {
        if (apiKey && !creating) {
          const result = await updateTenantApiKey({
            variables: {
              apiKey: {
                _id: apiKey._id,
                ...values,
              },
            },
          });
          updated = result?.data?.updateTenantApiKey?.apiKey;
          dispatch(updateApiKeyAction(updated));
          notification.success({
            message: 'Saved',
            description: 'API Key updated successfully',
          });
        } else {
          const result = await createTenantApiKey({
            variables: {
              apiKey: {
                _id: generateUuid(),
                ...values,
              },
            },
          });
          updated = result?.data?.createTenantApiKey?.apiKey;
          dispatch(createApiKeyAction(updated));
          notification.success({
            message: 'Saved',
            description: 'API Key created successfully',
          });
        }
        form.resetFields();
        onFinish(creating, updated);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      form,
      apiKey,
      createTenantApiKey,
      updateTenantApiKey,
      dispatch,
      creating,
      onFinish,
    ],
  );

  return (
    <>
      <Modal
        title={creating ? 'Create API Key' : 'API Key'}
        open={!!visible}
        closable
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={handleBack}
        cancelText="Cancel"
        okText="Save"
        onOk={() => form.submit()}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
      >
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item
            label="Label"
            name="label"
            rules={[{ required: true, message: 'Please enter a label' }]}
          >
            <Input ref={labelInput} disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="desc"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <div style={{ height: 16 }} />
          {error && (
            <div style={{ marginTop: 16 }}>
              <Text type="danger">{error}</Text>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditTenantApiKeyModal;
