import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import {
  Form,
  Button,
  Checkbox,
  Typography,
  Card,
  Tooltip,
  message,
} from 'antd';
import {
  CopyOutlined,
  DownloadOutlined,
  MailOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Title, Text, Paragraph } = Typography;

const ShowPrivateKeyModal = ({ apiKey, onYes }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = useCallback(() => {
    onYes();
  }, [onYes]);

  return (
    <>
      <Modal
        title="API Key Successfully Created"
        open={!!apiKey}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        okText="OK"
        onOk={() => form.submit()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ loading }}
      >
        <div style={{ textAlign: 'left' }}>
          <Text>
            The following is your new private key. Please store this in a safe
            place.
          </Text>
          {apiKey && (
            <div className="key">
              <Paragraph style={{ marginRight: 8, width: 'calc(100% - 40px)' }}>
                <pre>{apiKey.privateKey}</pre>
              </Paragraph>
              <CopyToClipboard
                text={apiKey.privateKey}
                onCopy={() => message.success('Copied!')}
              >
                <Button icon={<CopyOutlined />} type="link" />
              </CopyToClipboard>
            </div>
          )}
          <Form form={form} onFinish={handleFinish}>
            <Form.Item
              name="checkbox"
              rules={[{ required: true, message: 'Please check the box' }]}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Checkbox>
                I understand that this private key will not be retrievable if I
                lose it.
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ShowPrivateKeyModal;
