import React, { useCallback, useMemo, useState } from 'react';
import { AuditStatuses } from '../../constants';
import { Button, Tag, Typography, notification } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { SendOutlined } from '@ant-design/icons';
import useIsTenantAdmin from '../use-is-tenant-admin';

const { Title, Text } = Typography;

export const sendJoon3ConfigMutation = gql`
  mutation SendJoon3Config($_id: ID!) {
    sendJoon3Config(_id: $_id)
  }
`;

export const sendTenantJoon3ConfigMutation = gql`
  mutation TenantSendJoon3Config($_id: ID!) {
    sendTenantJoon3Config(_id: $_id)
  }
`;

function AuditStatusSection({ audit }) {
  const isTenantAdmin = useIsTenantAdmin();
  const query = useMemo(() => {
    if (isTenantAdmin) {
      return sendTenantJoon3ConfigMutation;
    }
    return sendJoon3ConfigMutation;
  }, [isTenantAdmin]);
  const [sendJoon3Config] = useMutation(query);
  const [sending, setSending] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const onSend = useCallback(
    async (values) => {
      setSending(true);
      try {
        await sendJoon3Config({
          variables: {
            _id: audit._id,
          },
        });
        notificationApi.success({
          message: 'Success',
          description: 'Configuration sent to device.',
        });
      } catch (err) {
        console.error(err);
        notificationApi.error({
          message: 'Error',
          description: 'There was an sending your config',
        });
      }
      setSending(false);
    },
    [audit, sendJoon3Config, notificationApi],
  );
  const status = AuditStatuses[audit.auditStatus];
  return (
    <>
      {contextHolder}
      <Title level={2}>Configuration Status</Title>
      <div style={{ marginBottom: 32, display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 16 }}>
          {status ? (
            <Tag
              style={{
                fontSize: 18,
                paddingInline: 12,
                paddingTop: 4,
                paddingBottom: 4,
              }}
              color={status.color}
            >
              {status.label}
            </Tag>
          ) : (
            <Tag color={AuditStatuses.UNKNOWN.color}>
              {AuditStatuses.UNKNOWN.label}
            </Tag>
          )}
        </div>
        {audit.auditNotes && (
          <div style={{ marginRight: 16 }}>
            <Text>{audit.auditNotes}</Text>
          </div>
        )}
        <div style={{ flex: 1 }} />
        {audit.auditStatus == AuditStatuses.UNSENT.key && (
          <Button loading={sending} icon={<SendOutlined />} onClick={onSend}>
            Send Configuration
          </Button>
        )}
      </div>
    </>
  );
}

export default AuditStatusSection;
