import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import settings from '../../../settings';
import { useManyRemote } from '../../../shared/use-many-remote';
import { allJoonDeviceCellUsagesQuery } from '../constants';
import Chart from 'chart.js/auto';
import { Typography } from 'antd';
import filesize from 'filesize';
import { shallowEqual, useSelector } from 'react-redux';
import { shortEnglishHumanizer } from '../../../shared/utils';

const { Title } = Typography;

const ChartsToShow = [
  {
    title: 'Voice',
    type: 'VOICE',
    chartId: 'myVoiceChart',
    label: (context) => {
      return `${shortEnglishHumanizer(context.raw * 1000, {
        largest: 2,
        round: true,
      })}`;
    },
  },
  {
    title: 'Data',
    type: 'DATA',
    chartId: 'myDataChart',
    label: (context) => {
      return filesize(context.raw, {
        round: 1,
      });
    },
  },
  {
    title: 'SMS',
    type: 'SMS',
    chartId: 'mySmsChart',
    label: (context) => {
      return `${new Intl.NumberFormat('en-US').format(context.raw)} msgs`;
    },
  },
];

function CellUsage({ joonDevice }) {
  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      from: fromDate.getTime(),
      until: untilDate.getTime(),
      imei: joonDevice._id,
    };
  }, [joonDevice]);

  const [filters, setFilters] = useState(initialFilters);
  const {
    error,
    loading,
    data: usages,
    refetch,
    search: usageSearch,
  } = useManyRemote(
    allJoonDeviceCellUsagesQuery,
    (data) => data.allJoonDeviceCellUsages,
    1000,
    filters,
    [{ key: 'timestampMs', order: 'ASC' }],
  );
  const containerRef = useRef();
  const [width, setWidth] = useState();
  const myChart = useRef({});
  const locale = useSelector((store) => store.locale, shallowEqual);
  useLayoutEffect(() => {
    setWidth(containerRef.current.offsetWidth);
    setTimeout(() => {
      ChartsToShow.forEach((details) => {
        let data = [];
        let labels = [];
        if (usages && usages.length) {
          data = usages
            .filter((d) => d.type === details.type)
            .map((d) => d.totalUsage);
          const dateFormatter = new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          });
          labels = usages
            .filter((d) => d.type === details.type)
            .map((d) => dateFormatter.format(new Date(d.timestampMs)));
        }
        const ctx = document.getElementById(details.chartId);
        const backgroundColor = data.map(() => '#e7282f1f');
        const borderColor = data.map(() => '#e7282f');
        if (myChart.current[details.chartId]) {
          myChart.current[details.chartId].destroy();
        }
        myChart.current[details.chartId] = new Chart(ctx, {
          type: 'bar',
          data: {
            labels,
            datasets: [
              {
                label: details.title,
                data,
                backgroundColor,
                borderColor,
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: details.label,
                },
              },
            },
          },
        });
      });
    }, 0);
  }, [usages, locale]);
  return (
    <div ref={containerRef}>
      {ChartsToShow.map((details) => (
        <div key={details.chartId} style={{ marginBottom: 16 }}>
          <Title level={2}>{details.title}</Title>
          {width !== undefined && (
            <canvas id={details.chartId} width={width} height={300} />
          )}
        </div>
      ))}
      <style jsx>{``}</style>
    </div>
  );
}

export default CellUsage;
