import React, { useMemo } from 'react';
import { notNullOrUndefined } from '../../../shared/utils';
import PlotSomethingScatter from './PlotSomethingScatter';

export function getSince(now, v, maxValue) {
  if (notNullOrUndefined(v)) {
    return (now - v) / 1000 / 60 / 60;
  }
  return maxValue;
}

export function getHour(v, maxValue) {
  if (notNullOrUndefined(v)) {
    return v / 1000 / 60 / 60;
  }
  return maxValue;
}

export function getAsIsValue(v, maxValue) {
  if (notNullOrUndefined(v)) {
    return v;
  }
  return maxValue;
}

export function getAsIsValueNoZero(v, maxValue) {
  if (v) {
    return v;
  }
  return maxValue;
}

export function getPercent(v, maxValue) {
  if (v) {
    return v * 100;
  }
  return maxValue;
}

function ASomethingPlot({
  filteredReports,
  loading,
  name,
  idAttr,
  labelAttr,
  label,
  getValue,
  maxValue,
  filter,
  yAxisType = 'log',
  yAxisLabel = 'Hours',
}) {
  const axis = useMemo(() => {
    const items = filteredReports.map((deviceReport) => {
      const since = getValue(deviceReport[labelAttr], maxValue);
      return {
        _id: deviceReport[idAttr],
        since,
      };
    });
    const itemsOrdered = items.sort((a, b) => a.since - b.since);
    const x = itemsOrdered.map((d, i) => i);
    const y = itemsOrdered.map((d, i) => d.since);
    const text = itemsOrdered.map((d, i) => d._id);
    return {
      x,
      y,
      text,
      name,
      label,
    };
  }, [filteredReports, idAttr, labelAttr, name, label, getValue, maxValue]);

  return (
    <div style={{ marginBottom: 48 }}>
      <PlotSomethingScatter
        loading={loading}
        name={name}
        height={600}
        mode={undefined}
        axes={[axis]}
        yAxisType={yAxisType}
        yAxisLabel={yAxisLabel}
      />
      {filter}
    </div>
  );
}

export default ASomethingPlot;
