export const READ_JOON3_DEVICE_REPORTS = 'READ_JOON3_DEVICE_REPORTS';
export const CREATE_JOON3_DEVICE_REPORT = 'CREATE_JOON3_DEVICE_REPORT';
export const UPDATE_JOON3_DEVICE_REPORT = 'UPDATE_JOON3_DEVICE_REPORT';
export const DELETE_JOON3_DEVICE_REPORT = 'DELETE_JOON3_DEVICE_REPORT';

export const readJoon3DeviceReportsAction = (
  // eslint-disable-next-line no-shadow
  joon3DeviceReports,
  queryId = 'default',
) => ({
  type: READ_JOON3_DEVICE_REPORTS,
  payload: {
    joon3DeviceReports,
    queryId,
  },
});

export const createJoon3DeviceReportAction = (
  joon3DeviceReport,
  queryId = 'default',
) => ({
  type: CREATE_JOON3_DEVICE_REPORT,
  payload: {
    joon3DeviceReport,
    queryId,
  },
});

export const updateJoon3DeviceReportAction = (
  joon3DeviceReport,
  queryId = 'default',
) => ({
  type: UPDATE_JOON3_DEVICE_REPORT,
  payload: {
    joon3DeviceReport,
    queryId,
  },
});

export const deleteJoon3DeviceReportAction = (
  joon3DeviceReportId,
  queryId = 'default',
) => ({
  type: DELETE_JOON3_DEVICE_REPORT,
  payload: {
    joon3DeviceReportId,
    queryId,
  },
});

const initialJoon3DeviceReports = { default: {} };

export function joon3DeviceReports(state = initialJoon3DeviceReports, action) {
  switch (action.type) {
    case READ_JOON3_DEVICE_REPORTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joon3DeviceReports.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON3_DEVICE_REPORT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3DeviceReport._id]:
            action.payload.joon3DeviceReport,
        },
      };
    case UPDATE_JOON3_DEVICE_REPORT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][
            action.payload.joon3DeviceReport._id
          ]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3DeviceReport._id]: {
            ...current,
            ...action.payload.joon3DeviceReport,
          },
        },
      };
    }
    case DELETE_JOON3_DEVICE_REPORT: {
      const { joon3DeviceReportId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joon3DeviceReportId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
