import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DeleteSafeZoneModal from './DeleteSafeZoneModal';
import EditSafeZonesModal from './EditSafeZonesModal';
import useFilterGeofences from './use-filter-geofences';
import { formatAddress, generateUuid } from '../../../../shared/utils';

const { Title, Text } = Typography;

function SafeZones({ joonDevice }) {
  const safeZones = useFilterGeofences({
    deviceId: joonDevice && joonDevice._id,
  });
  const [editSafeZone, setEditSafeZone] = useState();
  const [deleting, setDeleting] = useState();
  const handleCancelEditingSafeZone = useCallback(() => {
    setEditSafeZone(undefined);
  }, []);
  const handleFinishEditingSafeZone = useCallback(() => {
    setEditSafeZone(undefined);
  }, []);
  const handleCancelDeleteWifi = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleFinishDeleteWifi = useCallback(() => {
    setDeleting(undefined);
  }, []);

  const handleCreateSafeZone = useCallback(
    (record) => {
      setEditSafeZone({
        safeZone: { _id: generateUuid() },
        creating: true,
        joonDeviceId: joonDevice._id,
      });
    },
    [joonDevice],
  );
  const handleEditSafeZone = useCallback(
    (record) => {
      setEditSafeZone({
        safeZone: record,
        joonDeviceId: joonDevice._id,
      });
    },
    [joonDevice],
  );
  const handleDeleteSafeZone = useCallback(
    async (record) => {
      setDeleting({
        safeZone: record,
        joonDeviceId: joonDevice._id,
      });
    },
    [joonDevice],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const tableData = safeZones || [];

  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <div className="top-actions">
        <Title level={2} style={{ marginTop: 32 }}>
          Safe Zones
        </Title>
        <Button
          icon={<PlusCircleOutlined />}
          onClick={handleCreateSafeZone}
          style={{ marginTop: 16, float: 'right' }}
        >
          Add New Safe Zone
        </Button>
      </div>
      {!!tableData && (
        <Table
          dataSource={tableData}
          pagination={false}
          style={{ width: '100%' }}
          rowKey="key"
          locale={{
            emptyText: 'No Safe Zones Found.',
          }}
        >
          <Table.Column title="Name" dataIndex="name" key="name" />
          <Table.Column
            title="Address"
            key="address"
            render={(_, record) => formatAddress(record.address)}
          />
          <Table.Column
            title="Center"
            key="center"
            render={(_, record) =>
              record.center && (
                <div>
                  {`${record.center.lat}, ${record.center.lon}`}
                  <br />
                  <a
                    href={`http://maps.google.com/maps?t=k&q=loc:${record.center.lat}+${record.center.lon}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Map
                  </a>
                </div>
              )
            }
          />
          <Table.Column
            title="Action"
            render={(_, record) => (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      onClick={() => handleEditSafeZone(record)}
                      icon={<EditOutlined />}
                    />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button
                      onClick={() => handleDeleteSafeZone(record)}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          ></Table.Column>
        </Table>
      )}
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
      <EditSafeZonesModal
        editSafeZone={editSafeZone}
        onFinish={handleFinishEditingSafeZone}
        onCancel={handleCancelEditingSafeZone}
      />
      <DeleteSafeZoneModal
        deleting={deleting}
        onCancel={handleCancelDeleteWifi}
        onSuccess={handleFinishDeleteWifi}
      />
    </div>
  );
}

export default SafeZones;
