import { BarChartOutlined, GlobalOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { createJoonDeviceActivityReportMutation } from '../constants';
import MapView from './MapView';

const { Title, Text, Paragraph } = Typography;

function MapViewPage() {
  const [createJoonDeviceActivityReport] = useMutation(
    createJoonDeviceActivityReportMutation,
  );
  const [loading, setLoading] = useState(false);

  const doActivityReport = useCallback(async () => {
    setLoading(true);
    try {
      const result = await createJoonDeviceActivityReport({
        variables: {
          filters: {},
          sortBy: [{ key: 'updatedAt', order: 'DESC' }],
        },
      });
      notification.success({
        message: 'Report Generated',
        description: (
          <>
            <div>
              <a href={result.data.createJoonDeviceActivityReport.url}>
                Click to Download
              </a>
            </div>
          </>
        ),
        duration: 0,
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: err.message,
      });
    }
    setLoading(false);
  }, [createJoonDeviceActivityReport]);

  return (
    <CSPage
      title="Theora Connect&trade; Map"
      containerStyle={{ maxWidth: 'unset' }}
    >
      <CSPageHeader
        titleComponent={
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            <GlobalOutlined style={{ marginRight: 16 }} />
            Theora Connect&trade; Map
          </Title>
        }
        topActions={[
          <Button
            key="export"
            type="link"
            size="large"
            icon={<BarChartOutlined />}
            loading={loading}
            onClick={doActivityReport}
          >
            Generate Activity Report
          </Button>,
        ]}
      />
      <MapView />
      <style jsx>{``}</style>
    </CSPage>
  );
}

export default MapViewPage;
