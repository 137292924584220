import { useMemo } from 'react';
import useQueryParams from '../../../shared/use-query-params';
import { notNullOrUndefined } from '../../../shared/utils';
import { getArrayFromUrlFilters } from './utils';
import {
  displayDurationSetting,
  displayRoundedDurationSetting,
} from '../../joonDevices/ViewJoonDevice/settings-v2/utils';
import { AuditStatuses } from '../../joonDevices/constants';

function makeArrayStringFilter(labels, data, urlFilters, name, makeLabel) {
  let _data = data;
  if (urlFilters[name]) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    _data = data.filter(
      (d) =>
        (value.includes('__undefined__') && !d[name]) ||
        value.includes(d[name]),
    );
    value.forEach((v) => {
      labels.push({
        type: 'string[]',
        name,
        value: v,
        label: makeLabel(v),
      });
    });
  }
  return _data;
}

function makeArrayBooleanFilter(labels, data, urlFilters, name, makeLabel) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    _data = data.filter(
      (d) =>
        (value.includes('__undefined__') && !notNullOrUndefined(d[name])) ||
        value.includes(d[name].toString()),
    );
    value.forEach((v) => {
      labels.push({
        type: 'bool[]',
        name,
        value: v,
        label: makeLabel(v),
      });
    });
  }
  return _data;
}

function makeArrayIntFilter(labels, data, urlFilters, name, makeLabel) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    _data = data.filter(
      (d) =>
        (value.includes('__undefined__') && !notNullOrUndefined(d[name])) ||
        value.includes(d[name]?.toString()),
    );
    value.forEach((v) => {
      labels.push({
        type: 'int[]',
        name,
        value: v,
        label: makeLabel(v),
      });
    });
  }
  return _data;
}

function makeArrayIntLengthFilter(labels, data, urlFilters, name, makeLabel) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    _data = data.filter(
      (d) =>
        (value.includes('__undefined__') && !notNullOrUndefined(d[name])) ||
        value.includes(d[name].length.toString()),
    );
    value.forEach((v) => {
      labels.push({
        type: 'int[]',
        name,
        value: v,
        label: makeLabel(v),
      });
    });
  }
  return _data;
}

function makeHoursSinceLogRangeFilter(
  labels,
  data,
  urlFilters,
  name,
  makeLabel,
  maxValue,
  now,
) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    const start = value[0] * 60 * 60 * 1000;
    const end = value[1] * 60 * 60 * 1000;
    _data = data.filter((d) => {
      let v;
      if (notNullOrUndefined(d[name])) {
        v = now - d[name];
      } else {
        v = maxValue;
      }
      if (v >= start && v < end) {
        return true;
      }
      return false;
    });
    labels.push({
      type: 'range',
      name,
      value,
      label: makeLabel(value),
    });
  }
  return _data;
}

function makePercentRangeFilter(
  labels,
  data,
  urlFilters,
  name,
  makeLabel,
  maxValue,
) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    const start = value[0] / 100;
    const end = value[1] / 100;
    _data = data.filter((d) => {
      let v;
      if (notNullOrUndefined(d[name])) {
        v = d[name];
      } else {
        v = maxValue;
      }
      if (v >= start && v <= end) {
        return true;
      }
      return false;
    });
    labels.push({
      type: 'range',
      name,
      value,
      label: makeLabel(value),
    });
  }
  return _data;
}

function makeSimpleRangeFilter(
  labels,
  data,
  urlFilters,
  name,
  makeLabel,
  maxValue,
) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    const start = value[0];
    const end = value[1];
    _data = data.filter((d) => {
      let v;
      if (notNullOrUndefined(d[name])) {
        v = d[name];
      } else {
        v = maxValue;
      }
      if (v >= start && v <= end) {
        return true;
      }
      return false;
    });
    labels.push({
      type: 'range',
      name,
      value,
      label: makeLabel(value),
    });
  }
  return _data;
}

function makeFallStatsFilter(
  labels,
  data,
  urlFilters,
  name,
  makeLabel,
  maxValue,
) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const attr_name = name.replace('fallStats.', '');
    const value = getArrayFromUrlFilters(urlFilters, name);
    const start = value[0];
    const end = value[1];
    _data = data.filter((d) => {
      let v;
      if (notNullOrUndefined(d.fallStats?.[attr_name])) {
        v = d.fallStats?.[attr_name];
      } else {
        v = maxValue;
      }
      if (v >= start && v <= end) {
        return true;
      }
      return false;
    });
    labels.push({
      type: 'range',
      name,
      value,
      label: makeLabel(value),
    });
  }
  return _data;
}

function makeHourRangeFilter(
  labels,
  data,
  urlFilters,
  name,
  makeLabel,
  maxValue,
) {
  let _data = data;
  if (notNullOrUndefined(urlFilters[name])) {
    const value = getArrayFromUrlFilters(urlFilters, name);
    const start = value[0] * 60 * 60 * 1000;
    const end = value[1] * 60 * 60 * 1000;
    _data = data.filter((d) => {
      let v;
      if (notNullOrUndefined(d[name])) {
        v = d[name];
      } else {
        v = maxValue * 60 * 60 * 1000;
      }
      if (v >= start && v <= end) {
        return true;
      }
      return false;
    });
    labels.push({
      type: 'range',
      name,
      value,
      label: makeLabel(value),
    });
  }
  return _data;
}

function useNightlyFilters(unfiltered, report) {
  const tenants = useMemo(() => {
    if (unfiltered) {
      return unfiltered.reduce(
        (prev, curr) => {
          if (prev[curr.tenantId] === undefined) {
            prev[curr.tenantId] = curr.tenantName;
          }
          return prev;
        },
        { __undefined__: 'No Tenant' },
      );
    }
    return { __undefined__: 'No Tenant' };
  }, [unfiltered]);
  const urlFilters = useQueryParams();
  const [filtered, filterLabels] = useMemo(() => {
    let data = unfiltered || [];
    let labels = [];
    data = makeArrayStringFilter(
      labels,
      data,
      urlFilters,
      'tenantId',
      (v) => `Tenant = ${tenants[v]}`,
    );
    data = makeArrayStringFilter(labels, data, urlFilters, 'fwVer', (v) =>
      v === '__undefined__' ? 'No Firmware Version' : `Firmware Version = ${v}`,
    );
    data = makeArrayStringFilter(labels, data, urlFilters, 'appVer', (v) =>
      v === '__undefined__' ? 'No App Version' : `App Version = ${v}`,
    );
    data = makeArrayStringFilter(labels, data, urlFilters, 'fallAppVer', (v) =>
      v === '__undefined__' ? 'No Fall App Version' : `Fall App Version = ${v}`,
    );
    data = makeArrayBooleanFilter(
      labels,
      data,
      urlFilters,
      'fallDetectionEnabled',
      (v) =>
        v === '__undefined__'
          ? 'Fall Detection = Not Specified'
          : `Fall Detection = ${v ? 'Enabled' : 'Disabled'}`,
    );
    data = makeArrayIntFilter(
      labels,
      data,
      urlFilters,
      'heartbeatIntervalMs',
      (v) =>
        v === '__undefined__'
          ? 'Heartbeat Not Specified'
          : displayDurationSetting(v),
    );
    data = makeArrayStringFilter(labels, data, urlFilters, 'auditStatus', (v) =>
      v === '__undefined__'
        ? 'No Config Status'
        : `Config Status = ${AuditStatuses[v]?.label || 'Bad Config Status'}`,
    );
    const createdAt = new Date(report?.createdAt).getTime();
    data = makeHoursSinceLogRangeFilter(
      labels,
      data,
      urlFilters,
      'latestPingMs',
      (v) => {
        const start = v[0] * 60 * 60 * 1000;
        const end = v[1] * 60 * 60 * 1000;
        return `Latest ping between ${displayRoundedDurationSetting(
          start,
        )} and ${displayRoundedDurationSetting(end)}`;
      },
      365 * 24,
      createdAt,
    );
    data = makeHoursSinceLogRangeFilter(
      labels,
      data,
      urlFilters,
      'latestLocationMs',
      (v) => {
        const start = v[0] * 60 * 60 * 1000;
        const end = v[1] * 60 * 60 * 1000;
        return `Latest location between ${displayRoundedDurationSetting(
          start,
        )} and ${displayRoundedDurationSetting(end)}`;
      },
      365 * 24,
      createdAt,
    );
    data = makePercentRangeFilter(
      labels,
      data,
      urlFilters,
      'pMissedHeartbeats',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Percent heartbeats missed between ${start} and ${end}`;
      },
      100,
    );
    data = makeHourRangeFilter(
      labels,
      data,
      urlFilters,
      'aveBatteryLifeMs',
      (v) => {
        const start = v[0] * 60 * 60 * 1000;
        const end = v[1] * 60 * 60 * 1000;
        return `Average battery life between ${displayDurationSetting(
          start,
        )} and ${displayDurationSetting(end)}`;
      },
      48,
    );
    data = makeSimpleRangeFilter(
      labels,
      data,
      urlFilters,
      'aveSignal',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Average signal strength between ${start} and ${end}`;
      },
      0,
    );
    data = makeFallStatsFilter(
      labels,
      data,
      urlFilters,
      'fallStats.detected',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Falls detected between ${start} and ${end}`;
      },
      0,
    );
    data = makeFallStatsFilter(
      labels,
      data,
      urlFilters,
      'fallStats.verified',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Falls verified between ${start} and ${end}`;
      },
      0,
    );
    data = makeFallStatsFilter(
      labels,
      data,
      urlFilters,
      'fallStats.canceled',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Falls cancelled between ${start} and ${end}`;
      },
      0,
    );
    data = makeFallStatsFilter(
      labels,
      data,
      urlFilters,
      'fallStats.no_response',
      (v) => {
        const start = v[0];
        const end = v[1];
        return `Falls no response between ${start} and ${end}`;
      },
      0,
    );
    data = makeArrayIntLengthFilter(
      labels,
      data,
      urlFilters,
      'suspectedLockups',
      (v) =>
        v === '__undefined__' ? 'Unknown' : `# of Suspected lockups = ${v}`,
    );
    return [data, labels];
  }, [unfiltered, urlFilters, tenants, report]);

  return {
    filtered,
    filterLabels,
  };
}

export default useNightlyFilters;
