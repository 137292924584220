export const READ_JOON_DATASETS = 'READ_JOON_DATASETS';
export const CREATE_JOON_DATASET = 'CREATE_JOON_DATASET';
export const UPDATE_JOON_DATASET = 'UPDATE_JOON_DATASET';
export const DELETE_JOON_DATASET = 'DELETE_JOON_DATASET';
export const BULK_CREATE_JOON_DATASETS = 'BULK_CREATE_JOON_DATASETS';

// eslint-disable-next-line no-shadow
export const readJoonDatasetsAction = (joonDatasets, queryId = 'default') => ({
  type: READ_JOON_DATASETS,
  payload: {
    joonDatasets,
    queryId,
  },
});

export const createJoonDatasetAction = (joonDataset, queryId = 'default') => ({
  type: CREATE_JOON_DATASET,
  payload: {
    joonDataset,
    queryId,
  },
});

export const bulkCreateJoonDatasetsAction = (
  // eslint-disable-next-line no-shadow
  joonDatasets,
  queryId = 'default',
) => ({
  type: BULK_CREATE_JOON_DATASETS,
  payload: {
    joonDatasets,
    queryId,
  },
});

export const updateJoonDatasetAction = (joonDataset, queryId = 'default') => ({
  type: UPDATE_JOON_DATASET,
  payload: {
    joonDataset,
    queryId,
  },
});

export const deleteJoonDatasetAction = (
  joonDatasetId,
  queryId = 'default',
) => ({
  type: DELETE_JOON_DATASET,
  payload: {
    joonDatasetId,
    queryId,
  },
});

const initialJoonDatasets = { default: {} };

export function joonDatasets(state = initialJoonDatasets, action) {
  switch (action.type) {
    case READ_JOON_DATASETS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joonDatasets.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON_DATASET:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDataset._id]: action.payload.joonDataset,
        },
      };
    case BULK_CREATE_JOON_DATASETS:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          ...action.payload.joonDatasets.reduce((prev, curr) => {
            prev[curr._id] = curr;
            return prev;
          }, {}),
        },
      };
    case UPDATE_JOON_DATASET: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joonDataset._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDataset._id]: {
            ...current,
            ...action.payload.joonDataset,
          },
        },
      };
    }
    case DELETE_JOON_DATASET: {
      const { joonDatasetId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joonDatasetId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
