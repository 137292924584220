import { useMutation, gql } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { DeviceUserRoles } from '../../constants';

const { Title, Text, Paragraph } = Typography;

export const deleteUserXJoon3Mutation = gql`
  mutation DeleteUserXJoon3($_id: ID!) {
    deleteUserXJoon3(_id: $_id) {
      deletedId
    }
  }
`;

const DeleteDeviceUserModal = ({ visible, onSuccess, onCancel, queryId }) => {
  const [loading, setLoading] = useState(false);

  const { deviceUser } = visible || {};

  const [deleteUserXJoon3] = useMutation(deleteUserXJoon3Mutation);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await deleteUserXJoon3({
        variables: {
          _id: deviceUser._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Device user removed',
      });
      if (onSuccess) onSuccess();
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'There was an error deleting this device user',
      });
    }
    setLoading(false);
  }, [deleteUserXJoon3, deviceUser, onSuccess]);

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Delete Device User
        </Title>

        <Paragraph style={{ textAlign: 'center' }}>
          {`Are you sure you want to remove this device user?`}
        </Paragraph>
        {deviceUser && deviceUser.user && (
          <div style={{ textAlign: 'center' }}>
            {`${deviceUser.user.name} (${
              deviceUser.role &&
              DeviceUserRoles[deviceUser.role] &&
              DeviceUserRoles[deviceUser.role].label
            })`}
          </div>
        )}
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button danger onClick={handleFinish}>
            Delete
          </Button>
        </div>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default DeleteDeviceUserModal;
