import { Spin, Typography } from 'antd';
import Plotly from 'plotly.js-dist';
import settings from '../../../settings';
import React, { useEffect, useRef } from 'react';
import useSafeState from '../../../shared/use-safe-state';

const { Title } = Typography;

function PlotSomethingScatter({
  loading,
  name,
  height,
  mode,
  axes,
  xAxisType = 'linear',
  yAxisType = 'linear',
  xAxisLabel,
  yAxisLabel,
  style,
}) {
  const data = useRef({});
  const first = useRef({});
  const [srCounter, setSRCounter, _srCounter] = useSafeState(0);
  useEffect(() => {
    if (data.current[name] === undefined) {
      data.current[name] = {};
    }
    axes.forEach((a) => {
      if (data.current[name][a.name] === undefined) {
        data.current[name][a.name] = {
          label: a.label,
          x: a.x,
          y: a.y,
          text: a.text,
        };
      }
    });

    if (!first.current[name]) {
      first.current[name] = true;
      Plotly.newPlot(`plot_${name}`, [], {
        xaxis: {
          autorange: true,
          type: xAxisType,
          title: xAxisLabel && {
            text: xAxisLabel,
          },
        },
        yaxis: {
          autorange: true,
          type: yAxisType,
          title: yAxisLabel && {
            text: yAxisLabel,
          },
        },
        margin: {
          l: 64,
          r: 0,
          b: 24,
          t: 24,
          pad: 4,
        },
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1,
        },
      });
      Object.entries(data.current[name]).forEach(([axisName, axi]) => {
        const { label: _label, ...values } = axi;
        Plotly.addTraces(`plot_${name}`, {
          ...values,
          type: 'scatter',
          mode,
          name: _label,
        });
        first.current[axisName] = true;
      });
    }
  }, [name, mode, axes, xAxisType, yAxisType, xAxisLabel, yAxisLabel]);

  useEffect(() => {
    axes.forEach((a) => {
      data.current[name][a.name] = {
        x: a.x,
        y: a.y,
        text: a.text,
      };
    });
    setSRCounter(_srCounter.current + 1);
  }, [axes, _srCounter, setSRCounter, name]);

  useEffect(() => {
    try {
      Plotly.update(
        `plot_${name}`,
        {
          x: Object.values(data.current[name]).map((a) => a.x),
          y: Object.values(data.current[name]).map((a) => a.y),
          text: Object.values(data.current[name]).map((a) => a.text),
        },
        {
          xaxis: {
            autorange: true,
            type: xAxisType,
            title: xAxisLabel && {
              text: xAxisLabel,
            },
          },
          yaxis: {
            autorange: true,
            type: yAxisType,
            title: yAxisLabel && {
              text: yAxisLabel,
            },
          },
        },
        Object.values(data.current[name]).map((a, i) => i),
      );
    } catch (err) {
      console.error(err);
    }
  }, [srCounter, name, xAxisType, yAxisType, xAxisLabel, yAxisLabel]);

  return (
    <div
      className="plot-holder"
      style={{
        width: '100%',
        height: height + 32,
        position: 'relative',
        ...style,
      }}
    >
      <div
        id={`plot_${name}`}
        className="plot"
        style={{
          height,
        }}
      />
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
          }}
        >
          <Spin spinning>
            <div
              style={{ height: height + 32, background: settings.colors.ghost }}
            />
          </Spin>
        </div>
      )}
    </div>
  );
}

export default PlotSomethingScatter;
