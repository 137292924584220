export const READ_JOON3_AUDITS = 'READ_JOON3_AUDITS';
export const CREATE_JOON3_AUDIT = 'CREATE_JOON3_AUDIT';
export const UPDATE_JOON3_AUDIT = 'UPDATE_JOON3_AUDIT';
export const DELETE_JOON3_AUDIT = 'DELETE_JOON3_AUDIT';

export const readJoon3AuditsAction = (
  // eslint-disable-next-line no-shadow
  joon3Audits,
  queryId = 'default',
) => ({
  type: READ_JOON3_AUDITS,
  payload: {
    joon3Audits,
    queryId,
  },
});

export const createJoon3AuditAction = (joon3Audit, queryId = 'default') => ({
  type: CREATE_JOON3_AUDIT,
  payload: {
    joon3Audit,
    queryId,
  },
});

export const updateJoon3AuditAction = (joon3Audit, queryId = 'default') => ({
  type: UPDATE_JOON3_AUDIT,
  payload: {
    joon3Audit,
    queryId,
  },
});

export const deleteJoon3AuditAction = (joon3AuditId, queryId = 'default') => ({
  type: DELETE_JOON3_AUDIT,
  payload: {
    joon3AuditId,
    queryId,
  },
});

const initialJoon3Audits = { default: {} };

export function joon3Audits(state = initialJoon3Audits, action) {
  switch (action.type) {
    case READ_JOON3_AUDITS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joon3Audits.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON3_AUDIT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Audit._id]: action.payload.joon3Audit,
        },
      };
    case UPDATE_JOON3_AUDIT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joon3Audit._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joon3Audit._id]: {
            ...current,
            ...action.payload.joon3Audit,
          },
        },
      };
    }
    case DELETE_JOON3_AUDIT: {
      const { joon3AuditId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joon3AuditId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
