import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useQueryParams() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  Object.keys(params).forEach((key) => {
    if (params[key] === 'true') {
      params[key] = true;
    }
    if (params[key] === 'false') {
      params[key] = false;
    }
  });
  return params;
}

export default useQueryParams;
