import { compareObjects } from '../../../shared/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { getLastUpdated } from './utils';
import {
  ClockCircleFilled,
  CompassOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import settings from '../../../settings';
import { JoonLocationTypes } from '../constants';
import { createRoot } from 'react-dom/client';
import { renderToStaticMarkup } from 'react-dom/server';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useSafeState from '../../../shared/use-safe-state';

const { Title, Text, Paragraph } = Typography;

const popupHtml = (() => {
  return renderToStaticMarkup(
    <div>
      <div data-style="{{caregiverNameDisplay}}">
        <span>{`Caregiver: {{caregiverName}}`}</span>
      </div>
      <div data-style="{{wearerNameDisplay}}">
        <span>{`Wearer: {{wearerName}}`}</span>
      </div>
      <div data-style="{{batteryDisplay}}">
        <span>{`Battery: {{battery}}`}</span>
      </div>
      <div data-style="{{signalStrengthDisplay}}">
        <span>{`Signal Strength: {{signalStrength}}`}</span>
      </div>
      {/* <div data-style="{{gsmNumberDisplay}}">
        <span>{`{{msisdn}}`}</span>
      </div> */}
      {/* <div data-style="{{gsmDateDisplay}}">
        <span>{`{{gsmDate}}`}</span>
      </div> */}
      <div data-style="{{timeSinceUpdateDisplay}}">
        <span>{`{{timeSinceUpdate}}`}</span>
      </div>
      {/* <div data-style="{{locProviderDisplay}}">
        <span>{`{{locProvider}}`}</span>
      </div> */}
      <div data-style="{{accuracyDisplay}}">
        <span>{`{{accuracy}}`}</span>
      </div>
      <div data-style="{{imeiDisplay}}">
        <span>{`IMEI: {{imei}}`}</span>
      </div>
      <a href={`/joon-device/view/{{imei}}/settings`}>Go to device</a>
      {/* <div data-style="{{satelliteDisplay}}">
        <a
          // href={`https://www.google.com/maps/search/?api=1&query={{latitude}},{{longitude}}&basemap=satellite`}
          href={`http://maps.google.com/maps?t=k&q=loc:{{latitude}}+{{longitude}}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Satellite View
        </a> 
      </div> */}
    </div>,
  );
})();

function fixHtml(html, prop, cssName, dataName) {
  let _html;
  if (prop && prop !== 'null') {
    _html = html.replace(
      `data-style="{{${cssName}}}"`,
      'style="display: block;"',
    );
    _html = html.replace(`{{${dataName}}}`, prop);
  } else {
    _html = html.replace(
      `data-style="{{${cssName}}}"`,
      'style="display: none;"',
    );
  }
  return _html;
}

const markerElement = (secondsSinceUpdate) => {
  const element = document.createElement('div');
  let color;
  if (secondsSinceUpdate === undefined) {
    color = 'black';
  } else {
    if (secondsSinceUpdate <= 86400) {
      color = settings.colors.green;
    } else if (secondsSinceUpdate <= 1209600) {
      color = settings.colors.yellow;
    } else {
      color = settings.colors.red;
    }
    // let x;
    // if (secondsSinceUpdate > 604800) {
    //   x = 1;
    // } else {
    //   x = secondsSinceUpdate / 604800;
    // }
    // color = getJetColor(x);
  }
  const root = createRoot(element);
  root.render(
    <div style={{ backgroundColor: 'white', lineHeight: 1, borderRadius: 36 }}>
      <ClockCircleFilled style={{ color, fontSize: 28 }} />
    </div>,
  );
  return element;
};

function DeviceMap({ devices, handleCenterTo }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const map = useRef(null);
  handleCenterTo.current = useCallback((now) => {
    if (now && now.loc) {
      map.current.flyTo({
        center: [now.loc.lon, now.loc.lat],
        zoom: 15,
      });
    }
  }, []);
  const markersOnTheMap = useRef({});
  const refreshMarkers = useCallback(() => {
    Object.keys(markersOnTheMap.current).forEach(function (_id) {
      markersOnTheMap.current[_id].remove();
      delete markersOnTheMap.current[_id];
    });
    map.current.querySourceFeatures('devices').forEach(function (feature) {
      if (feature.properties && !feature.properties.cluster) {
        const _id = feature.properties._id;
        if (!markersOnTheMap.current[_id]) {
          const device = JSON.parse(feature.properties.device);
          const now = JSON.parse(feature.properties.now);
          const { timeSinceUpdate, secondsSinceUpdate } = getLastUpdated(
            now.updatedAt,
          );
          var newMarker = new tt.Marker({
            element: markerElement(secondsSinceUpdate),
            anchor: 'center',
          }).setLngLat(feature.geometry.coordinates);
          newMarker.addTo(map.current);
          let html = popupHtml;
          html = fixHtml(
            html,
            device.caregiverName,
            'caregiverNameDisplay',
            'caregiverName',
          );
          html = fixHtml(
            html,
            device.wearerName,
            'wearerNameDisplay',
            'wearerName',
          );
          html = fixHtml(html, device._id, 'imeiDisplay', 'imei');
          html = fixHtml(html, device.msisdn, 'gsmNumberDisplay', 'msisdn');
          let _signalLevel;
          if (now.signalLevel > -90) {
            _signalLevel = 'Very good';
          } else if (now.signalLevel > -106) {
            _signalLevel = 'Good';
          } else if (now.signalLevel > -120) {
            _signalLevel = 'Fair';
          } else if (now.signalLevel > -140) {
            _signalLevel = 'Poor';
          } else {
            _signalLevel = 'No signal';
          }
          html = fixHtml(
            html,
            _signalLevel,
            'signalStrengthDisplay',
            'signalStrength',
          );
          html = fixHtml(
            html,
            now.batteryLevel !== 'null' ? `${now.batteryLevel}%` : undefined,
            'batteryDisplay',
            'battery',
          );
          html = fixHtml(
            html,
            now.updatedAt &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'medium',
                timeStyle: 'medium',
              }).format(new Date(now.updatedAt)),
            'gsmDateDisplay',
            'gsmDate',
          );
          html = fixHtml(
            html,
            timeSinceUpdate,
            'timeSinceUpdateDisplay',
            'timeSinceUpdate',
          );
          html = fixHtml(
            html,
            JoonLocationTypes[now.loc.type] &&
              `Location Provider: ${JoonLocationTypes[now.loc.type].label}`,
            'locProviderDisplay',
            'locProvider',
          );
          html = fixHtml(
            html,
            now.loc.acc &&
              `Accuracy: ${new Intl.NumberFormat('en-US', {
                style: 'unit',
                unit: 'meter',
              }).format(now.loc.acc)}`,
            'accuracyDisplay',
            'accuracy',
          );
          html = fixHtml(html, device._id, 'imeiDisplay', 'imei');
          html = html.replace('{{satelliteDisplay}}', 'block');
          html = html.replace('{{latitude}}', now.loc.lat);
          html = html.replace('{{longitude}}', now.loc.lon);

          newMarker.setPopup(
            new tt.Popup({
              closeButton: false,
              closeOnClick: true,
              offset: 30,
            }).setHTML(html),
          );
          // TODO: in order to add accuracy circles use @turf/circle to create a GeoJSON circle and add it to the map here
          markersOnTheMap.current[_id] = newMarker;
        }
      }
    });
  }, [locale]);

  const [mapLoaded, setMapLoaded, _mapLoaded] = useSafeState(0);
  const [isSatelliteMode, setIsSatelliteMode] = useState(false);
  const eventListenersAdded = useRef(false);
  useEffect(() => {
    const apiKey = 'qn31xnA19ybA0VrrF9XiinoAXWQQhv2c';
    map.current = tt.map({
      key: apiKey,
      container: 'mapView',
      style: isSatelliteMode
        ? `https://api.tomtom.com/style/1/style/*?map=2/basic_street-satellite&poi=2/poi_dynamic-satellite&key=${apiKey}`
        : undefined,
    });
    map.current.addControl(new tt.FullscreenControl());
    map.current.addControl(new tt.NavigationControl());
    map.current.on('load', () => {
      const interval = setInterval(() => {
        if (map.current.isStyleLoaded()) {
          setMapLoaded(_mapLoaded.current + 1);
          clearInterval(interval);
        }
      }, 100);
    });
    map.current.on('data', function (e) {
      if (
        e.sourceId !== 'devices' ||
        !map.current.getSource('devices') ||
        !map.current.getSource('devices').loaded()
      ) {
        return;
      }
      refreshMarkers();
      if (!eventListenersAdded.current) {
        // map.current.on('move', refreshMarkers);
        map.current.on('moveend', refreshMarkers);
        eventListenersAdded.current = true;
      }
    });
    map.current.on('click', 'clusters', function (e) {
      var features = map.current.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      var clusterId = features[0].properties.cluster_id;
      map.current
        .getSource('devices')
        .getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) {
            return;
          }
          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });
    map.current.on('mouseenter', 'clusters', function () {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', 'clusters', function () {
      map.current.getCanvas().style.cursor = '';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshMarkers, isSatelliteMode]);

  const source = useRef();
  const prev = useRef();
  useEffect(() => {
    if (
      devices &&
      map.current &&
      map.current.isStyleLoaded() &&
      mapLoaded &&
      !compareObjects(prev.current, devices)
    ) {
      prev.current = devices;
      const _geoJson = {
        type: 'FeatureCollection',
        features: devices
          .map((device) => {
            const loc =
              device && device.now && device.now.loc && device.now.loc;
            if (!loc) {
              return null;
            }
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [loc.lon, loc.lat],
              },
              properties: {
                _id: device.device._id,
                ...device,
              },
            };
          })
          .filter((d) => d),
      };
      if (source.current) {
        try {
          if (map.current.getSource('devices')) {
            map.current.removeLayer('clusters');
            map.current.removeLayer('cluster-count');
            map.current.removeLayer('unclustered-point');
            map.current.removeSource('devices');
          }
        } catch (err) {
          console.error(err);
        }
      }
      map.current.addSource('devices', {
        type: 'geojson',
        data: _geoJson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });
      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'devices',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#203448',
            4,
            '#203448',
            7,
            '#203448',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 15, 4, 20, 7, 25],
          'circle-stroke-width': 1,
          'circle-stroke-color': 'white',
          'circle-stroke-opacity': 1,
        },
      });
      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'devices',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
        },
        paint: {
          'text-color': 'white',
        },
      });
      map.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'devices',
        filter: ['!has', 'point_count'],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
      source.current = _geoJson;
    }
  }, [devices, mapLoaded]);

  const windowDimensions = useSelector(
    (store) => store.windowDimensions,
    shallowEqual,
  );
  return (
    <>
      <div id="mapView">
        <div className="toggle-sat">
          {isSatelliteMode ? (
            <Button
              icon={<CompassOutlined />}
              onClick={() => {
                prev.current = null;
                source.current = null;
                setMapLoaded(0);
                setIsSatelliteMode(false);
              }}
            >
              Normal
            </Button>
          ) : (
            <Button
              icon={<RocketOutlined />}
              onClick={() => {
                prev.current = null;
                source.current = null;
                setMapLoaded(0);
                setIsSatelliteMode(true);
              }}
            >
              Satellite
            </Button>
          )}
        </div>
      </div>
      <style jsx global>{`
        #mapView {
          min-height: 300px;
          height: 70vh;
          position: relative;
        }
        .toggle-sat {
          position: absolute;
          z-index: 10000;
          top: 16px;
          left: 16px;
        }
      `}</style>
    </>
  );
}

export default DeviceMap;
