import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsCategoryTable from './SettingsCategoryTable';
import {
  FallDetectAlgorithmNos,
  FallDetectSampleRate,
  TenantSettings,
} from './constants';
import EditCheckboxEnabledModal from './edit-modals/EditCheckboxEnabledModal';
import EditCheckboxYesModal from './edit-modals/EditCheckboxYesModal';
import EditMultiChoiceModal from './edit-modals/EditMultiChoiceModal';
import EditNumberSecondsModal from './edit-modals/EditNumberSecondsModal';
import SelectFallDetectAlgorithm from './edit-modals/SelectFallDetectAlgorithm';
import {
  displayEnabledDisabledSetting,
  displayMultiChoice,
  displaySampleRateSetting,
  displayYesNoSetting,
  getUnifiedSetting,
  makeAuditStatusRow,
} from './utils';

const { Title, Text } = Typography;

function FallDetectionSettingsTable({
  deviceConfig,
  tenantConfig,
  systemConfig,
  audit,
  loading,
  isDevice,
  isTenantAdmin,
}) {
  const makeSettingRow = useCallback(
    (table, key, label, displayFunc, editModal) => {
      const [value, src] = getUnifiedSetting(
        systemConfig,
        tenantConfig,
        deviceConfig,
        table,
        key,
      );
      return {
        key,
        label,
        value: displayFunc(value),
        src,
        editModal,
        isEditable: !!editModal,
      };
    },
    [systemConfig, tenantConfig, deviceConfig],
  );
  const tableData = useMemo(() => {
    if (
      (!isDevice || (deviceConfig && audit)) &&
      tenantConfig &&
      systemConfig
    ) {
      return [
        makeAuditStatusRow(audit, 'fallDetection'),
        {
          key: 'system._id',
          label: 'System Config Version',
          value: systemConfig.fallDetection?._id,
          editable: false,
        },
        {
          key: 'tenant._id',
          label: 'Tenant Config Version',
          value: tenantConfig.fallDetection?._id,
          editable: false,
        },
        isDevice && {
          key: 'device._id',
          label: 'Device Config Version',
          value: deviceConfig.fallDetection?._id,
          editable: false,
        },
        makeSettingRow(
          'fallDetection',
          'enabled',
          'Fall Detection Enabled',
          (v) => displayEnabledDisabledSetting(v),
          <EditCheckboxEnabledModal
            table="fallDetection"
            settingKey="enabled"
            label="Fall Detection"
          />,
        ),
        makeSettingRow(
          'fallDetection',
          'dataLengthBefore',
          'Data Length Before',
          (v) =>
            v != undefined && displaySampleRateSetting(v, FallDetectSampleRate),
          <EditNumberSecondsModal
            table="fallDetection"
            settingKey="dataLengthBefore"
            label="Data Length Before"
            initialValue={750}
            sampleRate={FallDetectSampleRate}
          />,
        ),
        makeSettingRow(
          'fallDetection',
          'dataLengthAfter',
          'Data Length After',
          (v) =>
            v != undefined && displaySampleRateSetting(v, FallDetectSampleRate),
          <EditNumberSecondsModal
            table="fallDetection"
            settingKey="dataLengthAfter"
            label="Data Length After"
            initialValue={750}
            sampleRate={FallDetectSampleRate}
          />,
        ),
        makeSettingRow(
          'fallDetection',
          'algorithmNo',
          'Fall Detect Algorithm',
          (v) => displayMultiChoice(v, FallDetectAlgorithmNos),
          <EditMultiChoiceModal
            table="fallDetection"
            settingKey="algorithmNo"
            label="Fall Detect Algorithm"
            choices={FallDetectAlgorithmNos}
            SelectComponent={SelectFallDetectAlgorithm}
          />,
        ),
        makeSettingRow(
          'fallDetection',
          'verifyFallOnDevice',
          'Verify Fall on Device',
          (v) => displayYesNoSetting(v),
          <EditCheckboxYesModal
            table="fallDetection"
            settingKey="verifyFallOnDevice"
            label="Verify Fall on Device"
            initialValue={false}
          />,
        ),
      ]
        .filter((f) => f)
        .filter((row) => {
          if (isTenantAdmin) {
            return TenantSettings.fallDetection.includes(row.key);
          }
          return row;
        });
    }
    return [];
  }, [
    deviceConfig,
    tenantConfig,
    systemConfig,
    audit,
    makeSettingRow,
    isDevice,
    isTenantAdmin,
  ]);

  return (
    <>
      <Title level={2}>Fall Detection Settings</Title>
      <SettingsCategoryTable
        systemConfig={systemConfig}
        tenantConfig={tenantConfig}
        deviceConfig={deviceConfig}
        tableData={tableData}
        loading={loading}
      />
    </>
  );
}

export default FallDetectionSettingsTable;
