import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Tabs, DatePicker, Space, Button, Typography } from 'antd';
import Plot from 'react-plotly.js';
import { useManyRemote } from '../../../../shared/use-many-remote';
import { searchJoonEventsQuery } from '../../constants';
import settings from '../../../../settings';
import dayjs from 'dayjs';

const { Title } = Typography;
const lightBlueColor = 'rgb(31, 119, 180)';

const plotConfig = {
  responsive: true,
  scrollZoom: true,
  displayModeBar: true,
  useResizeHandler: true,
};

function GraphsTab({ joonDevice }) {
  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 20);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      from: fromDate.getTime(),
      until: untilDate.getTime(),
      deviceId: joonDevice._id,
    };
  }, [joonDevice]);

  const filters = useRef(initialFilters);
  const {
    error,
    loading,
    data: events,
    refetch,
  } = useManyRemote(
    searchJoonEventsQuery,
    (data) => data.searchJoonEvents,
    settings.querySize,
    filters.current,
    [{ key: 'timestampMs', order: 'DESC' }],
  );

  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'days'));
  const [toDate, setToDate] = useState(dayjs());
  const [layout, setLayout] = useState({
    xaxis: { title: 'Timestamp' },
    yaxis: { title: '' },
    width: 1200,
    height: 600,
    dragmode: 'zoom',
    fixedrange: false,
  });

  useEffect(() => {
    if (fromDate && toDate) {
      filters.current = {
        ...filters.current,
        from: fromDate.toDate().getTime(),
        until: toDate.toDate().getTime(),
      };
      refetch();
    }
  }, [fromDate, toDate, refetch]);

  const handleQuickSelect = (days) => {
    setFromDate(dayjs().subtract(days, 'days'));
    setToDate(dayjs());
  };

  const getBatteryColor = (batteryLevel) => {
    if (batteryLevel > 50) {
      return 'rgb(31, 119, 180)';
    } else if (batteryLevel > 20) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const plotData = useCallback(
    ({ yFunc, yLabel, yMax, colorFunc, filterFunc }) => {
      const xData = events
        .filter(filterFunc)
        .map((event) => new Date(event.timestampMs));
      const yData = events.filter(filterFunc).map(yFunc);
      const markerColors = yData.map((yValue) =>
        colorFunc ? colorFunc(yValue) : lightBlueColor,
      );
      return {
        x: xData,
        y: yData,
        type: 'scatter',
        mode: 'lines+markers',
        connectgaps: true,
        marker: { color: markerColors },
        line: { color: markerColors, width: 2 },
        name: yLabel,
      };
    },
    [events],
  );
  const plotLayout = useCallback(
    (title, yLabel) => {
      const validFromDate = fromDate
        ? fromDate.toDate()
        : dayjs().subtract(20, 'days').toDate();
      const validToDate = toDate ? toDate.toDate() : dayjs().toDate();
      return {
        ...layout,
        title,
        xaxis: {
          ...layout.xaxis,
          range: [validFromDate, validToDate],
        },
        yaxis: {
          ...layout.yaxis,
          title: yLabel,
        },
      };
    },
    [fromDate, toDate, layout],
  );

  const items = useMemo(() => {
    return [
      {
        label: 'Cell Signal',
        key: 'signalLevel',
        children: (
          <Plot
            data={[
              plotData({
                yFunc: (event) => event.signalLevel,
                yLabel: 'Signal Level',
                yMax: 0,
                filterFunc: (a) => a,
              }),
            ]}
            layout={plotLayout('Cell Signal', 'Signal Level (dBm)')}
            config={plotConfig}
          />
        ),
      },
      {
        label: 'Battery Level',
        key: 'batteryLevel',
        children: (
          <Plot
            data={[
              plotData({
                yFunc: (event) => event.batteryLevel,
                yLabel: 'Battery Level',
                yMax: 100,
                colorFunc: getBatteryColor,
                filterFunc: (a) => a.batteryLevel,
              }),
            ]}
            layout={plotLayout('Battery Level', 'Battery Percent (%)')}
            config={plotConfig}
          />
        ),
      },
      {
        label: 'Steps Since Midnight',
        key: 'stepsSinceMidnight',
        children: (
          <Plot
            data={[
              plotData({
                yFunc: (event) => event.stepsSinceMidnight,
                yLabel: 'Steps Since Midnight',
                yMax: 50000,
                colorFunc: () => lightBlueColor,
                filterFunc: (a) => a.stepsSinceMidnight !== undefined,
              }),
            ]}
            layout={plotLayout('Steps Since Midnight', 'Steps')}
            config={plotConfig}
          />
        ),
      },
      // {
      //   label: 'Latest Ping',
      //   key: 'latestPingMs',
      //   children: (
      //     <Plot
      //       data={[
      //         plotData(
      //           'latestPingMs',
      //           'Latest Ping',
      //           24 * 365,
      //           () => lightBlueColor,
      //           (a) => a.type === 'PING',
      //         ),
      //       ]}
      //       layout={plotLayout('Latest Ping', 'Hours Since Last Ping')}
      //       config={plotConfig}
      //     />
      //   ),
      // },
      {
        label: 'Heartbeats',
        key: 'heartbeatIntervalMs',
        children: (
          <Plot
            data={[
              plotData({
                yFunc: (event) => 1,
                yLabel: 'Heartbeats',
                yMax: 100,
                colorFunc: () => lightBlueColor,
                filterFunc: (a) => a.type === 'HB',
              }),
            ]}
            layout={plotLayout('Heartbeats', 'Heartbeats')}
            config={plotConfig}
          />
        ),
      },
    ];
  }, [plotData, plotLayout]);

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <DatePicker
          value={fromDate}
          onChange={(date) => setFromDate(date)}
          showTime
          format="YYYY-MM-DD HH:mm:ss"
        />
        <DatePicker
          value={toDate}
          onChange={(date) => setToDate(date)}
          showTime
          format="YYYY-MM-DD HH:mm:ss"
        />
        <Button onClick={() => handleQuickSelect(1)}>Last 1 Day</Button>
        <Button onClick={() => handleQuickSelect(7)}>Last 7 Days</Button>
      </Space>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        style={{ height: 700 }}
        items={items}
      />
    </div>
  );
}

export default GraphsTab;
