import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createJoonDeviceAction,
  deleteJoonDeviceAction,
  updateJoonDeviceAction,
} from '../../../redux-store/joon-devices-store';

const TenantDeviceAttributes = gql`
  {
    _id
    desc
    tags
    tenantId
    ownerId
    ownerName
    wearerId
    wearerName
    serialNo
    imsi
    eid
    iccid
    msisdn
    callCenterId
    fwVer
    appVer
    appFlavor
    fallAppVer
    fallAppFlavor
    confVer
    auditStatus
    updatedAt
  }
`;

const TenantDeviceUpdateAttributes = gql`
{
  type
  crud
  id
  new ${TenantDeviceAttributes}
}
`;

const allTenantJoonDeviceUpdatesSubscription = gql`
  subscription AllTenantJoonDeviceUpdates($tenantId: ID!) {
    allTenantJoonDeviceUpdates(tenantId: $tenantId) ${TenantDeviceUpdateAttributes}
  }
`;

function useTenantDeviceUpdates(tenantId) {
  const queryId = tenantId || 'default';
  const dispatch = useDispatch();
  useSubscription(allTenantJoonDeviceUpdatesSubscription, {
    skip: !tenantId,
    variables: {
      tenantId,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const updates = result?.data?.allTenantJoonDeviceUpdates;
        updates
          .filter((u) => u)
          .forEach((update) => {
            switch (update.type) {
              case 'JOON_DEVICE_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createJoonDeviceAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateJoonDeviceAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteJoonDeviceAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useTenantDeviceUpdates;
