import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs, Typography, notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { updateJoon3ReportAction } from '../../../redux-store/joon3-reports-store';
import settings from '../../../settings';
import useOne from '../../../shared/use-one';
import DevicesTab from './DevicesTab';
import FilterLabels from './FilterLabels';
import GraphsTab from './GraphsTab';
import LoadingModal from './LoadingModal';
import ReportTags from './ReportTags';
import { joon3ReportQuery } from './constants';
import useNightlyFilters from './use-nightly-filters';

const { Title, Text } = Typography;

const queryId = 'reportsDashboard';

function ViewNightlyReportPage() {
  const { _id, _tab } = useParams();
  const locale = useSelector((store) => store.locale, shallowEqual);

  const fetchResult = useOne(
    joon3ReportQuery,
    (data) => data && data.joon3Report,
    updateJoon3ReportAction,
    'joon3Reports',
    _id,
    queryId,
    undefined,
    'cache-first',
  );
  const { data: report, loading, error } = fetchResult;

  const [notificationApi, contextHolder] = notification.useNotification();
  useEffect(() => {
    if (error) {
      console.error(error);
      notificationApi.error({
        description: 'There was an error fetching your nightly report',
        message: 'Error',
      });
    }
  }, [notificationApi, error]);

  const title = useMemo(() => {
    if (report) {
      const datetime = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
        timeStyle: 'short',
      }).format(new Date(report.createdAt));
      return (
        <>
          <Title className="cs-header-title" style={{ marginBottom: 8 }}>
            {report.name}
          </Title>
          <div>
            <Text style={{ fontSize: 18, color: settings.colors.primary }}>
              {datetime}
            </Text>
          </div>
          {report && <ReportTags report={report} queryId={queryId} />}
        </>
      );
    }
    return (
      <Title className="cs-header-title" style={{ marginBottom: 8 }}>
        Theora Connect Nightly Report
      </Title>
    );
  }, [report, locale]);

  const unfiltered = report?.deviceReports;
  const { filtered, filterLabels } = useNightlyFilters(unfiltered, report);
  const history = useHistory();

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      {contextHolder}
      <CSPageHeader
        backActions={[
          <Link to="/report-dashboard/reports" key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Reports Dashboard
            </Button>
          </Link>,
        ]}
        titleComponent={title}
      />
      <LoadingModal loading={loading} />
      <FilterLabels filterLabels={filterLabels} />
      <Tabs
        defaultActiveKey="graphs"
        activeKey={_tab}
        onChange={(key) =>
          history.push(`/report-dashboard/view/${_id}/${key}${location.search}`)
        }
        size="large"
      >
        <Tabs.TabPane tab="Graphs" key="graphs">
          <GraphsTab
            report={report}
            loading={loading}
            unfiltered={unfiltered}
            filtered={filtered}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Devices" key="devices">
          <DevicesTab reports={filtered} loading={loading} />
        </Tabs.TabPane>
      </Tabs>
    </CSPage>
  );
}

export default ViewNightlyReportPage;
