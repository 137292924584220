import { useMutation } from '@apollo/client';
import { Form, Input, Modal, Typography, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  createWebhookAction,
  updateWebhookAction,
} from '../../../redux-store/webhooks-store';
import useIsCreating from '../../../shared/use-is-creating';
import SelectTenant from '../../tenants/SelectTenant';
import SelectWebhookType from '../SelectWebhookType';
import { createWebhookMutation, updateWebhookMutation } from '../constants';

const { Text } = Typography;

function EditWebhookModal({ tenantId, webhook, setWebhook }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateWebhook] = useMutation(updateWebhookMutation);
  const [createWebhook] = useMutation(createWebhookMutation);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useEffect(() => {
    if (webhook) {
      const { ...otherFields } = webhook;
      form.setFieldsValue(otherFields);
    }
  }, [form, webhook]);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const handleBack = () => {
    form.resetFields();
    setWebhook(null);
  };

  const onSubmit = useCallback(
    async (_values) => {
      const { ...values } = _values;
      setLoading(true);
      setError(null);
      try {
        if (webhook && !webhook.creating) {
          const result = await updateWebhook({
            variables: {
              webhook: {
                _id: webhook._id,
                ...values,
              },
            },
          });
          dispatch(updateWebhookAction(result.data.updateWebhook.webhook));
          notification.success({
            message: 'Saved',
            description: 'Webhook updated successfully',
          });
        } else {
          const result = await createWebhook({
            variables: {
              webhook: {
                _id: webhook._id,
                ...values,
              },
            },
          });
          dispatch(createWebhookAction(result.data.createWebhook.webhook));
          notification.success({
            message: 'Saved',
            description: 'Webhook created successfully',
          });
        }
        setWebhook(null);
        form.resetFields();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [form, webhook, setWebhook, createWebhook, updateWebhook, dispatch],
  );

  const { title, showDelete } = useIsCreating(
    location,
    webhook,
    loading,
    'Create New Webhook',
    'Edit Webhook',
  );

  return (
    <>
      <Modal
        title={`Edit Webhook`}
        open={!!webhook}
        closable
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={handleBack}
        cancelText="Cancel"
        okText="Save Webhook"
        onOk={() => form.submit()}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
      >
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editWebhook"
          form={form}
          style={{ maxWidth: 500 }}
        >
          <Form.Item
            label="Webhook Name"
            name="name"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input ref={labelInput} disabled={loading} />
          </Form.Item>
          {!tenantId && (
            <Form.Item
              label="Tenant"
              name="tenantId"
              rules={[{ required: true, message: 'Please select a tenant' }]}
            >
              <SelectTenant disabled={loading} />
            </Form.Item>
          )}
          <Form.Item
            label="Webhook Type"
            name="type"
            rules={[
              { required: true, message: 'Please select a trigger action' },
            ]}
          >
            <SelectWebhookType disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Target URL"
            extra="Must be a valid and secured HTTPS URL"
            name="target"
            rules={[
              { required: true, message: 'This field is required' },
              {
                pattern:
                  /https\:\/\/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
                message: 'Please enter a valid https URL',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsError }) => {
              const errorList = getFieldsError();
              let showError = false;
              errorList.forEach((errors) => {
                if (errors.errors.length) {
                  showError = true;
                }
              });
              return (
                showError && (
                  <Text type="danger" style={{ marginTop: 16 }}>
                    Please correct the errors above
                  </Text>
                )
              );
            }}
          </Form.Item>
          <div style={{ height: 16 }} />
          {error && (
            <div style={{ marginTop: 16 }}>
              <Text type="danger">{error}</Text>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditWebhookModal;
