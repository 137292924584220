import { useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createGeofenceAction,
  deleteGeofenceAction,
  updateGeofenceAction,
} from '../../../../redux-store/geofences-store';
import { geofenceSubscription } from './constants';

function useGeofenceUpdates(deviceId) {
  const queryId = deviceId || 'default';
  useSubscription(geofenceSubscription, {
    variables: {
      deviceId,
    },
    skip: !deviceId,
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        if (result && result.data && result.data.geofenceUpdates) {
          const updates = result.data.geofenceUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'GEOFENCE_CRUD': {
                switch (update.crud) {
                  case 'create':
                    dispatch(createGeofenceAction(update.new, queryId));
                    break;
                  case 'read':
                    dispatch(updateGeofenceAction(update.new, queryId));
                    break;
                  case 'update':
                    dispatch(updateGeofenceAction(update.new, queryId));
                    break;
                  case 'delete':
                    dispatch(deleteGeofenceAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useGeofenceUpdates;
