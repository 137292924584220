import { useMemo } from 'react';

function useIsCreating(location, item, loading, createTitle, editTitle) {
  const result = useMemo(() => {
    let creating = true;
    if (item) {
      creating = false;
    } else if (loading) {
      if (
        location.state &&
        location.state.mode &&
        location.state.mode === 'create'
      ) {
        creating = true;
      } else {
        creating = false;
      }
    }
    return { title: creating ? createTitle : editTitle, showDelete: !creating };
  }, [loading, item, location, createTitle, editTitle]);
  return result;
}

export default useIsCreating;
