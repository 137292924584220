import { Select, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { readTenantsAction } from '../../redux-store/tenants-store';
import settings from '../../settings';
import useMany from '../../shared/use-many';
import { allTenantsQuery } from './constants';

const { Text } = Typography;

function SelectTenant({ disabled, value, onChange, filters, ...more }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: tenants,
    refetch,
  } = useMany(
    allTenantsQuery,
    (data) => data.allTenants,
    readTenantsAction,
    'tenants',
    ['_id', 'name', 'notes'],
    settings.querySize,
    filters,
    (a, b) => a.name.localeCompare(b.name, locale),
  );
  return (
    <Select
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a tenant"
      value={value}
      {...more}
    >
      {loading && (
        <Select.Option value="__loading__">
          <Spin spinning={loading} style={{ margin: 8 }} />
        </Select.Option>
      )}
      {!loading && error && (
        <Select.Option value="__error__">
          <Text type="danger">{error.message}</Text>
        </Select.Option>
      )}
      {!loading &&
        tenants.map((tenant) => (
          <Select.Option
            key={tenant._id}
            value={tenant._id}
            title={tenant.name}
          >
            <div className="tenant-option">
              <div>
                <Text>{tenant.name}</Text>
              </div>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectTenant;
