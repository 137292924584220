import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { readRolesAction } from '../../../redux-store/roles-store';
import settings from '../../../settings';
import useMany from '../../../shared/use-many';
import { generateUuid } from '../../../shared/utils';
import { allRolesQuery } from '../constants';

const { Title, Text } = Typography;

function ListRoles() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleUpdate = useCallback(
    (roleId) => () => {
      history.push(`role/edit/${roleId}`, {
        mode: 'update',
      });
    },
    [history],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: roles,
    refetch,
  } = useMany(
    allRolesQuery,
    (data) => data.allRoles,
    readRolesAction,
    'roles',
    ['name', 'desc', 'permissions'],
    settings.querySize,
    {},
    (a, b) => a.name.localeCompare(b.name, locale),
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );
  return (
    <CSPage title="Roles" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="Roles"
        topActions={[
          <Link
            key="add"
            to={{
              pathname: `role/edit/${generateUuid()}`,
              state: { mode: 'create' },
            }}
          >
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new role
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Table
        dataSource={roles}
        loading={!roles.length && loading}
        pagination={{ ...pagination, total: roles.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Description" dataIndex="desc" key="desc" />
        <Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.createdAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {/* <Tooltip title="View">
                <Button
                  onClick={() => history.push(`/deployment/${record.serialNo}`)}
                  icon={<EyeOutlined />}
                />
              </Tooltip> */}
              <Tooltip title="Edit">
                <Link
                  key="add"
                  to={{
                    pathname: `role/edit/${record._id}`,
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ListRoles;
