import React from 'react';
import MySliderFilter from './MySliderFilter';

function HoursSliderFilter({ name, label }) {
  return (
    <MySliderFilter
      name={name}
      label={label}
      minValue={-2000}
      maxValue={4000}
      marks={{
        '-2000': '36 s.',
        '-1000': '6 min.',
        0: '1 hrs.',
        1000: '10 hrs.',
        2000: '100 hrs.',
        3000: '~6 wks.',
        4000: '>1 yr.',
      }}
      isLog
    />
  );
}

export default HoursSliderFilter;
