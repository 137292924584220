export const READ_FIRMWARE_VERSIONS = 'READ_FIRMWARE_VERSIONS';
export const CREATE_FIRMWARE_VERSION = 'CREATE_FIRMWARE_VERSION';
export const UPDATE_FIRMWARE_VERSION = 'UPDATE_FIRMWARE_VERSION';
export const DELETE_FIRMWARE_VERSION = 'DELETE_FIRMWARE_VERSION';

export const readFirmwareVersionsAction = (
  // eslint-disable-next-line no-shadow
  firmwareVersions,
  queryId = 'default',
) => ({
  type: READ_FIRMWARE_VERSIONS,
  payload: {
    firmwareVersions,
    queryId,
  },
});

export const createFirmwareVersionAction = (
  firmwareVersion,
  queryId = 'default',
) => ({
  type: CREATE_FIRMWARE_VERSION,
  payload: {
    firmwareVersion,
    queryId,
  },
});

export const updateFirmwareVersionAction = (
  firmwareVersion,
  queryId = 'default',
) => ({
  type: UPDATE_FIRMWARE_VERSION,
  payload: {
    firmwareVersion,
    queryId,
  },
});

export const deleteFirmwareVersionAction = (
  firmwareVersionId,
  queryId = 'default',
) => ({
  type: DELETE_FIRMWARE_VERSION,
  payload: {
    firmwareVersionId,
    queryId,
  },
});

const initialFirmwareVersions = { default: {} };

export function firmwareVersions(state = initialFirmwareVersions, action) {
  switch (action.type) {
    case READ_FIRMWARE_VERSIONS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.firmwareVersions.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_FIRMWARE_VERSION:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.firmwareVersion._id]: action.payload.firmwareVersion,
        },
      };
    case UPDATE_FIRMWARE_VERSION: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.firmwareVersion._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.firmwareVersion._id]: {
            ...current,
            ...action.payload.firmwareVersion,
          },
        },
      };
    }
    case DELETE_FIRMWARE_VERSION: {
      const { firmwareVersionId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [firmwareVersionId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
