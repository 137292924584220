import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import React from 'react';

const ProfilePhoto = ({ thumbnailUrl, onUploadDelete, deleted }) => {
  return (
    <Space
      className="profile-photo"
      style={{ paddingRight: 0, alignItems: 'flex-start', marginBottom: 16 }}
    >
      {thumbnailUrl ? (
        <img className="profile-photo-img" src={thumbnailUrl} />
      ) : (
        <div className="placeholder">
          <UserOutlined style={{ fontSize: 24 }} />
        </div>
      )}
      {!deleted && (
        <Tooltip title="Remove">
          <Button
            onClick={onUploadDelete}
            icon={<DeleteOutlined />}
            type="text"
          />
        </Tooltip>
      )}
      <style jsx>{`
        .profile-photo-img {
          width: 200px;
        }
        .placeholder {
          height: 200px;
          width: 200px;
          border-radius: 100px;
          background: hsl(195deg 13% 90%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </Space>
  );
};

export default ProfilePhoto;
