import { SendOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { envNameQuery, requestHeartbeatMutation } from '../../constants';

const { Text } = Typography;

function SendRequestHeartbeatCommand({ joonDevice }) {
  const [form] = Form.useForm();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [requestHeartbeat] = useMutation(requestHeartbeatMutation);

  const handleSendCommand = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        await requestHeartbeat({
          variables: {
            _id: joonDevice._id,
          },
        });
        message.success('Location update requested');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [joonDevice, requestHeartbeat],
  );

  return (
    <Card
      title="Request Location Update"
      className="config-cmd"
      style={{ marginBottom: 16 }}
    >
      <Form
        onFinish={handleSendCommand}
        layout="vertical"
        style={{ maxWidth: 600 }}
        form={form}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsError }) => {
            const errorList = getFieldsError();
            let showError = false;
            errorList.forEach((errors) => {
              if (errors.errors.length) {
                showError = true;
              }
            });
            return (
              showError && (
                <Text type="danger" style={{ marginTop: 16 }}>
                  Please correct the errors above
                </Text>
              )
            );
          }}
        </Form.Item>
        <Form.Item>
          <Button icon={<SendOutlined />} loading={loading} htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <style jsx global>{`
        .config-cmd .ant-input {
          padding: 4px 8px;
        }
        .config-cmd .ant-form-item-control-input {
          min-height: 32px;
        }
        .config-cmd .ant-input-number-input {
          height: 32px;
        }
      `}</style>
    </Card>
  );
}

export default SendRequestHeartbeatCommand;
