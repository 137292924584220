import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectUser from '../../../users/SelectUser';
import { registerJoonDeviceMutation } from './constants';
import {
  formatPhoneNumber,
  validatePhoneNumber,
} from '../settings-v2/edit-modals/EditPhoneNoSettingModal';

const { Title, Text, Paragraph } = Typography;

const registerOwnerUserQuery = gql`
  query RegisterNewOwner($_id: ID!) {
    user(_id: $_id) {
      _id
      phone
    }
  }
`;

const RegisterNewOwnerModal = ({ visible, onSuccess, onCancel, queryId }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { joonDevice } = visible || {};

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  const ownerId = Form.useWatch('ownerId', form);
  const client = useApolloClient();
  useEffect(() => {
    async function doAsyncStuff() {
      const result = await client.query({
        query: registerOwnerUserQuery,
        variables: {
          _id: ownerId,
        },
        fetchPolicy: 'cache-first',
      });
      const phone = result?.data?.user?.phone;
      if (phone) {
        form.setFieldsValue({
          sosPhoneNumber: phone,
        });
      }
    }
    if (ownerId) {
      doAsyncStuff();
    }
  }, [ownerId, client, form]);

  const [registerJoonDevice] = useMutation(registerJoonDeviceMutation);

  const handleFinish = useCallback(
    async (_values) => {
      setLoading(true);
      const { sosPhoneNumber: _sosPhoneNumber, ...values } = _values;
      try {
        const sosPhoneNumber = formatPhoneNumber(_sosPhoneNumber);
        await registerJoonDevice({
          variables: {
            registration: {
              deviceId: joonDevice._id,
              ...values,
              sosPhoneNumber,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Device owner registered',
        });
        if (onSuccess) onSuccess();
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'There was an error registering a device owner',
        });
      }
      setLoading(false);
    },
    [registerJoonDevice, joonDevice, onSuccess],
  );

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Register New Owner
        </Title>

        <Form onFinish={handleFinish} layout="vertical" form={form}>
          <Form.Item
            label="Owner"
            name="ownerId"
            extra="Must be an existing user"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <SelectUser disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Wearer's Full Name"
            name="wearerName"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Relation to Wearer"
            name="relationToWearer"
            extra="e.g. Son, Daughter, Brother, Me"
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Device Name for This User"
            name="nickName"
            extra="Will be displayed on the owner's mobile app, e.g. Mom, Dad, My Watch"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="SOS Phone Number"
            name="sosPhoneNumber"
            rules={[
              validatePhoneNumber,
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={onCancel}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Register
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default RegisterNewOwnerModal;
