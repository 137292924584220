import { useQuery } from '@apollo/client';
import { Button, Spin, Typography } from 'antd';
import Plotly from 'plotly.js-dist';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import settings from '../../../../settings';
import { useManyRemote } from '../../../../shared/use-many-remote';
import { allJoonDeviceDataQuery, joonDatasetDataQuery } from '../../constants';
import {
  plot1sAngleDiffs,
  plot1sAveAves,
  plot1sIntegrals,
  plot1sRms,
  plot1sWindowFfts,
  plotDiffFromAve,
  plotFft,
  plotPeaksAndValleys,
  plotRawMotion,
  plotTotal,
  plotTotalDiffFromAve,
  setupPlot,
  windowAve,
  windowSum,
  windowTheWindow,
} from './plot-utils';

const { Title, Text } = Typography;

const PlotProps = [
  {
    source: 'rawMotion',
    label: 'Accel & Gyro Data',
    xtype: 'date',
    axes: ['ax', 'ay', 'az', 'gx', 'gy', 'gz', 'step', 'wt', 'pf', 'cmc'],
  },
  // {
  //   source: 'diffAve',
  //   label: 'Diff from ave per axis (y - 1sec window ave)',
  //   xtype: 'date',
  //   axes: ['ax', 'ay', 'az', 'gx', 'gy', 'gz'],
  // },
  // {
  //   source: 'fft',
  //   label: 'FFT',
  //   xtype: 'linear',
  //   axes: ['a', 'g'],
  // },
  // {
  //   source: 'fftWindows',
  //   label: '1s FFTs',
  //   xtype: 'date',
  //   axes: ['a', 'g'],
  // },
  {
    source: 'totalMotion',
    label: 'Combined Accel & Gyro Data',
    xtype: 'date',
    axes: ['a', 'g'],
  },
  // {
  //   source: 'aves',
  //   label: 'Average',
  //   xtype: 'date',
  //   axes: ['a', 'g', 'A 1s Average', 'G 1s Average'],
  // },
  // {
  //   source: 'falls',
  //   label: 'Fall Data (y - 1sec window ave)',
  //   xtype: 'date',
  //   axes: ['a', 'g'],
  // },
  // {
  //   source: 'totalDistance',
  //   label: 'Total Distance (integrate 1s windows)',
  //   xtype: 'date',
  //   axes: ['av', 'gv', 'ad', 'gd'],
  // },
  {
    source: 'rms',
    label: 'STD',
    xtype: 'date',
    axes: ['a', 'g'],
  },
  {
    source: 'longStd',
    label: 'Long STD',
    xtype: 'date',
    axes: ['a', 'g'],
  },
  // {
  //   source: 'angleDiff',
  //   label: 'Diff btw ave angle and y angle',
  //   xtype: 'date',
  //   axes: ['a', 'g'],
  // },
  {
    source: 'peakCount',
    label: 'Count the peaks in a second',
    xtype: 'date',
    axes: [
      'a',
      'ap',
      'av',
      'apw',
      'avw',
      'aw',
      'g',
      'gp',
      'gv',
      'gpw',
      'gvw',
      'gw',
    ],
  },
];

function JoonDatasetPlot({ datasetId, getData, height = 500 }) {
  const [plotted, setPlotted] = useState({});
  const first = useRef({});
  const _srCounter = useRef(0);
  const [srCounter, setSRCounter] = useState(0);
  const data = useRef({});

  getData.current = useCallback(() => {
    return data.current.rawMotion;
  }, []);

  const initialFilters = useMemo(() => {
    return {
      datasetId,
    };
  }, [datasetId]);

  const filters = useRef(initialFilters);
  const extract = useCallback((result) => result.allJoonDeviceData, []);
  const [num, setNum] = useState(5000);
  const { data: rawData, loading: dataLoading } = useQuery(
    joonDatasetDataQuery,
    {
      variables: {
        _id: datasetId,
      },
      fetchPolicy: 'network-only',
    },
  );
  const datasetData = rawData && rawData.joonDatasetData;
  const refetch = () => {};
  const search = () => {};
  const hasNextPage = false;
  const totalCount = 100;
  const next = () => {};
  // const {
  //   error: dataError,
  //   loading: dataLoading,
  //   data: datasetData,
  //   refetch,
  //   search: datasetSearch,
  //   hasNextPage,
  //   totalCount,
  //   next,
  // } = useManyRemote(allJoonDeviceDataQuery, extract, num, filters.current, [
  //   { key: 'timestampMs', order: 'ASC' },
  // ]);

  useEffect(() => {
    PlotProps.forEach(({ source, axes }) => {
      setupPlot(data, source, axes);
    });

    PlotProps.forEach((property) => {
      const { source } = property;
      if (!first.current[source]) {
        Plotly.newPlot(`plot_${source}`, [], {
          xaxis: {
            autorange: true,
            type: 'date',
          },
          yaxis: {
            autorange: true,
            type: 'linear',
          },
          margin: {
            l: 50,
            r: 25,
            b: 100,
            t: 25,
            pad: 4,
          },
          legend: {
            x: 1,
            xanchor: 'right',
            y: 1,
          },
        });
        first.current[source] = true;
      }
      Object.entries(data.current[source]).forEach(([anchorId, axes]) => {
        Plotly.addTraces(`plot_${source}`, {
          ...axes,
          type: 'scatter',
          mode:
            ['peakCount', 'rawMotion'].includes(source) &&
            ['ap', 'av', 'step', 'wt', 'pf', 'cmc'].includes(anchorId)
              ? 'markers'
              : undefined,
          name: `${anchorId}`,
        });
        first.current[anchorId] = true;
      });
    });
  }, []);

  const index = useRef(0);
  useEffect(() => {
    if (datasetData && datasetData.slice(index.current).length > 0) {
      const newData = datasetData.slice(index.current);

      const WindowLen = 25;

      plotRawMotion(newData, data, 'rawMotion');
      // plotDiffFromAve(newData, data, 'diffAve', WindowLen);
      plotTotal(newData, data, 'totalMotion');
      // plotTotalDiffFromAve(newData, data, 'falls', WindowLen);

      // plotFft(newData, data, 'fft', () => {
      //   _srCounter.current += 1;
      //   setSRCounter(_srCounter.current);
      // });
      // plot1sAveAves(newData, data, 'aves', WindowLen);

      // plot1sRmsTensor(newData, data, 'rms', WindowLen, () => {
      //   _srCounter.current += 1;
      //   setSRCounter(_srCounter.current);
      // });
      plot1sRms(data, 'rms', WindowLen);
      plot1sRms(data, 'longStd', WindowLen * 10);

      // plot1sIntegrals(data, 'totalDistance', WindowLen);
      // plot1sAngleDiffs(newData, data, 'angleDiff', WindowLen);
      plotPeaksAndValleys(data, 'peakCount', WindowLen, 5);

      // plot1sWindowFfts(newData, data, 'fftWindows', 50, () => {
      //   _srCounter.current += 1;
      //   setSRCounter(_srCounter.current);
      // });

      index.current = datasetData.length;
      _srCounter.current += 1;
      setSRCounter(_srCounter.current);
    }
  }, [datasetData]);

  const handleClear = useCallback(() => {
    data.current = {};
    PlotProps.forEach(({ source, axes }) => {
      setupPlot(data, source, axes);
    });
    _srCounter.current = 0;
    setSRCounter(0);
    index.current = 0;
  }, []);

  const containerRef = useRef();

  // console.log('data', data.current);

  useEffect(() => {
    try {
      PlotProps.forEach((property) => {
        const { source } = property;
        if (data.current[source]) {
          Plotly.update(
            `plot_${source}`,
            {
              x: Object.values(data.current[source]).map((a) => a.x),
              y: Object.values(data.current[source]).map((a) => a.y),
              text: Object.values(data.current[source]).map((a) =>
                a.y.map((y, i) => `${i}`),
              ),
            },
            {
              xaxis: {
                autorange: true,
                type: property.xtype,
              },
              yaxis: {
                autorange: true,
                type: 'linear',
              },
            },
            Object.values(data.current[source]).map((a, i) => i),
          );
        }
      });
      setPlotted(first.current);
    } catch (err) {
      console.error(err);
    }
  }, [srCounter]);

  return (
    <div ref={containerRef} style={{ marginBottom: 200 }}>
      {PlotProps.map((property) => (
        <div
          key={property.source}
          className="plot-holder"
          style={{
            width: '100%',
            height,
            position: 'relative',
          }}
        >
          <div className="plot-title">
            <Title level={3} style={{ marginBottom: 0, marginRight: 16 }}>
              {property.label}
            </Title>
          </div>
          <div
            id={`plot_${property.source}`}
            className="plot"
            style={{
              height,
            }}
          />
          {dataLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
            >
              <Spin spinning>
                <div style={{ height, background: settings.colors.ghost }} />
              </Spin>
            </div>
          )}
        </div>
      ))}
      {hasNextPage && (
        <div className="load-more">
          <Button onClick={() => next()} style={{ marginRight: 16 }}>
            Load More
          </Button>
          <Button
            onClick={() => {
              handleClear();
              setNum(totalCount);
            }}
          >
            Load Everything
          </Button>
        </div>
      )}
      <style jsx>{`
        .top-box {
          border: 1px solid ${settings.colors.borderGray};
          border-radius: 24px;
          padding: 16px 24px;
          margin-bottom: 24px;
        }
        .load-more {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
}

export default JoonDatasetPlot;
