import { shallowEqual, useSelector } from 'react-redux';
import { useManyRemote } from '../../../shared/use-many-remote';
import { useCallback, useMemo, useRef, useState } from 'react';
import { allJoon3ReportsQuery } from './constants';
import settings from '../../../settings';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

function NightlyReportsTable() {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }),
    [locale],
  );

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({});
  const {
    error,
    loading,
    data: reports,
    refetch,
    search: reportsSearch,
    hasNextPage,
    next,
  } = useManyRemote(
    allJoon3ReportsQuery,
    (data) => data.allJoon3Reports,
    settings.querySize,
    filters.current,
    sortBy.current,
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  return (
    <Table
      dataSource={reports}
      loading={!reports.length && loading}
      pagination={{ ...pagination, total: reports.length }}
      onChange={handleTableChange}
      style={{ width: '100%', marginTop: 16 }}
      rowKey="_id"
      scroll={{
        x: 800,
      }}
    >
      <Column title="Name" dataIndex="name" key="name" />
      <Column
        title="Tags"
        dataIndex="tags"
        key="tags"
        render={(text, record) => {
          return (
            record.tags &&
            record.tags.map((tag) => (
              <Tag
                key={tag}
                color={settings.colors.secondary}
                style={{ marginBottom: 4 }}
              >
                {tag}
              </Tag>
            ))
          );
        }}
      />
      <Column
        title="# of Devices"
        dataIndex="numDeviceReports"
        key="numDeviceReports"
      />
      <Column
        title="Created On"
        dataIndex="createdAt"
        render={(text, record) => {
          return dateFormat.format(new Date(record.createdAt));
        }}
      />
      <Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="View">
              <Link to={`/report-dashboard/view/${record._id}/graphs`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        )}
      />
    </Table>
  );
}

export default NightlyReportsTable;
