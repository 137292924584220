import { parsePhoneNumber } from 'awesome-phonenumber';

export function getFormattedPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    const parsed = parsePhoneNumber(phoneNumber, {
      regionCode: 'US',
    });
    return parsed && parsed.number && parsed.number.international;
  }
  return null;
}
