import { useMemo, useRef } from 'react';
import Fuse from 'fuse.js';
import { shallowEqual, useSelector } from 'react-redux';

const searchOptions = {
  includeScore: true,
  keys: ['name'],
};

function defaultSort(a, b) {
  if (a && a.name && b && b.name) {
    return a.name.localeCompare(b.name);
  }
  if (a && a.name) {
    return 1;
  }
  if (b && b.name) {
    return -1;
  }
  return 0;
}

function useFilterWebhooks({ filters, sortBy }) {
  const queryId = 'default';
  const data = useSelector(
    (store) =>
      (store.webhooks[queryId] && Object.values(store.webhooks[queryId])) || [],
    shallowEqual,
  );
  const lastResult = useRef();
  const jsonFilters = JSON.stringify(filters);
  const filtered = useMemo(() => {
    let _data = data.filter((i) => i);
    if (filters && filters.tenantId) {
      _data = _data.filter((i) => i.tenantId === filters.tenantId);
    }
    if (filters && filters.search) {
      const fuse = new Fuse(_data, searchOptions);
      _data = fuse
        .search(filters.search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      lastResult.current = _data;
    } else {
      lastResult.current = _data;
    }
    if (sortBy) {
      return lastResult.current.sort(sortBy);
    }
    return lastResult.current.sort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonFilters, data, sortBy]);
  return filtered;
}

export default useFilterWebhooks;
