import { gql, useQuery } from '@apollo/client';

export const callCenterCoMetadataQuery = gql`
  query CallCenterCoMetadata($_id: ID!) {
    callCenterCoMetadata(_id: $_id) {
      _id
      deviceRequiredFields
      tenantRequiredFields
      deviceEditableFields
      tenantEditableFields
    }
  }
`;

function useCallCenterCoMeta(callCenterCo) {
  const { loading, error, data, refetch } = useQuery(
    callCenterCoMetadataQuery,
    {
      variables: {
        _id: callCenterCo,
      },
      skip: !callCenterCo,
      fetchPolicy: 'network-only',
    },
  );
  const metadata = data?.callCenterCoMetadata;
  return metadata;
}

export default useCallCenterCoMeta;
