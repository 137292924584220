import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useChangeFilters() {
  const history = useHistory();
  const changeFilters = useCallback(
    (values) => {
      const search = `?${queryString.stringify(values)}`;
      history.push(`${window.location.pathname}${search}`);
    },
    [history],
  );
  return changeFilters;
}

export default useChangeFilters;
