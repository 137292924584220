import { Tabs } from 'antd';
import ASomethingPie from './ASomethingPie';
import ASomethingPlot, {
  getAsIsValue,
  getAsIsValueNoZero,
  getHour,
  getPercent,
  getSince,
} from './ASomethingPlot';
import HoursSliderFilter from './HoursSliderFilter';
import MySliderFilter from './MySliderFilter';
import { useMemo } from 'react';
import { notNullOrUndefined } from '../../../shared/utils';
import { displayDurationSetting } from '../../joonDevices/ViewJoonDevice/settings-v2/utils';
import { AuditStatuses } from '../../joonDevices/constants';

function GraphsTab({ loading, unfiltered, filtered, report }) {
  const items = useMemo(() => {
    return [
      {
        label: 'Tenants',
        key: 'tenantId',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="tenantId"
            idAttr="tenantId"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.tenantId) ? r.tenantName : noLabel
            }
            noLabel="No Tenant"
            filterLabel="Filter devices by tenant"
          />
        ),
      },
      {
        label: 'Firmware Version',
        key: 'fwVer',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="fwVer"
            idAttr="fwVer"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.fwVer) ? r.fwVer : noLabel
            }
            noLabel="No Firmware Version"
            filterLabel="Filter devices by firmware version"
          />
        ),
      },
      {
        label: 'App Version',
        key: 'appVer',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="appVer"
            idAttr="appVer"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.appVer) ? r.appVer : noLabel
            }
            noLabel="No App Version"
            filterLabel="Filter devices by app version"
          />
        ),
      },
      {
        label: 'Fall App Version',
        key: 'fallAppVer',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="fallAppVer"
            idAttr="fallAppVer"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.fallAppVer) ? r.fallAppVer : noLabel
            }
            noLabel="No App Version"
            filterLabel="Filter devices by fall app version"
          />
        ),
      },
      {
        label: 'Fall Detection Enabled',
        key: 'fallDetectionEnabled',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="fallDetectionEnabled"
            idAttr="fallDetectionEnabled"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.fallDetectionEnabled)
                ? r.fallDetectionEnabled
                  ? 'Enabled'
                  : 'Disabled'
                : noLabel
            }
            noLabel="Not Specified"
            filterLabel="Filter devices by fall detection enabled"
          />
        ),
      },
      {
        label: 'Falls Detected',
        key: 'fallStats.detected',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="fallStats.detected"
            idAttr="_id"
            labelAttr="fallStats"
            label="Falls Detected"
            getValue={(v, maxValue) => v.detected}
            maxValue={100}
            yAxisType="range"
            yAxisLabel="Occurrences"
            filter={
              <MySliderFilter
                name="fallStats.detected"
                label="Filter devices by falls detected"
                minValue={0}
                maxValue={100}
                marks={{
                  0: '1',
                  20: '20',
                  40: '40',
                  60: '60',
                  80: '80',
                  100: '100',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Falls Verified',
        key: 'fallStats.verified',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="fallStats.verified"
            idAttr="_id"
            labelAttr="fallStats"
            label="Falls Verified"
            getValue={(v, maxValue) => v.verified}
            maxValue={100}
            yAxisType="range"
            yAxisLabel="Occurrences"
            filter={
              <MySliderFilter
                name="fallStats.verified"
                label="Filter devices by falls verified"
                minValue={0}
                maxValue={100}
                marks={{
                  0: '1',
                  20: '20',
                  40: '40',
                  60: '60',
                  80: '80',
                  100: '100',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Falls Cancelled',
        key: 'fallStats.cancelled',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="fallStats.canceled"
            idAttr="_id"
            labelAttr="fallStats"
            label="Falls Cancelled"
            getValue={(v, maxValue) => v.canceled}
            maxValue={100}
            yAxisType="range"
            yAxisLabel="Occurrences"
            filter={
              <MySliderFilter
                name="fallStats.canceled"
                label="Filter devices by falls cancelled"
                minValue={0}
                maxValue={100}
                marks={{
                  0: '1',
                  20: '20',
                  40: '40',
                  60: '60',
                  80: '80',
                  100: '100',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Falls No Response',
        key: 'fallStats.no_response',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="fallStats.no_response"
            idAttr="_id"
            labelAttr="fallStats"
            label="Falls No Response"
            getValue={(v, maxValue) => v.no_response}
            maxValue={100}
            yAxisType="range"
            yAxisLabel="Occurrences"
            filter={
              <MySliderFilter
                name="fallStats.no_response"
                label="Filter devices by falls no response"
                minValue={0}
                maxValue={100}
                marks={{
                  0: '1',
                  20: '20',
                  40: '40',
                  60: '60',
                  80: '80',
                  100: '100',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Heartbeat Interval',
        key: 'heartbeatIntervalMs',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="heartbeatIntervalMs"
            idAttr="heartbeatIntervalMs"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.heartbeatIntervalMs)
                ? displayDurationSetting(r.heartbeatIntervalMs)
                : noLabel
            }
            noLabel="Not Specified"
            filterLabel="Filter devices by heartbeat interval"
          />
        ),
      },
      {
        label: 'Configuration Status',
        key: 'auditStatus',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="auditStatus"
            idAttr="auditStatus"
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.auditStatus)
                ? AuditStatuses[r.auditStatus]?.label || 'Bad Config Status'
                : noLabel
            }
            noLabel="Not Specified"
            filterLabel="Filter devices by config status"
          />
        ),
      },
      {
        label: 'Latest Ping',
        key: 'latestPingMs',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="latestPingMs"
            idAttr="_id"
            labelAttr="latestPingMs"
            label="Latest Ping"
            getValue={(v, maxValue) => {
              const now = new Date(report?.createdAt).getTime();
              return getSince(now, v, maxValue);
            }}
            maxValue={24 * 365}
            filter={
              <HoursSliderFilter
                name="latestPingMs"
                label="Filter devices by latest ping"
              />
            }
          />
        ),
      },
      {
        label: 'Latest Location',
        key: 'latestLocationMs',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="latestLocationMs"
            idAttr="_id"
            labelAttr="latestLocationMs"
            label="Latest Ping"
            getValue={(v, maxValue) => {
              const now = new Date(report?.createdAt).getTime();
              return getSince(now, v, maxValue);
            }}
            maxValue={24 * 365}
            filter={
              <HoursSliderFilter
                name="latestLocationMs"
                label="Filter devices by latest location"
              />
            }
          />
        ),
      },
      {
        label: 'Percent Missed Heartbeats',
        key: 'pMissedHeartbeats',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="pMissedHeartbeats"
            idAttr="_id"
            labelAttr="pMissedHeartbeats"
            label="Percent Missed Heartbeats"
            getValue={getPercent}
            maxValue={100}
            yAxisType="range"
            yAxisLabel="Percent Missed"
            filter={
              <MySliderFilter
                name="pMissedHeartbeats"
                label="Filter devices by percent heartbeats missed"
                minValue={0}
                maxValue={100}
                marks={{
                  0: '1',
                  20: '20',
                  40: '40',
                  60: '60',
                  80: '80',
                  100: '100',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Average Battery Life',
        key: 'aveBatteryLifeMs',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="aveBatteryLifeMs"
            idAttr="_id"
            labelAttr="aveBatteryLifeMs"
            label="Average Battery Life"
            getValue={getHour}
            maxValue={48}
            yAxisType="range"
            yAxisLabel="Hours"
            filter={
              <MySliderFilter
                name="aveBatteryLifeMs"
                label="Filter devices by average battery life"
                minValue={0}
                maxValue={48}
                marks={{
                  0: '0',
                  12: '12',
                  24: '24',
                  36: '36',
                  48: '48',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Average Signal Strength',
        key: 'aveSignal',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="aveSignal"
            idAttr="_id"
            labelAttr="aveSignal"
            label="Average Signal Strength"
            getValue={getAsIsValue}
            maxValue={0}
            yAxisType="range"
            yAxisLabel="Signal Strength"
            filter={
              <MySliderFilter
                name="aveSignal"
                label="Filter devices by average signal"
                minValue={-200}
                maxValue={-0}
                marks={{
                  '-200': '-200',
                  '-150': '-150',
                  '-100': '-100',
                  '-50': '-50',
                  0: '0',
                }}
              />
            }
          />
        ),
      },
      {
        label: 'Max Steps Since Midnight',
        key: 'maxStepsSinceMidnight',
        children: (
          <ASomethingPlot
            filteredReports={filtered}
            loading={loading}
            name="maxStepsSinceMidnight"
            idAttr="_id"
            labelAttr="maxStepsSinceMidnight"
            label="Max Steps Since Midnight"
            getValue={getAsIsValueNoZero}
            maxValue={50000}
            yAxisType="log"
            yAxisLabel="Steps"
            filter={
              <MySliderFilter
                name="maxStepsSinceMidnight"
                label="Filter devices by max steps since midnight"
                minValue={0}
                maxValue={5000}
                marks={{
                  0: '1',
                  1000: '10',
                  2000: '100',
                  3000: '1000',
                  4000: '10000',
                  5000: '100000',
                }}
                isLog
              />
            }
          />
        ),
      },
      {
        label: 'Suspected Lockups',
        key: 'suspectedLockups',
        children: (
          <ASomethingPie
            unfilteredReports={unfiltered}
            filteredReports={filtered}
            loading={loading}
            name="suspectedLockups"
            idAttr={(r) => r.suspectedLockups?.length || 0}
            makeLabel={(r, noLabel) =>
              notNullOrUndefined(r.suspectedLockups)
                ? r.suspectedLockups?.length || 0
                : noLabel
            }
            noLabel="Unknown"
            filterLabel="Filter devices # of suspected lockups"
          />
        ),
      },
    ];
  }, [filtered, unfiltered, loading, report]);
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="left"
      style={{ height: 700 }}
      items={items}
    />
  );
}

export default GraphsTab;
