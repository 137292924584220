import { Select, Typography } from 'antd';
import React from 'react';
import { WebhookTypes } from './constants';

const { Text } = Typography;

function SelectWebhookType({ disabled, value, onChange, more }) {
  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="Select a Webhook Trigger Action"
      {...more}
    >
      {Object.values(WebhookTypes).map((wt) => (
        <Select.Option key={wt.key} value={wt.key} title={wt.label}>
          <Text>{wt.label}</Text>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectWebhookType;
