import { Table, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../../settings';

const { Title, Text } = Typography;

function FirmwareStatus({ joon3 }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const tableData = useMemo(() => {
    if (joon3) {
      return [
        {
          key: 'fwVer',
          label: 'Firmware Version',
          value: joon3.fwVer,
        },
        {
          key: 'appVer',
          label: 'App Version',
          value: joon3.appVer,
        },
        {
          key: 'appFlavor',
          label: 'App Flavor',
          value: joon3.appFlavor,
        },
        {
          key: 'fallAppVer',
          label: 'Fall App Version',
          value: joon3.fallAppVer,
        },
        {
          key: 'fallAppFlavor',
          label: 'Fall App Flavor',
          value: joon3.fallAppFlavor,
        },
        {
          key: 'commVer',
          label: 'Comms. Version',
          value: joon3.commVer,
        },
        {
          key: 'updatedAt',
          label: 'Updated At',
          value: new Intl.DateTimeFormat(locale, {
            timeStyle: 'medium',
            dateStyle: 'short',
          }).format(new Date(joon3.updatedAt)),
        },
      ];
    }
    return undefined;
  }, [joon3, locale]);
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      <Title level={2} style={{ marginBottom: 24 }}>
        Firmware Status
      </Title>
      {!!tableData && (
        <Table
          dataSource={tableData}
          pagination={false}
          style={{ width: '100%' }}
          rowKey="key"
        >
          <Table.Column title="Name" dataIndex="label" key="label" />
          <Table.Column title="Value" dataIndex="value" key="value" />
        </Table>
      )}
      {!tableData && (
        <div
          style={{
            display: 'flex',
            height: 100,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              color: settings.colors.gray,
              fontSize: 18,
              fontWeight: 'bolder',
            }}
          >
            Device status not found.
          </Text>
        </div>
      )}
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default FirmwareStatus;
