import React, { useCallback, useState } from 'react';
import { Typography, Button, Card, Space, Spin, Table, Tooltip } from 'antd';
import CSPage from '../../components/CSPage';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { generateUuid } from '../../shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../settings';
import CSPageHeader from '../../components/CSPageHeader';
import { readApiKeysAction } from '../../redux-store/api-keys-store';
import useMany from '../../shared/use-many';
import { gql } from '@apollo/client';
import EditTenantApiKeyModal from './EditTenantApiKeyModal';
import DeleteApiKeyModal from './DeleteApiKeyModal';
import ShowPrivateKeyModal from './ShowPrivateKeyModal';
import { TenantApiKeyDetailedAttributes } from './constants';

export const allTenantApiKeysQuery = gql`
  query AllTenantApiKeys($first: Int, $after: String, $filters: ApiKeyFilters, $sortBy: [SortBy]) {
    allTenantApiKeys(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${TenantApiKeyDetailedAttributes}
      }
    }
  }
`;

const { Title, Text } = Typography;

function TenantApiKeysPage() {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const {
    error,
    loading,
    data: apiKeys,
    refetch,
  } = useMany(
    allTenantApiKeysQuery,
    (data) => data.allTenantApiKeys,
    readApiKeysAction,
    'apiKeys',
    ['label', 'desc', 'issuer', 'audience'],
    settings.querySize,
    {},
    (a, b) => a.label.localeCompare(b.label, locale),
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const [created, setCreated] = useState();
  const [deleting, setDeleting] = useState();
  const [editing, setEditing] = useState();
  const onCreate = () => setEditing({ creating: true });
  const onEdit = (apiKey) => setEditing({ apiKey });
  const onCancelEdit = () => setEditing(undefined);
  const onFinishEdit = (creating, apiKey) => {
    setEditing(undefined);
    if (creating) {
      setCreated(apiKey);
    }
  };
  const onDelete = (apiKey) => setDeleting(apiKey);
  const onFinishDelete = () => setDeleting(undefined);
  const onCancelDelete = () => setDeleting(undefined);
  const onPrivateKeyModalClosed = () => setCreated(undefined);

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="API Keys"
        topActions={[
          <Button
            key="add"
            type="link"
            icon={<PlusCircleOutlined />}
            size="large"
            onClick={onCreate}
          >
            Add a new API key
          </Button>,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Table
        dataSource={apiKeys}
        loading={!apiKeys.length && loading}
        pagination={{ ...pagination, total: apiKeys.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        scroll={{ x: 800 }}
        rowKey="_id"
      >
        <Table.Column title="Label" dataIndex="label" key="label" />
        <Table.Column title="Description" dataIndex="desc" key="desc" />
        <Table.Column
          title="Tenant"
          key="tenant"
          render={(text, record) => record.tenant?.name}
        />
        <Table.Column
          title="Role"
          dataIndex="role"
          render={(text, record) => {
            return record.role.name;
          }}
        />
        <Table.Column
          title="Created"
          dataIndex="createdAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.createdAt));
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {/* <Tooltip title="View">
                <Button
                  onClick={() => history.push(`/deployment/${record.serialNo}`)}
                  icon={<EyeOutlined />}
                />
              </Tooltip> */}
              <Tooltip title="Edit">
                <Button
                  onClick={() => onEdit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  onClick={() => onDelete(record)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <EditTenantApiKeyModal
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeleteApiKeyModal
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
        apiKey={deleting}
      />
      <ShowPrivateKeyModal apiKey={created} onYes={onPrivateKeyModalClosed} />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
      `}</style>
    </CSPage>
  );
}

export default TenantApiKeysPage;
