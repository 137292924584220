import React from 'react';
import { Select } from 'antd';
import useFilterGeofences from './use-filter-geofences';

function SelectSafeZone({ deviceId, value, onChange, ...other }) {
  const safeZones = useFilterGeofences({
    deviceId,
  });
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select a safe zone"
      allowClear
      {...other}
    >
      {safeZones.map((field) => (
        <Select.Option key={field._id} value={field._id}>
          {field.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectSafeZone;
