import { gql } from '@apollo/client';

export const WebhookTypes = {
  JOON_DEVICE_UPDATES: {
    key: 'JOON_DEVICE_UPDATES',
    label: 'Theora Connect Updates',
  },
};

export const WebhookDetailedAttributes = gql`
  {
    _id
    name
    tenantId
    tenant {
      _id
      name
    }
    target
    type
    publicKey
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const WebhookUpdateAttributes = gql`
{
  type
  crud
  id
  new ${WebhookDetailedAttributes}
}
`;

export const webhookQuery = gql`
  query Webhook($_id: ID!) {
    webhook(_id: $_id) ${WebhookDetailedAttributes}
  }
`;

export const allWebhooksQuery = gql`
  query AllWebhooks($first: Int, $after: String, $filters: WebhookFilters, $sortBy: [SortBy]) {
    allWebhooks(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${WebhookDetailedAttributes}
      }
    }
  }
`;

export const deleteWebhookMutation = gql`
  mutation deleteWebhook($_id: ID!) {
    deleteWebhook(_id: $_id) {
      deletedId
    }
  }
`;

export const createWebhookMutation = gql`
  mutation createWebhook($webhook: WebhookCreateInput!) {
    createWebhook(webhook: $webhook) {
      webhook ${WebhookDetailedAttributes}
    }
  }
`;

export const updateWebhookMutation = gql`
  mutation updateWebhook($webhook: WebhookUpdateInput!) {
    updateWebhook(webhook: $webhook) {
      webhook ${WebhookDetailedAttributes}
    }
  }
`;

export const webhookSubscription = gql`
subscription WebhookUpdates($tenantId: ID) {
  webhookUpdates(tenantId: $tenantId) ${WebhookUpdateAttributes}
}
`;
