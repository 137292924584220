import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import settings from '../../../settings';
import { useManyRemote } from '../../../shared/use-many-remote';
import { allJoonDeviceCellUsagesQuery } from '../constants';
import Chart from 'chart.js/auto';
import { Button, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { ExportOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ChartsToShow = [
  {
    title: 'Voice',
    type: 'VOICE',
    chartId: 'mySimpleVoiceChart',
    label: (context) => {
      return context.raw > 0.5 ? 'Used' : 'Not Used';
    },
  },
  {
    title: 'Data',
    type: 'DATA',
    chartId: 'mySimpleDataChart',
    label: (context) => {
      return context.raw > 0.5 ? 'Used' : 'Not Used';
    },
  },
  {
    title: 'SMS',
    type: 'SMS',
    chartId: 'mySimpleSmsChart',
    label: (context) => {
      return context.raw > 0.5 ? 'Used' : 'Not Used';
    },
  },
];

function SimpleUsage({ joonDevice, handleExportToCsv }) {
  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      from: fromDate.getTime(),
      until: untilDate.getTime(),
      imei: joonDevice._id,
    };
  }, [joonDevice]);

  const [filters, setFilters] = useState(initialFilters);
  const {
    error,
    loading,
    data: usages,
    refetch,
    search: usageSearch,
  } = useManyRemote(
    allJoonDeviceCellUsagesQuery,
    (data) => data.allJoonDeviceCellUsages,
    settings.querySize,
    filters,
    [{ key: 'timestampMs', order: 'ASC' }],
  );
  const containerRef = useRef();
  const [width, setWidth] = useState();
  const myChart = useRef({});
  const locale = useSelector((store) => store.locale, shallowEqual);
  useLayoutEffect(() => {
    setWidth(containerRef.current.offsetWidth);
    setTimeout(() => {
      ChartsToShow.forEach((details) => {
        let data = [];
        let labels = [];
        if (usages && usages.length) {
          data = usages
            .filter((d) => d.type === details.type)
            .map((d) => (d.totalUsage > 0 ? 1 : 0.25));
          const dateFormatter = new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          });
          labels = usages
            .filter((d) => d.type === details.type)
            .map((d) => dateFormatter.format(new Date(d.timestampMs)));
        }
        const ctx = document.getElementById(details.chartId);
        const backgroundColor = data.map((d) =>
          d > 0.5 ? '#52c41a1f' : '#e7282f1f',
        );
        const borderColor = data.map((d) => (d > 0.5 ? '#52c41a' : '#e7282f'));
        if (myChart.current[details.chartId]) {
          myChart.current[details.chartId].destroy();
        }
        myChart.current[details.chartId] = new Chart(ctx, {
          type: 'bar',
          data: {
            labels,
            datasets: [
              {
                label: details.title,
                data,
                backgroundColor,
                borderColor,
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: details.label,
                },
              },
            },
          },
        });
      });
    }, 0);
  }, [usages, locale]);

  handleExportToCsv.current = useCallback(() => {
    if (usages && usages.length) {
      const data = [];
      const dateFormatter = new Intl.DateTimeFormat(locale, {
        dateStyle: 'short',
      });
      ChartsToShow.forEach((details) => {
        usages
          .filter((d) => d.type === details.type)
          .forEach((d) => {
            data.push({
              IMEI: joonDevice._id,
              'Serial No.': joonDevice.serialNo,
              ICCID: joonDevice.iccid,
              IMSI: joonDevice.imsi,
              'Data Type': details.title,
              Date: dateFormatter.format(new Date(d.timestampMs)),
              Usage: d.totalUsage > 0 ? 'Used' : 'Not Used',
            });
          });
      });
      const options = mkConfig({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showColumnHeaders: true,
        useTextFile: false,
        useBom: true,
        filename: `SimpleUsage-${dateFormatter
          .format(new Date(usages[usages.length - 1].timestampMs))
          .replace(/\//g, '-')}-IMEI${joonDevice._id}`,
        columnHeaders: [
          'IMEI',
          'Serial No.',
          'ICCID',
          'IMSI',
          'Data Type',
          'Date',
          'Usage',
        ],
      });

      const csv = generateCsv(options)(data);
      download(options)(csv);
    }
  }, [usages, joonDevice, locale]);
  return (
    <div ref={containerRef}>
      {ChartsToShow.map((details) => (
        <div key={details.chartId} style={{ marginBottom: 16 }}>
          <Title level={2}>{details.title}</Title>
          {width !== undefined && (
            <canvas id={details.chartId} width={width} height={300} />
          )}
        </div>
      ))}
      <style jsx>{``}</style>
    </div>
  );
}

export default SimpleUsage;
