import { gql } from '@apollo/client';

export const Joon3ContactAttributes = gql`
  {
    _id
    deviceId
    tenantId
    name
    phone
    profilePhotoId
    profilePhoto {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    primaryThumbnailUrl
    userId
    isDisplayed
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const createJoon3ContactMutation = gql`
  mutation CreateJoon3Contact($contact: Joon3ContactCreateInput!) {
    createJoon3Contact(contact: $contact) {
      contact ${Joon3ContactAttributes}
    }
  }
`;

export const updateJoon3ContactMutation = gql`
  mutation UpdateJoon3Contact($contact: Joon3ContactUpdateInput!) {
    updateJoon3Contact(contact: $contact) {
      contact ${Joon3ContactAttributes}
    }
  }
`;

export const deleteJoon3ContactMutation = gql`
  mutation DeleteJoon3Contact($_id: ID!) {
    deleteJoon3Contact(_id: $_id) {
      deletedId
    }
  }
`;
