import { debounceTime, switchMap, takeUntil, skip } from 'rxjs/operators';

const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args) =>
      selector(...args).pipe(takeUntil(source$.pipe(skip(1)))),
    ),
  );

export default autocomplete;
