import React from 'react';
import { Spin } from 'antd';
import settings from '../settings';

const AppLoading = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Spin spinning size="default" />
        <div
          style={{
            color: settings.colors.primary,
            marginTop: 16,
            fontSize: 16,
          }}
        >
          Loading ...
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
