import React, { useEffect, useMemo } from 'react';
import useOne from '../../../../shared/use-one';
import {
  extractAudit,
  extractConfig,
  joon3AuditQuery,
  joon3ConfigQuery,
  SYSTEM_DEFAULT_CONFIG_ID,
  tenantJoon3AuditQuery,
  tenantJoon3ConfigQuery,
} from './constants';
import { updateJoon3ConfigAction } from '../../../../redux-store/joon3-configs-store';
import GeneralSettingsTable from './GeneralSettingsTable';
import { notification } from 'antd';
import useJoon3ConfigUpdates from './use-joon3-config-updates';
import useFilterJoon3Config from './use-filter-joon3-config';
import FallDetectionSettingsTable from './FallDetectionSettingsTable';
import EnvironmentSettingsTable from './EnvironmentSettingsTable';
import CallCenterSettingsTable from './CallCenterSettingsTable';
import OnDeviceSettingsTable from './OnDeviceSettingsTable';
import UserSettingsTable from './UserSettingsTable';
import { updateJoon3AuditAction } from '../../../../redux-store/joon3-audits-store';
import useJoon3AuditUpdates from './use-joon3-audit-updates';
import useFilterJoon3Audit from './use-filter-joon3-audit';
import AuditStatusSection from './AuditStatusSection';
import FirmwareStatus from './FirmwareStatus';
import useIsTenantAdmin from '../use-is-tenant-admin';

function SettingsV2Tab({ joon3 }) {
  const isTenantAdmin = useIsTenantAdmin();
  const configQuery = useMemo(() => {
    if (isTenantAdmin) {
      return tenantJoon3ConfigQuery;
    }
    return joon3ConfigQuery;
  }, [isTenantAdmin]);
  const auditQuery = useMemo(() => {
    if (isTenantAdmin) {
      return tenantJoon3AuditQuery;
    }
    return joon3AuditQuery;
  }, [isTenantAdmin]);
  const fetchResult1 = useOne(
    configQuery,
    (data) => extractConfig(data, isTenantAdmin),
    updateJoon3ConfigAction,
    'joon3Configs',
    joon3._id,
    joon3._id,
  );
  const fetchResult2 = useOne(
    configQuery,
    (data) => extractConfig(data, isTenantAdmin),
    updateJoon3ConfigAction,
    'joon3Configs',
    joon3.tenantId,
    joon3.tenantId,
  );
  const fetchResult3 = useOne(
    configQuery,
    (data) => extractConfig(data, isTenantAdmin),
    updateJoon3ConfigAction,
    'joon3Configs',
    SYSTEM_DEFAULT_CONFIG_ID,
    SYSTEM_DEFAULT_CONFIG_ID,
  );
  const fetchResult4 = useOne(
    auditQuery,
    (data) => extractAudit(data, isTenantAdmin),
    updateJoon3AuditAction,
    'joon3Audits',
    joon3._id,
    joon3._id,
  );

  useJoon3ConfigUpdates(joon3._id, isTenantAdmin);
  useJoon3ConfigUpdates(joon3.tenantId, isTenantAdmin);
  useJoon3ConfigUpdates(SYSTEM_DEFAULT_CONFIG_ID, isTenantAdmin);
  useJoon3AuditUpdates(joon3._id, isTenantAdmin);
  const deviceConfig = useFilterJoon3Config(joon3._id);
  const tenantConfig = useFilterJoon3Config(joon3.tenantId);
  const systemConfig = useFilterJoon3Config(SYSTEM_DEFAULT_CONFIG_ID);
  const audit = useFilterJoon3Audit(joon3._id);
  const { loading: loading1, error: error1 } = fetchResult1;
  const { loading: loading2, error: error2 } = fetchResult2;
  const { loading: loading3, error: error3 } = fetchResult3;
  const { loading: loading4, error: error4 } = fetchResult4;
  const loading = loading1 || loading2 || loading3 || loading4;
  useEffect(() => {
    if (error1 || error2 || error3 || error4) {
      notification.error({
        description: 'There was an error fetching configuration',
        message: 'Error',
      });
    }
  }, [error1, error2, error3, error4]);

  return (
    <>
      {audit && <AuditStatusSection audit={audit} />}
      <GeneralSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <CallCenterSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <OnDeviceSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <FallDetectionSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <UserSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <EnvironmentSettingsTable
        deviceConfig={deviceConfig}
        tenantConfig={tenantConfig}
        systemConfig={systemConfig}
        audit={audit}
        loading={loading}
        isDevice={true}
        isTenantAdmin={isTenantAdmin}
      />
      <FirmwareStatus joon3={joon3} />
    </>
  );
}

export default SettingsV2Tab;
