export const READ_JOON_DEVICE_CONFIGS = 'READ_JOON_DEVICE_CONFIGS';
export const CREATE_JOON_DEVICE_CONFIG = 'CREATE_JOON_DEVICE_CONFIG';
export const UPDATE_JOON_DEVICE_CONFIG = 'UPDATE_JOON_DEVICE_CONFIG';
export const DELETE_JOON_DEVICE_CONFIG = 'DELETE_JOON_DEVICE_CONFIG';
export const BULK_CREATE_JOON_DEVICE_CONFIGS =
  'BULK_CREATE_JOON_DEVICE_CONFIGS';

export const readJoonDeviceConfigsAction = (
  // eslint-disable-next-line no-shadow
  joonDeviceConfigs,
  queryId = 'default',
) => ({
  type: READ_JOON_DEVICE_CONFIGS,
  payload: {
    joonDeviceConfigs,
    queryId,
  },
});

export const createJoonDeviceConfigAction = (
  joonDeviceConfig,
  queryId = 'default',
) => ({
  type: CREATE_JOON_DEVICE_CONFIG,
  payload: {
    joonDeviceConfig,
    queryId,
  },
});

export const bulkCreateJoonDeviceConfigsAction = (
  // eslint-disable-next-line no-shadow
  joonDeviceConfigs,
  queryId = 'default',
) => ({
  type: BULK_CREATE_JOON_DEVICE_CONFIGS,
  payload: {
    joonDeviceConfigs,
    queryId,
  },
});

export const updateJoonDeviceConfigAction = (
  joonDeviceConfig,
  queryId = 'default',
) => ({
  type: UPDATE_JOON_DEVICE_CONFIG,
  payload: {
    joonDeviceConfig,
    queryId,
  },
});

export const deleteJoonDeviceConfigAction = (
  joonDeviceConfigId,
  queryId = 'default',
) => ({
  type: DELETE_JOON_DEVICE_CONFIG,
  payload: {
    joonDeviceConfigId,
    queryId,
  },
});

const initialJoonDeviceConfigs = { default: {} };

export function joonDeviceConfigs(state = initialJoonDeviceConfigs, action) {
  switch (action.type) {
    case READ_JOON_DEVICE_CONFIGS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.joonDeviceConfigs.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_JOON_DEVICE_CONFIG:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDeviceConfig._id]:
            action.payload.joonDeviceConfig,
        },
      };
    case BULK_CREATE_JOON_DEVICE_CONFIGS:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          ...action.payload.joonDeviceConfigs.reduce((prev, curr) => {
            prev[curr._id] = curr;
            return prev;
          }, {}),
        },
      };
    case UPDATE_JOON_DEVICE_CONFIG: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.joonDeviceConfig._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.joonDeviceConfig._id]: {
            ...current,
            ...action.payload.joonDeviceConfig,
          },
        },
      };
    }
    case DELETE_JOON_DEVICE_CONFIG: {
      const { joonDeviceConfigId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [joonDeviceConfigId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
