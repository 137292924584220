import { Form, Input, Slider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from '../../../shared/autocomplete';
import useQueryParams from '../../../shared/use-query-params';
import useChangeFilters from '../use-change-filters';
import { getArrayFromUrlFilters } from './utils';
import { notNullOrUndefined } from '../../../shared/utils';

function MySliderFilter({ name, label, minValue, maxValue, marks, isLog }) {
  const [filter, setFilter] = useState([minValue, maxValue]);
  const urlFilters = useQueryParams();
  const _urlFilters = useRef(urlFilters);

  useEffect(() => {
    _urlFilters.current = urlFilters;
  }, [urlFilters]);

  const last = useRef();
  useEffect(() => {
    const nextV = getArrayFromUrlFilters(urlFilters, name);
    if (JSON.stringify(nextV) !== JSON.stringify(last.current)) {
      last.current = nextV;
      try {
        let start = notNullOrUndefined(nextV[0])
          ? nextV[0]
          : isLog
          ? Math.pow(10, minValue / 1000)
          : minValue;
        let end = notNullOrUndefined(nextV[1])
          ? nextV[1]
          : isLog
          ? Math.pow(10, maxValue / 1000)
          : maxValue;

        if (isLog) {
          setFilter([
            Math.log10(parseFloat(start)) * 1000,
            Math.log10(parseFloat(end)) * 1000,
          ]);
        } else {
          setFilter([parseFloat(start), parseFloat(end)]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [urlFilters, name, isLog, minValue, maxValue]);
  const term$ = useRef();
  const changeFilters = useChangeFilters();
  useEffect(() => {
    term$.current = new BehaviorSubject('__undefined__');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          if (term !== '__undefined__') {
            const [start, end] = term;
            let nextVal;
            if (isLog) {
              nextVal = [Math.pow(10, start / 1000), Math.pow(10, end / 1000)];
            } else {
              nextVal = [start, end];
            }
            return of(
              changeFilters({
                ..._urlFilters.current,
                [name]: nextVal,
              }),
            );
          }
          return of();
        }),
      )
      .subscribe();
  }, [changeFilters, name, isLog]);
  return (
    <div style={{ paddingRight: 16, paddingLeft: 16 }}>
      <div>{label}</div>
      <Slider
        range={{ draggableTrack: true }}
        tooltip={{
          formatter: (val) => {
            if (isLog) {
              return Math.pow(10, val / 1000.0).toFixed(1);
            } else {
              return val.toFixed(1);
            }
          },
        }}
        min={minValue}
        max={maxValue}
        defaultValue={[minValue, maxValue]}
        marks={marks}
        value={filter}
        onChange={(term) => {
          term$.current.next(term);
          setFilter(term);
        }}
      />
    </div>
  );
}

export default MySliderFilter;
