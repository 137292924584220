import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  EventLevels,
  JoonDeviceEventTypes,
  JoonLocationTypes,
} from '../../constants';
import humanizeDuration from 'humanize-duration';
import { getFormattedPhoneNumber } from '../contacts/utils';
import { notNullOrUndefined } from '../settings-v2/utils';

function exportEventsToCsv(deviceId, events, locale) {
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'long',
  });
  const timeFormat = new Intl.DateTimeFormat(locale, {
    timeStyle: 'medium',
  });
  const byteFormat = Intl.NumberFormat(locale, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });
  console.log('events', events);

  const data = events.map((event) => {
    return {
      'Event ID': event._id,
      'Event Type': event.type,
      'Event Type Label': JoonDeviceEventTypes[event?.type]?.label,
      'Event Level': event.level,
      'Event Level Label': EventLevels[event?.level]?.label,
      'Timestamp (ms)': event.timestampMs,
      'Timestamp Formatted': dateFormat.format(event?.timestampMs),
      'Location Type': event.loc?.type,
      'Location Type Label': JoonLocationTypes[event.loc?.type]?.label,
      'Location Latitude': event.loc?.lat,
      'Location Longitude': event.loc?.lon,
      'Location Accuracy': event.loc?.acc,
      'Location Accuracy Formatted':
        event.loc?.acc &&
        new Intl.NumberFormat('en-US', {
          style: 'unit',
          unit: 'meter',
        }).format(event.loc.acc),
      'Signal Strength (RSRP) (dBm)': event.signalLevel,
      'Battery Percent (%)': event.batteryLevel,
      'Step Count (Since Midnight)': event.stepsSinceMidnight,
      'Message Sent': timeFormat.format(event.sentAt),
      'Message Received': timeFormat.format(event.receivedAt),
      EARFNC: event.earfcn,
      'Cellular RX Bytes': event.mRxBytes && byteFormat.format(event.mRxBytes),
      'Cellular TX Bytes': event.mTxBytes && byteFormat.format(event.mTxBytes),
      'Cellular Bytes Period':
        notNullOrUndefined(event.mBytesMs) &&
        humanizeDuration(event.mBytesMs, {
          largest: 1,
          round: true,
        }),
      'Time on since midnight':
        notNullOrUndefined(event.onSinceMMs) &&
        humanizeDuration(event.onSinceMMs, {
          largest: 1,
          round: true,
        }),
      'Time charging since midnight':
        notNullOrUndefined(event.onChargerSinceMMs) &&
        humanizeDuration(event.onChargerSinceMMs, {
          largest: 1,
          round: true,
        }),
      'Screen on time since midnight':
        notNullOrUndefined(event.screenOnSinceMMs) &&
        humanizeDuration(event.screenOnSinceMMs, {
          largest: 1,
          round: true,
        }),
      'Time since boot':
        notNullOrUndefined(event.onChargerSinceMMs) &&
        humanizeDuration(event.onChargerSinceMMs, {
          largest: 1,
          round: true,
        }),
      Timezone: event.timezone,
      'Is charging': notNullOrUndefined(event.isCharging)
        ? event.isCharging
          ? 'Yes'
          : 'No'
        : undefined,
      'Is in Turbo Mode': notNullOrUndefined(event.isTurboMode)
        ? event.isTurboMode
          ? 'Yes'
          : 'No'
        : undefined,
      'Current Wi-Fi SSID': event.wifiSsid,
      IMEI: event.deviceId,
      ICCID: event.iccid,
      MSISDN: getFormattedPhoneNumber(event),
      'FW Version': event.fwVer,
      'App Version': event.appVer,
      'App Flavor': event.appFlavor,
      'API Version': event.commVer,
      'Configuration Version': event.confVer,
      'Call Center Settings Version': event.j3ConfVers?.callCenter,
      'Environment Settings Version': event.j3ConfVers?.environment,
      'Fall Detection Settings Version': event.j3ConfVers?.fallDetection,
      'General Settings Version': event.j3ConfVers?.general,
      'Device Settings Version': event.j3ConfVers?.onDevice,
      'User Settings Version': event.j3ConfVers?.user,
      'User Settings - Shake-to-wake': notNullOrUndefined(
        event.userSettings?.shakeToWake,
      )
        ? event.userSettings?.shakeToWake
          ? 'Enabled'
          : 'Disabled'
        : undefined,
      'User Settings - Lockdown Mode': notNullOrUndefined(
        event.userSettings?.lockdownModeEnabled,
      )
        ? event.userSettings?.lockdownModeEnabled
          ? 'Enabled'
          : 'Disabled'
        : undefined,
      'User Settings - Screen Brightness': event.userSettings?.screenBrightness,
      'User Settings - Call Volume': event.userSettings?.callVolume,
      'User Settings - Ring Volume': event.userSettings?.ringVolume,
      'User Settings - Use Metric': notNullOrUndefined(
        event.userSettings?.useMetric,
      )
        ? event.userSettings?.useMetric
          ? 'Yes'
          : 'No'
        : undefined,
      'Power-off reason': event.powerOffReason,
      'Extra Notes': event.notes,
      Contact: event.contact?.name,
      Users:
        event.users?.length > 0
          ? event.users.map((u) => u.name).join(', ')
          : undefined,
      Tenant: event.tenant?.name,
      'Initiated by': event.initiatedBy?.name,
    };
  });

  const options = mkConfig({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    useTextFile: false,
    useBom: true,
    filename: `device-${deviceId}-events`,
    showColumnHeaders: true,
    useKeysAsHeaders: true,
  });

  const csv = generateCsv(options)(data);
  download(options)(csv);
}

export default exportEventsToCsv;
