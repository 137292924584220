import { useMutation } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteJoon3ContactMutation } from './constants';
import { getFormattedPhoneNumber } from './utils';

const { Title, Text, Paragraph } = Typography;

const DeleteJoon3ContactModal = ({ visible, onSuccess, onCancel }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { contact } = visible || {};

  const [deleteJoon3Contact] = useMutation(deleteJoon3ContactMutation);
  const dispatch = useDispatch();

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await deleteJoon3Contact({
        variables: {
          _id: contact._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Device contact deleted',
      });
      if (onSuccess) onSuccess();
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [deleteJoon3Contact, contact, onSuccess]);

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Delete Device Contact
        </Title>

        <Paragraph style={{ textAlign: 'center' }}>
          {`Are you sure you want to delete this device contact?`}
        </Paragraph>
        {contact && (
          <Paragraph style={{ textAlign: 'center' }}>
            {`${contact.name} (${getFormattedPhoneNumber(contact.phone)})`}
          </Paragraph>
        )}
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button danger onClick={handleFinish} type="primary">
            Delete
          </Button>
        </div>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default DeleteJoon3ContactModal;
