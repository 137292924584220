import { Button, Space, Table, Tooltip } from 'antd';
import React from 'react';
import EditSomeSettingModal from './edit-modals/EditSomeSettingModal';
import { ConfigSources } from './utils';
import { AlertOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DeleteSomeSettingButton from './edit-modals/DeleteSomeSettingButton';

function SettingsCategoryTable({
  systemConfig,
  tenantConfig,
  deviceConfig,
  tableData,
  loading,
}) {
  return (
    <Table
      dataSource={tableData}
      loading={loading}
      pagination={false}
      style={{ width: '100%', marginBottom: 32 }}
      rowKey="key"
    >
      <Table.Column title="Name" dataIndex="label" key="label" />
      <Table.Column
        title="Configuration Source"
        dataIndex="src"
        key="src"
        render={(text, record) =>
          record.src && ConfigSources[record.src]?.label
        }
      />
      <Table.Column
        title="Value"
        dataIndex="value"
        key="value"
        render={(text, record) => {
          if (record.component) {
            return record.component;
          }
          return record.value;
        }}
      />
      {!!deviceConfig && (
        <Table.Column
          title=""
          dataIndex="error"
          key="error"
          render={(text, record) => {
            if (record.error) {
              return (
                <Tooltip title={record.error}>
                  <AlertOutlined />
                </Tooltip>
              );
            }
            return null;
          }}
        />
      )}
      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            {record.isEditable && record.editModal && (
              <EditSomeSettingModal
                systemConfig={systemConfig}
                tenantConfig={tenantConfig}
                deviceConfig={deviceConfig}
              >
                {record.editModal}
              </EditSomeSettingModal>
            )}
            {record.viewLink && (
              <Tooltip title={record.viewLink.label}>
                <Link to={record.viewLink.link}>
                  <Button icon={<EyeOutlined />} size="medium" />
                </Link>
              </Tooltip>
            )}
            {record.deleteProps && (
              <DeleteSomeSettingButton
                systemConfig={systemConfig}
                tenantConfig={tenantConfig}
                deviceConfig={deviceConfig}
                {...record.deleteProps}
              />
            )}
          </Space>
        )}
      />
    </Table>
  );
}

export default SettingsCategoryTable;
