import React, { useMemo } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { useParams, Link, useHistory } from 'react-router-dom';
import useOne from '../../shared/use-one';
import { updateTenantAction } from '../../redux-store/tenants-store';
import { Button, List, Skeleton, Tabs, Typography } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import PhotoCircle from '../../components/PhotoCircle';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../settings';
import SettingsTab from './SettingsTab';
import { tenantQuery } from './constants';

const { Text, Title, Paragraph } = Typography;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text
          style={{
            color: settings.colors.primary,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 16 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

function ViewTenant() {
  const { _id, tab } = useParams();
  const queryId = 'view-tenant';
  const { data: tenant, loading } = useOne(
    tenantQuery,
    (data) => data && data.tenant,
    updateTenantAction,
    'tenants',
    _id,
    queryId,
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dashboardFields = useMemo(() => {
    if (tenant && !loading) {
      return [
        {
          title: 'Name',
          value: tenant.name,
        },
        {
          title: 'Created',
          value: new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(new Date(tenant.createdAt)),
        },
      ].filter((a) => a.value !== undefined || a.component !== undefined);
    }
  }, [loading, tenant, locale]);
  const title = tenant ? tenant.name : 'View Tenant';
  const history = useHistory();
  return (
    <>
      <CSPage title={title} containerStyle={{ maxWidth: 'unset' }}>
        <CSPageHeader
          titleComponent={
            tenant ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PhotoCircle
                    diameter={100}
                    color={settings.colors.primary}
                    imageUrl={tenant.primaryThumbnailUrl}
                    textStyle={{ color: 'white', fontSize: 24 }}
                    initials={
                      tenant &&
                      tenant.name
                        .split()
                        .map((p) => p[0])
                        .join()
                    }
                  />
                  <Title
                    className="cs-header-title"
                    style={{ marginBottom: 8, marginLeft: 16 }}
                  >
                    {tenant.name}
                  </Title>
                </div>
                <Link to={`/tenant/edit/${_id}`}>
                  <Button icon={<EditOutlined />}>Edit Tenant</Button>
                </Link>
              </div>
            ) : (
              <Title className="cs-header-title" style={{ marginBottom: 8 }}>
                {title}
              </Title>
            )
          }
          backActions={[
            <Link to="/tenants" key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to Tenants
              </Button>
            </Link>,
          ]}
        />
        <div
          style={{
            border: `1px solid ${settings.colors.borderGray}`,
            borderRadius: 24,
            padding: '16px 24px',
            marginBottom: 16,
          }}
        >
          {loading && <Skeleton active paragraph title />}
          <List
            dataSource={dashboardFields}
            renderItem={(item) => (
              <DisplayField
                title={item.title}
                value={item.value}
                component={item.component}
              />
            )}
            loading={loading}
            locale={{
              emptyText: <div />,
            }}
            grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
          />
          <div>
            <Text
              style={{
                color: settings.colors.primary,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Notes
            </Text>
            <Paragraph>{tenant?.notes || 'Nothing yet.'}</Paragraph>
          </div>
        </div>
        <Tabs
          activeKey={tab}
          items={[
            tenant && {
              key: 'settings',
              label: 'Tenant Settings',
              children: <SettingsTab tenant={tenant} />,
            },
          ].filter((t) => t)}
          onChange={(key) => history.push(`/tenant/view/${_id}/${key}`)}
        />
      </CSPage>
    </>
  );
}

export default ViewTenant;
